import { ReactNode, useContext } from 'react';
import cx from 'classnames';
import { LocalizationContext } from 'Services/LocalizationService';
import { DeviceServiceContext } from 'Services/DeviceService';
import removeDiacritics from 'Helpers/removeDiacritics';
import VinistoLink from 'Components/VinistoLink';
import Skeleton from 'react-loading-skeleton';

type TBundleProducerProps = {
	flag: ReactNode;
	name: string;
	url?: string;
	isLoading?: boolean;
	openInNewTab?: boolean;
	className?: string;
	isLink?: boolean;
};

const BundleProducer = ({
	flag,
	name,
	url,
	isLoading = false,
	openInNewTab,
	className,
	isLink = true,
}: TBundleProducerProps) => {
	const deviceContext = useContext(DeviceServiceContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const producerUrl = url ?? removeDiacritics(`${name}`)?.replace(/\s/g, '+');
	const producerRoute = `/${t({
		id: 'routes.products.route',
	})}/Vyrobce/${producerUrl}`;

	return isLoading ? (
		<Skeleton
			width="100%"
			containerClassName={cx(
				'vinisto-wine__variety',
				'vinisto-wine__variety--loading',
				className
			)}
			inline
		/>
	) : isLink ? (
		<VinistoLink
			to={isLoading ? '#' : producerRoute}
			className={cx('vinisto-wine__variety', className)}
			target={
				openInNewTab && !(deviceContext.isMobile || deviceContext.isTablet)
					? '_blank'
					: undefined
			}
		>
			{flag}
			<span className="vinisto-wine__variety__text">{name}</span>
		</VinistoLink>
	) : (
		<div className={cx('vinisto-wine__variety', className)}>
			{flag}
			<span className="vinisto-wine__variety__text">{name}</span>
		</div>
	);
};

export default BundleProducer;
