import { useContext } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { LocalizationContext } from 'Services/LocalizationService';

import styles from './styles.module.css';

interface BundleTagProps {
	tagDetail: any;
	isLink?: boolean;
	view?: string; // kept for compatibility with old code
	className?: string;
}

const BundleTag = ({ tagDetail, isLink = true, className }: BundleTagProps) => {
	const t = useContext(LocalizationContext).useFormatMessage();

	const getLocalizedValue = useLocalizedValue();

	const tagName = getLocalizedValue(tagDetail?.name ?? '');

	const tagUrl = getLocalizedValue(tagDetail?.url ?? []);

	const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>) =>
		event.stopPropagation();

	return isLink ? (
		<Link
			to={`/${t({ id: 'routes.tag.route' })}/${tagUrl}`}
			onClick={handleOnClick}
			className={cx(styles.bundleLabel, className)}
			style={{
				backgroundColor: tagDetail?.color ?? 'red',
			}}
		>
			{tagName}
		</Link>
	) : (
		<div
			className={styles.bundleLabel}
			style={{
				backgroundColor: tagDetail?.color ?? 'red',
			}}
		>
			{tagName}
		</div>
	);
};

export default BundleTag;
