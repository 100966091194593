import { useCallback } from 'react';
import { dayjsInstance as dayjs } from 'Services/Date';

const DEFAULT_FORMAT = 'L'; // Month numeral, day of month, year

const useLocalizedDateTime = () => {
	return useCallback(
		(date: number, format: string = DEFAULT_FORMAT) =>
			dayjs(date).format(format),
		[]
	);
};

export default useLocalizedDateTime;
