import * as React from 'react';
import ApiService from 'Services/ApiService';
import { NotificationsContext } from 'Services/NotificationService';
import { LocalizationContext } from 'Services/LocalizationService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { OrderContext } from 'Services/OrderService/context';
import { DEFAULT_COUNTRY_CODE } from 'Services/OrderService/constants';
import {
	VinistoOrderDllModelsApiDeliveryDeliveryGetByConditionsParameters,
	VinistoOrderDllModelsApiReturnDataDeliveriesReturn,
} from 'vinisto_api_client/src/api-types/order-api';

import { ERRORS } from './constants';
import { IOrderDeliveryData } from './interfaces';

const useDeliveryDetail = (deliveryId: string) => {
	const [deliveryDetail, setDeliveryDetail] =
		React.useState<IOrderDeliveryData>({
			isLoaded: false,
			data: {},
			deliveryPrice: null,
		});

	const apiService = React.useMemo(() => new ApiService(), []);
	const authenticationContext = React.useContext(AuthenticationContext);
	const localizationContext = React.useContext(LocalizationContext);
	const notificationsContext = React.useContext(NotificationsContext);
	const orderContext = React.useContext(OrderContext);

	React.useEffect(() => {
		if (!deliveryId) {
			setDeliveryDetail({
				isLoaded: true,
				data: {},
				deliveryPrice: null,
			});
			return;
		}
		setDeliveryDetail({
			isLoaded: false,
			data: {},
			deliveryPrice: null,
		});
		if (!deliveryId) return;
		const authorizationParams = authenticationContext?.isLoggedIn
			? {
					userLoginHash: authenticationContext?.vinistoUser?.loginHash,
			  }
			: {
					anonymousUser: authenticationContext?.anonymousUID?.anonymousUserId,
			  };

		apiService
			.post<VinistoOrderDllModelsApiReturnDataDeliveriesReturn>(
				`order-api/deliveries/GetDeliveriesByBasket`,
				{
					language: localizationContext?.activeLanguageKey,
					currency: localizationContext?.activeCurrency?.currency,
					allowedCountry: DEFAULT_COUNTRY_CODE,
					...authorizationParams,
				} as VinistoOrderDllModelsApiDeliveryDeliveryGetByConditionsParameters,
				true
			)
			.then((response) => {
				const selectedDelivery = response?.deliveries
					?.filter(({ isActive }) => isActive)
					?.find(({ id }) => id === deliveryId);

				const deliveryPrice = selectedDelivery?.prices?.[0] ?? null;

				if (!selectedDelivery || !deliveryPrice) {
					throw new Error(ERRORS.DELIVERY_NO_LONGER_AVAILABLE);
				}

				setDeliveryDetail({
					isLoaded: true,
					data: selectedDelivery,
					deliveryPrice,
				});
			})
			.catch((err) => {
				orderContext.clearDeliveryPayment();
				if (err?.message === ERRORS.DELIVERY_NO_LONGER_AVAILABLE) {
					notificationsContext.handleShowErrorNotification(
						'notification.message.orderOverview.deliveryNoLongerAvailable'
					);
				} else {
					notificationsContext.handleShowErrorNotification(
						'notification.message.orderOverview.deliveryLoadError'
					);
				}
				setDeliveryDetail({
					isLoaded: true,
					data: {},
					deliveryPrice: null,
				});
			});
	}, [deliveryId, notificationsContext]);

	return deliveryDetail;
};

export default useDeliveryDetail;
