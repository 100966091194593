import cx from 'classnames';

import MobileMenuLink, { IMobileMenuLinkProps } from '../Link';

import styles from './styles.module.css';

/**
 * Styled Mobile Menu Link that navigates inside mobile menu instead of browser router
 */
export const MobileMenuItemLink = ({
	children,
	to,
	hasSubmenu,
	...rest
}: IMobileMenuLinkProps) => {
	return (
		<MobileMenuLink
			to={to}
			className={cx(styles.menuItemLink, {
				[styles.menuItemLinkNoAfter]: !hasSubmenu,
			})}
			hasSubmenu={hasSubmenu}
			{...rest}
		>
			{children}
		</MobileMenuLink>
	);
};

export default MobileMenuItemLink;
