import CarouselSection from 'Components/CarouselSection';
import DeliveryMethods from 'Components/DeliveryMethods';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import BundleTag from 'Components/ProductBox/Components/BundleTag';
import ButtonAddToFavorites from 'Components/ProductBox/Components/ButtonAddToFavorites';
import ProductBasketCount from 'Components/ProductBox/Components/ProductBasketCount';
import { useProductBasketCount } from 'Components/ProductBox/hooks';
import Rating from 'Components/Rating';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import Config from 'Config';
import useIdenticalBundles from 'Hooks/Queries/useIdenticalBundles';
import BannerListProduct from 'Pages/Bundle/Components/BundleDetail/Components/BundleBanner';
import CustomerSupport from 'Pages/Bundle/Components/BundleDetail/Components/CustomerSupport';
import PaymentItem from 'Pages/Bundle/Components/BundleDetail/Components/PaymentItem';
import SellInfo from 'Pages/Bundle/Components/BundleDetail/Components/SellInfo';
import SellerInfo from 'Pages/Bundle/Components/BundleDetail/Components/SellerInfo';
import { BundleDetailProps, IBundleDetailProps } from 'Pages/Bundle/interfaces';
import ArticleTabs from 'Pages/Home/Components/ArticleTabs';
import { BasketContext } from 'Services/BasketService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { DeviceServiceAction } from 'Services/DeviceService/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import cx from 'classnames';
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useMatch } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import useFavoriteItem from 'Components/ProductBox/Components/ButtonAddToFavorites/hook';
import useBundlePrice from 'Hooks/useBundlePrice';
import useCreateGiftMarks from 'vinisto_ui/src/components/gift-progress-bar/hooks';
import { GiftPurchaseListView, SommelierRating } from 'vinisto_ui';
import { ShareProductLink } from 'vinisto_ui';
import { BundleService } from 'vinisto_api_client/src/product-service';
import { useQuery } from '@tanstack/react-query';
import {
	useGiftsForBundle,
	useGiftsOrderPriceFrom,
} from 'Hooks/useGiftProducts';
import SetBundle from 'Pages/Bundle/Components/BundleDetail/Components/SetBundle';
import { SOMMELIER_RATING_ID } from 'Pages/Bundle/constants';
import SpecificationService from 'vinisto_api_client/src/product-service/specification';

import LinkWidget from '../../../Home/Components/LinkWidget';

import BundleBreadcrumb from './Components/BundleBreadcrumb';
import BundleInfoParams from './Components/BundleInfoParams';
import OtherSellerOffers from './Components/OtherSellerOffers';
import Profile from './Components/Profile';
import ReviewSection from './Components/ReviewSection';
import RichText from './Components/RichText';
import ShopControls from './Components/ShopControls';
import {
	BundleItemVariants,
	QuantityBoxVariants,
} from './Components/ShopControls/Components/BundleItem/constants';
import SpecificationExplanationView from './Components/SpecificationExplanationView';
import styles from './styles.module.css';
import { useSpecificationExplanation } from './Components/SpecificationExplanationView/hook';

import {
	VinistoOrderDllModelsApiDeliveryDelivery,
	VinistoOrderDllModelsApiPaymentPayment,
} from '@/api-types/order-api';
import { VinistoProductDllModelsApiBundleBundle } from '@/api-types/warehouse-api';
import { Allowed_Sections } from '@/domain/link-widget/enums';

const BundleDetail = ({
	bundle,
	carouselData,
	categoriesData,
	carouselSupplierData,
	deliveriesData,
	paymentsData,
	refreshData,
}: IBundleDetailProps) => {
	const localizationContext = useContext(LocalizationContext);
	const {
		footerHeight,
		dispatch,
		sellerSelectionFooterHeight,
		isMobile,
		isTablet,
		isDesktop,
	} = useContext(DeviceServiceContext);
	const basketContext = useContext(BasketContext);
	const warehouseContext = useContext(WarehouseContext);
	const t = localizationContext.useFormatMessage();
	const parentRef = useRef<HTMLDivElement>(null);
	const sellerSelectionRef = useRef<HTMLDivElement>(null);
	const [topDistance, setTopDistance] = useState(0);
	const [open, setOpen] = useState(false);
	const bundleTotalRatingCount =
		bundle.bundleEvaluation?.totalEvaluationCount ?? 0;

	const {
		data: identicalBundlesData,
		isLoading: isIdenticalBundlesDataLoading,
	} = useIdenticalBundles(bundle.id);

	const { basketQuantityPopover } = useProductBasketCount(bundle);

	const lastViewedBundles = useMemo(
		() =>
			carouselData?.lastViewedBundles?.filter(
				(bundle) => bundle?.id !== bundle.id
			),
		[bundle.id, carouselData?.lastViewedBundles]
	);

	const [isStickyBarOpen, setIsStickyBarOpen] = useState(false);

	useEffect(() => {
		setIsStickyBarOpen(bundle?.isOnlySupplier ?? false);
	}, [bundle?.isOnlySupplier]);

	const handleOpenGallery = useCallback(() => {
		if ((bundle.bundleImages ?? []).length > 0) {
			setOpen(true);
		}
	}, [bundle]);

	const getBundlePrice = useBundlePrice();
	const { lowestPrice } = getBundlePrice(bundle);

	const { handleAddItemToFavorites, handleRemoveItemFromFavorites } =
		useFavoriteItem(bundle.id, {
			currency: bundle?.prices?.[0]?.currency ?? 'CZK',
			price: lowestPrice,
			item_name: bundle.bundleName,
		});

	useEffect(() => {
		if (parentRef.current) {
			setTopDistance(
				window.pageYOffset + parentRef.current?.getBoundingClientRect()?.top
			);
		}
	}, [parentRef.current]);

	const matchBundleDetail = useMatch(`/${t({ id: 'routes.product.route' })}/*`);

	useEffect(() => {
		if (matchBundleDetail === null) {
			return;
		}

		dispatch([
			DeviceServiceAction.setSellerSelectionFooterHeight,
			(sellerSelectionRef?.current?.offsetHeight as number) + footerHeight,
		]);
	}, [
		footerHeight,
		sellerSelectionFooterHeight,
		isStickyBarOpen,
		matchBundleDetail,
	]);

	const allBundles = isIdenticalBundlesDataLoading
		? []
		: [bundle, ...(identicalBundlesData ?? [])];

	const isQuantityLoading = allBundles?.some(
		(bundle) => warehouseContext.getQuantity(bundle?.id ?? '') === undefined
	);

	const bundlesSortedByAvailabilityAndPrice = allBundles?.sort((a, b) => {
		const aPurchasability = a?.temporaryUnavailable || a?.isGift ? 1 : 0;
		const bPurchasability = b?.temporaryUnavailable || b?.isGift ? 1 : 0;

		const aAvailability = warehouseContext.getQuantity(a?.id ?? '') ? 1 : 0;
		const bAvailability = warehouseContext.getQuantity(b?.id ?? '') ? 1 : 0;

		const aPrice =
			a.prices?.reduce((prev, curr) =>
				(prev.valueWithVat ?? 0) < (curr.valueWithVat ?? 0) ? prev : curr
			)?.valueWithVat ?? 0;
		const bPrice =
			b.prices?.reduce((prev, curr) =>
				(prev.valueWithVat ?? 0) < (curr.valueWithVat ?? 0) ? prev : curr
			)?.valueWithVat ?? 0;

		const aAvailableQuantity = warehouseContext.getQuantity(a?.id ?? '') ?? 0;
		const bAvailableQuantity = warehouseContext.getQuantity(b?.id ?? '') ?? 0;

		return (
			// Sort by purchaseability (temporary unavailable/gift goes down the list)
			aPurchasability - bPurchasability ||
			// Sort by availability
			bAvailability - aAvailability ||
			// Sort by price
			aPrice - bPrice ||
			// Sort by available quantity
			bAvailableQuantity - aAvailableQuantity
		);
	});

	const isRawBundle = (
		bundle:
			| VinistoProductDllModelsApiBundleBundle
			| BundleDetailProps
			| undefined
	): bundle is VinistoProductDllModelsApiBundleBundle =>
		!Object.prototype.hasOwnProperty.call(bundle ?? {}, 'bundleShopControls');

	const cheapestAvailableBundle = isRawBundle(
		bundlesSortedByAvailabilityAndPrice[0]
	)
		? bundlesSortedByAvailabilityAndPrice[0]
		: bundlesSortedByAvailabilityAndPrice[0].bundleShopControls;

	const otherBundles = bundlesSortedByAvailabilityAndPrice
		.slice(1)
		.map((bundle) => (isRawBundle(bundle) ? bundle : bundle.bundleShopControls))
		.filter((bundle) => !bundle.temporaryUnavailable && !bundle.isGift);

	const { data: bundle_gifts } = useQuery(['bundle-gifts', bundle.id], () =>
		BundleService.getBundleGifts(bundle.id)
	);

	const GiftProgressBarMarks = useCreateGiftMarks(
		basketContext.minimalPriceForFreeDelivery,
		basketContext.basketState?.basketPriceWithVat ?? 0,
		basketContext.assignedGifts,
		basketContext.possibleGifts
	);

	const giftPurchaseListViewProducts = useGiftsOrderPriceFrom(
		basketContext.minimalPriceForFreeDelivery,
		basketContext.basketState?.basketPriceWithVat ?? 0,
		basketContext.assignedGifts,
		basketContext.possibleGifts
	);

	const gifts_for_bundle_title = `${t({ id: 'giftInfo.heading.productGift' })}`;
	const gifts_for_bundle = useGiftsForBundle(bundle_gifts);

	const handleScrollToProfile = (isMobile = false) => {
		const reviewSection = document.getElementById(
			'review-section-' + (isMobile ? 'mobile' : 'desktop')
		);
		const scrollOffset = isMobile ? 50 : 150;
		reviewSection &&
			window.scrollTo({
				top: reviewSection.offsetTop - scrollOffset,
				behavior: 'smooth',
			});
	};

	const bundle_set_products = bundle.setBundles ?? [];

	const { data: sommelierRatingSpecificationValues } = useQuery(
		['bundle-specification-values', bundle.id, SOMMELIER_RATING_ID],
		() =>
			SpecificationService.getSpecificationValueForBundle(
				bundle.id,
				SOMMELIER_RATING_ID
			)
	);

	const sommelierRating = sommelierRatingSpecificationValues?.[0]?.value;

	const specificationExplanationData = useSpecificationExplanation(
		bundle.specificationDetails
	);

	return (
		<section
			id="content-wrapper"
			className={styles.section}
		>
			<ContainerFullWidth>
				<BundleBreadcrumb
					categories={categoriesData}
					bundleName={bundle?.bundleName ?? ''}
				/>
			</ContainerFullWidth>
			<Lightbox
				carousel={{
					finite: true,
				}}
				plugins={[Zoom]}
				open={Config.galleryEnabled ? open : false}
				close={() => setOpen(false)}
				styles={{ container: { backgroundColor: 'rgba(#000, .5)' } }}
				slides={bundle?.bundleImages}
			/>
			<ContainerFullWidth>
				<div className={styles.bundle}>
					<div
						className={styles.bundleInfo}
						ref={parentRef}
					>
						<div>
							{(isMobile || isTablet) && (
								<>
									<p className={styles.bundleVariety}>
										{
											<BundleProducer
												flag={bundle?.bundleFlag}
												name={bundle?.bundleProducerName ?? ''}
											/>
										}
										<ShareProductLink
											className="h-100 mb-auto"
											bundleName={bundle.bundleName ?? ''}
											isTabletMobile={isMobile || isTablet}
										/>
									</p>
									<div className={styles.bundleNameWrapper}>
										<p className={styles.bundleName}>
											{bundle?.bundleName ?? ''}
										</p>
									</div>
									<div>
										<div className="d-flex align-center my-2 pe-2">
											{Boolean(
												bundle?.bundleAverageRating && !bundle.isSet
											) && (
												<div className={styles.bundleRating}>
													<Rating
														defaultValue={bundle?.bundleAverageRating ?? 0}
														handleOnClick={() => handleScrollToProfile(true)}
														readOnly
													/>

													<button
														className={styles.bundleRatingCount}
														onClick={() => handleScrollToProfile(true)}
													>
														({bundleTotalRatingCount})
													</button>
												</div>
											)}
											{typeof sommelierRating === 'number' && (
												<SommelierRating
													rating={sommelierRating}
													label={t({ id: 'bundle.sommelierRating' })}
													className="ms-auto"
												/>
											)}
										</div>

										<span className={cx(styles.bundleLabelsMobile)}>
											{bundle?.tagsDetail?.map((tagDetail, index) => (
												<BundleTag
													{...{ tagDetail }}
													key={`tag-desktop-${tagDetail.id ?? index}`}
													className={styles.bundleLabel}
												/>
											))}
										</span>
									</div>
								</>
							)}

							<button
								onClick={handleOpenGallery}
								className={styles.bundleImageWrap}
							>
								<img
									className={styles.bundleImage}
									src={bundle?.bundleImageSmall}
									alt={`${t({
										id: 'alt.bundleImage',
									})}`}
								/>
								{!bundle?.temporaryUnavailable && !bundle?.isGift && (
									<ButtonAddToFavorites
										itemId={bundle.id ?? ''}
										addToFavorites={handleAddItemToFavorites}
										removeItemFromFavorites={handleRemoveItemFromFavorites}
										className={styles.addToFavorites}
										size="bundle"
									/>
								)}
								<ProductBasketCount
									text={basketQuantityPopover}
									className={styles.infoBox}
								/>
							</button>

							{(isMobile || isTablet) && (
								<>
									{bundle?.bundleShortDescription && (
										<p className={styles.bundleShortDescription}>
											{bundle.bundleShortDescription}
										</p>
									)}
									<SellInfo
										supplierName={bundle.supplierName}
										deliveryDate={warehouseContext.deliveryDate}
									/>
									{basketContext?.minimalPriceForFreeDelivery !== null && (
										<div className={styles.giftsWrap}>
											<GiftPurchaseListView
												giftProgressBarProps={GiftProgressBarMarks}
												translations={{
													title: t({ id: 'bundle.giftsTitle' }) as string,
												}}
												giftPurchaseProps={giftPurchaseListViewProducts}
												giftProductsProps={{
													giftProducts: gifts_for_bundle,
													translations: { title: gifts_for_bundle_title },
												}}
											/>
										</div>
									)}
								</>
							)}

							{isDesktop && (
								<>
									<div className={styles.bundleAbout}>
										<h2 className={styles.bundleHeading}>
											{t({ id: 'bundle.aboutBundle' })}
										</h2>

										<RichText
											className={cx(styles.bundleAboutText)}
											content={bundle?.bundleDescription ?? ''}
										/>
									</div>
									<div className={styles.bundleAbout}>
										<h2 className={styles.bundleHeading}>
											{t({ id: 'bundle.bundleProperties' })}
										</h2>
										<div className={styles.bundleParams}>
											<BundleInfoParams
												bundleParams={bundle?.specificationDetails ?? []}
											/>
										</div>
									</div>

									{bundle.isSet && (
										<div className={styles.bundleAbout}>
											<h2 className={styles.bundleHeading}>
												{t({ id: 'bundle.bundleCollection' })}
											</h2>
											<div className={styles.bundleSets}>
												{bundle_set_products.map((bundle_set, index) =>
													bundle_set.setItems.map((setItem) =>
														[...Array(setItem.amount)].map(() => (
															<SetBundle
																{...{ ...bundle_set, setItem: setItem }}
																key={index}
															/>
														))
													)
												)}
											</div>
										</div>
									)}

									<div id="review-section-desktop"></div>
									{(bundle?.totalEvaluationCount ?? 0) > 0 && (
										<Profile
											evaluation={bundle?.bundleEvaluation ?? {}}
											isLoading={false}
										/>
									)}
									<SpecificationExplanationView
										data={specificationExplanationData}
									/>
									{!bundle.isSet && (
										<ReviewSection
											{...{ bundle }}
											isLoading={false}
											refreshBundleData={refreshData}
										/>
									)}
								</>
							)}

							<div className="vinisto-right-info-tablet-mobile">
								{(isMobile || isTablet) && (
									<>
										<BannerListProduct />

										{paymentsData?.length > 0 && (
											<>
												<p className={styles.paymentWrapperHeading}>
													{t({ id: 'productDetail.paymentMethods' })}
												</p>

												<div className="bundle-detail-payment">
													<div className={styles.paymentMethods}>
														{paymentsData?.map(
															(
																payment: VinistoOrderDllModelsApiPaymentPayment
															) => (
																<PaymentItem
																	key={payment?.id}
																	payment={payment}
																/>
															)
														)}
													</div>
												</div>
											</>
										)}

										{deliveriesData?.length > 0 && (
											<DeliveryMethods
												deliveries={
													deliveriesData as VinistoOrderDllModelsApiDeliveryDelivery[]
												}
											/>
										)}

										<CustomerSupport />

										{otherBundles.length > 0 && (
											<OtherSellerOffers otherOffers={otherBundles} />
										)}

										<div className="vinisto-product-detail-beseller-info">
											<SellerInfo />
										</div>

										<h2 className={styles.bundleHeading}>
											{t({ id: 'bundle.aboutBundle' })}
										</h2>
										<div className={styles.bundleAboutText}>
											<RichText
												className={cx(styles.bundleAboutText)}
												content={bundle?.bundleDescription ?? ''}
											/>
										</div>

										<div className={styles.bundleAbout}>
											<h2 className={styles.bundleHeading}>
												{t({ id: 'bundle.bundleProperties' })}
											</h2>
											<div className={styles.bundleParams}>
												<BundleInfoParams
													bundleParams={bundle?.specificationDetails ?? []}
												/>
											</div>
										</div>

										{bundle.isSet && (
											<div className={styles.bundleAbout}>
												<h2 className={styles.bundleHeading}>
													{t({ id: 'bundle.bundleCollection' })}
												</h2>
												<div className={styles.bundleSets}>
													{bundle_set_products.map((bundle_set, index) =>
														bundle_set.setItems.map((setItem) =>
															[...Array(setItem.amount)].map(() => (
																<SetBundle
																	{...{ ...bundle_set, setItem: setItem }}
																	key={index}
																/>
															))
														)
													)}
												</div>
											</div>
										)}

										<div id="review-section-mobile"></div>
										{(bundle?.totalEvaluationCount ?? 0) > 0 && (
											<Profile
												evaluation={bundle?.bundleEvaluation ?? {}}
												isLoading={false}
											/>
										)}

										<SpecificationExplanationView
											data={specificationExplanationData}
										/>

										{!bundle.isSet && (
											<ReviewSection
												{...{ bundle }}
												isLoading={false}
												refreshBundleData={refreshData}
											/>
										)}
									</>
								)}
							</div>
						</div>
					</div>

					{isDesktop && (
						<div className={styles.bundleShopCol}>
							<div
								style={{
									position: 'sticky',
									top: topDistance,
								}}
							>
								<p className={styles.bundleVariety}>
									{
										<BundleProducer
											flag={bundle?.bundleFlag}
											name={bundle?.bundleProducerName ?? ''}
											url={bundle?.bundleProducerUrl ?? ''}
										/>
									}
									<ShareProductLink
										className="h-100 mb-auto mt-1"
										bundleName={bundle.bundleName ?? ''}
										isTabletMobile={isMobile || isTablet}
									/>
								</p>
								<div className={styles.bundleNameWrapper}>
									<p className={styles.bundleName}>
										{bundle?.bundleName ?? ''}
									</p>
								</div>
								<div className="d-flex align-center mb-2 pe-2">
									{Boolean(bundle?.bundleAverageRating && !bundle.isSet) && (
										<div className={styles.bundleRating}>
											<Rating
												defaultValue={bundle?.bundleAverageRating ?? 0}
												handleOnClick={() => handleScrollToProfile()}
												readOnly
											/>
											<button
												className={styles.bundleRatingCount}
												onClick={() => handleScrollToProfile()}
											>
												({bundleTotalRatingCount})
											</button>
										</div>
									)}
									{typeof sommelierRating === 'number' && (
										<SommelierRating
											rating={sommelierRating}
											label={t({ id: 'bundle.sommelierRating' })}
											className="ms-auto"
										/>
									)}
								</div>

								{bundle?.tagsDetail && bundle?.tagsDetail?.length > 0 && (
									<div className={styles.bundleLabels}>
										{bundle?.tagsDetail?.map((tagDetail, index) => (
											<BundleTag
												{...{ tagDetail }}
												key={`tag-desktop-${tagDetail.id ?? index}`}
												className={styles.bundleLabel}
											/>
										))}
									</div>
								)}

								{bundle?.bundleShortDescription && (
									<p className={styles.bundleShortDescription}>
										{bundle.bundleShortDescription}
									</p>
								)}
								<div className="shop-controls">
									<div className={styles.mainShopControls}>
										<ShopControls
											bundlesToShow={cheapestAvailableBundle}
											variant={BundleItemVariants.STANDARD}
											isLoading={
												isQuantityLoading || isIdenticalBundlesDataLoading
											}
											quantityBox={QuantityBoxVariants.STANDARD}
										/>
									</div>

									{basketContext?.minimalPriceForFreeDelivery !== null && (
										<div className={styles.giftsWrap}>
											<GiftPurchaseListView
												giftProgressBarProps={GiftProgressBarMarks}
												translations={{
													title: t({ id: 'bundle.giftsTitle' }) as string,
												}}
												giftPurchaseProps={giftPurchaseListViewProducts}
												giftProductsProps={{
													giftProducts: gifts_for_bundle,
													translations: { title: gifts_for_bundle_title },
												}}
											/>
										</div>
									)}

									<BannerListProduct />

									<div
										className={cx(
											styles.paymentWrapper,
											'bundle-detail-payment'
										)}
									>
										{paymentsData?.length > 0 && (
											<>
												<p className={styles.paymentWrapperHeading}>
													{t({ id: 'productDetail.paymentMethods' })}
												</p>
												<div className={styles.paymentMethods}>
													{paymentsData?.map(
														(
															payment: VinistoOrderDllModelsApiPaymentPayment
														) => (
															<PaymentItem
																key={payment?.id}
																payment={payment}
															/>
														)
													)}
												</div>
											</>
										)}

										{deliveriesData?.length > 0 && (
											<DeliveryMethods
												deliveries={
													deliveriesData as VinistoOrderDllModelsApiDeliveryDelivery[]
												}
											/>
										)}

										<CustomerSupport />
									</div>
									{otherBundles.length > 0 && (
										<OtherSellerOffers otherOffers={otherBundles} />
									)}
									<div className="vinisto-product-detail-beseller-info">
										<SellerInfo />
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</ContainerFullWidth>
			{carouselData?.similarBundles?.length !== 0 && (
				<CarouselSection
					data={carouselData?.similarBundles}
					title={`${t({
						id: 'productDetail.carouselHeading.similarProducts',
					})}`}
					isLoading={Boolean(!carouselData?.similarBundles)}
				/>
			)}
			{carouselData?.manufacturerBundles?.length !== 0 && (
				<CarouselSection
					data={carouselData?.manufacturerBundles}
					title={`${t({
						id: 'productDetail.carouselHeading.manufacturerProducts',
					})}`}
					isLoading={Boolean(!carouselData?.manufacturerBundles)}
				/>
			)}
			{lastViewedBundles?.length !== 0 && (
				<CarouselSection
					data={lastViewedBundles}
					title={`${t({
						id: 'productDetail.carouselHeading.currentlyRecommended',
					})}`}
					isLoading={Boolean(!carouselData?.lastViewedBundles)}
				/>
			)}
			<div className="container">
				<div className="row">
					<ArticleTabs />
				</div>
			</div>
			{carouselSupplierData.data?.bundles?.length !== 0 && (
				<CarouselSection
					data={carouselSupplierData.data?.bundles ?? []}
					title={`${t({
						id: 'productDetail.carouselHeading.supplierProducts',
					})}`}
					isLoading={Boolean(!carouselSupplierData.data?.bundles)}
				/>
			)}
			<div className={styles.quickLinks}>
				<LinkWidget
					section={
						isMobile || isTablet
							? Allowed_Sections.HOMEPAGE_MOBILE
							: Allowed_Sections.HOMEPAGE_DESKTOP
					}
				/>
			</div>
			<div
				className="vinisto-user-section__menu vinisto-user-section__menu--mobile tablet-mobile-only show position-fixed"
				style={{ bottom: `${footerHeight}px` }}
				ref={sellerSelectionRef}
			>
				<nav className="navbar navbar-dark p-0">
					{(isMobile || isTablet) && (
						<div
							className={cx(styles.sellers, styles.isOnlySeller, {
								reset: !(bundle?.isOnlySupplier ?? false),
							})}
						>
							<ShopControls
								bundlesToShow={cheapestAvailableBundle}
								variant={BundleItemVariants.COMPACT}
								isLoading={isQuantityLoading || isIdenticalBundlesDataLoading}
								quantityBox={QuantityBoxVariants.DIRECT}
							/>
						</div>
					)}
				</nav>
			</div>
		</section>
	);
};

export default BundleDetail;
