import { get, isPlainObject, map } from 'lodash-es';
import { LangValuePair } from 'Hooks/useLocalizedValue/interfaces';
import { IQueryArgument } from 'Services/ApiService/interfaces';
import { apiServiceInstance } from 'Services/ApiService';
import {
	BundleCarouselsData,
	BundleData,
	CategoryData,
} from 'Services/Bundle/interfaces';
import BundleAdapter from 'Services/Bundle/adapter';

import {
	AUTOCOMPLETE_API_ENDPOINT,
	AUTOCOMPLETE_LIMIT,
	BUNDLE_DETAIL_URI,
	BUNDLES_URI,
	CATEGORIES_URI,
	CURRENTLY_RECOMMENDED_TAG_ID,
	LIST_API_ENDPOINT,
	SIMILAR_BUNDLES_CAROUSEL_ENDPOINT,
	SUPPLIER_CAROUSEL_ENDPOINT,
	WAREHOUSE_API_ENDPOINT,
} from './constants';

import { VinistoWarehouseDllModelsApiWarehouseItemWarehouseItemQuantityReturn } from '@/api-types/warehouse-api';
import {
	VinistoHelperDllEnumsBundleSortableColumns,
	VinistoHelperDllEnumsLanguage,
	VinistoProductDllModelsApiBundleBundle,
	VinistoProductDllModelsApiBundleBundleCategoriesReturn,
	VinistoProductDllModelsApiBundleBundleDetailCarouselReturn,
	VinistoProductDllModelsApiBundleBundleReturn,
	VinistoProductDllModelsApiBundleBundlesFilterReturn,
	VinistoProductDllModelsApiBundleBundlesGetWithoutLimitParameters,
	VinistoProductDllModelsApiBundleBundlesReturn,
	VinistoProductDllModelsApiCategoryCategoriesReturn,
	VinistoProductDllModelsApiCategoryCategory,
	VinistoProductDllModelsApiCategoryCategoryReturn,
	VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn,
	VinistoProductDllModelsApiHomePageHomePageInterestingCarouselReturn,
} from '@/api-types/product-api';
import {
	DEFAULT_BUNDLE_API_PARAMS,
	DEFAULT_BUNDLE_API_PARAMS_PROCESSED,
} from '@/shared';

const adapter = new BundleAdapter();

const mapApiToBundleEntity = (
	bundle?: VinistoProductDllModelsApiBundleBundle
): BundleData | undefined => {
	if (
		!bundle ||
		!isPlainObject(bundle) ||
		!bundle.id ||
		!bundle.name ||
		!bundle.description
	) {
		return;
	}
	return {
		id: bundle.id,
		name: bundle.name as LangValuePair[],
		description: bundle.description as LangValuePair[],
		shortDescription: bundle.shortDescription as LangValuePair[],
		text: bundle.text ?? [],
		url: bundle.url ?? [],
		images: bundle.images ?? [],
		prices: bundle.prices ?? [],
		isEnabled: bundle?.isEnabled,
		isDeleted: bundle?.isDeleted,
		tags: bundle.tags ?? [],
		tagsDetail: bundle.tagsDetail ?? [],
		categories: bundle.categories ?? [],
		language: bundle.language ?? VinistoHelperDllEnumsLanguage.CZECH,
		alternativeBundles: bundle.alternativeBundles ?? [],
		alternativeBundleObjects: bundle.alternativeBundleObjects ?? [],
		specificationDetails: bundle.specificationDetails ?? [],
		productsDetail: bundle.productsDetail ?? [],
		supplierId: bundle.supplierId ?? '',
		supplier: bundle.supplier ?? null,
		isDeliveryFree: bundle?.isDeliveryFree,
		bundleEvaluation: bundle.bundleEvaluation ?? {},
		scoring: bundle.scoring ?? 0,
		scoringWarehouse: bundle.scoringWarehouse ?? 0,
		scoringDiscount: bundle.scoringDiscount ?? 0,
		scoringAdmin: bundle.scoringAdmin ?? 0,
		isForLogged: bundle.isForLogged,
		temporaryUnavailable: bundle.temporaryUnavailable,
		isSaleOver: bundle.isSaleOver ?? false,
		isGift: bundle.isGift,
		flags: bundle.flags ?? {},
		orderLimitation: bundle.orderLimitation ?? {},
		bundleShopControls: bundle,
		categoriesDetail: bundle.categoriesDetail ?? [],
		isSet: bundle.isSet ?? false,
		setBundles:
			bundle.setBundles?.map((setBundle) => {
				const items = bundle.items.filter((i) => i.itemId === setBundle.id);
				return {
					...setBundle,
					setItems: items,
				};
			}) ?? [],
		items: bundle.items,
	};
};
const mapApiToCategoryEntity = (
	category?: VinistoProductDllModelsApiCategoryCategory | null
): CategoryData | undefined => {
	if (
		!category ||
		!isPlainObject(category) ||
		!category.id ||
		!category.name ||
		!category.description
	) {
		return;
	}
	return {
		id: category.id,
		name: category.name,
		description: category.description,
		url: category.url ?? [],
		images: category.images ?? [],
		type: category.type,
		specificationDetails: category.specificationDetails ?? [],
		metaDescription: category.metaDescription ?? [],
	};
};

const mapApiToBundleCarouselsEntity = (
	bundleCarousels?: VinistoProductDllModelsApiBundleBundleDetailCarouselReturn
): BundleCarouselsData | undefined => {
	if (!bundleCarousels) {
		return;
	}
	return {
		lastViewedBundles: bundleCarousels.lastViewedBundles ?? [],
		similarBundles: bundleCarousels.similarBundles ?? [],
		manufacturerBundles: bundleCarousels.manufacturerBundles ?? [],
	};
};

const getBundlesInCategory = async (categoryId: string) => {
	const requestParams = {
		isCache: false,
		categoryId,
	};
	return apiServiceInstance
		.post(
			LIST_API_ENDPOINT,
			{
				...requestParams,
				limit: 1,
			},
			true
		)
		.then((payload) => {
			const totalCount = get(payload, 'count');
			if (!totalCount) return [];
			return apiServiceInstance
				.post(
					LIST_API_ENDPOINT,
					{
						...requestParams,
						limit: totalCount,
					},
					true
				)
				.then((payload) =>
					map(
						get(payload, 'bundles', []),
						(bundle: VinistoProductDllModelsApiBundleBundle) =>
							mapApiToBundleEntity(bundle)
					).filter((item): item is BundleData => item !== undefined)
				);
		});
};

const getAutocompleteBundles = (value: string) => {
	return apiServiceInstance.getCollection<VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn>(
		AUTOCOMPLETE_API_ENDPOINT,
		[
			{ key: 'searchingNameString', value: value },
			{ key: 'limit', value: AUTOCOMPLETE_LIMIT },
			...DEFAULT_BUNDLE_API_PARAMS_PROCESSED,
		],
		true
	);
};

const getWarehouseItemsQuantities = (bundleIds: string[]) => {
	const queryParams: IQueryArgument[] = bundleIds.map((id) => ({
		key: 'bundleIds',
		value: id,
	}));

	return apiServiceInstance.get<VinistoWarehouseDllModelsApiWarehouseItemWarehouseItemQuantityReturn>(
		WAREHOUSE_API_ENDPOINT,
		true,
		undefined,
		queryParams
	);
};

const getBundleById = (id: string) =>
	apiServiceInstance
		.get<VinistoProductDllModelsApiBundleBundleReturn>(`${BUNDLES_URI}/${id}`)
		.then((payload) => payload.bundle);

const getBundleByUrl = (url: string) => {
	const requestParams = [{ key: 'IsCache', value: true }];

	return apiServiceInstance
		.get<VinistoProductDllModelsApiBundleBundleReturn>(
			`${BUNDLES_URI}/${url}/GetBundleByUrl`,
			true,
			undefined,
			requestParams
		)
		.then((payload) => {
			if (payload.bundle) {
				return adapter.fromApi(payload.bundle);
			} else {
				return undefined;
			}
		});
};

const getIdenticalBundles = async (bundleId: string) => {
	const requestParams = [
		{
			key: 'IsCache',
			value: true,
		},
	];
	return apiServiceInstance
		.get<VinistoProductDllModelsApiBundleBundlesReturn>(
			`${BUNDLES_URI}/${bundleId}/GetIdenticalBundles`,
			true,
			undefined,
			requestParams
		)
		.then((response) => response.bundles);
};

const getAvailableFilters = async (
	req: VinistoProductDllModelsApiBundleBundlesGetWithoutLimitParameters
) => {
	const response =
		await apiServiceInstance.post<VinistoProductDllModelsApiBundleBundlesFilterReturn>(
			`${BUNDLES_URI}/get-available-filters`,
			req,
			true
		);
	return response;
};
const getBundlesCategories = async (
	req: VinistoProductDllModelsApiBundleBundlesGetWithoutLimitParameters
) => {
	const response =
		await apiServiceInstance.post<VinistoProductDllModelsApiCategoryCategoriesReturn>(
			`${BUNDLES_URI}/get-bundles-categories`,
			req,
			true
		);
	return response;
};

const getSupplierBundlesCarousel = async (bundleId: string) => {
	const requestParams = [
		{
			key: 'BundleId',
			value: bundleId,
		},
		{
			key: 'IsCache',
			value: true,
		},
		{
			key: 'Language',
			value: VinistoHelperDllEnumsLanguage.CZECH,
		},
	];
	const response =
		await apiServiceInstance.get<VinistoProductDllModelsApiBundleBundlesReturn>(
			`${SUPPLIER_CAROUSEL_ENDPOINT}`,
			true,
			undefined,
			requestParams
		);
	return response;
};

const getSimilarBundles = async (bundleId: string) => {
	const requestParams = [
		{
			key: 'BundleId',
			value: bundleId,
		},
		{
			key: 'IsCache',
			value: true,
		},
		{
			key: 'Language',
			value: VinistoHelperDllEnumsLanguage.CZECH,
		},
	];
	const response =
		await apiServiceInstance.get<VinistoProductDllModelsApiBundleBundlesReturn>(
			`${SIMILAR_BUNDLES_CAROUSEL_ENDPOINT}`,
			true,
			undefined,
			requestParams
		);
	return response;
};

export type BundleCategoryData = {
	loading: boolean;
	categoryData: Record<string, any>;
};

const getBundleCategories = (bundleId: string): Promise<CategoryData[]> => {
	const requestParams = [
		{
			key: 'IsCache',
			value: true,
		},
		{
			key: 'IsB2b',
			value: false,
		},
	];
	return apiServiceInstance
		.get<VinistoProductDllModelsApiBundleBundleCategoriesReturn>(
			`${BUNDLES_URI}/${bundleId}/categories`,
			true,
			undefined,
			requestParams
		)
		.then((payload) => {
			if (payload?.categories == null) return [];

			const data = payload?.categories.map((category) => {
				return mapApiToCategoryEntity(category);
			}) as CategoryData[];

			return data;
		});
};

const getCategoriesById = (categoryId: string) => {
	const requestParams = [
		{
			key: 'IsCache',
			value: true,
		},
	];
	return apiServiceInstance
		.get<VinistoProductDllModelsApiCategoryCategoryReturn>(
			`${CATEGORIES_URI}/${categoryId}`,
			true,
			undefined,
			requestParams
		)
		.then((payload) => {
			return mapApiToCategoryEntity(payload.category);
		});
};

const getBundleCarousels = (bundleId: string) =>
	apiServiceInstance
		.get<VinistoProductDllModelsApiBundleBundleDetailCarouselReturn>(
			BUNDLE_DETAIL_URI,
			true,
			undefined,
			[
				{ key: 'BundleId', value: bundleId ?? null },
				{ key: 'IsCache', value: true },
			]
		)
		.then((payload) => {
			return mapApiToBundleCarouselsEntity(payload);
		});

const getInterestingCarousel = async () => {
	const response =
		await apiServiceInstance.get<VinistoProductDllModelsApiHomePageHomePageInterestingCarouselReturn>(
			'product-api/home-page/carousels/GetInterestingCarousel',
			false,
			undefined,
			[{ key: 'IsCache', value: true }]
		);
	if (response.isError) return Promise.reject(response.error);

	return response.interestingBundles;
};

const getCurrentlyRecommendedBundles = () =>
	apiServiceInstance.post<VinistoProductDllModelsApiBundleBundlesReturn>(
		'product-api/bundles/GetBundles',
		{
			isInImperialUnits: false,
			isCache: true,
			...DEFAULT_BUNDLE_API_PARAMS,
			isInStock: true,
			tagId: CURRENTLY_RECOMMENDED_TAG_ID,
			sortingColumn: VinistoHelperDllEnumsBundleSortableColumns.SCORING,
			isSortingDescending: true,
		}
	);

/**
 * @deprecated use vinisto_api_client instead, this one should be removed asap
 */
const BundleService = {
	getBundlesInCategory,
	getAutocompleteBundles,
	getBundleById,
	getBundleByUrl,
	getBundleCategories,
	getWarehouseItemsQuantities,
	getIdenticalBundles,
	getAvailableFilters,
	getBundlesCategories,
	getSupplierBundlesCarousel,
	getSimilarBundles,
	getCategoriesById,
	getBundleCarousels,
	getCurrentlyRecommendedBundles,
	getInterestingCarousel,
};

export default BundleService;
