import FilterDropdownArrowIcon from 'Components/Icons/FilterDropdownArrow';
import FilterDropdownArrowOpenedIcon from 'Components/Icons/FilterDropdownArrowOpened';
import Loader from 'Components/View/Loader';
import { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/warehouse-api';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { Suspense, useContext, useMemo, useState } from 'react';

import ShopControls from '../ShopControls';
import {
	BundleItemVariants,
	QuantityBoxVariants,
} from '../ShopControls/Components/BundleItem/constants';

import styles from './styles.module.css';

interface OtherSellerOffersProps {
	otherOffers: VinistoProductDllModelsApiBundleBundle[];
}

const OtherSellerOffers = ({ otherOffers }: OtherSellerOffersProps) => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const { isMobile } = useContext(DeviceServiceContext);
	const currency = useContext(LocalizationContext).activeCurrency?.title;

	const [isExpanded, setIsExpanded] = useState(false);

	const identicalBundlesPriceRange = useMemo(() => {
		const allOtherSellersPrices: number[][] = [];

		otherOffers.forEach((bundle) => {
			allOtherSellersPrices.push(
				bundle?.prices?.map((p) => p?.valueWithVat ?? 0) ?? []
			);
		});

		const otherSellersLowestPrices = allOtherSellersPrices.flatMap((prices) =>
			Math.min(...prices)
		);

		return [
			Math.round(Math.min(...otherSellersLowestPrices)),
			Math.round(Math.max(...otherSellersLowestPrices)),
		];
	}, [otherOffers]);

	const arePricesEqual = useMemo(() => {
		return identicalBundlesPriceRange[0] === identicalBundlesPriceRange[1];
	}, [identicalBundlesPriceRange]);

	return (
		<div className={styles.identicalBundles}>
			<div className={styles.otherSellersHeading}>
				{t(
					{
						id: 'productDetail.otherSellersOffers',
					},
					{ count: otherOffers.length ?? '' }
				)}
			</div>
			{isExpanded ? (
				<div className="vinisto-product-detail-other-sellers-offers">
					<ShopControls
						bundlesToShow={otherOffers}
						variant={BundleItemVariants.STANDARD}
						quantityBox={
							isMobile
								? QuantityBoxVariants.DIRECT
								: QuantityBoxVariants.STANDARD
						}
					/>
				</div>
			) : (
				<>
					<div className={styles.otherSellersPriceRange}>
						{arePricesEqual ? (
							<>
								{identicalBundlesPriceRange[0]} {currency}
							</>
						) : (
							<>
								{identicalBundlesPriceRange[0]} -{' '}
								{identicalBundlesPriceRange[1]} {currency}
							</>
						)}
					</div>
				</>
			)}
			<button
				className={styles.identicalBundlesButton}
				onClick={() => setIsExpanded((prev) => !prev)}
			>
				{t({
					id: isExpanded
						? 'productDetail.otherSellersOffers.collapse'
						: 'productDetail.otherSellersOffers.expand',
				})}
				{!isExpanded ? (
					<Suspense fallback={<Loader blank />}>
						<FilterDropdownArrowIcon className={styles.arrow} />
					</Suspense>
				) : (
					<Suspense fallback={<Loader blank />}>
						<FilterDropdownArrowOpenedIcon className={styles.arrow} />
					</Suspense>
				)}
			</button>
		</div>
	);
};

export default OtherSellerOffers;
