import CarouselSection from 'Components/CarouselSection';
import {
	DocumentHeaderAction,
	OpenGraphItemType,
	TwitterCardType,
} from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import Config from 'Config';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { apiServiceInstance } from 'Services/ApiService';
import {
	VinistoProductDllModelsApiEvaluationEvaluationsReturn,
	VinistoProductDllModelsApiHomePageHomePageCustomCarouselsReturn,
	VinistoProductDllModelsApiHomePageHomePageInspireCarouselReturn,
} from 'vinisto_api_client/src/api-types/product-api';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { DeviceServiceContext } from 'Services/DeviceService';

import ArticleTabs from './Components/ArticleTabs';
import BannerListBottom from './Components/BannerListBottom';
import BannerListTop from './Components/BannerListTop';
import BannerListUSP from './Components/BannerListUSP';
import LinkWidget from './Components/LinkWidget';
import PresentationPanel from './Components/RecensionsPanel';
import { NUM_OF_TOP_CAROUSELS } from './constants';

import { Allowed_Sections } from '@/domain/link-widget/enums';
import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';

const HomePage = () => {
	const { isMobile, isTablet, isDesktop } = useContext(DeviceServiceContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const warehouseContext = useContext(WarehouseContext);
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const getLocalizedValue = useLocalizedValue();

	const inspireCarouselsQuery = useQuery(['inspireCarouses'], async () => {
		const response =
			await apiServiceInstance.get<VinistoProductDllModelsApiHomePageHomePageInspireCarouselReturn>(
				'product-api/home-page/carousels/GetInspireCarousel',
				false,
				undefined,
				[{ key: 'IsCache', value: false }]
			);
		if (response.isError) return Promise.reject(response.error);

		const uniqueIds = Array.from(
			new Set((response.inspireBundles ?? []).map((item) => item?.id))
		).filter((id): id is string => Boolean(id));

		warehouseContext.fetchQuantity(uniqueIds);

		const evaluationsPromises = (response.inspireBundles || []).map((value) => {
			return apiServiceInstance.get<VinistoProductDllModelsApiEvaluationEvaluationsReturn>(
				'product-api/evaluations',
				false,
				undefined,
				[
					{
						key: 'ProductId',
						value: value.items?.[0]?.itemId || '',
					},
					{ key: 'limit', value: 1 },
					{ key: 'SortingColumn', value: 'TIME' },
					{ key: 'IsSortingDescending', value: true },
				]
			);
		});

		const evaluations = await Promise.all(evaluationsPromises);

		return (response.inspireBundles || []).map((bundle, index) => ({
			...bundle,
			evaluations: evaluations[index] || [],
		}));
	});

	const customCarouselsQuery = useQuery(['customCarousels'], async () => {
		const processedParams: any[] = [];
		Object.entries({ ...DEFAULT_BUNDLE_API_PARAMS }).forEach(([key, value]) => {
			processedParams.push({ key: key, value: value });
		});

		const response =
			await apiServiceInstance.get<VinistoProductDllModelsApiHomePageHomePageCustomCarouselsReturn>(
				'product-api/home-page/custom-carousels',
				false,
				undefined,
				[
					{ key: 'IsCache', value: true },
					{ key: 'Limit', value: 10 },
					{ key: 'SortingColumn', value: 'SEQUENCE_NUMBER' },
					...processedParams,
				]
			);

		if (response.isError) return Promise.reject(response.error);

		const uniqueIds = Array.from(
			new Set(
				(response.homePageCustomCarousels ?? [])
					.flatMap((carousel) => carousel.bundles ?? [])
					.map((bundle) => bundle.id)
			)
		).filter((id): id is string => Boolean(id));

		warehouseContext.fetchQuantity(uniqueIds);

		const topCarousels = response.homePageCustomCarousels?.slice(
			0,
			NUM_OF_TOP_CAROUSELS
		);

		const bottomCarousels =
			response.homePageCustomCarousels?.slice(NUM_OF_TOP_CAROUSELS);

		return { topCarousels, bottomCarousels };
	});

	const [isFirstRender, setIsFirstRender] = useState(true);

	useEffect(() => {
		if (!isFirstRender) return;
		setIsFirstRender(false);
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.set,
			value: {
				title: `${t({ id: 'app.title.name' })}`,
				description: `${t({ id: 'app.description' })}`,
				twitterCard: {
					card: TwitterCardType.summary,
					title: Config.domainName,
					description: `${t({ id: 'app.description' })}`,
				},
				openGraph: {
					type: OpenGraphItemType.website,
					title: Config.domainName,
					description: `${t({ id: 'app.description' })}`,
					url: Config.baseUrl,
					image: '', // TODO: missing image from product ppl
					fbAdmins: Config.market.socials.facebookAdmins,
				},
				jsonLd: {
					'@context': 'https://schema.org',
					'@type': 'OnlineStore',
					name: `${t({ id: 'app.title.name' })}`,
					description: `${t({ id: 'app.description' })}`,
					image: [], // TODO: missing image from product ppl
					url: Config.baseUrl,
				},
			},
		});
		//TODO: fixing dep array will cause infinite loop, extract t() usage out of useEffect and test Home page afterwards
	}, [isFirstRender, t]);

	return (
		<>
			<section id="content-wrapper">
				<BannerListTop />
				{(isTablet || isMobile) && (
					<LinkWidget section={Allowed_Sections.HOMEPAGE_MOBILE} />
				)}
				<BannerListUSP />
				<div className="container">
					<div className="row">
						<ArticleTabs />
					</div>
				</div>
				{customCarouselsQuery?.data?.topCarousels?.map((carousel, index) => (
					<CarouselSection
						key={`topCarousels-${carousel?.id}-${index}`}
						data={carousel?.bundles ?? []}
						title={getLocalizedValue(carousel?.name)}
						isLoading={customCarouselsQuery.isLoading}
					/>
				))}
				<BannerListBottom />
				{customCarouselsQuery?.data?.bottomCarousels?.map((carousel, index) => (
					<CarouselSection
						key={`bottomCarousels-${carousel?.id}-${index}`}
						data={carousel?.bundles ?? []}
						title={getLocalizedValue(carousel?.name)}
						isLoading={customCarouselsQuery.isLoading}
					/>
				))}
				<PresentationPanel
					data={inspireCarouselsQuery.data}
					isLoading={inspireCarouselsQuery.isLoading}
				/>

				{isDesktop && (
					<LinkWidget section={Allowed_Sections.HOMEPAGE_DESKTOP} />
				)}
			</section>
			<Outlet />
		</>
	);
};

export default HomePage;
