import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { dayjsInstance as dayjs } from 'Services/Date';
import { CMS_QUERY_TYPES } from 'Services/ApiService/Cms/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import { Author } from 'Services/ApiService/Cms/interfaces';
import BlogService from 'Services/ApiService/Cms/Blog';
import { BlogContext } from 'Pages/Blog/context';
import { WarehouseContext } from 'Services/WarehouseService';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import {
	DocumentHeaderAction,
	OpenGraphItemType,
	TwitterCardType,
} from 'Components/DocumentHeader/constants';
import sanitizeHTML from 'Helpers/sanitizeHTML';
import type { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/warehouse-api';
const fallbackImage = '/assets/images/blog-fallback.webp';
import Config from 'Config';

import BlogPostCard from '../PostCard';
import BlogBreadcrumb from '../Breadcrumb';
import BlogTagList from '../TagList';

import PostLinkedBundles from './LinkedBundles';
import './styles.css';
import styles from './styles.module.css';

const MAX_SUGGESTED_POSTS = 4;

const getUniqueByProperty = (
	arr: VinistoProductDllModelsApiBundleBundle[],
	prop: keyof VinistoProductDllModelsApiBundleBundle
) => {
	const seen = new Set();
	return arr.filter((item) => {
		const val = item[prop];
		if (seen.has(val)) {
			return false;
		}
		seen.add(val);
		return true;
	});
};

const BlogPostDetail = () => {
	const { posts, activePostUrl, activeTagUrl } = useContext(BlogContext);
	const { fetchQuantity } = useContext(WarehouseContext);
	const t = useContext(LocalizationContext).useFormatMessage();
	const navigate = useNavigate();

	const { data, isLoading } = useQuery(
		[`${CMS_QUERY_TYPES.POST}, ${activePostUrl}`],
		() => BlogService.getArticle(activePostUrl ?? '')
	);

	useEffect(() => {
		const bundles = data?.bundleDetails || [];
		const uniqueBundles = getUniqueByProperty(bundles, 'id');
		const bundleIds = uniqueBundles.map(
			(bundle: VinistoProductDllModelsApiBundleBundle) => {
				return bundle.id || '';
			}
		);
		fetchQuantity(bundleIds);
	}, [data?.bundleDetails, fetchQuantity]);

	const documentHeaderContext = useContext(DocumentHeaderContext);
	const pageTitle = `${t(
		{ id: 'app.title.page' },
		{
			title: data?.title,
		}
	)}`;
	const pageDescription = data?.meta;

	useEffect(() => {
		if (data === null) {
			navigate('/404');
		}
	}, [data, navigate]);

	useEffect(() => {
		if (!activePostUrl) return;
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.set,
			value: {
				title: pageTitle,
				description: pageDescription,
				twitterCard: {
					card: TwitterCardType.summary,
					title: pageTitle,
					description: pageDescription,
					image: data?.image ? data?.image?.urls?.thumb_1000 : fallbackImage,
				},
				openGraph: {
					type: OpenGraphItemType.article,
					title: pageTitle,
					description: pageDescription ?? '',
					url: `${Config.baseUrl}${t({
						id: 'routes.blog.route',
					})}/${activePostUrl}`,
					image: data?.image ? data?.image?.urls?.thumb_1000 : fallbackImage,
					fbAdmins: Config.market.socials.facebookAdmins,
				},
			},
		});
	}, [activePostUrl, pageDescription, pageTitle, t]);

	if (isLoading)
		return (
			<div className="container">
				<Skeleton />
			</div>
		);

	const authors = data?.authorDetails
		?.map((author: Author) => author.name)
		.join(', ');
	const sanitizedHTML = sanitizeHTML(data?.content ?? '');
	const suggestedPosts = posts
		?.filter((p) => p.url !== activePostUrl)
		.slice(0, MAX_SUGGESTED_POSTS);

	return (
		<>
			<BlogBreadcrumb
				tagId={data?.tags[0]}
				postTitle={data?.title}
			/>
			<BlogTagList tagId={data?.tags[0]} />
			<div className="container">
				<div className={styles.postDetail}>
					<div className={styles.postWrap}>
						<h1 className={styles.postTitle}>{data?.title}</h1>
						<div className={styles.postInfo}>
							<span>
								{dayjs(data?.publishDate)
									.locale(navigator.language)
									.format('LL')}
							</span>
							<span className={styles.postAuthor}> - {authors} - </span>
							<span>
								{t(
									{ id: 'blog.readingTime' },
									{
										time: data?.readingTime.toString(),
									}
								)}
							</span>
						</div>
						<h6 className={styles.postMeta}>{data?.perex}</h6>
						<div className={styles.postImgWrap}>
							<img
								className={styles.postImg}
								src={
									data?.image ? data?.image?.urls?.thumb_1000 : fallbackImage
								}
								alt={data?.image?.alternativeText ?? ''}
							/>
						</div>
						<div
							className="v-blog-post"
							dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
						></div>
						<PostLinkedBundles
							bundles={data?.bundleDetails ?? []}
							listingType={data?.productType}
							bundlesTitle={data?.productListTitle ?? ''}
						/>
					</div>
					<div className={styles.nextPostsWrap}>
						<h2 className={styles.nextPostsTitle}>
							{t(
								{ id: 'blog.suggestedArticles' },
								{
									topic: activeTagUrl,
								}
							)}
						</h2>
						<div className={styles.nextPosts}>
							{suggestedPosts?.map((post) => (
								<BlogPostCard
									key={post.id}
									post={post}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlogPostDetail;
