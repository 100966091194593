import { AbstractAdapter } from 'Services/ApiService/Adapter';
import {
	VinistoHelperDllEnumsLanguage,
	VinistoProductDllModelsApiBundleBundle,
} from 'vinisto_api_client/src/api-types/product-api';
import { BundleData } from 'Services/Bundle/interfaces';

class BundleAdapter extends AbstractAdapter<
	BundleData,
	VinistoProductDllModelsApiBundleBundle
> {
	fromApi(apiData: VinistoProductDllModelsApiBundleBundle): BundleData {
		return {
			id: apiData.id ?? '',
			name: this.convertMultiLangValue(apiData.name ?? undefined),
			description: this.convertMultiLangValue(apiData.description ?? undefined),
			shortDescription: this.convertMultiLangValue(
				apiData.shortDescription ?? undefined
			),
			text: apiData.text ?? [],
			url: this.convertMultiLangValue(apiData.url ?? undefined),
			images: apiData.images ?? [],
			prices: apiData.prices ?? [],
			isEnabled: apiData?.isEnabled,
			isDeleted: apiData?.isDeleted,
			tags: apiData.tags ?? [],
			tagsDetail: apiData.tagsDetail ?? [],
			categories: apiData.categories ?? [],
			language: apiData.language ?? VinistoHelperDllEnumsLanguage.CZECH,
			alternativeBundles: apiData.alternativeBundles ?? [],
			alternativeBundleObjects: apiData.alternativeBundleObjects ?? [],
			specificationDetails: apiData.specificationDetails ?? [],
			productsDetail: apiData.productsDetail ?? [],
			supplierId: apiData.supplierId ?? '',
			supplier: apiData?.supplier ?? null,
			isDeliveryFree: apiData?.isDeliveryFree,
			bundleEvaluation: apiData.bundleEvaluation ?? {},
			scoring: apiData.scoring ?? 0,
			scoringWarehouse: apiData.scoringWarehouse ?? 0,
			scoringDiscount: apiData.scoringDiscount ?? 0,
			scoringAdmin: apiData.scoringAdmin ?? 0,
			isForLogged: apiData.isForLogged,
			temporaryUnavailable: apiData.temporaryUnavailable,
			isSaleOver: apiData.isSaleOver ?? false,
			isGift: apiData.isGift,
			flags: apiData.flags ?? {},
			orderLimitation: apiData.orderLimitation ?? {},
			//TODO: Detele this row after refactor ShopControls - BundleItem
			bundleShopControls: apiData,
			items: apiData.items ?? [],
			categoriesDetail: apiData.categoriesDetail ?? [],
			isSet: apiData.isSet ?? false,
			setBundles:
				apiData.setBundles?.map((setBundle) => {
					const items = apiData.items.filter((i) => i.itemId === setBundle.id);
					return {
						...setBundle,
						setItems: items,
					};
				}) ?? [],
		};
	}

	/**
	 * Not implemented
	 */
	toApi(domainData: BundleData): VinistoProductDllModelsApiBundleBundle {
		return domainData as VinistoProductDllModelsApiBundleBundle;
	}

	isValid(item: unknown): item is VinistoProductDllModelsApiBundleBundle {
		return (
			typeof item === 'object' &&
			item !== null &&
			typeof (item as VinistoProductDllModelsApiBundleBundle).id === 'string'
		);
	}
}

export default BundleAdapter;
