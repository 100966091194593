import * as React from 'react';
import { get, merge } from 'lodash-es';
import { LocalizationContext } from 'Services/LocalizationService';

import usePriceActiveCurrency from '../usePriceActiveCurrency';

const FALLBACK_CURRENCY_TITLE = 'Kč';

const useLocalizedCurrency = () => {
	const localizationContext = React.useContext(LocalizationContext);
	const getPriceActiveCurrency = usePriceActiveCurrency();
	const currencies = get(localizationContext, 'currencies', []);
	return React.useCallback(
		(list: Record<any, any>[]) => {
			const currencyData = getPriceActiveCurrency(list);
			const title = get(
				getPriceActiveCurrency(currencies),
				'title',
				FALLBACK_CURRENCY_TITLE
			);
			return merge(currencyData, { title });
		},
		[getPriceActiveCurrency]
	);
};

export default useLocalizedCurrency;
