import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import { BundleOrderBundle } from 'Pages/UserSection/Orders/interfaces';

import styles from './styles.module.css';

interface GiftDesktopProps {
	isLoading: boolean;
	orderItem: BundleOrderBundle;
	index: number;
	url: string;
	quantity: number;
}

const GiftDesktop = ({
	isLoading,
	orderItem,
	url,
	quantity,
}: GiftDesktopProps) => {
	const t = useContext(LocalizationContext).useFormatMessage();

	return (
		<div className={styles.product}>
			{!isLoading && (
				<Link
					className={styles.overlay}
					to={`/${t({
						id: 'routes.product.route',
					})}/${url}`}
				/>
			)}
			<div className={styles.productImg}>
				<img
					src={getBundleImage(
						[orderItem.bundle.mainImage ?? {}],
						IMAGE_SIZE_THUMB_64x80
					)}
					alt={`${t({
						id: 'alt.bundleImage',
					})}`}
				/>
			</div>
			<div className={styles.secondColumn}>
				<div className={styles.productName}></div>
			</div>
			<div className={styles.thirdColumn}>
				<div className={styles.productPrice}>zdarma</div>
			</div>
			<div className={styles.productCount}>
				{t(
					{ id: 'order.pcs' },
					{
						count: quantity,
					}
				)}
			</div>
			<div className={styles.prices}>
				<div className={styles.productPrice}>zdarma</div>
			</div>
		</div>
	);
};

export default GiftDesktop;
