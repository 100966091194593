import { lazy, Suspense, useContext } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { Breadcrumb } from 'react-bootstrap';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { LocalizationContext } from 'Services/LocalizationService';
import Loader from 'Components/View/Loader';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';

import { CategoryContext } from '../../context';
import { BundlesWithFiltersContext } from '../CategoryBundlesWithFilters/context';

const BreadcrumbHomeIcon = lazy(
	() => import('Components/Icons/BreadcrumbHome')
);

const CategoryBreadcrumb = () => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const { categoryData } = useContext(CategoryContext);
	const { specificationsQuery } = useContext(BundlesWithFiltersContext);
	const isCategoryDataLoading = categoryData?.isLoading || false;
	const isLoading = isCategoryDataLoading || specificationsQuery?.isLoading;

	const categoryName = categoryData?.data?.category?.name;

	return (
		<ContainerFullWidth className="px-4">
			{isLoading ? (
				<Skeleton
					containerClassName="breadcrumb"
					width="300px"
				/>
			) : (
				<Breadcrumb>
					<Breadcrumb.Item
						linkAs={Link}
						linkProps={{ to: '/' }}
					>
						<Suspense fallback={<Loader blank />}>
							<BreadcrumbHomeIcon
								alt={t({ id: 'alt.breadcrumb.home' })}
								title={``}
								className={`BreadcrumbHomeIcon`}
							/>
						</Suspense>
					</Breadcrumb.Item>
					<Breadcrumb.Item active>
						{getLocalizedValue(categoryName ?? [])}
					</Breadcrumb.Item>
				</Breadcrumb>
			)}
		</ContainerFullWidth>
	);
};

export default CategoryBreadcrumb;
