import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './styles.module.css';
import Loading from './loading';

type IBundleChange = {
	bundleId: string;
	quantity: number;
};

type OrderItemProps = {
	id: string;
	isLoading: boolean;
	internalId: string;
	totalPrice: string;
	state: string;
	color: string;
	createdAtDate: string;
	trackingLink?: string;
	isDesktop: boolean;
	showDetailButton?: boolean;
	orderNumberAsLink?: boolean;
	className?: string;
	showBuyAgainButton: boolean;
	addItemsToBasket: (items: IBundleChange[]) => void;
	itemsToAdd?: IBundleChange[];
};

const OrderItem = ({
	id,
	isLoading,
	createdAtDate,
	internalId,
	state,
	color,
	totalPrice,
	trackingLink,
	isDesktop,
	showDetailButton = true,
	orderNumberAsLink = true,
	className,
	showBuyAgainButton,
	addItemsToBasket,
	itemsToAdd,
}: OrderItemProps) => {
	if (isLoading) return <Loading isDesktop />;

	if (isDesktop)
		return (
			<div className={cx(styles.orderDesktop, className)}>
				<div className={styles.orderNumber}>
					{orderNumberAsLink ? (
						<Link
							to={`/uzivatelska-sekce/objednavky?id=${id}`}
							className={styles.orderNumberLink}
						>
							Objednávka č. {internalId}
						</Link>
					) : (
						<>Objednávka č. {internalId}</>
					)}
				</div>
				<div
					className={styles.orderState}
					style={{
						color: color ?? '',
					}}
				>
					{state}
				</div>
				<div className={styles.orderCreatedAtDate}>{createdAtDate}</div>
				<div className={styles.orderPriceWrapper}>
					Celková cena{' '}
					<span className={styles.orderPrice}>{totalPrice} Kč</span>
				</div>

				<div className={styles.buttons}>
					{!showBuyAgainButton && trackingLink && (
						<a
							href={trackingLink}
							className={styles.button}
						>
							Sledovat zásilku
						</a>
					)}
					{showBuyAgainButton && itemsToAdd && (
						<button
							className={styles.buyAgainButton}
							onClick={async () => await addItemsToBasket(itemsToAdd)}
						>
							Objednat znovu
						</button>
					)}
					{showDetailButton && (
						<Link
							to={`/uzivatelska-sekce/objednavky?id=${id}`}
							className={styles.linkButton}
						>
							Podrobnosti
						</Link>
					)}
				</div>
			</div>
		);

	return (
		<>
			<div className={styles.orderGrid}>
				<div>
					<div className={styles.orderNumber}>Objednávka č. {internalId}</div>
					<div className={styles.orderPriceWrapper}>
						Celková cena{' '}
						<span className={styles.orderPrice}>{totalPrice} Kč</span>
					</div>
				</div>
				<div>
					<div
						className={styles.orderState}
						style={{
							color: color ?? '',
						}}
					>
						{state}
					</div>
					<div className={styles.orderCreatedAtDate}>{createdAtDate}</div>
				</div>
			</div>

			<div className={styles.buttons}>
				{!showBuyAgainButton && trackingLink && (
					<a
						href={trackingLink}
						className={styles.button}
					>
						Sledovat zásilku
					</a>
				)}
				{showBuyAgainButton && itemsToAdd && (
					<button
						className={styles.buyAgainButton}
						onClick={async () => await addItemsToBasket(itemsToAdd)}
					>
						Objednat znovu
					</button>
				)}
				{showDetailButton && (
					<Link
						to={`/uzivatelska-sekce/objednavky?id=${id}`}
						className={styles.linkButton}
					>
						Podrobnosti
					</Link>
				)}
			</div>
		</>
	);
};
export default OrderItem;
