import { ReactNode } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

interface ShowMoreButtonProps {
	onClick: () => void;
	showMorelabel: ReactNode;
	showLessLabel: ReactNode;
	showMore: boolean;
}

const ShowMore = ({
	onClick,
	showMorelabel,
	showLessLabel,
	showMore,
}: ShowMoreButtonProps) => {
	return (
		<button
			type="button"
			className={cx(
				'underline-effect',
				styles.component,
				showMore ? styles.showMore : styles.showLess
			)}
			onClick={onClick}
		>
			<span className="underline-item">
				{showMore ? showMorelabel : showLessLabel}
			</span>
		</button>
	);
};

export default ShowMore;
