import { DefaultBundleApiParams } from './types';

const countryCodes = ['CZ', 'SK', 'DE', 'UK'] as const;
type CountryCode = (typeof countryCodes)[keyof typeof countryCodes];

const languages = ['CZECH', 'SLOVAK', 'ENGLISH', 'GERMAN'] as const;
type Language = (typeof languages)[keyof typeof languages];

interface LangValuePair {
	language?: string | null;
	value?: string | null;
}

const DEFAULT_CURRENCY = 'CZK';

const DEFAULT_BUNDLE_API_PARAMS = {
	hiddenSpecification: false,
	isDeleted: false,
	isEnabled: true,
	isGift: false,
	isTemporaryUnavailable: false,
	isSaleOver: false,
	isB2b: false,
} as DefaultBundleApiParams;

const DEFAULT_BUNDLE_API_PARAMS_PROCESSED: any[] = [];
Object.entries(DEFAULT_BUNDLE_API_PARAMS).forEach(([key, value]) => {
	DEFAULT_BUNDLE_API_PARAMS_PROCESSED.push({ key: key, value: value });
});

export {
	languages,
	DEFAULT_CURRENCY,
	countryCodes,
	DEFAULT_BUNDLE_API_PARAMS,
	DEFAULT_BUNDLE_API_PARAMS_PROCESSED,
};
export type { Language, LangValuePair, CountryCode };
