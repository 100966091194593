import React from 'react';

export interface IExampleValue {
	count: number;
	changeCount: () => void;
}

export enum CARD_TYPE {
	ARTICLE,
	CUSTOM,
}

export interface ICarouselProps {
	products?: any;
	carouselType: string;
	cardType: CARD_TYPE;
	data?: Record<any, any> | Record<any, any>[] | React.FC[] | null | undefined;
	isLoading?: boolean;
}
