import { Fragment, useContext, useEffect } from 'react';
import { reduce } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';
import { ICurrency } from 'Services/LocalizationService/interfaces';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { LocalizationContext } from 'Services/LocalizationService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { NotificationsContext } from 'Services/NotificationService';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { DeviceServiceContext } from 'Services/DeviceService';
import userSectionStyles from 'Pages/UserSection/styles.module.css';
import { CREATED, NOT_FOUND } from 'Pages/UserSection//Orders/constants';
import {
	getLocalizedOrderState,
	getPdfDocument,
	openPdf,
} from 'Pages/UserSection/Orders/helpers';

import BreadCrumbsUserSection from '../Breadcrumbs';

import Order from './Order';
import { fetchOrder } from './helpers';

import { VinistoOrderDllModelsApiReturnDataOrderReturn } from '@/api-types/order-api';

interface OrderDetailProps {
	orderId: string;
}

const OrderDetail = ({ orderId }: OrderDetailProps) => {
	const { useFormatMessage, currencies } = useContext(LocalizationContext);
	const { vinistoUser } = useContext(AuthenticationContext);
	const { handleShowErrorNotification } = useContext(NotificationsContext);
	const { dispatch } = useContext(DocumentHeaderContext);
	const { isMobile, isTablet } = useContext(DeviceServiceContext);

	const t = useFormatMessage();

	const userLoginHash = vinistoUser.loginHash ?? '';

	const {
		data,
		isLoading,
	}: {
		data?: VinistoOrderDllModelsApiReturnDataOrderReturn;
		isLoading: boolean;
	} = useQuery(['order-detail', orderId], () =>
		fetchOrder(orderId, userLoginHash)
	);

	const orderData = data?.order ?? null;

	useEffect(() => {
		dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.user-section.orders.name' })}` }
			)}`,
		});
	}, []);

	const handleOnClickInvoice =
		(orderId: string, documentUrl: string) => async () => {
			const userLoginHash = vinistoUser.loginHash ?? '';
			try {
				const encodedPdfContent = await getPdfDocument(
					orderId,
					documentUrl,
					userLoginHash
				);
				openPdf(encodedPdfContent as string);
			} catch {
				handleShowErrorNotification('userSection.order.invoice.loadError');
			}
		};

	const localizedState = getLocalizedOrderState(orderData?.state ?? '');

	const date: number =
		reduce(
			orderData?.stateChangeRecords ?? [],
			(result: number, change: Record<string, any>) =>
				result > NOT_FOUND
					? result
					: change.state === CREATED
					? change.changeTime
					: result,
			NOT_FOUND
		) * 1000; // API returns seconds, not miliseconds

	const localizedCurrency: string = reduce(
		currencies,
		(result: string, item: ICurrency) =>
			result !== ''
				? result
				: item.currency === orderData?.orderCurrency
				? item.title
				: result,
		''
	);

	const orderDataLocalized = {
		...orderData,
		date: date > NOT_FOUND ? date : null,
		localizedCurrency,
		localizedState,
	};

	return (
		<>
			<BreadCrumbsUserSection
				subpageTitle="routes.user-section.myOrders.name"
				subpageLink={`/${t({ id: 'routes.user-section.route' })}/${t({
					id: 'routes.user-section.orders.route',
				})}`}
			/>

			<h1 className={userSectionStyles.userSectionMainHeader}>
				{t({ id: 'userSection.order.btn.detail' })}{' '}
				{((isMobile || isTablet) && orderData?.orderNumber) ?? ''}
			</h1>

			<div>
				<Order
					isLoading={isLoading}
					order={orderDataLocalized}
					{...{ handleOnClickInvoice }}
				/>
			</div>
		</>
	);
};

export default OrderDetail;
