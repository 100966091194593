import { VinistoOrderDllModelsApiDeliveryDelivery } from 'vinisto_api_client/src/api-types/order-api';
import { LocalizationContext } from 'Services/LocalizationService';
import { useContext } from 'react';
import DeliveryItem from 'Pages/CartShippingPayment/Components/DeliveryItem';
import cx from 'classnames';

import styles from './styles.module.css';
import { DeliveryMethodsPlacement } from './types';

interface DeliveryMethodsProps {
	deliveries: VinistoOrderDllModelsApiDeliveryDelivery[];
	isLoading?: boolean;
	selectable?: boolean;
	monthName?: boolean;
	placement?: DeliveryMethodsPlacement;
}

const DeliveryMethods = ({
	deliveries,
	isLoading = false,
	selectable = false,
	monthName = true,
	placement = DeliveryMethodsPlacement.PRODUCT_DETAIL,
}: DeliveryMethodsProps) => {
	const t = useContext(LocalizationContext).useFormatMessage();

	// in order to not spend half a day on refactoring, we just overwrite the name property with alternativeName
	// this ensures that the correct name is rendered for this non-checkout delivery list
	const filteredDeliveries = deliveries
		.filter((delivery) => delivery.isOnProductDetail)
		.map((delivery) => ({
			...delivery,
			name: delivery.alternativeName ?? [],
		}));

	return (
		<>
			<p
				className={cx(styles.title, {
					[styles.cartTitleHeading]:
						placement === DeliveryMethodsPlacement.CART ||
						placement === DeliveryMethodsPlacement.CHECKOUT,
					[styles.productDetailTitleHeading]:
						placement === DeliveryMethodsPlacement.PRODUCT_DETAIL,
				})}
			>
				{t({ id: 'productDetail.deliveryMethods' })}
			</p>
			<div
				className={cx(styles.list, {
					[styles.cartTitle]:
						placement === DeliveryMethodsPlacement.CART ||
						placement === DeliveryMethodsPlacement.CHECKOUT,
					[styles.productDetailTitle]:
						placement === DeliveryMethodsPlacement.PRODUCT_DETAIL,
				})}
			>
				{filteredDeliveries?.map(
					(delivery: VinistoOrderDllModelsApiDeliveryDelivery) => (
						<div key={delivery?.id}>
							<DeliveryItem
								key={delivery?.id}
								delivery={delivery}
								isLoading={isLoading}
								selectable={selectable}
								monthName={monthName}
								placement={placement}
							/>
						</div>
					)
				)}
			</div>
		</>
	);
};

export default DeliveryMethods;
