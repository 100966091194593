import type { FC } from 'react';
import {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useInViewport } from 'react-in-viewport';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import {
	COUPON_USED_ERROR,
	NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR,
} from 'Services/BasketService/constants';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import useAnalytics from 'Hooks/useAnalytics';
import useDebounce from 'Hooks/useDebounce';
import useFormRefStore from 'Hooks/useFormRefPersist';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import usePriceActiveCurrency from 'Hooks/usePriceActiveCurrency';
import useDeliveryDetail from 'Pages/CartShippingPayment/Hooks/useDeliveryDetail';
import usePaymentDetail from 'Pages/CartShippingPayment/Hooks/usePaymentDetail';
import useChat from 'Hooks/useChat';
import {
	AddressesApiHooks,
	BillingInfoApiHooks,
} from 'Services/Addresses/hooks';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { PreloaderContext } from 'Components/Preloader/context';
import AuthenticationService from 'Services/AuthenticationService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';
import { OrderContext } from 'Services/OrderService/context';
import { StorageContext } from 'Services/StorageService/context';
import { TrackEvent } from 'Services/FacebookPixel';
import Form from 'Components/Forms';
import OrderOverview from 'Pages/CartShippingPayment/Components/OrderOverview';
import { eHubService } from 'Services/eHub';
import { EHUB_STORAGE_KEY } from 'Services/eHub/constants';
import { TEST_IDS } from 'Constants/test-ids';

import { CTA_VISIBILITY_THRESHOLD } from './constants';
import { CartShippingDataFormSchema } from './schema';
import CartShippingPaymentHeader from './Components/CartShippingDataHeader';
import DeliveryAddresses from './Components/DeliveryAddresses';
import DeliveryForm from './Components/DeliveryForm';
import EmailForm from './Components/EmailForm';
import InvoiceAddresses from './Components/InvoiceAddresses';
import InvoiceForm from './Components/InvoiceForm';
import LoginMessage from './Components/LoginMessage';
import NewsletterCheckbox from './Components/NewsletterCheckbox';
import PickupPointForm from './Components/PickupPointForm';
import TermsAndConditionsMessage from './Components/TermsAndConditionsMessage';
import { ContextBasketState, SavedAddressesIds } from './interfaces';
import '../UserSection/Orders/styles.css';
import styles from './styles.module.css';

import './styles.css';

const CartShippingData: FC = () => {
	const storageContext = useContext(StorageContext);
	const {
		deliveryMethod,
		utmPostData: orderContextUtmPostData,
		paymentMethod,
		formValues,
		createSignedOrder,
		createUnregisteredOrder,
	} = useContext(OrderContext);
	let { current: currentFormValues } = formValues;
	const preloaderContext = useContext(PreloaderContext);
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const {
		isLoggedIn,
		vinistoUser: { isNewsletterActive, email: vinistoUserEmail },
	} = useContext(AuthenticationContext);
	const {
		basketState,
		basketPriceWithVat,
		basketPrice,
		basketItemsWithTemp,
		basketItemsGoogleAnalyticsData,
		handleOnRemoveCoupon,
	} = useContext(BasketContext);

	const {
		items,
		discountCoupons,
		currency,
		basketPrice: basketStatePrice,
	} = basketState as ContextBasketState;
	const { useFormatMessage, activeCurrency } = useContext(LocalizationContext);
	const deviceContext = useContext(DeviceServiceContext);
	const { isMobile, isTablet, isDesktop, headerHeight, footerHeight } =
		deviceContext;
	const notificationContext = useContext(NotificationsContext);

	const appliedDiscountCoupons = (
		discountCoupons?.discountCoupons ?? []
	).filter((coupon) => coupon?.isCouponApplied);

	const unappliedCoupons = (discountCoupons?.discountCoupons ?? []).filter(
		(coupon) => coupon && !coupon?.isCouponApplied
	);

	const t = useFormatMessage();
	const navigate = useNavigate();
	const getPriceActiveCurrency = usePriceActiveCurrency();
	const getLocalizedValue = useLocalizedValue();
	const { sendEvent: sendAnalyticsEvent } = useAnalytics();
	const { hideWidget, showWidget } = useChat();

	const isBasketLoading = !items ? true : false;

	const deliveryId = deliveryMethod?.id ?? null;
	const utmPostData = orderContextUtmPostData ?? null;
	const deliveryDetail = useDeliveryDetail(deliveryId ?? '');
	const deliveryPriceData = useMemo(
		() => getPriceActiveCurrency(deliveryDetail.data?.prices ?? []),
		[deliveryDetail, getPriceActiveCurrency]
	);
	const deliveryPriceWithoutVat = deliveryDetail?.deliveryPrice?.value ?? 0;
	const deliveryPriceWithVat = deliveryDetail?.deliveryPrice?.valueWithVat ?? 0;

	const paymentId = paymentMethod?.id ?? null;
	const paymentDetail = usePaymentDetail(paymentId ?? '');
	const paymentPriceData = useMemo(
		() => getPriceActiveCurrency(paymentDetail.data?.prices ?? []),
		[paymentDetail, getPriceActiveCurrency]
	);
	const paymentPriceWithoutVat = paymentPriceData?.value || 0;
	const paymentPriceWithVat = paymentPriceData?.valueWithVat || 0;

	const totalPriceWithVAT =
		(basketPriceWithVat ?? 0) + deliveryPriceWithVat + paymentPriceWithVat;

	const totalPriceWithoutVAT =
		(basketPrice ?? 0) + deliveryPriceWithoutVat + paymentPriceWithoutVat;

	const discountedTotalPriceWithVAT =
		(discountCoupons?.discountedBasketPriceWithVat ?? 0) +
		(deliveryPriceData?.valueWithVat || 0) +
		(paymentPriceData?.valueWithVat || 0);

	const discountedTotalPriceWithoutVAT =
		(discountCoupons?.discountedBasketPrice ?? 0) +
		(deliveryPriceData?.value || 0) +
		(paymentPriceData?.value || 0);

	const isDeliveryLoading = !deliveryDetail.isLoaded;
	const isPaymentLoading = !paymentDetail.isLoaded;
	const isLoading = isBasketLoading || isDeliveryLoading || isPaymentLoading;

	const [isShowFloatingFooter, setIsShowFloatingFooter] = useState(false);
	const ctaRef = useRef<HTMLDivElement>(null);
	const { inViewport: isCtaInViewport } = useInViewport(ctaRef, {
		// consider CTA button hidden behind header and footer
		rootMargin: `${headerHeight * -1}px 0px ${footerHeight * -1}px 0px`,
		threshold: CTA_VISIBILITY_THRESHOLD,
	});
	const parentRef = useRef<HTMLDivElement>(null);
	const [topDistance, setTopDistance] = useState(0);
	const [maxOrderOverviewHeight, setMaxOrderOverviewHeight] = useState(
		`${window.innerHeight - topDistance - 16}px`
	);
	const basketItems = basketItemsWithTemp.items ?? [];

	const [isAnalyticsSent, setIsAnalyticsSent] = useState<boolean>(false);

	useEffect(() => {
		if (
			basketItemsGoogleAnalyticsData.length === 0 ||
			isDeliveryLoading ||
			isAnalyticsSent
		)
			return;

		sendAnalyticsEvent(GA_EVENT.ADD_SHIPPING_INFO, {
			currency: currency,
			value: basketStatePrice,
			shipping_tier: getLocalizedValue(deliveryDetail.data?.name ?? []),
			items: basketItemsGoogleAnalyticsData,
		});
		setIsAnalyticsSent(true);
	}, [
		basketItemsGoogleAnalyticsData,
		isDeliveryLoading,
		isAnalyticsSent,
		basketState,
		deliveryDetail,
		sendAnalyticsEvent,
		setIsAnalyticsSent,
		currency,
		basketStatePrice,
		getLocalizedValue,
	]);

	const [isPaymentAnalyticsSent, setIsPaymentAnalyticsSent] =
		useState<boolean>(false);
	useEffect(() => {
		if (
			basketItemsGoogleAnalyticsData.length === 0 ||
			isDeliveryLoading ||
			isPaymentAnalyticsSent
		) {
			return;
		}

		TrackEvent('track', 'AddPaymentInfo', {
			content_type: 'product',
			content_ids: basketState.items.map(
				(bundle: { bundleId: string }) => bundle.bundleId
			),
			value: basketState?.basketPrice ?? 0,
			currency,
		});

		sendAnalyticsEvent(GA_EVENT.ADD_PAYMENT_INFO, {
			currency: currency,
			value: basketStatePrice,
			payment_type: getLocalizedValue(paymentDetail.data?.name || []),
			items: basketItemsGoogleAnalyticsData,
		});
		setIsPaymentAnalyticsSent(true);
	}, [
		basketItemsGoogleAnalyticsData,
		isDeliveryLoading,
		isPaymentAnalyticsSent,
		basketState,
		paymentDetail,
		sendAnalyticsEvent,
		setIsPaymentAnalyticsSent,
	]);

	useEffect(() => {
		hideWidget();

		return () => showWidget();
	}, []);

	useEffect(() => {
		if (Object.keys(basketState).length === 0) {
			if (Object.keys(items ?? {}).length === 0) {
				navigate(`/${t({ id: 'routes.cart.route' })}`);
			}
		}
		if (!deliveryMethod?.id || !paymentMethod?.id) {
			navigate(`/${t({ id: 'routes.cart.shippingPayment.route' })}`);
		}
	}, [basketState, basketItems, deliveryId, paymentId]);

	useEffect(() => {
		if (ctaRef.current && getComputedStyle(ctaRef.current).display !== 'none') {
			setIsShowFloatingFooter(!isCtaInViewport);
		}
	}, [
		setIsShowFloatingFooter,
		isCtaInViewport,
		isMobile, // trigger on mobile viewport status change
	]);

	const handleGoBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	useLayoutEffect(() => {
		setMaxOrderOverviewHeight(
			`${window.innerHeight - topDistance - 50 - 16}px`
		);
	}, [topDistance, deviceContext]);

	useLayoutEffect(() => {
		if (parentRef.current) {
			setTopDistance(
				window.pageYOffset + parentRef.current?.getBoundingClientRect()?.top
			);
		}
	}, [parentRef.current, deviceContext]);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.cart.shipping.name' })}` }
			)}`,
		});
	}, []);

	const invoiceAddresses = BillingInfoApiHooks.useGetAll();
	const deliveryAddresses = AddressesApiHooks.useGetAll();

	const initialValues = currentFormValues ?? {};

	// Load from local storage only on mount
	const savedAddressesIds = useMemo(
		() =>
			storageContext.StorageService.getStorageItem(
				'CART_SHIPPING_DATA'
			) as SavedAddressesIds,
		[storageContext.StorageService]
	);

	const methods = useForm<CartShippingDataFormSchema>({
		mode: 'onTouched',
		defaultValues: {
			isNewsletterActive: isNewsletterActive ?? false,
			useBillingInfo: false,
			specSymbol: '',
			userCustomOrderNumber: '',
			email: { delivery: vinistoUserEmail ?? '' },
			name: { delivery: '', billingInfo: '' },
			lastname: { delivery: '', billingInfo: '' },
			organization: { delivery: '', billingInfo: '' },
			phone: { delivery: '', billingInfo: '' },
			street: {
				delivery: {
					value: '',
					selectedItem: null,
				},
				billingInfo: {
					value: deliveryMethod?.pickupPoint?.street ?? '',
					selectedItem: null,
				},
			},
			landRegistryNumber: {
				delivery: deliveryMethod?.pickupPoint?.landRegistryNumber ?? '',
				billingInfo: '',
			},
			numberHouse: {
				delivery: deliveryMethod?.pickupPoint?.houseNumber ?? '',
				billingInfo: '',
			},
			city: {
				delivery: deliveryMethod?.pickupPoint?.city ?? '',
				billingInfo: '',
			},
			zip: {
				delivery: deliveryMethod?.pickupPoint?.zip ?? '',
				billingInfo: '',
			},
			ico: { billingInfo: '' },
			dic: { billingInfo: '' },
			accountNumber: { billingInfo: '' },
			useCompanyData: false,
			...initialValues,
			deliveryAddressId:
				initialValues?.deliveryAddressId ?? savedAddressesIds?.deliveryAddressId
					? savedAddressesIds?.deliveryAddressId
					: null,
			billingInfoId:
				initialValues?.billingInfoId ??
				(savedAddressesIds?.billingInfoId
					? savedAddressesIds?.billingInfoId
					: null),
			utm: {
				source: utmPostData ? utmPostData?.source : null,
				medium: utmPostData ? utmPostData?.medium : null,
				campaign: utmPostData ? utmPostData?.campaign : null,
				gad: utmPostData ? utmPostData?.gad : null,
				gclId: utmPostData ? utmPostData?.gclId : null,
			},
		},
	});

	const {
		register,
		watch,
		setFocus,
		handleSubmit,
		getValues,
		setValue,
		formState,
	} = methods;

	// Save to order context ref
	useFormRefStore(formValues, { watch });

	const useBillingInfo = watch('useBillingInfo');
	const email = watch('email.delivery');
	const debouncedEmail = useDebounce(email, 600);

	const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
	const [isEmailInUse, setIsEmailInUse] = useState<boolean>(false);

	const handleOnSubmit = async (values: CartShippingDataFormSchema) => {
		preloaderContext.togglePreloader(true);
		if (
			!deliveryAddresses?.data?.addresses?.find(
				({ id }) => values?.deliveryAddressId === id
			)
		) {
			values.deliveryAddressId = null;
		}
		if (
			!invoiceAddresses?.data?.billingInfos?.find(
				({ id }) => values?.billingInfoId === id
			)
		) {
			values.billingInfoId = null;
		}

		if (unappliedCoupons.length) {
			// TODO maybe the endpont is ready to accept multiple coupons?
			unappliedCoupons.forEach((coupon) => {
				handleOnRemoveCoupon(coupon.id);
			});
		}

		const createOrderFn = isLoggedIn
			? createSignedOrder
			: createUnregisteredOrder;

		const resetForm = () => {
			currentFormValues = {};
			storageContext.StorageService.setItem('CART_SHIPPING_DATA', {
				deliveryAddressId: getValues('deliveryAddressId'),
				billingInfoId: getValues('billingInfoId'),
			});
		};

		const ehub = storageContext.StorageService.getStorageItem(EHUB_STORAGE_KEY);

		await createOrderFn(values, resetForm)
			?.then(async (result) => {
				if (result && 'order' in result && ehub) {
					await eHubService
						.sendEHubRequest({
							orderId: result.order?.orderNumber ?? '',
							couponDiscount: discountCoupons.discountCoupons
								? discountCoupons.discountCoupons
										.map((coupon) => coupon.discountAmount ?? 0)
										.join(',')
								: '0',
							currency: activeCurrency.currency ?? '',
							orderAmount: String(basketPrice),
							visitId: String(ehub),
						})
						.then(() => {
							storageContext.StorageService.removeItem(EHUB_STORAGE_KEY);
						});
				}
			})
			?.catch((error: Error) => {
				let errorMessage = 'createOrder.error.response';
				if (error.message === NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR) {
					errorMessage = 'createOrder.error.warehouse';
				} else if (error.message === COUPON_USED_ERROR) {
					errorMessage = 'createOrder.error.coupon';
				}
				notificationContext.handleShowErrorNotification(errorMessage);
			})
			.finally(() => preloaderContext.togglePreloader(false));
	};

	useEffect(() => {
		if (isEmailValid && !isLoggedIn) {
			new AuthenticationService()
				.checkIfEmailIsAlreadyInUse(debouncedEmail)
				.then((res: any) => {
					setIsEmailInUse(res.result ?? false);
				});
		}
	}, [debouncedEmail, isLoggedIn]);

	useEffect(() => {
		if (isLoggedIn) {
			setFocus('name.delivery');
		} else {
			setFocus('email.delivery');
		}
	}, [isLoggedIn, setFocus]);

	useEffect(() => {
		if (formState?.isDirty) {
			setValue('isDirty', true);
		}
	}, [formState?.isDirty]);

	useEffect(() => {
		// Set data if form is not dirty
		if (getValues('isDirty') === true) return;

		if (
			getValues('email.billingInfo') === '' &&
			isLoggedIn &&
			vinistoUserEmail
		) {
			setValue('email.billingInfo', vinistoUserEmail);
		}
	}, []);

	const showFullForm = (!isLoggedIn && isEmailValid) || isLoggedIn;

	return (
		<section id="content-wrapper">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<CartShippingPaymentHeader />
					</div>
				</div>
			</div>
			<Form.Provider {...methods}>
				<Form onSubmit={handleSubmit(handleOnSubmit)}>
					<>
						<div className="container vinisto-shipping-data">
							<div className="row">
								<div className="vinisto-shipping-data__wrap">
									<div className="vinisto-shipping-data__big-col">
										{!deliveryMethod?.pickupPoint && (
											<h1 className="vinisto-heading underline mb-0">
												{t({
													id: 'cartShippingData.deliveryDetails',
												})}
											</h1>
										)}
										<div className="vinisto-shipping-data__left-wrap">
											<div className="vinisto-shipping-data__left-wrap__wrap">
												{!isLoggedIn && <EmailForm />}
												{showFullForm && (
													<>
														{isEmailInUse && <LoginMessage className="mb-3" />}
														{deliveryAddresses?.isFetched &&
															deliveryAddresses?.data?.count === 0 && (
																<div className={styles.readOnlyEmailWrapper}>
																	<label
																		className={styles.readOnlyEmailLabel}
																		htmlFor="readonly-email"
																	>
																		{t({
																			id: 'form.input.email.label',
																		})}
																	</label>
																	<Form.Input
																		className={styles.readOnlyEmail}
																		value={email}
																		name="readonly-email"
																		readOnly
																		label={`${t({
																			id: 'cartShippingData.form.specificField.label',
																		})}`}
																	/>
																</div>
															)}
														{!deliveryMethod?.pickupPoint ? (
															<>
																{isLoggedIn &&
																deliveryAddresses?.isFetched &&
																deliveryAddresses?.data?.count !== 0 ? (
																	<DeliveryAddresses />
																) : (
																	<DeliveryForm />
																)}
																<Form.Checkbox
																	{...register('useBillingInfo')}
																	className="vinisto-shipping-data__checkbox-label"
																>
																	{t({
																		id: 'cartShippingData.useBillingInfo',
																	})}
																</Form.Checkbox>
																{useBillingInfo && (
																	<>
																		{isLoggedIn &&
																		invoiceAddresses?.data?.count !== 0 ? (
																			<InvoiceAddresses />
																		) : (
																			<InvoiceForm />
																		)}
																		<Form.InputField
																			id="specSymbol"
																			name="specSymbol"
																			inputMode="numeric"
																			label={`${t({
																				id: 'cartShippingData.form.specificField.label',
																			})}`}
																			placeholder={`${t({
																				id: 'cartShippingData.form.specificField.placeholder',
																			})}`}
																		/>

																		<Form.InputField
																			id="userCustomOrderNumber"
																			name="userCustomOrderNumber"
																			inputMode="numeric"
																			label={`${t({
																				id: 'cartShippingData.form.internalField.label',
																			})}`}
																			placeholder={`${t({
																				id: 'cartShippingData.form.internalField.placeholder',
																			})}`}
																		/>
																	</>
																)}
															</>
														) : isLoggedIn &&
														  invoiceAddresses?.data?.count !== 0 ? (
															<>
																<InvoiceAddresses />
																<Form.InputField
																	id="specSymbol"
																	name="specSymbol"
																	inputMode="numeric"
																	label={`${t({
																		id: 'cartShippingData.form.specificField.label',
																	})}`}
																	placeholder={`${t({
																		id: 'cartShippingData.form.specificField.placeholder',
																	})}`}
																/>

																<Form.InputField
																	id="userCustomOrderNumber"
																	name="userCustomOrderNumber"
																	inputMode="numeric"
																	label={`${t({
																		id: 'cartShippingData.form.internalField.label',
																	})}`}
																	placeholder={`${t({
																		id: 'cartShippingData.form.internalField.placeholder',
																	})}`}
																/>
															</>
														) : (
															<>
																<h1 className="mt-0 h5">
																	{t({
																		id: 'cartShippingData.billingInfo',
																	})}
																</h1>
																<PickupPointForm />
															</>
														)}
													</>
												)}
											</div>
										</div>

										{showFullForm && (
											<div className="vinisto-shipping-data__right-wrap desktop-only">
												<div className="d-flex justify-content-end">
													<NewsletterCheckbox id="desktop" />
												</div>
												<TermsAndConditionsMessage />
											</div>
										)}

										<div className="vinisto-crosssell__ctas desktop-only">
											<a
												className="vinisto-crosssell__ctas__back"
												onClick={handleGoBack}
											>
												&lt;{' '}
												{t({
													id: 'basket.backToShipping',
												})}
											</a>
											<button
												type="submit"
												className="vinisto-btn vinisto-success-btn vinisto-shipping-data__finish-btn"
												data-testid={TEST_IDS.BASKET_FINISH_ORDER_DESKTOP}
											>
												{t({
													id: 'basket.orderFinish',
												})}
											</button>
										</div>
									</div>
									<div
										ref={parentRef}
										className="vinisto-shipping-data__small-col"
									>
										<div
											style={{
												position: isDesktop ? 'sticky' : 'relative',
												top: isDesktop ? topDistance : '0',
												height: isDesktop ? maxOrderOverviewHeight : 'auto',
											}}
										>
											<OrderOverview
												{...{
													deliveryDetail,
													deliveryPriceWithVat,
													paymentDetail,
													paymentPriceWithVat,
													totalPriceWithVAT: appliedDiscountCoupons.length
														? discountedTotalPriceWithVAT
														: totalPriceWithVAT,
													totalPriceWithoutVAT: appliedDiscountCoupons.length
														? discountedTotalPriceWithoutVAT
														: totalPriceWithoutVAT,
													isLoading,
													isBasketLoading,
													isShowAgreement: false,
													isShowButtons: false,
													toggleFloatingFooter: setIsShowFloatingFooter,
												}}
											>
												{isTablet && showFullForm && (
													<>
														<NewsletterCheckbox id="tablet" />
														<TermsAndConditionsMessage />
														<div
															className="vinisto-crosssell__ctas tablet-only"
															ref={ctaRef}
														>
															<div
																className="vinisto-crosssell__ctas__back"
																onClick={handleGoBack}
															>
																&lt; {t({ id: 'basket.backToShipping' })}
															</div>
															<button
																type="submit"
																className="vinisto-btn vinisto-user-orders__orders__order__cta__btn vinisto-crosssell__ctas__cta vinisto-success-btn"
															>
																{t({ id: 'basket.orderFinish' })}
															</button>
														</div>
													</>
												)}
											</OrderOverview>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="container vinisto-crosssell vinisto-shipping">
							<div className="row">
								<div className="col-12">
									{showFullForm && (
										<div className="vinisto-shipping-data__right-wrap mobile-only mt-3">
											<NewsletterCheckbox id="mobile" />
											<TermsAndConditionsMessage />
										</div>
									)}
									<div
										className="vinisto-crosssell__ctas mobile-only"
										ref={ctaRef}
									>
										<button
											type="submit"
											className="vinisto-btn vinisto-user-orders__orders__order__cta__btn vinisto-crosssell__ctas__cta vinisto-success-btn vinisto-cart-btn-next w-100"
										>
											{t({
												id: 'basket.orderFinish',
											})}
										</button>
									</div>
								</div>
							</div>
						</div>

						{isShowFloatingFooter && (
							<div
								className="container vinisto-crosssell vinisto-crosssell--floating tablet-mobile-only"
								style={{
									bottom: `${footerHeight}px`,
								}}
							>
								<div className="row">
									<div className="col-12">
										<div className="vinisto-crosssell__ctas">
											<div className="vinisto-crosssell__ctas__back d-none"></div>
											<div className="vinisto-crosssell__price-continue">
												<div className="vinisto-user-orders__orders__order-body__item__prices w-100">
													<div className="vinisto-user-orders__orders__order-body__item__prices__total w-100 d-flex flex-row justify-content-between">
														<span className="vinisto-user-orders__orders__order-body__item__prices__big-total">
															{`${t({
																id: 'basket.totalPriceBig',
															})} `}
														</span>
														<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
															{isLoading ? (
																<Skeleton width="80px" />
															) : appliedDiscountCoupons.length ? (
																`${createCurrencyValue(
																	discountedTotalPriceWithVAT
																)} ${activeCurrency.title ?? ''}`
															) : (
																`${createCurrencyValue(totalPriceWithVAT)} ${
																	activeCurrency.title ?? ''
																}`
															)}
														</span>
													</div>
													<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat w-100 d-flex flex-row justify-content-between">
														{isLoading ? (
															<Skeleton width="100px" />
														) : (
															<>
																{`${t({
																	id: 'basket.priceWithoutVAT',
																})} `}
																<span className="fw-bolder price-span">
																	{appliedDiscountCoupons.length
																		? ` ${createCurrencyValue(
																				discountedTotalPriceWithoutVAT
																		  )} ${activeCurrency.title ?? ''}`
																		: ` ${createCurrencyValue(
																				totalPriceWithoutVAT
																		  )} ${activeCurrency.title ?? ''}`}
																</span>
															</>
														)}
													</div>
												</div>
												<button
													type="submit"
													className="vinisto-btn vinisto-user-orders__orders__order__cta__btn vinisto-crosssell__ctas__cta vinisto-success-btn vinisto-cart-btn-next w-100"
												>
													{t({
														id: 'basket.orderFinish',
													})}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</>
				</Form>
			</Form.Provider>
		</section>
	);
};

export default CartShippingData;
