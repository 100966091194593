import React, { useState } from 'react';
import { get } from 'lodash-es';
import { dayjsInstance as dayjs } from 'Services/Date';
import { useQueryClient } from '@tanstack/react-query';
import { CartShippingDataFormSchema } from 'Pages/CartShippingData/schema';
import { PAYMENT_MODAL } from 'Components/Modal/constants';
import {
	DELIVERY_ADDRESSES_QUERY_KEY,
	INVOICE_ADDRESSES_QUERY_KEY,
} from 'Services/Addresses/constants';
import {
	COUPON_USED_ERROR,
	NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR,
} from 'Services/BasketService/constants';
import { UTM_CAMPAIGN_DATA } from 'Services/RoutingService/Components/StorageUTM/constants';
import {
	AddressesApiHooks,
	BillingInfoApiHooks,
} from 'Services/Addresses/hooks';
import { ModalContext } from 'Components/Modal/context';
import { AddressesApi, BillingInfoApi } from 'Services/Addresses';
import { apiServiceInstance } from 'Services/ApiService';
import {
	VinistoHelperDllEnumsCountryCode,
	VinistoHelperDllEnumsCurrency,
	VinistoOrderDllModelsApiOrderOrdersAddSignedParameters,
	VinistoOrderDllModelsApiOrderOrdersAddUnregisteredParameters,
	VinistoOrderDllModelsApiReturnDataDeliveriesReturn,
	VinistoOrderDllModelsApiReturnDataOrderReturn,
} from 'vinisto_api_client/src/api-types/order-api';
import {
	VinistoAuthDllModelsApiAddressAddress,
	VinistoAuthDllModelsApiAddressUserAddressCreateParameters,
	VinistoAuthDllModelsApiBillingInfoBillingInfo,
	VinistoAuthDllModelsApiBillingInfoUserBillingInfoCreateParameters,
} from 'vinisto_api_client/src/api-types/user-api';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import NewsletterService from 'Services/NewsletterService';
import { NotificationsContext } from 'Services/NotificationService';
import { StorageContext } from 'Services/StorageService/context';
import UserService from 'Services/UserService';
import { Optional } from 'types';

import {
	DEFAULT_COUNTRY_CODE,
	DEFAULT_CURRENCY,
	DEFAULT_LANGUAGE,
	DELIVERIES_LIMIT,
	DELIVERIES_URI,
} from './constants';
import {
	DeliveryMethod,
	IOrderServiceModel,
	IOrderServiceProps,
	OrderUtmParameters,
	PaymentMethod,
	PaymentState,
} from './interfaces';

const defaultOrderServiceModel: IOrderServiceModel = {
	formValues: { current: {} },
	createSignedOrder: () => Promise.resolve(),
	createUnregisteredOrder: () => Promise.resolve(),
	clearDeliveryPayment: () => null,
	deliveryMethod: null,
	setDeliveryMethod: () => null,
	paymentMethod: null,
	setPaymentMethod: () => null,
	utmPostData: {},
};

export const OrderContext = React.createContext(defaultOrderServiceModel);

const OrderServiceProvider = (props: IOrderServiceProps) => {
	const queryClient = useQueryClient();
	const localizationContext = React.useContext(LocalizationContext);
	const authenticationContext = React.useContext(AuthenticationContext);
	const modalContext = React.useContext(ModalContext);
	const notificationContext = React.useContext(NotificationsContext);
	const storageContext = React.useContext(StorageContext);

	const deliveryAddresses = AddressesApiHooks.useGetAll();
	const invoiceAddresses = BillingInfoApiHooks.useGetAll();

	const t = React.useContext(LocalizationContext).useFormatMessage();

	const formValues = React.useRef<
		CartShippingDataFormSchema | Record<string, never>
	>({});
	const [deliveryMethod, setDeliveryMethod] = useState<null | DeliveryMethod>(
		null
	);
	const [paymentMethod, setPaymentMethod] = useState<null | PaymentMethod>(
		null
	);
	const [orderPaymentState, setOrderPaymentState] =
		React.useState<PaymentState>({
			creatingOrderPayment: false,
			createdOrderPayment: false,
		});

	const utmData = storageContext.StorageService.getStorageItem(
		UTM_CAMPAIGN_DATA
	) as OrderUtmParameters;

	const utmPostData =
		dayjs()
			.add(1, 'm')
			.isAfter(utmData?.expires ?? dayjs()) === false
			? {
					source: utmData?.source,
					medium: utmData?.medium,
					campaign: utmData?.campaign,
					gad: utmData?.gad,
					gclId: utmData?.gclId,
			  }
			: null;

	const createSignedOrder = async (
		storedOrderForm: CartShippingDataFormSchema,
		resetForm: () => void
	) => {
		if (orderPaymentState.creatingOrderPayment) return;
		setOrderPaymentState({
			creatingOrderPayment: true,
			createdOrderPayment: false,
		});

		const storedUserState = authenticationContext?.vinistoUser;

		let deliveryAddressId = storedOrderForm.deliveryAddressId ?? null;

		let deliveryAddress:
			| Optional<VinistoAuthDllModelsApiAddressAddress, 'id'>
			| null
			| undefined = deliveryAddresses.data?.addresses?.find(
			(address) => address?.id === deliveryAddressId
		);

		if (deliveryAddress && !deliveryAddress.email)
			deliveryAddress.email = authenticationContext.vinistoUser.email;

		// Create new delivery address
		if (!deliveryAddress) {
			try {
				const requestData: VinistoAuthDllModelsApiAddressUserAddressCreateParameters =
					{
						title: 'Adresa #1',
						userLoginHash: authenticationContext?.vinistoUser?.loginHash,
						email:
							storedOrderForm?.email?.delivery ??
							authenticationContext.vinistoUser.email,
						name: storedOrderForm?.name.delivery,
						surname: storedOrderForm?.lastname?.delivery,
						company: storedOrderForm?.organization.delivery || null,
						phone: storedOrderForm?.phone.delivery,
						street: storedOrderForm?.street?.delivery?.value,
						landRegistryNumber: storedOrderForm?.landRegistryNumber?.delivery,
						houseNumber: storedOrderForm?.numberHouse?.delivery || null,
						city: storedOrderForm?.city?.delivery,
						zip: storedOrderForm?.zip.delivery,
						countryCode:
							DEFAULT_COUNTRY_CODE as VinistoHelperDllEnumsCountryCode,
					};
				if (deliveryMethod?.pickupPoint) {
					deliveryAddress = requestData;
				} else {
					const data = await AddressesApi.create({
						userId: authenticationContext?.vinistoUser?.id ?? '',
						...requestData,
					});
					queryClient.invalidateQueries([DELIVERY_ADDRESSES_QUERY_KEY]);
					if (!deliveryAddress) {
						deliveryAddress = get(data, 'address', undefined);
					}
					deliveryAddressId = get(data, 'address.id', null);
				}
			} catch (err) {
				return notificationContext.handleShowErrorNotification(
					'createOrder.error.response'
				);
			}
		}

		let billingAddressId = storedOrderForm?.billingInfoId ?? null;

		let billingAddress = invoiceAddresses.data?.billingInfos?.find(
			(address) => address?.id === billingAddressId
		);

		const shouldUseBillingAddress =
			(storedOrderForm?.useBillingInfo && !billingAddressId) ||
			(deliveryMethod?.pickupPoint && !billingAddressId);

		const shouldUseCompanyData =
			(deliveryMethod?.pickupPoint && storedOrderForm?.useCompanyData) ||
			!deliveryMethod?.pickupPoint;

		if (!storedOrderForm?.email?.billingInfo)
			storedOrderForm.email.billingInfo =
				authenticationContext.vinistoUser.email ?? '';

		// Create new billing address
		if (shouldUseBillingAddress) {
			try {
				const requestData: VinistoAuthDllModelsApiBillingInfoUserBillingInfoCreateParameters =
					{
						title: 'Adresa #1',
						userLoginHash: authenticationContext?.vinistoUser?.loginHash,
						ico: shouldUseCompanyData
							? storedOrderForm?.ico?.billingInfo || null
							: null,
						name: storedOrderForm?.name?.billingInfo,
						surname: storedOrderForm?.lastname?.billingInfo,
						email:
							storedOrderForm?.email?.billingInfo ??
							authenticationContext.vinistoUser.email ??
							'',
						phone: storedOrderForm?.phone.billingInfo,
						street: storedOrderForm?.street?.billingInfo?.value,
						landRegistryNumber:
							storedOrderForm?.landRegistryNumber?.billingInfo,
						houseNumber: storedOrderForm?.numberHouse?.billingInfo || null,
						city: storedOrderForm?.city?.billingInfo,
						zip: storedOrderForm?.zip?.billingInfo,
						dic: shouldUseCompanyData
							? storedOrderForm?.dic?.billingInfo || null
							: null,
						company: shouldUseCompanyData
							? storedOrderForm?.organization?.billingInfo || null
							: null,
						accountNumber: shouldUseCompanyData
							? storedOrderForm?.accountNumber?.billingInfo
									?.split('/')?.[0]
									?.trim()
									?.replace(/^[-]+/, '')
									?.trim() || null
							: null,
						countryCode:
							DEFAULT_COUNTRY_CODE as VinistoHelperDllEnumsCountryCode,
					};
				const data = await BillingInfoApi.create({
					userId: authenticationContext?.vinistoUser?.id ?? '',
					...requestData,
				});
				billingAddressId = data?.billingInfo?.id ?? null;
				billingAddress =
					data?.billingInfo as VinistoAuthDllModelsApiBillingInfoBillingInfo;
				queryClient.invalidateQueries([INVOICE_ADDRESSES_QUERY_KEY]);
			} catch (err) {
				return notificationContext.handleShowErrorNotification(
					'createOrder.error.response'
				);
			}
		}

		const billing =
			billingAddressId &&
			(storedOrderForm?.useBillingInfo || deliveryMethod?.pickupPoint)
				? {
						billingAddressId,
				  }
				: {
						billingAddress: deliveryAddress,
				  };

		const requestData: VinistoOrderDllModelsApiOrderOrdersAddSignedParameters =
			{
				currency: (localizationContext.activeCurrency?.currency ??
					DEFAULT_CURRENCY) as VinistoHelperDllEnumsCurrency,
				language: localizationContext.activeLanguageKey ?? DEFAULT_LANGUAGE,
				deliveryId: (deliveryMethod?.id as string) ?? null,
				pickupPoint: deliveryMethod?.pickupPoint
					? {
							addressee: `${billingAddress?.name} ${billingAddress?.surname}`,
							code: deliveryMethod?.pickupPoint?.code,
							email:
								billingAddress?.email ??
								authenticationContext?.vinistoUser?.email ??
								'',
							phone: billingAddress?.phone ?? '',
							type: deliveryMethod?.pickupPoint?.type,
							address: {
								city: deliveryMethod?.pickupPoint?.city,
								countryCode: deliveryMethod?.pickupPoint
									?.countryCode as VinistoHelperDllEnumsCountryCode,
								houseNumber: deliveryMethod?.pickupPoint?.houseNumber,
								landRegistryNumber:
									deliveryMethod?.pickupPoint?.landRegistryNumber,
								street: deliveryMethod?.pickupPoint?.street,
								zip: deliveryMethod?.pickupPoint?.zip,
							},
					  }
					: undefined,
				isNewsletterActive: storedOrderForm?.isNewsletterActive ?? false,
				paymentId: (paymentMethod?.id as string) ?? null,
				deliveryAddressId,
				...billing,
				authorizationParameters: {
					userLoginHash: authenticationContext?.vinistoUser?.loginHash,
				},
				utm: {
					source: utmPostData ? utmPostData?.source : null,
					medium: utmPostData ? utmPostData?.medium : null,
					campaign: utmPostData ? utmPostData?.campaign : null,
					gad: utmPostData ? utmPostData?.gad : null,
					gclId: utmPostData ? utmPostData?.gclId : null,
				},
			};

		const shouldUseSpecificSymbolAndCustomOrderNumber =
			(!deliveryMethod?.pickupPoint && storedOrderForm?.useBillingInfo) ||
			(deliveryMethod?.pickupPoint &&
				(storedOrderForm?.useCompanyData || storedOrderForm?.billingInfoId));

		if (shouldUseSpecificSymbolAndCustomOrderNumber) {
			requestData.deliveryAddressId = deliveryAddressId;

			if (storedOrderForm?.specSymbol) {
				requestData.specSymbol = storedOrderForm?.specSymbol;
			}
			if (storedOrderForm?.userCustomOrderNumber) {
				requestData.userCustomOrderNumber =
					storedOrderForm?.userCustomOrderNumber;
			}
		}

		return apiServiceInstance
			.post<VinistoOrderDllModelsApiReturnDataOrderReturn>(
				'order-api/orders/signed-user',
				requestData,
				true
			)
			.then((payload) => {
				if (!get(payload, 'order.id', null)) {
					throw new Error();
				}
				return new Promise<VinistoOrderDllModelsApiReturnDataOrderReturn>(
					(resolve) => {
						if (
							storedOrderForm?.isNewsletterActive === true &&
							authenticationContext?.vinistoUser?.isNewsletterActive === false
						) {
							NewsletterService.subscribe(storedUserState?.email ?? '')
								.then((result) => {
									if (!result as boolean) return;
									UserService.update(storedUserState?.id ?? '', {
										userLoginHash: get(storedUserState, 'loginHash'),
										email:
											storedUserState?.email ??
											authenticationContext.vinistoUser.email ??
											'',
										isNewsletterActive: true,
									}).then((newVinistoUser) => {
										authenticationContext.saveVinistoUser(newVinistoUser);
									});
								})
								.finally(() => resolve(payload));
						} else {
							resolve(payload);
						}
					}
				);
			})
			.then((payload) => {
				resetForm();
				setOrderPaymentState({
					creatingOrderPayment: false,
					createdOrderPayment: true,
				});
				modalContext.handleOpenModal(PAYMENT_MODAL, {
					order: payload,
					location,
				});
				return payload;
			})
			.catch((error: Error) => {
				setOrderPaymentState({
					creatingOrderPayment: false,
					createdOrderPayment: false,
				});
				if (
					error.message === NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR ||
					error.message === COUPON_USED_ERROR
				) {
					throw error;
				}
				if (error.message.includes('Name: ')) {
					const regex = /Name: (.*)(?= does not have assigned supplier\.)/;
					const match = error.message.match(regex);
					const extractedText = match?.[1];
					const errorMessage = (
						t(
							{ id: 'createOrder.error.response.productError' },
							{
								bundleName: extractedText,
							}
						) ?? ''
					).toString();
					notificationContext.handleShowErrorNotification(errorMessage);
					return;
				}
				notificationContext.handleShowErrorNotification(
					'createOrder.error.response'
				);
			});
	};

	const createUnregisteredOrder = (
		storedOrderForm: CartShippingDataFormSchema,
		resetForm: () => void
	) => {
		if (orderPaymentState.creatingOrderPayment) return;
		setOrderPaymentState({
			creatingOrderPayment: true,
			createdOrderPayment: false,
		});

		const deliveryAddress: VinistoOrderDllModelsApiOrderOrdersAddUnregisteredParameters['deliveryAddress'] =
			{
				email: storedOrderForm?.email.delivery || null,
				name: storedOrderForm?.name.delivery,
				surname: storedOrderForm?.lastname.delivery,
				company: storedOrderForm?.organization.delivery || null,
				phone: storedOrderForm?.phone.delivery,
				street: storedOrderForm?.street?.delivery?.value,
				landRegistryNumber: storedOrderForm?.landRegistryNumber.delivery,
				houseNumber: storedOrderForm?.numberHouse?.delivery || null,
				city: storedOrderForm?.city?.delivery,
				zip: storedOrderForm?.zip.delivery,
				countryCode: DEFAULT_COUNTRY_CODE as VinistoHelperDllEnumsCountryCode,
			};

		const shouldUseBillingAddress =
			(!deliveryMethod?.pickupPoint && storedOrderForm?.useBillingInfo) ||
			deliveryMethod?.pickupPoint;

		const shouldUseCompanyData =
			(deliveryMethod?.pickupPoint && storedOrderForm?.useCompanyData) ||
			!deliveryMethod?.pickupPoint;

		const requestData: VinistoOrderDllModelsApiOrderOrdersAddUnregisteredParameters =
			{
				currency: (localizationContext?.activeCurrency?.currency ??
					DEFAULT_CURRENCY) as VinistoHelperDllEnumsCurrency,
				language: localizationContext?.activeLanguageKey ?? DEFAULT_LANGUAGE,
				deliveryId: (deliveryMethod?.id as string) ?? null,
				pickupPoint: deliveryMethod?.pickupPoint
					? {
							addressee:
								`${storedOrderForm?.name?.billingInfo} ${storedOrderForm?.lastname?.billingInfo}` ||
								'',
							code: deliveryMethod?.pickupPoint?.code || '',
							type: deliveryMethod?.pickupPoint?.type,
							email: storedOrderForm?.email?.delivery ?? '',
							phone: storedOrderForm?.phone?.billingInfo || '',
							address: {
								city: deliveryMethod?.pickupPoint?.city,
								countryCode: deliveryMethod?.pickupPoint
									?.countryCode as VinistoHelperDllEnumsCountryCode,
								houseNumber: deliveryMethod?.pickupPoint?.houseNumber,
								landRegistryNumber:
									deliveryMethod?.pickupPoint?.landRegistryNumber,
								street: deliveryMethod?.pickupPoint?.street,
								zip: deliveryMethod?.pickupPoint?.zip,
							},
					  }
					: undefined,
				paymentId: (paymentMethod?.id as string) ?? null,
				isNewsletterActive: storedOrderForm?.isNewsletterActive ?? false,
				anonymousUserId: authenticationContext?.anonymousUID
					?.anonymousUserId as string,
				userEmail: storedOrderForm?.email?.delivery,
				deliveryAddress: !deliveryMethod?.pickupPoint
					? deliveryAddress
					: undefined,
				billingAddress: shouldUseBillingAddress
					? {
							ico: shouldUseCompanyData
								? storedOrderForm?.ico?.billingInfo || null
								: null,
							name: storedOrderForm?.name.billingInfo,
							surname: storedOrderForm?.lastname?.billingInfo,
							company: shouldUseCompanyData
								? storedOrderForm?.organization?.billingInfo || null
								: null,
							email: deliveryMethod?.pickupPoint
								? storedOrderForm?.email?.delivery || null
								: storedOrderForm?.email?.billingInfo || null,
							phone: storedOrderForm?.phone?.billingInfo,
							street: storedOrderForm?.street?.billingInfo?.value,
							landRegistryNumber:
								storedOrderForm?.landRegistryNumber?.billingInfo,
							houseNumber: storedOrderForm?.numberHouse?.billingInfo || null,
							city: storedOrderForm?.city?.billingInfo,
							zip: storedOrderForm?.zip.billingInfo,
							dic: shouldUseCompanyData
								? storedOrderForm?.dic?.billingInfo || null
								: null,
							bankCode: shouldUseCompanyData
								? storedOrderForm?.accountNumber?.billingInfo
										?.split('/')?.[1]
										?.trim() || null
								: null,
							accountNumber: shouldUseCompanyData
								? storedOrderForm?.accountNumber?.billingInfo
										?.split('/')?.[0]
										?.trim()
										?.replace(/^[-]+/, '')
										?.trim() || null
								: null,
							countryCode:
								DEFAULT_COUNTRY_CODE as VinistoHelperDllEnumsCountryCode,
					  }
					: deliveryAddress,
				utm: {
					source: utmPostData ? utmPostData?.source : null,
					medium: utmPostData ? utmPostData?.medium : null,
					campaign: utmPostData ? utmPostData?.campaign : null,
					gad: utmPostData ? utmPostData?.gad : null,
					gclId: utmPostData ? utmPostData?.gclId : null,
				},
			};

		const shouldUseSpecificSymbolAndCustomOrderNumber =
			(!deliveryMethod?.pickupPoint && storedOrderForm?.useBillingInfo) ||
			(deliveryMethod?.pickupPoint && storedOrderForm?.useCompanyData);

		if (shouldUseSpecificSymbolAndCustomOrderNumber) {
			if (storedOrderForm?.specSymbol) {
				requestData.specSymbol = storedOrderForm?.specSymbol;
			}
			if (storedOrderForm?.userCustomOrderNumber) {
				requestData.userCustomOrderNumber =
					storedOrderForm?.userCustomOrderNumber;
			}
		}

		return apiServiceInstance
			.post<VinistoOrderDllModelsApiReturnDataOrderReturn>(
				'order-api/orders/unregistered-user',
				requestData,
				true
			)
			.then((payload) => {
				if (!get(payload, 'order.id', null)) {
					throw new Error();
				}
				return new Promise<VinistoOrderDllModelsApiReturnDataOrderReturn>(
					(resolve) => {
						if (storedOrderForm?.isNewsletterActive) {
							NewsletterService.subscribe(
								get(payload, 'order.user.email', '')
							).finally(() => {
								resolve(payload);
							});
						} else {
							resolve(payload);
						}
					}
				);
			})
			.then((payload) => {
				resetForm();
				setOrderPaymentState({
					creatingOrderPayment: false,
					createdOrderPayment: true,
				});
				modalContext.handleOpenModal(PAYMENT_MODAL, {
					order: payload,
					location,
				});
				return payload;
			})
			.catch((error: Error) => {
				setOrderPaymentState({
					creatingOrderPayment: false,
					createdOrderPayment: false,
				});
				throw error;
			});
	};

	const clearDeliveryPayment = () => {
		setDeliveryMethod(null);
		setPaymentMethod(null);
	};

	const getDeliveries = (
		isForCustomerDelivery: boolean,
		isForStocking: boolean
	) =>
		apiServiceInstance
			.get<VinistoOrderDllModelsApiReturnDataDeliveriesReturn>(
				DELIVERIES_URI,
				true,
				undefined,
				[
					{ key: 'Language', value: DEFAULT_LANGUAGE },
					{ key: 'Currency', value: DEFAULT_CURRENCY },
					{ key: 'AllowedCountry', value: DEFAULT_COUNTRY_CODE },
					{ key: 'Limit', value: DELIVERIES_LIMIT },
					{ key: 'isForCustomerDelivery', value: isForCustomerDelivery },
					{ key: 'isForStocking', value: isForStocking },
				]
			)
			.then((payload) => payload);

	const orderServiceModel: IOrderServiceModel = {
		formValues,
		createSignedOrder,
		createUnregisteredOrder,
		clearDeliveryPayment,
		deliveryMethod,
		setDeliveryMethod,
		paymentMethod,
		setPaymentMethod,
		utmPostData,
		getDeliveries,
	};

	return (
		<OrderContext.Provider value={orderServiceModel}>
			{get(props, 'children', '')}
		</OrderContext.Provider>
	);
};

export default OrderServiceProvider;
