import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { filter, get, isEmpty, map, orderBy, uniqBy } from 'lodash-es';
import {
	DocumentHeaderAction,
	OpenGraphItemType,
	TwitterCardType,
} from 'Components/DocumentHeader/constants';
import Config from 'Config';
import useIdenticalBundles from 'Hooks/Queries/useIdenticalBundles';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import BundleService from 'Services/Bundle';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';
import { WarehouseContext } from 'Services/WarehouseService';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { useBundleDetail } from 'Pages/Bundle/hooks';
import { CategoryData } from 'Services/Bundle/interfaces';
import { TrackEvent } from 'Services/FacebookPixel';
import {
	fetchDeliveriesData,
	fetchPaymentsData,
} from 'Pages/Bundle/Components/BundleDetail/helpers';
import {
	ALLOWED_COUNTRY,
	CURRENCY,
	LANGUAGE,
	LIMIT,
} from 'Pages/Bundle/constants';
import { IQueryArgument } from 'Services/ApiService/interfaces';
import useAnalytics from 'Hooks/useAnalytics';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import { GaItem } from 'Hooks/useAnalytics/types';
import useBundlePrice from 'Hooks/useBundlePrice';

import BundleDetail from './Components/BundleDetail';
import BundleDetailSkeleton from './Components/BundleDetailSkeleton';
import { BundleDetailProps } from './interfaces';

import { VinistoProductDllModelsApiBundleBundle } from '@/api-types/product-api';

const Bundle = () => {
	const localizationContext = useContext(LocalizationContext);
	const notificationsContext = useContext(NotificationsContext);
	const { dispatch } = useContext(DocumentHeaderContext);
	const { fetchQuantity } = useContext(WarehouseContext);
	const { sendEvent: sendAnalyticsEvent } = useAnalytics();
	const localize = useLocalizedValue();
	const t = localizationContext.useFormatMessage();
	const navigate = useNavigate();
	const { itemUrl: bundleUrl } = useParams();

	const {
		data: bundleData,
		refetch: fetchBundleData,
		isLoading: isBundleLoading,
	} = useQuery({
		queryKey: ['bundleDetailData', bundleUrl],
		queryFn: () => BundleService.getBundleByUrl(bundleUrl ?? ''),
		onSuccess: (bundleData) => fetchQuantity(bundleData?.id ?? ''),
		onError: () => {
			notificationsContext.handleShowErrorNotification(
				'productDetail.loading.error'
			);
			navigate('/404', { replace: true });
		},
	});

	const bundle = useBundleDetail(bundleData);

	const { data: categoriesData, isLoading: isCategoryDataLoading } = useQuery({
		queryKey: ['bundleDetailCategoryData', bundle.bundleCateogryId],
		queryFn: () => BundleService.getBundleCategories(bundle.id),
		onSuccess: (data) => {
			if (data.length) bundleData && setMetaTags(bundle, data[0]);
			else bundleData && setMetaTags(bundle);
			return data || [];
		},
		onError: () => {
			bundleData && setMetaTags(bundle);
		},
		enabled: Boolean(bundle.bundleCateogryId),
	});

	const bundleCarouselsData = useQuery(
		['bundleDetailCarouselsData', bundle.id],
		() => BundleService.getBundleCarousels(bundle.id),
		{ enabled: Boolean(bundle.id) }
	);

	const supplierBundlesCarousel = useQuery(
		['supplierBundlesCarousel', bundle.id],
		() => {
			return BundleService.getSupplierBundlesCarousel(bundle.id);
		},
		{ enabled: Boolean(bundle.id) }
	);

	const { data: identicalBundlesData } = useIdenticalBundles(bundle.id);

	useEffect(() => {
		const extractIds = (
			bundles: VinistoProductDllModelsApiBundleBundle[] | undefined
		) => {
			if (!Array.isArray(bundles)) return [];
			return bundles
				.map((bundle) => bundle.id)
				.filter((id): id is string => Boolean(id));
		};

		if (!bundleCarouselsData.data && !Array.isArray(identicalBundlesData))
			return;

		const allIds = [
			...extractIds(bundleCarouselsData.data?.similarBundles ?? []),
			...extractIds(bundleCarouselsData.data?.manufacturerBundles ?? []),
			...extractIds(bundleCarouselsData.data?.lastViewedBundles ?? []),
			...extractIds(supplierBundlesCarousel.data?.bundles ?? []),
			...(Array.isArray(identicalBundlesData)
				? identicalBundlesData.map((bundle) => bundle.id)
				: []),
		].filter((id): id is string => Boolean(id));

		if (allIds.length > 0) {
			fetchQuantity(allIds);
		}
	}, [
		bundleCarouselsData.data,
		identicalBundlesData,
		fetchQuantity,
		supplierBundlesCarousel.data?.bundles,
	]);

	const deliveriesData = useQuery(
		['deliveries'],
		async () => {
			const requestQuery: IQueryArgument[] = [
				{ key: 'Language', value: LANGUAGE },
				{ key: 'Currency', value: CURRENCY },
				{ key: 'AllowedCountry', value: ALLOWED_COUNTRY },
				{ key: 'Limit', value: LIMIT },
				{ key: 'isForCustomerDelivery', value: true },
				{ key: 'isForStocking', value: false },
				{ key: 'IsCache', value: true },
			];
			return await fetchDeliveriesData(requestQuery);
		},
		{
			cacheTime: 0,
		}
	);

	const deliveries = useMemo(() => {
		const trimmedDeliveries = map(
			// @ts-expect-error fetch fn is written incorrectly, it needs to throw an error not return an empty array!
			deliveriesData.data?.deliveries ?? [],
			(delivery) => ({
				...delivery,
				name: map(delivery.name, (nameItem) => ({
					...nameItem,
					value: nameItem.value.trim(),
				})),
			})
		);

		const activeDeliveries = orderBy(
			uniqBy(filter(trimmedDeliveries, 'isActive'), 'alternativeName[0].value'),
			['order', (delivery) => delivery.deliveryTime ?? 0],
			['asc', 'asc']
		);
		return activeDeliveries;
	}, [deliveriesData]);

	const paymentsData = useQuery(
		['payments'],
		async () => {
			const requestQuery: IQueryArgument[] = [
				{ key: 'Language', value: LANGUAGE },
				{ key: 'AllowedCountry', value: ALLOWED_COUNTRY },
				{ key: 'IsCache', value: true },
			];
			return await fetchPaymentsData(requestQuery);
		},
		{
			cacheTime: 0,
		}
	);

	const payments = useMemo(() => {
		const activePayments = orderBy(
			uniqBy(
				filter(get(paymentsData, 'data.payments', []), {
					image: {},
					isActive: true,
				}),
				'name[0].value'
			),
			'order',
			'asc'
		);
		return activePayments;
	}, [deliveriesData.isFetched, deliveriesData.data]);

	const setMetaTags = useCallback(
		(bundle: BundleDetailProps, category?: CategoryData) => {
			const breadcrumbs: Record<string, any>[] = [
				{
					'@type': 'ListItem',
					position: 1,
					name: Config.domainName,
					item: Config.baseUrl,
				},
			];
			if (!isEmpty(category)) {
				breadcrumbs.push({
					'@type': 'ListItem',
					position: 2,
					name: localize(category?.name ?? []),
					item: `${Config.baseUrl}${t({
						id: 'routes.category.route',
					})}/${localize(category?.url ?? []) ?? ''}`,
				});
			}
			breadcrumbs.push({
				'@type': 'ListItem',
				position: breadcrumbs.length + 1,
				name: bundle.bundleName,
			});
			dispatch({
				type: DocumentHeaderAction.set,
				value: {
					title: `${t({ id: 'app.title.page' }, { title: bundle.bundleName })}`,
					description: bundle.bundleDescription,
					twitterCard: {
						card: TwitterCardType.summary,
						title: bundle.bundleName,
						description: bundle.bundleDescription,
						image: bundle.bundleImageSmall,
					},
					openGraph: {
						type: OpenGraphItemType.product,
						title: bundle.bundleName,
						url: `${Config.baseUrl}${t({
							id: 'routes.product.route',
						})}/${bundle.bundleUrl}`,
						description: bundle.bundleDescription,
						image: bundle.bundleImageSmall,
						productPriceAmount: bundle.bundlePriceWithVAT,
						productPriceCurrency: bundle.bundlePriceCurrency,
					},
					jsonLd: [
						{
							'@context': 'https://schema.org',
							'@type': 'BreadcrumbList',
							itemListElement: breadcrumbs,
						},
						{
							'@context': 'https://schema.org/',
							'@type': 'Product',
							name: bundle.bundleName,
							image: bundle?.bundleImages[0]?.src,
							description: bundle.bundleDescription,
							sku: bundle.id ?? '',
							brand: {
								'@type': 'Brand',
								name: bundle.bundleName,
							},
							offers: {
								'@type': 'Offer',
								price: bundle.bundlePriceWithVAT,
								priceCurrency: bundle.bundlePriceCurrency,
								itemCondition: 'https://schema.org/NewCondition',
							},
						},
					],
				},
			});
		},
		[
			bundle.bundleName,
			dispatch,
			t,
			bundle.bundleDescription,
			localize,
			bundle.bundlePriceWithVAT,
			bundle.bundlePriceCurrency,
		]
	);

	const getBundlePrice = useBundlePrice();
	const { price: discountedPrice } = getBundlePrice(bundle);

	useEffect(() => {
		if (!bundle.id || isCategoryDataLoading) return;
		const currency = bundle.bundleLocalizedPrice?.currency ?? '';
		const price = bundle.bundleLocalizedPrice?.value ?? 0;

		TrackEvent('track', 'ViewContent', {
			content_type: 'product',
			content_ids: [bundle?.id],
			content_category: categoriesData && (categoriesData[0]?.name ?? ''),
			value:
				Math.round(
					(bundle.bundleLocalizedPrice?.value + Number.EPSILON) * 100
				) / 100 || 0,
			content_name: bundle.bundleName,
			currency,
		});

		const gaItems: GaItem[] = [
			{
				item_category:
					localize(categoriesData && categoriesData[0]?.name) ?? '',
				discount: price - discountedPrice,
				index: 0,
				item_id: bundle.id,
				item_name: bundle.bundleName,
				price,
				quantity: bundle.availableQuantity[0],
			},
		];

		sendAnalyticsEvent(GA_EVENT.VIEW_ITEM, {
			currency,
			value: discountedPrice,
			items: gaItems,
		});
	}, [
		bundle,
		sendAnalyticsEvent,
		categoriesData,
		localize,
		discountedPrice,
		isCategoryDataLoading,
	]);

	if (isBundleLoading) return <BundleDetailSkeleton />;

	return (
		<BundleDetail
			bundle={bundle}
			categoriesData={categoriesData}
			carouselData={bundleCarouselsData.data}
			carouselSupplierData={supplierBundlesCarousel}
			deliveriesData={deliveries}
			paymentsData={payments}
			refreshData={fetchBundleData}
		/>
	);
};

export default Bundle;
