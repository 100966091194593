import { useCallback, useContext } from 'react';
import { BundlesWithFiltersContext } from 'Pages/Category/Components/CategoryBundlesWithFilters/context';
import { LocalizationContext } from 'Services/LocalizationService';

import OptionsList from '../OptionsList';
import { Option } from '../OptionsList/interfaces';

// This component is very similar to IsInStockFilter (minus heading), consider refactoring them into a single component
const IsDiscountedFilter = () => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const {
		query,
		setQuery,
		specificationsQuery,
		isDiscountedParam,
		specificationsWithBundleFilters,
	} = useContext(BundlesWithFiltersContext);

	const { isDiscountedFilters } = specificationsWithBundleFilters;
	const isDiscountedOccurence = isDiscountedFilters.find(
		(filter) => filter.value === true
	)?.occurence;

	const isLoading = specificationsQuery.isLoading;

	const handleOnOptionsChange = useCallback(
		(options: Option[]) => {
			const [option] = options;

			const updatedQuery = {
				...query,
				[isDiscountedParam]: option.checked ? t({ id: 'yes' }) : undefined,
			};
			setQuery(updatedQuery);
		},
		[isDiscountedParam, query, setQuery, t]
	);

	return (
		<fieldset className="pb-3">
			<OptionsList
				options={[
					{
						id: 'isDiscounted',
						value: `${t({ id: 'ProductsOnSale' })}`,
						checked: query[isDiscountedParam] === `${t({ id: 'yes' })}`,
						suffix: `(${isDiscountedOccurence ?? 0})`,
					},
				]}
				isLoading={isLoading}
				onChange={handleOnOptionsChange}
			/>
		</fieldset>
	);
};

export default IsDiscountedFilter;
