import LinkWidget from 'Pages/Home/Components/LinkWidget';
import { DeviceServiceContext } from 'Services/DeviceService';
import { useContext, useEffect, useState } from 'react';
import useSectionLinkWidgetsQuery from 'Hooks/use-section-link-widgets-query';
import LinkWidgetList from 'Components/link-widget';
import useWebSection from 'Hooks/use-web-section';
import cx from 'classnames';
import { useSwipeable } from 'react-swipeable';

import { LayoutPropsInterface } from './interfaces';
import styles from './styles.module.css';

import { Allowed_Sections } from '@/domain/link-widget/enums';

const Layout = ({ children }: LayoutPropsInterface) => {
	const { isEshop } = useWebSection();

	return (
		<>
			{isEshop && <NotificationBar />}
			{children}
		</>
	);
};

export default Layout;

const NotificationBar = () => {
	const { isDesktop } = useContext(DeviceServiceContext);

	const section = isDesktop
		? Allowed_Sections.HEADER_DESKTOP
		: Allowed_Sections.HEADER_MOBILE;
	if (isDesktop)
		return (
			<div
				className={styles.widgetWrapper}
				id="header-widgets"
			>
				<LinkWidget
					container={false}
					section={section}
					className={styles.header}
					itemClassName={styles.linkWidgetItem}
					widgetLimit={4}
				/>
			</div>
		);

	return <MobileRotator section={section} />;
};

type MobileRotatorProps = {
	section: Allowed_Sections;
};

const MobileRotator = ({ section }: MobileRotatorProps) => {
	const { query, filteredLinks } = useSectionLinkWidgetsQuery(
		section,
		undefined,
		4
	);
	const [currentIndex, setCurrentIndex] = useState(0);

	const filteredLinksLength = filteredLinks?.length ?? 0;

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredLinksLength);
		}, 5000);

		return () => clearInterval(interval);
	}, [filteredLinks, filteredLinksLength]);

	const swipeHandlers = useSwipeable({
		onSwipedLeft: () =>
			setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredLinksLength),
		onSwipedRight: () =>
			setCurrentIndex(
				(prevIndex) =>
					(prevIndex - 1 + filteredLinksLength) % filteredLinksLength
			),
	});

	return (
		<div {...swipeHandlers}>
			{filteredLinksLength > 0 && (
				<LinkWidgetList
					itemClassName={styles.linkWidgetItem}
					className={cx(styles.header, styles.headerMobile)}
					isLoading={query.isLoading}
					linkWidgets={[filteredLinks?.[currentIndex]].map((link) => ({
						id: link?.id,
						name: link?.name,
						imageLocator: link?.imageLocator,
						to: link?.url,
						type: link?.type,
					}))}
				/>
			)}
		</div>
	);
};
