import configuration from './_config.json';

const Config = {
	...configuration,
	baseUrl: import.meta.env.VITE_BASE_URI || '',
	apiUrl: import.meta.env.VITE_API_URI || '',
	advisorUrl: import.meta.env.VITE_ADVISOR_URI || '',
};

export default Config;
