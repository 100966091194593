import { useContext } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import {
	PopoverTypes,
	QuantityBoxPlusBtnTypes,
} from 'Components/QuantityBox/constants';
import useBundlePrice from 'Hooks/useBundlePrice';
import useTodayTomorrowDate from 'Hooks/useTodayTomorrowDate/useTodayTomorrowDate';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import { WarehouseContext } from 'Services/WarehouseService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { DirectQuantityBox, StandardQuantityBox } from 'Components/QuantityBox';
import quantityBoxStyles from 'Components/QuantityBox/styles.module.css';
//import { DiscountPercentage } from 'Components/DiscountPercentage';
import { DiscountPercentage } from 'vinisto_ui';

import DisabledShopControlsInfo from '../TemporaryUnavailableInfo';
import IsGiftInfo from '../IsGiftInfo';

import {
	BundleItemVariants,
	QuantityBoxVariants,
	SHOW_EXACT_UNDER_COUNT,
} from './constants';
import { BundleItemProps } from './interfaces';
import bundleItemStyles from './styles.module.css';

const styles: { [key: string]: string } = {
	...quantityBoxStyles,
	...bundleItemStyles,
};

const BundleItem = ({
	bundle,
	variant = BundleItemVariants.STANDARD,
	quantityBox,
	isLoading,
}: BundleItemProps) => {
	//TODO: Create new refactored component for BundlePage
	const t = useContext(LocalizationContext).useFormatMessage();
	const warehouseContext = useContext(WarehouseContext);
	const { isMobile } = useContext(DeviceServiceContext);
	const getBundlePrice = useBundlePrice();
	const getDateLabel = useTodayTomorrowDate();
	const {
		discountedFromPriceVat,
		priceWithVat,
		price,
		isPriceDiscounted,
		currencyTitle,
	} = getBundlePrice(bundle);

	const discountedFromPriceWithVAT =
		discountedFromPriceVat !== null
			? createCurrencyValue(discountedFromPriceVat)
			: null;
	const priceWithVAT = createCurrencyValue(priceWithVat);
	const priceWithoutVAT = createCurrencyValue(price);
	const availableQuantity = warehouseContext.getQuantity(bundle?.id ?? '') ?? 0;
	const isAvailable = (availableQuantity as number) > 0;
	const isDeleted = bundle?.isDeleted;
	const isTemporaryUnavailable = bundle?.temporaryUnavailable ?? false;
	const isGift = bundle?.isGift ?? false;
	const isSet = bundle?.isSet;
	const isSaleOver = bundle?.isSaleOver;

	const isSetDiscount = Boolean(
		(bundle?.prices[0]?.valueWithVat ?? 0) <
			(bundle?.prices[1]?.valueWithVat ?? 0)
	);

	const standardBundlePriceWithVAT = createCurrencyValue(
		bundle?.prices[0]?.valueWithVat ?? 0
	);
	const standardBundlePriceWithoutVAT = createCurrencyValue(
		bundle?.prices[0]?.value ?? 0
	);

	const bundlePriceWithVAT = createCurrencyValue(
		bundle?.prices[1]?.valueWithVat ?? 0
	);

	const supplier =
		bundle?.supplier?.nameWeb ??
		t({
			id: 'productDetail.seller.name.others',
		});

	if (isSaleOver)
		return (
			<DisabledShopControlsInfo
				isMobile={isMobile}
				translations={{
					cta:
						t({
							id: 'bundle.isSaleOver.productDetail.cta',
						})?.toString() ?? '',
					title:
						t({
							id: 'bundle.isSaleOver.productDetail.title',
						})?.toString() ?? '',
					seller:
						t({
							id: 'productDetail.seller.name',
						})?.toString() ?? '',
					withoutVat:
						t({
							id: 'basket.priceWithoutVAT',
						})?.toString() ?? '',
				}}
				price={`${standardBundlePriceWithVAT} Kč`}
				priceNoVat={`${standardBundlePriceWithoutVAT} Kč`}
				supplier={`${supplier}`}
			/>
		);

	if (isTemporaryUnavailable)
		return (
			<DisabledShopControlsInfo
				isMobile={isMobile}
				translations={{
					cta:
						t({
							id: 'bundle.temporaryUnavailable.productDetail.cta',
						})?.toString() ?? '',
					title:
						t({
							id: 'bundle.temporaryUnavailable.productDetail.title',
						})?.toString() ?? '',
					seller:
						t({
							id: 'productDetail.seller.name',
						})?.toString() ?? '',
					withoutVat:
						t({
							id: 'basket.priceWithoutVAT',
						})?.toString() ?? '',
				}}
				price={`${bundlePriceWithVAT} Kč`}
				priceNoVat={`${priceWithoutVAT} Kč`}
				supplier={`${supplier}`}
			/>
		);

	if (isGift) return <IsGiftInfo />;

	if (variant === BundleItemVariants.COMPACT) {
		return (
			<div className={`${styles.wrapper} ${styles.compact}`}>
				<div className={styles.wrapperInfo}>
					<span className={styles.availableCount}>
						{availableQuantity === undefined || isLoading ? (
							<Skeleton width="120px" />
						) : availableQuantity > SHOW_EXACT_UNDER_COUNT ? (
							t(
								{ id: 'bundleAvailability.inStock.moreThanCount2' },
								{
									valueWrap: (
										<span
											className={cx(styles.availableCountValue, {
												[styles.textGreen]: availableQuantity > 0,
											})}
										>
											{t(
												{
													id: 'bundleAvailability.inStock.moreThanCount2.valueWrap',
												},
												{
													value: (
														<>
															{t(
																{
																	id: 'bundleAvailability.inStock.moreThanCount.info',
																},
																{
																	count: SHOW_EXACT_UNDER_COUNT,
																}
															)}
														</>
													),
												}
											)}
										</span>
									),
								}
							)
						) : (
							<span className={styles.availableCountName}>
								{availableQuantity < 1 && (
									<span className={styles.stockNotAvaible}>
										{t({
											id: 'bundleAvailability.outOfStock',
										})}{' '}
										|{' '}
									</span>
								)}
								{t(
									{ id: 'bundle.warehouse.info.stock' },
									{
										value: (
											<span
												className={cx(styles.availableCountValue, {
													[styles.textGreen]: availableQuantity > 0,
												})}
											>
												{t(
													{ id: 'bundle.warehouse.quantity' },
													{
														count: availableQuantity,
													}
												)}
											</span>
										),
									}
								)}
							</span>
						)}
					</span>
					{isAvailable && warehouseContext.deliveryDate !== undefined && (
						<>
							{' '}
							|{' '}
							<span className={styles.deliveryDate}>
								{t(
									{ id: 'bundle.supplier.deliveryShortDate' },
									{
										date: (
											<span
												className={`${styles.deliveryDateValue} ${styles.textGreen}`}
											>
												{getDateLabel(warehouseContext.deliveryDate)}
											</span>
										),
									}
								)}
							</span>
						</>
					)}
				</div>
				<div className={styles.shopWrap}>
					<div className="d-flex align-items-center gap-3">
						<div className={styles.pricesWrap}>
							{!isSet && discountedFromPriceWithVAT !== null && (
								<div className={styles.priceWithVatDiscounted}>
									{t(
										{ id: 'price' },
										{
											value: discountedFromPriceWithVAT,
											currency: currencyTitle,
										}
									)}
								</div>
							)}
							{isSet && isSetDiscount && (
								<div className={styles.priceWithVatDiscounted}>
									{t(
										{ id: 'price' },
										{
											value: bundlePriceWithVAT ?? 0,
											currency: currencyTitle,
										}
									)}
								</div>
							)}
							{isLoading ? (
								<Skeleton width="60px" />
							) : (
								<div className={styles.priceWithVat}>
									{t(
										{ id: 'price' },
										{
											value: priceWithVAT,
											currency: currencyTitle,
										}
									)}
								</div>
							)}
							<div className={styles.priceWithoutVatValue}>
								{isLoading ? (
									<Skeleton width="75px" />
								) : (
									t(
										{ id: 'price.withoutVAT' },
										{
											priceWithCurrency: (
												<span
													className={cx({
														[styles.priceWithoutVatDiscounted]:
															isPriceDiscounted || isSetDiscount,
													})}
												>
													{t(
														{ id: 'price' },
														{
															value: priceWithoutVAT,
															currency: currencyTitle,
														}
													)}
												</span>
											),
										}
									)
								)}
							</div>
						</div>
						<DiscountPercentage
							discountedPriceWithVat={priceWithVat}
							standardPriceWithVat={
								discountedFromPriceVat ?? bundle?.prices[1]?.valueWithVat ?? 0
							}
						/>
					</div>

					{isAvailable && !isDeleted && (
						<div className={styles.addToBasketWrap}>
							{quantityBox === QuantityBoxVariants.STANDARD ? (
								<StandardQuantityBox
									className={cx(styles.quantityBox, styles.productDetail)}
									bundle={bundle}
									isLoading={isLoading}
									orderLimitation={bundle.orderLimitation}
								/>
							) : (
								<DirectQuantityBox
									className={cx(styles.quantityBox, styles.productDetail)}
									plusBtnType={QuantityBoxPlusBtnTypes.EXTENDED}
									bundle={bundle}
									isLoading={isLoading}
									orderLimitation={bundle.orderLimitation}
									countPopover={true}
									popoverType={PopoverTypes.WINDOW}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}

	return (
		<div className={`${styles.wrapper} ${styles.standard}`}>
			<div className={styles.priceWithVat}>
				{!isSet && discountedFromPriceWithVAT !== null && (
					<span className={styles.priceWithVatDiscounted}>
						{t(
							{ id: 'price' },
							{
								value: discountedFromPriceWithVAT,
								currency: currencyTitle,
							}
						)}
					</span>
				)}
				{isSet && isSetDiscount && (
					<span className={styles.priceWithVatDiscounted}>
						{t(
							{ id: 'price' },
							{
								value: bundlePriceWithVAT ?? 0,
								currency: currencyTitle,
							}
						)}
					</span>
				)}
				{isLoading ? (
					<Skeleton
						width="70px"
						height="36px"
						className="d-block"
					/>
				) : (
					<div className="d-flex align-items-center gap-3 mt-2 mt-xxl-0">
						<div>
							{t(
								{ id: 'price' },
								{
									value: priceWithVAT,
									currency: currencyTitle,
								}
							)}
						</div>
						<DiscountPercentage
							discountedPriceWithVat={priceWithVat}
							standardPriceWithVat={
								discountedFromPriceVat ?? bundle?.prices[1]?.valueWithVat ?? 0
							}
						/>
					</div>
				)}
			</div>
			<div className={styles.priceWithoutVatValue}>
				{isLoading ? (
					<Skeleton width="75px" />
				) : (
					t(
						{ id: 'price.withoutVAT' },
						{
							priceWithCurrency: (
								<span
									className={cx({
										[styles.priceWithoutVatDiscounted]:
											isPriceDiscounted || isSetDiscount,
									})}
								>
									{t(
										{ id: 'price' },
										{
											value: priceWithoutVAT,
											currency: currencyTitle,
										}
									)}
								</span>
							),
						}
					)
				)}
			</div>
			<div className={styles.avaibilityDeliveryWrapper}>
				<span className={styles.availableCount}>
					{availableQuantity === undefined || isLoading ? (
						<Skeleton width="120px" />
					) : availableQuantity > SHOW_EXACT_UNDER_COUNT ? (
						t(
							{ id: 'bundleAvailability.inStock.moreThanCount2' },
							{
								valueWrap: (
									<span
										className={cx(styles.availableCountValue, {
											[styles.textGreen]: availableQuantity > 0,
										})}
									>
										{t(
											{
												id: 'bundleAvailability.inStock.moreThanCount2.valueWrap',
											},
											{
												value: (
													<>
														{t(
															{
																id: 'bundleAvailability.inStock.moreThanCount.info',
															},
															{
																count: SHOW_EXACT_UNDER_COUNT,
															}
														)}
													</>
												),
											}
										)}
									</span>
								),
							}
						)
					) : (
						t(
							{ id: 'bundle.warehouse.info.stock' },
							{
								value: (
									<span
										className={cx(styles.availableCountValue, {
											[styles.textGreen]: availableQuantity > 0,
										})}
									>
										{t(
											{ id: 'bundle.warehouse.quantity' },
											{
												count: availableQuantity,
											}
										)}
									</span>
								),
							}
						)
					)}
					<span
						className={cx(styles.availableCountValue, {
							[styles.textGreen]: availableQuantity > 0,
						})}
					>
						{', '}
						{t(
							{
								id: 'bundle.supplier.avaibility.available',
							},
							{
								value: t({
									id: 'bundle.supplier.avaibility.centralWarehouse',
								}),
							}
						)}
					</span>
				</span>
				{isAvailable ? (
					<div className={styles.avaibilityDelivery}>
						{warehouseContext.deliveryDate !== undefined && (
							<div className={styles.deliveryDate}>
								{t(
									{ id: 'bundle.supplier.deliveryDate' },
									{
										date: (
											<span
												className={`${styles.deliveryDateValue} ${styles.textGreen}`}
											>
												{getDateLabel(warehouseContext.deliveryDate, true)}
											</span>
										),
									}
								)}
							</div>
						)}
					</div>
				) : isLoading ? (
					<Skeleton
						width="100px"
						count={1.6}
						inline
						className="ms-0 me-0"
					/>
				) : (
					<span className="vinisto-wine__stock--not-available fw-bolder">
						{', '}
						{t({
							id: 'bundleAvailability.outOfStock',
						})}
					</span>
				)}
			</div>

			{isAvailable && (
				<>
					{quantityBox === QuantityBoxVariants.STANDARD ? (
						<StandardQuantityBox
							className={cx(
								styles.quantityBox,
								styles.productDetail,
								'other-sellers'
							)}
							bundle={bundle}
							isLoading={isLoading}
							orderLimitation={bundle.orderLimitation}
						/>
					) : (
						<DirectQuantityBox
							className={cx(
								styles.quantityBox,
								styles.productDetail,
								'other-sellers'
							)}
							plusBtnType={QuantityBoxPlusBtnTypes.EXTENDED}
							bundle={bundle}
							isLoading={isLoading}
							orderLimitation={bundle.orderLimitation}
						/>
					)}
				</>
			)}
			<div className={styles.seller}>
				{isLoading ? (
					<Skeleton
						width="100px"
						count={1.6}
						inline
						className="ms-0 me-0"
					/>
				) : (
					<>
						<span className={styles.sellerTitle}>{`${t({
							id: 'productDetail.seller.name',
						})}: `}</span>
						<span className={styles.sellerName}>{supplier}</span>
					</>
				)}
				{', '}
				{t(
					{
						id: 'bundle.warehouse.from',
					},
					{
						value: (
							<span className={styles.bolder}>
								{t({
									id: 'bundle.warehouse.from.vinisto',
								})}
							</span>
						),
					}
				)}
			</div>
		</div>
	);
};

export default BundleItem;
