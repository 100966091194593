import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-bootstrap/Modal';
import useChat from 'Hooks/useChat';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';
import Config from 'Config';

import ModalCloseIcon from '../Modal/Components/ModalCloseIcon';

import styles from './style.module.css';

const { advisorUrl: ADVISOR_URL } = Config;

const Advisor = () => {
	const { isLoggedIn, vinistoUser, anonymousUID } = useContext(
		AuthenticationContext
	);
	const { handleOnLoadBasket } = useContext(BasketContext);
	const { showWidget, hideWidget } = useChat();

	const navigate = useNavigate();
	const [show, setShow] = useState(true);

	const uuidRef = useRef<string | null>(null);

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout> | undefined;

		const hideWidgetOrTryLater = () => {
			if (document.getElementById('smartsupp-widget-container')?.children) {
				hideWidget();
				clearTimeout(timer);
			} else {
				timer = setTimeout(hideWidgetOrTryLater, 500);
			}
		};

		hideWidgetOrTryLater();

		return () => {
			clearTimeout(timer);
			showWidget();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		uuidRef.current = uuidv4();
		return () => {
			uuidRef.current = null;
		};
	}, []);

	const handleClose = () => {
		setShow(false);
		showWidget();
		navigate('..');
	};

	const onWatchEventHandler = useCallback(() => {
		handleOnLoadBasket();
	}, [handleOnLoadBasket]);

	useEffect(() => {
		window.addEventListener('message', onWatchEventHandler);
		return () => window.removeEventListener('message', onWatchEventHandler);
	}, [onWatchEventHandler]);

	const url = new URL(ADVISOR_URL);
	if (isLoggedIn && vinistoUser.loginHash) {
		url.searchParams.append('userLoginHash', vinistoUser.loginHash);
	} else if (anonymousUID.anonymousUserId) {
		url.searchParams.append('anonymousUserId', anonymousUID.anonymousUserId);
	}

	return (
		<Modal
			show={show}
			onHide={handleClose}
			dialogClassName={styles.dialog}
			contentClassName={styles.content}
			backdrop="static"
		>
			<Modal.Body className={styles.body}>
				<button
					className={styles.closeButton}
					onClick={handleClose}
				>
					<ModalCloseIcon />
				</button>
				{uuidRef.current && (
					<iframe
						className={styles.iframe}
						title="advisor"
						src={url.href}
						frameBorder={0}
						key={uuidRef.current}
					></iframe>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default Advisor;
