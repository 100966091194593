import { useContext } from 'react';
import { VinistoOrderDllModelsApiDeliveryDelivery } from 'vinisto_api_client/src/api-types/order-api';
import ShippingItem from 'Pages/CartShippingPayment/Components/ShippingItem';
import { OrderContext } from 'Services/OrderService/context';
import { DeliveryMethodsPlacement } from 'Components/DeliveryMethods/types';

import { useDeliveryItem } from './hooks';

const DeliveryItem = ({
	delivery,
	isLoading = false,
	selectable = true,
	monthName,
	dataTestid,
	placement = DeliveryMethodsPlacement.PRODUCT_DETAIL,
}: {
	delivery: VinistoOrderDllModelsApiDeliveryDelivery;
	isLoading?: boolean;
	selectable?: boolean;
	monthName?: boolean;
	dataTestid?: string;
	placement?: DeliveryMethodsPlacement;
}) => {
	const deliveryProps = useDeliveryItem(delivery, selectable, monthName);
	const orderContext = useContext(OrderContext);
	const handleReset = () => orderContext.setDeliveryMethod(null);

	if (!selectable) {
		return (
			<ShippingItem
				{...deliveryProps}
				onClick={() => {
					return;
				}}
				isLoading={isLoading}
				selectable={selectable}
				placement={placement}
			/>
		);
	}

	return (
		<ShippingItem
			{...deliveryProps}
			handleReset={handleReset}
			isLoading={isLoading}
			dataTestid={dataTestid}
			placement={placement}
		/>
	);
};

export default DeliveryItem;
