import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

type TInfoColumnProps = {
	links: {
		to: string | null;
		name: ReactNode;
	}[];
};

const InfoColumn = ({ links }: TInfoColumnProps) => {
	return (
		<ul className="nav flex-column underline-effect">
			{links?.map(({ name, to }, index) => (
				<li
					key={index}
					className="nav-item"
				>
					{typeof name === 'string' && typeof to === 'string' ? (
						<NavLink
							to={to}
							className="vnav-link p-0 underline-item"
						>
							{name}
						</NavLink>
					) : (
						<div className="vnav-link">{name}</div>
					)}
				</li>
			))}
		</ul>
	);
};

export default InfoColumn;
