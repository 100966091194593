import {
	createContext,
	createRef,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	entriesIn,
	find,
	forEach,
	get,
	map,
	set,
	uniq,
	valuesIn,
} from 'lodash-es';
import { dayjsInstance as dayjs } from 'Services/Date';
import {
	createIntl,
	IntlShape,
	MessageDescriptor,
	MessageFormatElement,
} from 'react-intl';
import { IntlProvider } from 'react-intl';

import { StorageContext } from '../StorageService/context';

import {
	ICountry,
	ICurrency,
	ILocalizationContextValue,
	ILocalizationServiceProps,
	IMessage,
} from './interfaces';
import { DEFAULT_LANGUAGE } from './constants';
import { getBrowserLanguage } from './helpers';
import locale_cs from './translations/cs.json';
// import locale_de from './translations/de.json';
// import locale_en from './translations/en.json';
// import locale_sk from './translations/sk.json';
import 'dayjs/locale/cs';

dayjs.locale(DEFAULT_LANGUAGE);

const translations = {
	cs: locale_cs,
	// en: locale_en,
	// de: locale_de,
	// sk: locale_sk,
};

const countries: Array<ICountry> = [
	{ code: 'cs', title: 'Čeština', lang: 'cs' },
	// { code: 'gb', title: 'English', lang: 'en' },
	// { code: 'sk', title: 'Slovencina', lang: 'sk' },
	// { code: 'de', title: 'Nemcina', lang: 'de' },
];

const currencies: ICurrency[] = [
	{ lang: 'cs', currency: 'CZK', title: 'Kč' },
	{ lang: 'en', currency: 'EUR', title: '€' },
	{ lang: 'sk', currency: 'EUR', title: '€' },
	{ lang: 'de', currency: 'EUR', title: '€' },
];

const browserLanguage = getBrowserLanguage();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getLangugeKey = (currentLang: string) => {
	// if (currentLang === 'cs') {
	return 'CZECH';
	// } else if (currentLang === 'en') {
	// 	return 'ENGLISH';
	// } else if (currentLang === 'sk') {
	// 	return 'SLOVAK';
	// } else if (currentLang === 'de') {
	// 	return 'GERMAN';
	// }
};

const defaultLocalizationContextValue: ILocalizationContextValue = {
	activeLanguage: browserLanguage,
	activeLanguageKey: getLangugeKey(browserLanguage),
	prevLanguage: createRef(),
	prevLanguageKey: createRef(),
	activeCurrency: find(
		currencies,
		(currency) => get(currency, 'code', 'cs') === browserLanguage
	) ?? { lang: 'cs', currency: 'CZK', title: 'Kč' },
	changeLanguage: () => null,
	useFormatMessage:
		() =>
		// eslint-disable-next-line react/function-component-definition, react/display-name
		() =>
			null,
	useFormatMessageInstance:
		// eslint-disable-next-line react/function-component-definition, react/display-name
		() => null,
	useFormatMessageAll: () => () => [],
	useFormatMessageAllStrings: () => () => [],
	useFormatMessageFromLanguage: () => () => '',
	useAllMessagesFromLanguage: () => () => ({}),
	currencies,
	countries,
};

/**
 * Creates object with all locale intls
 */
const loadIntls = (): Record<string, IntlShape> => {
	const intls = {};
	forEach(entriesIn(translations), ([locale, messages]) => {
		set(intls, `[${locale}]`, createIntl({ locale, messages }));
	});
	return intls;
};

const intls = loadIntls();

export const LocalizationContext = createContext(
	defaultLocalizationContextValue
);

/**
 * Localization Service Provider
 * @class LocalizationServiceProvider
 * @return JSX Component
 */
const LocalizationServiceProvider: FC<ILocalizationServiceProps> = (props) => {
	const storageContext = useContext(StorageContext);
	const [activeLanguage, setActiveLanguage] = useState(
		defaultLocalizationContextValue.activeLanguage
	);
	const prevLanguage = useRef(null);
	const prevLanguageKey = useRef(null);

	const useFormatMessageAll = () => getAllLangsForMessage;
	const useFormatMessageFromLanguage = () => getMessageForLanguage;
	const useFormatMessageAllStrings = () => getAllLangStringsForMessage;

	const changeLanguage = useCallback(
		(nextLanguage: string): void => {
			if (activeLanguage !== nextLanguage) {
				set(prevLanguage, 'current', activeLanguage);
				set(prevLanguageKey, 'current', getLangugeKey(activeLanguage));
				setActiveLanguage(nextLanguage);
				storageContext.StorageService.setItem('ACTIVE_LANGUAGE', nextLanguage);
			}
		},
		[activeLanguage, storageContext.StorageService]
	);

	useEffect(() => {
		const storedActiveLanguage =
			storageContext.StorageService.getStorageItem('ACTIVE_LANGUAGE');
		if (!storedActiveLanguage) {
			storageContext.StorageService.setItem('ACTIVE_LANGUAGE', activeLanguage);
		} else if (
			storedActiveLanguage &&
			storedActiveLanguage !== activeLanguage
		) {
			changeLanguage(`${storedActiveLanguage}`);
		}
	}, [activeLanguage, changeLanguage, storageContext.StorageService]);

	/**
	 * Get message for all languages
	 */
	const getAllLangsForMessage = (
		props: MessageDescriptor,
		values: Record<string, any> = {}
	): IMessage[] =>
		uniq(
			map(entriesIn(intls), ([locale, intl]: [string, IntlShape]) => ({
				lang: locale,
				message: intl.formatMessage(props, values),
			}))
		);

	/**
	 * Get message strings from all languages
	 * - Duplicated values are removed
	 */
	const getAllLangStringsForMessage = (
		props: MessageDescriptor,
		values: Record<string, any> = {}
	): string[] =>
		uniq(
			map(valuesIn(intls), (intl: IntlShape) =>
				intl.formatMessage(props, values)
			)
		);

	/**
	 * Get message for certain language
	 * @param {string} lang language code
	 */
	const getMessageForLanguage = (
		lang: string,
		props: MessageDescriptor,
		values: Record<string, string | number | boolean> = {}
	): string => {
		const intl = get(
			intls,
			`[${lang}]`,
			createIntl({ locale: lang, messages: {} })
		);
		return intl.formatMessage(props, values);
	};

	const useAllMessagesFromLanguage = () => getAllMessagesForLanguage;

	const getAllMessagesForLanguage = (
		lang: string
	): Record<string, MessageFormatElement[] | string> => {
		const intl = get(
			intls,
			`[${lang}]`,
			createIntl({ locale: lang, messages: {} })
		);
		return get(intl, 'messages', {});
	};

	const activeCurrency = useMemo(() => {
		return (
			find(
				currencies,
				(currency) => get(currency, 'lang', 'cs') === activeLanguage
			) ?? { lang: 'cs', currency: 'CZK', title: 'Kč' }
		);
	}, [activeLanguage]);

	/**
	 * Get message for certain language
	 * @param {string} lang language code
	 */
	const getMessageForLanguageInstance = (
		props: MessageDescriptor,
		values: Record<string, string | number | boolean> = {}
	): string => {
		const intl = get(
			intls,
			`[${activeLanguage}]`,
			createIntl({ locale: activeLanguage, messages: {} })
		);
		return intl.formatMessage(props, values);
	};

	const localizationContextValue: ILocalizationContextValue = {
		changeLanguage,
		activeLanguage,
		activeLanguageKey: getLangugeKey(activeLanguage),
		prevLanguage,
		prevLanguageKey,
		activeCurrency,
		useFormatMessage: () => getMessageForLanguageInstance,
		useFormatMessageInstance: getMessageForLanguageInstance,
		useFormatMessageAll,
		useFormatMessageAllStrings,
		useFormatMessageFromLanguage,
		useAllMessagesFromLanguage,
		currencies,
		countries,
	};

	return (
		<LocalizationContext.Provider value={localizationContextValue}>
			<IntlProvider
				locale={activeLanguage}
				messages={get(translations, activeLanguage, locale_cs)}
				defaultLocale={activeLanguage}
			>
				{props.children}
			</IntlProvider>
		</LocalizationContext.Provider>
	);
};

export default LocalizationServiceProvider;
