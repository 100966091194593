import { FC, lazy, Suspense, useContext } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import Loader from 'Components/View/Loader';

import { ITEM_NOT_AVAILABLE } from '../../constants';
import { OutOfStockCartItemDetailProps } from '../interfaces';
const CloseIcon = lazy(() => import('Components/Icons/Close'));

import styles from './styles.module.css';

const OutOfStockCartItemMobile: FC<OutOfStockCartItemDetailProps> = ({
	bundle,
	flag,
	producerName,
	discountedFromPriceVat,
	totalDiscountedFromPriceWithVatFormatted,
	totalPriceWithVAT,
	totalPriceWithoutVAT,
	currencyTitle,
	cartItemType,
	message,
	handleShowSimilarProducts,
	isCarouselLoading,
	carouselItemsBundles,
	handleOnKeepInBasket,
	handleRemoveItemFromBasket,
}) => {
	const localizationContext = useContext(LocalizationContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const bundleDetailUrl = `/${t({
		id: 'routes.product.route',
	})}/${getLocalizedValue(bundle.url)}`;

	return (
		<div
			className={cx('vinisto-user-favorite', 'vinisto-cart__item--mobile', {
				discounted: !!discountedFromPriceVat,
			})}
		>
			<div className="vinisto-user-orders__orders__order-body mt-0">
				<div className="vinisto-user-orders__orders__order-body__item border-0 pt-2">
					<Link
						className="vinisto-wine__item-overlay"
						to={bundleDetailUrl}
					/>
					<div className="vinisto-user-orders__orders__order-body__item__info__img">
						<div>
							<img
								src={getBundleImage(bundle.images, IMAGE_SIZE_THUMB_64x80)}
								alt={`${t({ id: 'alt.bundleImage' })}`}
								className="pb-1"
							/>
						</div>
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info">
						<div className="vinisto-user-orders__orders__order-body__item__info__data">
							<div className="vinisto-user-orders__orders__order-body__item__info__data__name">
								{getLocalizedValue(bundle.name)}
							</div>
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__info__data">
							<div>
								<div className="vinisto-user-orders__orders__order-body__item__info__data__winery mt-md-2">
									<BundleProducer
										flag={flag}
										name={producerName}
									/>
								</div>
								<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
									{t(
										{ id: 'bundle.supplier.name' },
										{
											name: (
												<span className="vinisto-color-success fw-bolder">
													{bundle.supplier.name}
												</span>
											),
										}
									)}
								</div>
							</div>
							<div>
								<div className="vinisto-user-orders__orders__order-body__item__prices">
									<div className="vinisto-user-orders__orders__order-body__item__prices__total">
										<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
											{`${
												totalDiscountedFromPriceWithVatFormatted ??
												totalPriceWithVAT
											} ${currencyTitle}`}
										</span>
										<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
											{`${totalPriceWithVAT} ${currencyTitle}`}
										</span>
									</div>
									<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
										{`${t({
											id: 'basket.priceWithoutVAT',
										})} `}
										<span className="fw-bolder price-span">{`${totalPriceWithoutVAT} ${currencyTitle}`}</span>
									</div>
								</div>
							</div>
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__info__data mt-md-2">
							<div className="vinisto-wine__option">
								<div className="d-flex flex-column">
									<span className={cx(styles.warning, 'mb-1')}>{message}</span>
									{cartItemType === ITEM_NOT_AVAILABLE.OUT_OF_STOCK ||
									cartItemType === ITEM_NOT_AVAILABLE.TEMPORARY_UNAVAILABLE ||
									cartItemType === ITEM_NOT_AVAILABLE.IS_DISABLED ? (
										<button
											type="button"
											onClick={handleShowSimilarProducts}
											disabled={
												isCarouselLoading || carouselItemsBundles.length === 0
											}
											className={`vinisto-btn vinisto-success-btn ${styles.chooseAlternativeBtn}`}
										>
											{t({ id: 'cart.outOfStockItem.chooseSimilarItem' })}
										</button>
									) : (
										<button
											type="button"
											onClick={handleOnKeepInBasket}
											className={cx(
												'vinisto-btn vinisto-success-btn',
												styles.chooseAddToBasketBtn
											)}
										>
											{t({ id: 'cart.outOfStockItem.keepInBasket' })}
										</button>
									)}
								</div>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__prices">
								<div className={styles.removeItemBox}>
									<button
										className={cx('btn', styles.removeItemBtn)}
										onClick={handleRemoveItemFromBasket}
									>
										<Suspense fallback={<Loader blank />}>
											<CloseIcon
												alt={t({ id: 'alt.delete' })}
												className={styles.removeIconMobile}
											/>
										</Suspense>
										<span className={styles.warning}>
											{t({ id: 'alt.remove' })}
										</span>
									</button>
								</div>
							</div>

							<div className="vinisto-user-favorite__delete-wrap d-none">
								<div className="vinisto-user-favorite__delete-wrap__text">
									{t({ id: 'basket.removed.label' })}
									<br />
									<span className="color-primary pointer fw-bolder">
										{t({ id: 'basket.removed.returnBack' })}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OutOfStockCartItemMobile;
