import { useContext, useLayoutEffect, useRef } from 'react';
import { DeviceServiceAction } from 'Services/DeviceService/constants';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { DeviceServiceContext } from 'Services/DeviceService';
import BasketHeader from 'Components/Navbar/Components/BasketHeader';
import useWebSection from 'Hooks/use-web-section';

import useStaticCategories from './useStaticCategories';
import useNavLinks from './useNavLinks';
import { NavbarContext } from './context';
import './styles.css';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';
import MobileNavMenu from './Components/MobileNavMenu';

const Navbar = () => {
	const {
		isMobile,
		isTablet,
		layoutHeight,
		layoutWidth,
		dispatch,
		footerHeight,
	} = useContext(DeviceServiceContext);

	const navbarContext = useContext(NavbarContext);
	const navLinks = useNavLinks();

	const menuRef = navbarContext?.menuRef;

	const headerRef = useRef<HTMLElement>(null);
	const menuBtnRef = useRef<HTMLDivElement>(null);

	const staticCategories = useStaticCategories();

	useOnClickOutside([menuBtnRef, menuRef], navbarContext.handleOnCloseMenu);

	useLayoutEffect(() => {
		if (headerRef.current?.clientHeight) {
			dispatch([
				DeviceServiceAction.setHeaderHeight,
				headerRef.current?.clientHeight ?? 0,
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [layoutWidth, layoutHeight]);

	const { isCheckout } = useWebSection();

	if (isCheckout) {
		return (
			<>
				<header
					ref={headerRef}
					className="cart-header"
				>
					<BasketHeader />
				</header>
				{(isMobile || isTablet) && navbarContext.isMenuOpen && (
					<div
						className="basket-mobile-menu"
						style={{
							height: layoutHeight - footerHeight,
						}}
					>
						<MobileNavMenu />
					</div>
				)}
			</>
		);
	}

	if (isMobile || isTablet) {
		return <MobileNavbar />;
	}

	return (
		<DesktopNavbar
			navLinks={navLinks}
			staticCategories={staticCategories}
		/>
	);
};

export default Navbar;
