import { VinistoBasketDllModelsApiBasketBasketDiscountCoupon } from 'vinisto_api_client/src/api-types/basket-api';

export enum DiscountCouponPageType {
	ORDER_SUMMARY = 'ORDER_SUMMARY',
	USER_SECTION = 'USER_SECTION',
}

export interface DiscountCouponProps {
	discountCoupon: VinistoBasketDllModelsApiBasketBasketDiscountCoupon;
	discountCouponPage?: DiscountCouponPageType;
	className?: string;
}
