import { FC, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';
import { IDeliveryPaymentProps } from 'Pages/CartConfirmation/Components/DeliveryPaymentItem/interfaces';
import { LangValuePair } from 'Hooks/useLocalizedValue/interfaces';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import useLocalizedCurrency from 'Hooks/useLocalizedCurrency';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { LocalizationContext } from 'Services/LocalizationService';

import styles from './styles.module.css';

const DeliveryPaymentItem: FC<IDeliveryPaymentProps> = ({ name, price }) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const itemName = getLocalizedValue((name as LangValuePair[]) ?? []);
	const itemPriceWithVAT = createCurrencyValue(price?.valueWithVat ?? 0);
	const isLoading = name.length === 0;
	const priceCurrency = [price?.currency];

	const getLocalizedCurrency = useLocalizedCurrency();
	const localizedCurrency = getLocalizedCurrency([priceCurrency])?.title;

	return (
		<div
			className={cx(
				'vinisto-user-orders__orders__order-body__item vinisto-cart__item vinisto-order-summary',
				styles.payment
			)}
		>
			<div className="vinisto-user-orders__orders__order-body__item__info">
				<div className={styles.name}>{isLoading ? <Skeleton /> : itemName}</div>
			</div>

			<div>
				{isLoading ? (
					<Skeleton
						count={1.6}
						width="80px"
					/>
				) : (
					<div className={styles.price}>
						{price?.valueWithVat === 0
							? t({ id: 'basket.price.free' })
							: t(
									{ id: 'price' },
									{
										value: itemPriceWithVAT,
										currency: localizedCurrency,
									}
							  )}
					</div>
				)}
			</div>
		</div>
	);
};

export default DeliveryPaymentItem;
