import { useContext, useRef } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import usePreventOverScroll from 'Hooks/usePreventOverScroll';
import FreeDeliveryProgressBar from 'Components/FreeDeliveryProgressBar';
import { useMainHeaderHeight } from 'Hooks/useMainHeaderHeight';
import { BundleType } from 'Services/Bundle/interfaces';

import BundleItem from './Components/BundleItem';
import SideBasketContextProvider from './context';
import styles from './styles.module.css';

const SideBasketUI = () => {
	const { useFormatMessage, activeCurrency } = useContext(LocalizationContext);
	const t = useFormatMessage();
	const basketContext = useContext(BasketContext);

	const totalPriceWithVAT = createCurrencyValue(
		basketContext?.basketPriceWithVat ?? 0
	);
	const totalPriceWithoutVAT = createCurrencyValue(
		basketContext?.basketPrice ?? 0
	);
	const discountedTotalPriceWithVAT = createCurrencyValue(
		basketContext?.basketState?.discountCoupon?.discountedBasketPriceWithVat ??
			0
	);
	const discountedTotalPriceWithoutVAT = createCurrencyValue(
		basketContext?.basketState?.discountCoupon?.discountedBasketPrice ?? 0
	);
	const itemsQuantity = basketContext?.itemsQuantity ?? 0;
	const isLoading = !basketContext?.basketState?.items;
	const itemsRef = useRef<HTMLDivElement>(null);
	const discountCoupon = basketContext?.basketState?.discountCoupon;

	usePreventOverScroll(itemsRef);

	const headerOffset = useMainHeaderHeight();
	const headerWidgets = document.getElementById('header-widgets');
	const headerWidgetsHeight = headerWidgets?.clientHeight || 0;

	if (Object.keys(basketContext.basketState).length === 0) {
		return <></>;
	}

	return (
		<div
			className={styles.sideBasket}
			onClick={(e) => e.stopPropagation()}
			style={{ cursor: 'auto', top: '-' + headerWidgetsHeight + 'px' }}
			onKeyDown={() => null}
			tabIndex={0}
			role="button"
		>
			<Link
				to={`${t({ id: 'routes.cart.route' })}`}
				className={styles.prices}
				style={{ flexBasis: headerOffset + headerWidgetsHeight + 'px' }}
			>
				<div
					className={styles.pricesWrap}
					style={{ paddingTop: headerWidgetsHeight + 'px' }}
				>
					<span className="fw-bolder">
						{isLoading ? (
							<Skeleton
								width="49px"
								height="12px"
							/>
						) : (
							<>
								{discountCoupon
									? `${discountedTotalPriceWithoutVAT} ${
											activeCurrency?.title ?? ''
									  }`
									: `${totalPriceWithoutVAT} ${activeCurrency?.title ?? ''}`}
								<br />
							</>
						)}
						<span className={styles.pricesSmall}>
							{isLoading ? (
								<Skeleton
									width="49px"
									height="12px"
								/>
							) : (
								t({ id: 'basket.priceWithoutVAT' })
							)}
						</span>
					</span>
					{isLoading ? (
						<Skeleton
							width="49px"
							height="12px"
							count={1.7}
						/>
					) : (
						<span className="fw-bolder">
							<span className={styles.pricesTotal}>
								{discountCoupon
									? `${discountedTotalPriceWithVAT} ${
											activeCurrency?.title ?? ''
									  }`
									: `${totalPriceWithVAT} ${activeCurrency?.title ?? ''}`}
							</span>
							<br />
							<span className={styles.pricesSmall}>
								{t({ id: 'basket.priceWithVAT' })}
							</span>
						</span>
					)}
				</div>
				<span className={styles.count}>
					{isLoading ? (
						<Skeleton
							width="16px"
							height="12px"
						/>
					) : (
						itemsQuantity
					)}
				</span>
			</Link>
			{basketContext?.minimalPriceForFreeDelivery !== null && (
				<div className={styles.freeDelivery}>
					<div className={styles.freeDeliveryInfo}>
						<span className={styles.freeDeliveryInfoBtn}>i</span>
						<div className={styles.freeDeliveryInfoBtnPopup}>
							<FreeDeliveryProgressBar variant="popup" />
						</div>
					</div>
					<FreeDeliveryProgressBar variant="small-vertical" />
				</div>
			)}
			<div
				ref={itemsRef}
				className={styles.items}
			>
				{isLoading ? (
					<div className={cx(styles.itemWrap, 'skeleton')}>
						<Skeleton
							width="55px"
							height="75px"
							count={10}
							style={{
								marginBottom: '10px',
							}}
						/>
					</div>
				) : (
					basketContext.basketState.items.map(
						(bundle: BundleType, key: string) => (
							<BundleItem
								bundleData={bundle}
								key={key}
							/>
						)
					)
				)}
			</div>
		</div>
	);
};

const SideBasket = () => {
	const basketContext = useContext(BasketContext);

	if (basketContext?.basketState?.items?.length === 0) {
		return <></>;
	}

	return (
		<SideBasketContextProvider>
			<SideBasketUI />
		</SideBasketContextProvider>
	);
};

export default SideBasket;
