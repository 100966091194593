import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { map, range } from 'lodash-es';
import AliceCarousel from 'react-alice-carousel';
import Config from 'Config';
import { DeviceServiceContext } from 'Services/DeviceService';

import ProductBox from '../ProductBox';

import { CARD_TYPE, ICarouselProps } from './interfaces';
import {
	SKELETONS_NUM_CLASSIC,
	SKELETONS_NUM_REVIEW_DESKTOP,
	SKELETONS_NUM_REVIEW_MOBILE,
	SKELETONS_NUM_REVIEW_TABLET,
} from './constants';
import CarouselTab from './Components/CarouselTab';
import ProducerCard from './Components/ProducerCard';
import ReviewCard from './Components/ReviewCard';

import 'react-alice-carousel/lib/alice-carousel.css';
import './styles.css';

const Carousel = ({
	carouselType,
	activeTabId,
	displayPriceAsRange = false,
	cardType,
	data,
	handleOnSelectTab,
	isCrossSell,
	isLoading = false,
	openBundleProducerInNewTab,
	quantityBoxType,
	redirectToCrossSell = true,
	showAddToBasketBtn,
}: ICarouselProps) => {
	const deviceServiceContext = useContext(DeviceServiceContext);

	// @ts-expect-error carouselType can't be used as an index type (it's a string)
	const currentCarouselConfig = Config.carousels[carouselType];

	const defaultTab = data?.[0];
	const [activeTab, setActiveTab] = useState(defaultTab?.id);

	const onSelectTab = useCallback(
		(data: any) => () => {
			if (data?.id !== activeTab) {
				setActiveTab(data?.id);
			}
		},
		[activeTab]
	);

	useEffect(() => {
		onSelectTab(defaultTab)();
	}, [defaultTab, onSelectTab]);

	const MemoizedCarousel = useMemo(() => {
		return (
			<>
				{/* CAROUSEL CLASSIC */}
				{cardType === CARD_TYPE.CAROUSEL_CLASSIC && (
					<div className="v-carousel-classic">
						<AliceCarousel
							{...currentCarouselConfig}
							items={
								isLoading
									? Array(SKELETONS_NUM_CLASSIC).map((index) => (
											<ProductBox
												key={index}
												bundleData={{}}
												displayPriceAsRange={displayPriceAsRange}
												showAddToBasketBtn={showAddToBasketBtn ?? true}
												isLoading={isLoading}
											/>
									  ))
									: map(data, (card, index) => (
											<div className="v-carousel-classic__item">
												<ProductBox
													key={card.id ?? index}
													bundleData={card}
													redirectToCrossSell={redirectToCrossSell}
													displayPriceAsRange={displayPriceAsRange}
													isCrossSell={isCrossSell ?? false}
													showAddToBasketBtn={showAddToBasketBtn ?? true}
													quantityBoxType={quantityBoxType}
													carouselType={carouselType}
												/>
											</div>
									  ))
							}
						/>
					</div>
				)}

				{/* CAROUSEL REVIEWS */}
				{cardType === CARD_TYPE.CAROUSEL_REVIEW && (
					<AliceCarousel
						{...currentCarouselConfig}
						items={
							isLoading
								? map(
										range(
											deviceServiceContext.isMobile
												? SKELETONS_NUM_REVIEW_MOBILE
												: deviceServiceContext.isTablet
												? SKELETONS_NUM_REVIEW_TABLET
												: SKELETONS_NUM_REVIEW_DESKTOP
										),
										(index) => (
											<ReviewCard
												key={index}
												bundleData={{
													isPromoted: false,
													isInFavorites: false,
													inBasketQty: 0,
													imageSrc: '',
													title: '',
													rating: 0,
													ratingsTotal: 0,
													flag_iso_code: '',
													variety: '',
													amountAvailable: 1,
													price: 0,
												}}
												isLoading={isLoading}
											/>
										)
								  )
								: map(data, (card, index) => (
										<ReviewCard
											key={index}
											bundleData={card}
											data={card || {}}
											displayPriceAsRange={displayPriceAsRange}
										/>
								  ))
						}
					/>
				)}

				{/* CAROUSEL PRODUCERS */}
				{cardType === CARD_TYPE.CAROUSEL_PRODUCER && (
					<AliceCarousel
						{...currentCarouselConfig}
						items={
							isLoading ? (
								<ProducerCard
									data={{
										bundles: [{ isLoading: true }, { isLoading: true }],
									}}
									isLoading={isLoading}
								/>
							) : (
								map(data, (card, index: number) => (
									<ProducerCard
										key={index}
										data={card}
									/>
								))
							)
						}
					/>
				)}

				{/* CAROUSEL TABS */}
				{cardType === CARD_TYPE.CAROUSEL_TAB && (
					<AliceCarousel
						{...currentCarouselConfig}
						items={map(isLoading ? range(5) : data, (tab, index) => (
							<div
								key={`homepage-vinisto-tab-${index}`}
								className={
									(activeTabId || activeTab) === tab.id
										? 'nav-item active'
										: 'nav-item'
								}
							>
								<CarouselTab
									key={tab.id ?? index}
									data={tab || {}}
									handleOnSelectTab={handleOnSelectTab ?? onSelectTab}
									isLoading={isLoading}
								/>
							</div>
						))}
					/>
				)}
			</>
		);
	}, [
		activeTab,
		activeTabId,
		displayPriceAsRange,
		cardType,
		currentCarouselConfig,
		data,
		deviceServiceContext.isMobile,
		deviceServiceContext.isTablet,
		handleOnSelectTab,
		isCrossSell,
		isLoading,
		onSelectTab,
		openBundleProducerInNewTab,
		quantityBoxType,
		redirectToCrossSell,
		showAddToBasketBtn,
	]);

	return MemoizedCarousel;
};

export default Carousel;
