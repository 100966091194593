import * as React from 'react';
import { get, map } from 'lodash-es';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { LocalizationContext } from 'Services/LocalizationService';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import useBundlePrice from 'Hooks/useBundlePrice';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import BundleTag from 'Components/ProductBox/Components/BundleTag';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import Rating from 'Components/Rating';

import { IOrderMobileProps } from './interfaces';

const OrderMobile: React.FC<IOrderMobileProps> = (
	props: IOrderMobileProps
): JSX.Element => {
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const getBundlePrice = useBundlePrice();

	const basketItem = get(props, 'basketItem', {});
	const quantity = get(basketItem, 'quantity', 1);
	const bundle = get(basketItem, 'bundle', {});

	const { price, priceWithVat, discountedFromPriceVat, currencyTitle } =
		getBundlePrice(bundle);
	const priceWithoutVAT = createCurrencyValue(price);
	const priceWithVAT = createCurrencyValue(priceWithVat);
	const totalPriceWithVAT = createCurrencyValue(priceWithVat, quantity);
	const totalPriceWithoutVAT = createCurrencyValue(price, quantity);
	const discountedFromPriceWithVat = discountedFromPriceVat;
	const discountedFromPriceWithVatFormatted = discountedFromPriceVat
		? createCurrencyValue(discountedFromPriceVat)
		: null;
	const totalDiscountedFromPriceWithVatFormatted = discountedFromPriceVat
		? createCurrencyValue(discountedFromPriceVat, quantity)
		: null;

	const isLoading = get(props, 'isLoading', false);
	const { shortVariety: producerName, component: flag } = getFlagSpecification(
		get(bundle, 'specificationDetails', [])
	);
	const bundleEvaluation = get(bundle, 'bundleEvaluation', {});
	const bundleAvgRating = get(bundleEvaluation, 'averageStars', 0) / 2;
	const bundleTotalRatingCount = get(
		bundleEvaluation,
		'totalEvaluationCount',
		0
	);
	const supplierName = get(bundle, 'supplier.nameWeb', '');

	return (
		<div
			className={cx(
				'vinisto-user-orders__orders__order vinisto-user-favorites vinisto-user-favorites--mobile vinisto-crosssell--mobile',
				{ discounted: !!discountedFromPriceWithVat }
			)}
		>
			<div className="vinisto-user-favorite">
				<div className="vinisto-user-orders__orders__order-body">
					<div
						className={cx(
							'vinisto-user-orders__orders__order-body__item',
							'cta-item-simplified',
							'pt-4',
							'vinisto-crosssell__item-link',
							{
								pointer: !isLoading,
							}
						)}
					>
						{!isLoading && (
							<Link
								className="vinisto-user-orders__orders__order-body-overlay"
								to={`/${t({
									id: 'routes.product.route',
								})}/${getLocalizedValue(get(bundle, 'url', []))}`}
							/>
						)}
						<div className="vinisto-user-orders__orders__order-body__item__info__img">
							{isLoading ? (
								<Skeleton
									containerClassName="w-100 h-100"
									height="100%"
								/>
							) : (
								<img
									src={getBundleImage(
										get(props, 'basketItem.bundle.images'),
										IMAGE_SIZE_THUMB_64x80
									)}
									alt={`${t({ id: 'alt.bundleImage' })}`}
								/>
							)}
						</div>

						<div className="vinisto-user-orders__orders__order-body__item__info ">
							<div className="vinisto-wine__img-wrap ">
								<div className="vinisto-wine__img-labels d-flex">
									{isLoading ? (
										<Skeleton
											width="50px"
											count={5.6}
											style={{ marginRight: '.125rem' }}
											inline
										/>
									) : (
										map(
											get(bundle, 'tagsDetail'),
											(tagDetail: Record<any, any>, index) => (
												<BundleTag
													{...{ tagDetail }}
													key={`category-detail-list-tag-item-${get(
														tagDetail,
														'id',
														index
													)}`}
												/>
											)
										)
									)}
								</div>
							</div>

							<div className="vinisto-user-orders__orders__order-body__item__info__data">
								<div className="vinisto-user-orders__orders__order-body__item__info__data__name">
									{isLoading ? (
										<Skeleton
											width="100px"
											height="20px"
										/>
									) : (
										getLocalizedValue(get(bundle, 'name', [])) ?? '-'
									)}
								</div>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__info__data">
								<div>
									<div className="vinisto-user-orders__orders__order-body__item__info__data__score">
										{isLoading ? (
											<Skeleton
												width="80px"
												count={1.6}
												style={{
													marginRight: '.125rem',
												}}
												inline
											/>
										) : (
											<div className="vinisto-wine__review mb-0">
												<Rating
													defaultValue={bundleAvgRating}
													readOnly
												/>
												<span className="vinisto-wine__review__wrap-count">
													({bundleTotalRatingCount})
												</span>
											</div>
										)}
									</div>
									{isLoading ? (
										<Skeleton width="130px" />
									) : (
										<div className="vinisto-user-orders__orders__order-body__item__info__data__winery mt-2">
											<BundleProducer
												flag={flag}
												name={producerName}
												isLoading={isLoading}
											/>
										</div>
									)}
								</div>
								<div>
									<div className="vinisto-user-orders__orders__order-body__item__prices">
										{isLoading ? (
											<Skeleton
												width="80px"
												height="20px"
												count={1.6}
											/>
										) : (
											<>
												<div className="vinisto-user-orders__orders__order-body__item__prices__total">
													<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
														{`${
															discountedFromPriceWithVatFormatted ??
															priceWithVAT
														} ${currencyTitle}`}
													</span>
													<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
														{`${priceWithVAT} ${currencyTitle}`}
													</span>
												</div>
												<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
													{`${t({
														id: 'basket.priceWithoutVAT',
													})} `}
													<span className="fw-bolder">
														{`${priceWithoutVAT} ${currencyTitle}`}
													</span>
												</div>
												<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
													{t(
														{
															id: 'bundle.supplier.name',
														},
														{
															name: (
																<span className="color-primary fw-bolder">
																	{supplierName.length > 0
																		? supplierName
																		: t({
																				id: 'productDetail.seller.name.others',
																		  })}
																</span>
															),
														}
													)}
												</div>
											</>
										)}
									</div>
								</div>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__info__data mt-2">
								<div className="vinisto-wine__count">
									{isLoading ? (
										<Skeleton
											width="35px"
											height="25px"
										/>
									) : (
										t({ id: 'basket.pcs' }, { count: quantity })
									)}
								</div>
								<div className="vinisto-user-orders__orders__order-body__item__prices">
									{isLoading ? (
										<Skeleton
											width="80px"
											height="20px"
											count={1.6}
										/>
									) : (
										<>
											<div className="vinisto-user-orders__orders__order-body__item__prices__total">
												{`${t({
													id: 'basket.totalPrice',
												})} `}
												<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
													{`${
														totalDiscountedFromPriceWithVatFormatted ??
														totalPriceWithVAT
													} ${currencyTitle}`}
												</span>
												<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
													{`${totalPriceWithVAT} ${currencyTitle}`}
												</span>
											</div>
											<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
												{`${t({
													id: 'basket.priceWithoutVAT',
												})} `}
												<span className="fw-bolder price-span">
													{`${totalPriceWithoutVAT} ${currencyTitle}`}
												</span>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderMobile;
