import QuantityBox from 'Components/QuantityBox';
import {
	QuantityBoxPlusBtnVariants,
	QuantityBoxTypes,
} from 'Components/QuantityBox/constants';

import { useStandardQuantityBox } from './hooks';
import { StandardQuantityBoxProps } from './interfaces';

const StandardQuantityBox = ({
	bundle,
	shouldRedirectToCrossSell = true,
	className,
	isLoading = false,
	orderLimitation,
	carouselType,
}: StandardQuantityBoxProps) => {
	const methods = useStandardQuantityBox(bundle, shouldRedirectToCrossSell);

	return (
		<QuantityBox
			type={QuantityBoxTypes.STANDARD}
			plusBtnVariant={QuantityBoxPlusBtnVariants.STANDARD}
			className={className}
			isLoading={isLoading}
			isForLogged={bundle.isForLogged ?? false}
			orderLimitation={orderLimitation}
			carouselType={carouselType}
			{...methods}
		/>
	);
};

export default StandardQuantityBox;
