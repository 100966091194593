import { useContext } from 'react';
import cx from 'classnames';
import { LocalizationContext } from 'Services/LocalizationService';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import useLocalizedCurrency from 'Hooks/useLocalizedCurrency';
import { BasketContext } from 'Services/BasketService';
import {
	DiscountCouponPageType,
	DiscountCouponProps,
} from 'Components/DiscountCoupon/interfaces';

import styles from './styles.module.css';

const DiscountCoupon = ({
	discountCoupon,
	discountCouponPage: couponPage,
	className,
}: DiscountCouponProps) => {
	const basketContext = useContext(BasketContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedCurrency = useLocalizedCurrency();

	// @ts-expect-error - TODO fix TS error
	const amountDiscountWithVAT = discountCoupon.discountValueWithVat;
	const discountCurrency = getLocalizedCurrency(
		basketContext?.basketState?.currency
	)?.title;

	return (
		<div
			className={cx(styles.discountCouponItem, className, {
				[styles.discountCouponItemOrderSummary]:
					couponPage === DiscountCouponPageType.ORDER_SUMMARY,
			})}
		>
			<div
				className={cx(styles.discountCouponCodeItem, {
					[styles.discountCouponCodeItemOrderSummary]:
						couponPage === DiscountCouponPageType.ORDER_SUMMARY,
				})}
			>
				{`${t({
					id: 'basket.discountCoupon.label',
				})} "${discountCoupon.code}"`}
			</div>

			<div
				className={cx(styles.discountCouponAmountItem, {
					[styles.discountCouponAmountItemOrderSummary]:
						couponPage === DiscountCouponPageType.ORDER_SUMMARY,
				})}
			>
				<div
					className={cx(styles.discountCouponAmountItemValue, {
						[styles.discountCouponAmountItemValueOrderSummary]:
							couponPage === DiscountCouponPageType.ORDER_SUMMARY,
					})}
				>
					{amountDiscountWithVAT &&
						t(
							{
								id: 'orderOverview.discountCoupon',
							},
							{
								value: createCurrencyValue(amountDiscountWithVAT, undefined, 2),
								currency: discountCurrency,
							}
						)}
				</div>
			</div>
		</div>
	);
};

export default DiscountCoupon;
