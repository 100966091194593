import React from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import useSectionLinkWidgetsQuery from 'Hooks/use-section-link-widgets-query';

import { ICategoryLinkListItem } from './Components/CategoryLinkList/interfaces';

import { Allowed_Sections } from '@/domain/link-widget/enums';

const useStaticCategoriesMobile = () => {
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const dynamic_links = useSectionLinkWidgetsQuery(
		Allowed_Sections.MAIN_NAVIGATION_MOBILE,
		'/',
		20
	);

	const dynamic_links_mapped =
		dynamic_links.query.data?.data.map((data) => {
			return {
				name: data.name || '',
				to: data.url || '',
				children: undefined,
			};
		}) || ([] as ICategoryLinkListItem[]);

	const links = React.useMemo(
		(): ICategoryLinkListItem[] => [
			{
				name: 'Dle výrobce',
				to: `/${t({ id: 'routes.producers.route' })}`,
			},
			{
				name: 'Země původu',
				to: `/${t({
					id: 'routes.category.route',
				})}/zeme-puvodu`,
			},
			{
				name: 'Destiláty',
				to: `/${t({ id: 'routes.category.route' })}/destilaty`,
			},
			{
				name: 'Piva',
				to: `/${t({ id: 'routes.category.route' })}/piva`,
			},
			{
				name: 'Speciální vína',
				to: `/${t({ id: 'routes.category.route' })}/ostatni-vina`,
			},
			{
				name: 'Šumivá',
				to: `/${t({ id: 'routes.category.route' })}/sumiva-vina`,
			},
			{
				name: 'Růžová',
				to: `/${t({ id: 'routes.category.route' })}/ruzova-vina`,
			},
			{
				name: 'Červená',
				to: `/${t({ id: 'routes.category.route' })}/cervena-vina`,
			},
			{
				name: 'Bílá',
				to: `/${t({ id: 'routes.category.route' })}/bila-vina`,
			},
			{
				name: 'Akce',
				to: `/${t({ id: 'routes.offers.route' })}`,
			},
			{
				name: 'Novinky',
				to: `/${t({ id: 'routes.news.route' })}`,
			},
			{
				name: 'Zvýhodněné sety',
				to: `/${t({
					id: 'routes.category.route',
				})}/zvyhodnene-sety`,
			},
			{
				name: 'Tipy na dárky',
				to: `/${t({
					id: 'routes.category.route',
				})}/tipy-na-darky`,
			},
			{
				name: 'Degustace',
				to: `/${t({
					id: 'routes.category.route',
				})}/degustace`,
			},
			{
				name: 'Ostatní',
				to: `/${t({
					id: 'routes.category.route',
				})}/ostatni`,
			},
		],
		[t]
	);

	return dynamic_links.query.isFetched
		? dynamic_links_mapped?.length > 0
			? dynamic_links_mapped
			: links
		: links;
};

export default useStaticCategoriesMobile;
