import * as React from 'react';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';
import ImageLocal from 'Components/View/ImageLocal';

import './styles.css';

const PaymentOptions: React.FC = (): JSX.Element => {
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const documentHeaderContext = React.useContext(DocumentHeaderContext);

	React.useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.payments.name' })}` }
			)}`,
		});
	}, []);

	return (
		<section id="content-wrapper">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="vinisto-card d-flex justify-content-center justify-content-sm-between align-items-center color-primary p-3">
							<ImageLocal
								fileName="payment-info.svg"
								alt=""
								className="d-none d-sm-block"
							/>
							<h1 className="h2 text-center">Možnosti platby</h1>
							<ImageLocal
								fileName="payment-info.svg"
								alt=""
								className="d-none d-sm-block"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12 col-xxl-6 mb-3 mb-xxl-0">
						<div className="vinisto-card vinisto-terms__card p-3 pt-2">
							<div className="vinisto-terms__text vinisto-font-18">
								<h2 className="vinisto-payment-info__heading">
									On-line platební kartou:{' '}
									<span className="vinisto-color-success">zdarma</span>
								</h2>
								<p className="mb-0">
									Pokud zvolíte jako platební metodu platbu kartou, budete po
									odeslání objednávky automaticky přesměrováni na platební
									bránu, kde je třeba zadat číslo karty, datum platnosti karty a
									CVC kód ze zadní strany karty. Platbu je následně třeba ověřit
									3D Secure ověřením ve Vaší bance přes SMS, PIN nebo otisk
									prstu. Platba kartou pak proběhne téměř okamžitě.
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-xxl-6">
						<div className="vinisto-card vinisto-terms__card p-3 pt-2">
							<div className="vinisto-terms__text vinisto-font-18">
								<h2 className="vinisto-payment-info__heading">
									On-line bankovní převod (služba GoPay.cz):{' '}
									<span className="vinisto-color-success">zdarma</span>
								</h2>
								<p className="mb-0">
									Online bankovní převod je způsob platby, při kterém vás
									platební brána přesměruje do Vašeho internetového
									bankovnictví. V bankovnictví se Vám zobrazí předvyplněný
									bankovní příkaz, který potvrdíte a tím provedete platbu. Po
									úspěšném zaplacení budete přesměrováni zpět na platební bránu,
									která vám zobrazí výsledek platby a pošle informaci o
									zaplacení e-mailem.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12 col-xxl-6 mb-3 mb-xxl-0">
						<div className="vinisto-card vinisto-terms__card p-3 pt-2">
							<div className="vinisto-terms__text vinisto-font-18">
								<h2 className="vinisto-payment-info__heading">
									Google Pay:{' '}
									<span className="vinisto-color-success">zdarma</span>
								</h2>
								<p className="mb-0">
									Google Pay je služba, která představuje rychlý a pohodlný
									způsob bezkontaktního placení, který je již velmi rozšířený.
									Pro používání této platební metody je nutné mít uloženou
									platební kartu v aplikaci Peněženka Google.
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-xxl-6">
						<div className="vinisto-card vinisto-terms__card p-3 pt-2">
							<div className="vinisto-terms__text vinisto-font-18">
								<h2 className="vinisto-payment-info__heading">
									Apple Pay:{' '}
									<span className="vinisto-color-success">zdarma</span>
								</h2>
								<p className="mb-0">
									Apple Pay je digitální peněženka od společnosti Apple, která
									umožňuje uživatelům svých zařízení iPhone, Apple Watch, iPad
									nebo Mac provádět platby. Platba přes Apple Pay je možná pouze
									pro uživatele Apple zařízení.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12 col-xxl-6 mb-3 mb-xxl-0">
						<div className="vinisto-card vinisto-terms__card p-3 pt-2">
							<div className="vinisto-terms__text vinisto-font-18">
								<h2 className="vinisto-payment-info__heading">
									Kartou při převzetí: 29 Kč
								</h2>
								<p className="mb-0">
									Dobírku je možné uhradit platební kartou i v hotovosti. Ne
									vždy však můžeme možnost úhrady platební kartou garantovat.
									Pokud zvolíte platbu dobírkou, doporučujeme mít připravenou
									hotovost. Vybavenost kurýrů v různých lokalitách se liší,
									případně může dojít k technickým problémům.
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-xxl-6">
						<div className="vinisto-card vinisto-terms__card p-3 pt-2">
							<div className="vinisto-terms__text vinisto-font-18">
								<h2 className="vinisto-payment-info__heading">
									Platba předem - proforma:{' '}
									<span className="vinisto-color-success">zdarma</span>
								</h2>
								<p className="mb-0">
									Platba předem na základě vystavené a e-mailem zaslané proforma
									faktury. Zboží bude dodáno do 48 hodin po připsání fakturované
									částky na náš účet.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<p className="color-primary ms-3 mb-0">
							* Podmínky plateb platí pro území České republiky. V případě
							zásilky do zahraničí je doprava řešena se zákazníkem individuálně.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PaymentOptions;
