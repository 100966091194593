import { apiServiceInstance } from 'Services/ApiService';
import {
	BundlesGetAutocompleteNamesListParams,
	VinistoHelperDllEnumsLanguage,
	VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn,
} from 'vinisto_api_client/src/api-types/product-api';
import { AUTOCOMPLETE_LIMIT } from 'Services/Bundle/constants';

import { AUTOCOMPLETE_API_ENDPOINT } from './constants';

import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';

export const getSearchResults = ({
	searchingNameString = '',
	language = VinistoHelperDllEnumsLanguage.CZECH,
	IsCache = true,
	limit = AUTOCOMPLETE_LIMIT,
	...rest
}: BundlesGetAutocompleteNamesListParams) => {
	const processedParams: any[] = [];
	Object.entries({ ...DEFAULT_BUNDLE_API_PARAMS, ...rest }).forEach(
		([key, value]) => {
			processedParams.push({ key: key, value: value });
		}
	);

	return apiServiceInstance.getCollection<VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn>(
		AUTOCOMPLETE_API_ENDPOINT,
		[
			{ key: 'searchingNameString', value: searchingNameString },
			{ key: 'IsCache', value: IsCache },
			{ key: 'language', value: language },
			{ key: 'limit', value: limit },
			{ key: 'isB2b', value: false },
			...processedParams,
		],
		true
	);
};
