import cx from 'classnames';
import { Link } from 'react-router-dom';

import { TopBannerProps } from './interfaces';
import styles from './styles.module.css';

const TopBanner = ({
	url,
	title,
	subtitle,
	imageOriginalUrl,
	srcSet,
	ctaLabel,
	cardOrder,
}: TopBannerProps) => {
	const bannerContent = (
		<div
			className={cx(styles.card, {
				[styles.primaryCard]: cardOrder === 1 || !cardOrder,
				[styles.secondaryCard]: cardOrder > 1,
			})}
		>
			{
				<div className={styles.heading}>
					<>
						{title && <div className={styles.title}>{title}</div>}
						{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
					</>
				</div>
			}

			<div className={styles.imgContainer}>
				<img
					srcSet={srcSet}
					sizes={`(max-width: 1399.98) 500px, ${
						cardOrder === 1 ? '950px' : '500px'
					}`}
					src={imageOriginalUrl}
					className={styles.img}
					alt=""
					// eslint-disable-next-line react/no-unknown-property
					fetchpriority={cardOrder === 1 ? 'high' : undefined}
				/>
			</div>

			{ctaLabel && <span className={styles.cta}>{ctaLabel}</span>}
		</div>
	);

	return url.match(/^https?:\/\//) === null ? (
		<Link
			to={url}
			className={cx(styles.card)}
		>
			{bannerContent}
		</Link>
	) : (
		<a
			href={url}
			className={cx(styles.card)}
		>
			{bannerContent}
		</a>
	);
};

export default TopBanner;
