import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/product-api';
import usePaginatedQuery from 'Hooks/usePaginatedQuery';
import usePagination from 'Hooks/usePagination';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import CategoryBoxList from 'Pages/Category/Components/CategoryBoxList';
import Grid from 'Pages/Category/Components/CategoryBundlesWithFilters/Components/Bundles/Components/Grid';
import BundleItem from 'Pages/Category/Components/CategoryBundlesWithFilters/Components/Bundles/Components/Grid/Components/BundleItem';
import PaginationNav from 'Components/Pagination';
import { AuthenticationContext } from 'Services/AuthenticationService/context';

import { fetchFullTextSearchResults } from './helpers';
import { LIMIT } from './constants';
import { ProductsProps } from './interfaces';

const Products = ({ searchTerm }: ProductsProps) => {
	const { loginHash } = useContext(AuthenticationContext).vinistoUser;
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const warehouseContext = useContext(WarehouseContext);

	const queryClient = useQueryClient();

	const initialData = useQuery(
		['full-search', searchTerm],
		() =>
			fetchFullTextSearchResults({
				UserLoginHash: loginHash,
				SearchString: searchTerm,
				Collections: ['BUNDLE', 'CATEGORY'],
				Page: 1,
				Limit: LIMIT,
			}),
		{
			onSuccess: (data) => {
				const bundleIds = data?.bundles?.map((bundle) => bundle.id ?? '');
				bundleIds && warehouseContext.fetchQuantity(bundleIds);

				queryClient.setQueryData(['full-search', 'categories', searchTerm, 1], {
					...data,
				});
				queryClient.setQueryData(['full-search', 'bundles', searchTerm, 1], {
					...data,
				});
			},
		}
	);

	const [categoriesPage, setCategoriesPage] = useState<[number, number]>([
		1, 1,
	]);

	const [categoriesData] = usePaginatedQuery(categoriesPage, (page) => ({
		queryKey: ['full-search', 'categories', searchTerm, page],
		queryFn: () =>
			fetchFullTextSearchResults({
				UserLoginHash: loginHash,
				SearchString: searchTerm,
				Collections: ['CATEGORY'],
				Page: page,
				Limit: LIMIT,
			}),
		enabled: initialData.isFetched,
	}));

	const [bundlesPage, setBundlesPage] = useState<[number, number]>([1, 1]);
	const [bundleCount, setBundleCount] = useState(0);

	const [bundlesData, { isFetched: isBundlesFetched }] = usePaginatedQuery(
		bundlesPage,
		(page) => ({
			queryKey: ['full-search', 'bundles', searchTerm, page],
			queryFn: () =>
				fetchFullTextSearchResults({
					UserLoginHash: loginHash,
					SearchString: searchTerm,
					Collections: ['BUNDLE'],
					Page: page,
					Limit: LIMIT,
				}).then((data) => {
					const bundleIds = data?.bundles?.map((bundle) => bundle.id ?? '');

					bundleIds && warehouseContext.fetchQuantity(bundleIds);

					return data;
				}),
			enabled: initialData.isFetched,
		})
	);

	const {
		startPage: bundlesStartPage,
		currentPage: currentBundlePage,
		itemsLeftOnNextPage: bundlesLeftOnNextPage,
		totalPages: totalBundlePages,
	} = usePagination({
		page: bundlesPage,
		limit: LIMIT,
		totalItemsCount: bundleCount,
	});

	useEffect(() => {
		if (isBundlesFetched) {
			const newBundleCount = bundlesData?.length
				? bundlesData[bundlesData?.length - 1]?.data?.bundlesCount
				: 0;
			if (bundleCount !== newBundleCount) {
				setBundleCount(newBundleCount);
			}
		} else if (initialData.isLoading) {
			setBundleCount(0);

			if (bundlesPage[0] !== 1 || bundlesPage[1] !== 1) {
				setBundlesPage([1, 1]);
			}
			if (categoriesPage[0] !== 1 || categoriesPage[1] !== 1) {
				setCategoriesPage([1, 1]);
			}
		}
	}, [isBundlesFetched, searchTerm]);

	const categories = initialData.isLoading
		? Array.from(Array(LIMIT), () => ({ isLoading: true }))
		: categoriesData?.flatMap((query) => query?.data?.categories);
	const bundles = initialData.isLoading
		? Array.from(Array(LIMIT), () => ({ isLoading: true }))
		: (bundlesData?.flatMap((query, index) => {
				if (!query.isFetched) {
					const totalItemsLeft =
						bundleCount - (bundlesStartPage - 1 + index) * LIMIT;
					const itemsLeft = totalItemsLeft > 0 ? totalItemsLeft : 0;
					return Array.from(
						Array(itemsLeft > LIMIT ? LIMIT : itemsLeft),
						() => ({ isLoading: true })
					);
				}
				return query?.data?.bundles;
		  }) as VinistoProductDllModelsApiBundleBundle[]);

	const isCategoryFound = categories?.length > 0;
	const isBundleFound = bundles?.length > 0;
	const isCategoryLoading = categoriesData[0]?.isLoading ?? false;
	const isBundleLoading = bundlesData[0]?.isLoading ?? false;

	const handleOnLoadMore = () =>
		setBundlesPage(([startPage, endPage]) => [startPage, endPage + 1]);

	const handleOnSelectNextPage = () =>
		setBundlesPage(([endPage]) => [endPage + 1, endPage + 1]);

	const handleOnSelectPreviousPage = () =>
		bundlesPage[0] > 1 &&
		setBundlesPage(([endPage]) => [endPage - 1, endPage - 1]);

	const handleOnSelectPage = (page: number) => setBundlesPage([page, page]);

	return (
		<>
			{!isCategoryFound &&
			!isBundleFound &&
			!isCategoryLoading &&
			!isBundleLoading ? (
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card vinisto-category-header">
								<h1 className="vinisto-category-header__heading">
									{t(
										{ id: 'search.page.header' },
										{
											category: (
												<>
													{t({
														id: 'search.page.category.categoriesAndProducts',
													})}
												</>
											),
											term: searchTerm,
										}
									)}
								</h1>
								<p className="mt-3">{t({ id: 'search.page.empty' })}</p>
							</div>
						</div>
					</div>
				</div>
			) : (
				<>
					{isCategoryFound && (
						<div className="container">
							<div className="row">
								<div className="col-12">
									<div className="vinisto-card vinisto-category-header">
										<h1 className="vinisto-category-header__heading desktop-only">
											{t(
												{ id: 'search.page.header' },
												{
													category: (
														<>
															{t({
																id: 'search.page.category.categories',
															})}
														</>
													),
													term: searchTerm,
												}
											)}
										</h1>
										<h2 className="vinisto-category-header__heading tablet-mobile-only">
											{t(
												{ id: 'search.page.header' },
												{
													category: (
														<>
															{t({
																id: 'search.page.category.categories',
															})}
														</>
													),
													term: searchTerm,
												}
											)}
										</h2>
										<CategoryBoxList categories={categories} />
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="container">
						<div className="row">
							<div className="col vinisto-category-list">
								<div className="vinisto-card">
									{!isBundleFound &&
										isCategoryFound &&
										initialData.isLoading && (
											<>
												<h1 className="vinisto-category-header__heading">
													{t(
														{
															id: 'search.page.header',
														},
														{
															category: (
																<>
																	{t({
																		id: 'search.page.category.products',
																	})}
																</>
															),
															term: searchTerm,
														}
													)}
												</h1>
												<p className="mt-3">{t({ id: 'search.page.empty' })}</p>
											</>
										)}
									{(isCategoryLoading ||
										isBundleLoading ||
										(!isBundleFound && !initialData.isLoading)) && (
										<div className="col-12">
											<div className="vinisto-wide-wine-wrap vinisto-wide-wine-wrap--category mt-2">
												{[...Array(LIMIT)].map((_, i) => (
													<BundleItem
														bundleData={bundles}
														key={i}
														isLoading={true}
													/>
												))}
											</div>
										</div>
									)}
									{isBundleFound && (
										<>
											<div className="vinisto-wide-wine-tabs vinisto-wide-wine-tabs--category">
												<nav className="navbar navbar-expand navbar-light p-0 justify-content-between">
													<h1 className="vinisto-category-header__heading">
														{t(
															{
																id: 'search.page.header',
															},
															{
																category: (
																	<>
																		{t({
																			id: 'search.page.category.products',
																		})}
																	</>
																),
																term: searchTerm,
															}
														)}
													</h1>
													<div className="vinisto-wine-tabs--category__right">
														<div className="vinisto-wine-tabs--category__right__count d-flex text-nowrap">
															{t(
																{
																	id: 'search.page.bundles.count',
																},
																{
																	count: bundleCount,
																}
															)}
														</div>
													</div>
												</nav>
											</div>
											<div className="col-12">
												<Grid>
													{bundles.map((bundle, i) => (
														<BundleItem
															bundleData={bundle}
															key={`category-grid-bundle-item-${
																// @ts-expect-error not proper discriminate union (missing discriminants)
																bundle?.id ?? i
															}`}
															// @ts-expect-error dtto
															isLoading={!!bundle?.isLoading}
														/>
													))}
												</Grid>
											</div>

											<div className="col-12">
												<PaginationNav
													currentPage={currentBundlePage}
													itemsToLoadMore={bundlesLeftOnNextPage}
													handleOnLoadMore={handleOnLoadMore}
													handleOnSelectPreviousPage={
														handleOnSelectPreviousPage
													}
													handleOnSelectNextPage={handleOnSelectNextPage}
													totalPaginationPages={totalBundlePages}
													handleOnSelectPage={handleOnSelectPage}
												/>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Products;
