import { FC } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { BottomBannerProps } from './interfaces';
import styles from './styles.module.css';

const BottomBanner: FC<BottomBannerProps> = ({
	url,
	title,
	subtitle,
	imageUrl,
	ctaLabel,
}) => {
	const bannerImageStyle = imageUrl
		? {
				backgroundImage: `url(${imageUrl})`,
		  }
		: {};

	const bannerContent = (
		<div
			className={cx('vinisto-card', styles.card, styles.cardImage)}
			style={bannerImageStyle}
		>
			<div>
				{title && (
					<p className="vinisto-card__heading h2 w-100 text-white">{title}</p>
				)}
				{subtitle && (
					<p className="vinisto-card__top-text text-white">{subtitle}</p>
				)}
			</div>
			{ctaLabel && (
				<div className="vinisto-card__bottom-link text-right underline-effect underline-effect--white">
					<span
						className={'underline-item text-white'}
						aria-current="page"
					>
						{ctaLabel}
					</span>
				</div>
			)}
		</div>
	);

	return url.match(/^https?:\/\//) === null ? (
		<Link to={url}>{bannerContent}</Link>
	) : (
		<a href={url}>{bannerContent}</a>
	);
};

export default BottomBanner;
