import { last, map, split, trim } from 'lodash-es';
import { apiServiceInstance } from 'Services/ApiService';
import { VinistoOrderDllModelsApiReturnDataPdfReturn } from 'vinisto_api_client/src/api-types/order-api';

import {
	CANCELLED,
	CREATED,
	DELIVERED,
	IN_WMS,
	LOSS_EVENT,
	OrderSortingColumn,
	PAID,
	REFUNDED,
	RETURNED,
	RETURNING_GOODS,
	REVERT_FINANCE_AND_FEES,
	SENT,
	WMS_ACCEPTED,
	WMS_INCOMPLETE,
	WMS_READY,
} from './constants';

export const fetchOrders = (
	userLoginHash: string,
	userEmail: string,
	page = 1,
	limit = 20,
	sortingColumn = OrderSortingColumn.ID,
	isDescending = false
): Promise<unknown> => {
	if (!userLoginHash)
		return new Promise((_, reject) =>
			reject('User login hash has to be provided.')
		);
	if (!userEmail)
		return new Promise((_, reject) =>
			reject('User e-mail has to be provided.')
		);

	const offset = (page - 1) * limit;
	return apiServiceInstance.get('order-api/orders', false, undefined, [
		{ key: 'Offset', value: offset },
		{ key: 'Limit', value: limit },
		{ key: 'UserLoginHash', value: userLoginHash },
		{ key: 'UserEmail', value: encodeURIComponent(userEmail) },
		{ key: 'SortingColumn', value: sortingColumn },
		{ key: 'IsSortingDescending', value: isDescending },
	]);
};

export const getLocalizedOrderState = (
	orderState: string
): Record<any, any> => {
	const latestState = last(
		map(split(orderState, ','), (state: string) => trim(state))
	);

	if (latestState === PAID) {
		return {
			text: 'userSection.order.state.paid',
			color: '#68A910',
		};
	}
	if (latestState === CANCELLED) {
		return {
			text: 'userSection.order.state.cancelled',
			color: 'red',
		};
	}
	if (latestState === IN_WMS) {
		return {
			text: 'userSection.order.state.inWms',
			color: '#C7A859',
		};
	}
	if (latestState === WMS_ACCEPTED) {
		return {
			text: 'userSection.order.state.wmsAccepted',
			color: '#C7A859',
		};
	}
	if (latestState === WMS_INCOMPLETE) {
		return {
			text: 'userSection.order.state.wmsIncomplete',
			color: '#C7A859',
		};
	}
	if (latestState === WMS_READY) {
		return {
			text: 'userSection.order.state.wmsReady',
			color: '#C7A859',
		};
	}
	if (latestState === SENT) {
		return {
			text: 'userSection.order.state.sent',
			color: '#68A910',
		};
	}
	if (latestState === DELIVERED) {
		return {
			text: 'userSection.order.state.delivered',
			color: '#68A910',
		};
	}

	if (latestState === RETURNED) {
		return {
			text: 'userSection.order.state.returned',
			color: '#68A910',
		};
	}
	if (latestState === REFUNDED) {
		return {
			text: 'userSection.order.state.refunded',
			color: '#68A910',
		};
	}
	if (latestState === REVERT_FINANCE_AND_FEES) {
		return {
			text: 'userSection.order.state.revertFinanceAndFees',
			color: 'red',
		};
	}
	if (latestState === LOSS_EVENT) {
		return {
			text: 'userSection.order.state.lossEvent',
			color: 'red',
		};
	}
	if (latestState === RETURNING_GOODS) {
		return {
			text: 'userSection.order.state.returningGoods',
			color: 'red',
		};
	}
	if (latestState === CREATED) {
		return {
			text: 'userSection.order.state.created',
			color: '#C7A859',
		};
	}
	return { text: 'userSection.order.state.unknown', color: '#C7A859' };
};

export const getPdfDocument = async (
	orderId: string,
	documentUrl: string,
	userLoginHash: string
) => {
	if (userLoginHash && orderId && documentUrl) {
		const apiService = apiServiceInstance;
		const response =
			await apiService.get<VinistoOrderDllModelsApiReturnDataPdfReturn>(
				`order-api/orders/${orderId}/GetPdfDocument`,
				true,
				undefined,
				[
					{
						key: 'UserLoginHash',
						value: userLoginHash,
					},
					{
						key: 'DocumentUrl',
						value: documentUrl,
					},
				]
			);
		return response.pdfData;
	}
};

export const openPdf = (data: string) => {
	const byteCharacters = window.atob(data);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i += 1) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const file = new Blob([byteArray], { type: 'application/pdf;base64' });
	const fileURL = URL.createObjectURL(file);

	window.open(fileURL);
};
