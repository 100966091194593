import { FC, lazy, Suspense, useCallback, useContext } from 'react';
import { get, uniqueId } from 'lodash-es';
import cx from 'classnames';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';
const AddToCartButtonMinus = lazy(
	() => import('Components/Icons/AddToCartButtonMinus')
);
const AddToCartButtonPlus = lazy(
	() => import('Components/Icons/AddToCartButtonPlus')
);
const BottleTimesIcon = lazy(() => import('Components/Icons/BottleTimes'));
import useLocalizedValue from 'Hooks/useLocalizedValue';
import useBundlePrice from 'Hooks/useBundlePrice';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import Loader from 'Components/View/Loader';

import { IInActiveCatItemMobileProps } from './interfaces';

const InActiveCartItemMobile: FC<IInActiveCatItemMobileProps> = (
	props
): JSX.Element => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const basketContext = useContext(BasketContext);
	const handleOnChangeItemQuantity = get(
		basketContext,
		'handleOnChangeItemQuantity',
		() => {}
	);

	const bundleId = get(props, 'cartItem.bundleId', '');
	const bundleData = get(props, 'cartItem.bundle', {});
	const supplierName = get(bundleData, 'supplier.nameWeb', '');
	const bundleQuantity = get(props, 'cartItem.quantity', 1);

	const getLocalizedValue = useLocalizedValue();
	const getBundlePrice = useBundlePrice();

	const { price, priceWithVat, discountedFromPriceVat, currencyTitle } =
		getBundlePrice(bundleData);
	const totalPriceWithVAT = createCurrencyValue(priceWithVat, bundleQuantity);
	const totalPriceWithoutVAT = createCurrencyValue(price, bundleQuantity);
	const discountedFromPriceWithVat = discountedFromPriceVat;
	const totalDiscountedFromPriceWithVatFormatted = discountedFromPriceWithVat
		? createCurrencyValue(discountedFromPriceWithVat, bundleQuantity)
		: null;

	const flagSpecification = getFlagSpecification(
		get(bundleData, 'specificationDetails')
	);

	const handleOnReturnItemToBasket = useCallback(() => {
		handleOnChangeItemQuantity(bundleQuantity, bundleId);
	}, [get(basketContext, 'basketState')]);

	const isSet = bundleData?.isSet;

	const isSetDiscount = Boolean(
		(bundleData?.prices?.[0]?.valueWithVat ?? 0) <
			(bundleData?.prices?.[1]?.valueWithVat ?? 0)
	);

	const setTotalDiscountedFromPriceWithVatFormatted = bundleData?.prices?.[1]
		?.valueWithVat
		? createCurrencyValue(bundleData?.prices?.[1]?.valueWithVat, bundleQuantity)
		: null;

	return (
		<div
			className={cx('vinisto-user-favorite vinisto-cart__item--mobile', {
				discounted: !!discountedFromPriceWithVat || isSetDiscount,
			})}
		>
			<div className="vinisto-user-orders__orders__order-body">
				<div className="vinisto-user-orders__orders__order-body__item">
					<div className="vinisto-user-orders__orders__order-body__item__info__img">
						<img
							src={getBundleImage(
								get(bundleData, 'images', []),
								IMAGE_SIZE_THUMB_64x80
							)}
							alt={`${t({ id: 'alt.bundleImage' })}`}
						/>
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info vinisto-cart-item-info">
						<div className="vinisto-user-orders__orders__order-body__item__info__data">
							<div className="vinisto-user-orders__orders__order-body__item__info__data__name mb-0">
								{getLocalizedValue(get(bundleData, 'name', [])) ?? '-'}
							</div>
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__info__data w-100">
							<div className="w-100">
								{/*<div className="vinisto-user-orders__orders__order-body__item__info__data__score">
									<Rating
										defaultValue={bundleAvgRating}
										readOnly
									/>
									<span className="vinisto-wine__review__wrap-count">
										({bundleTotalRatingCount})
									</span>
								</div>*/}
								<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
									{get(flagSpecification, 'component') &&
										get(flagSpecification, 'component')}
									<span>
										{get(flagSpecification, 'shortVariety') &&
											get(flagSpecification, 'shortVariety')}
									</span>
								</div>
							</div>
							<div>
								{/*<div className="vinisto-user-orders__orders__order-body__item__prices">
									<div className="vinisto-user-orders__orders__order-body__item__prices__total">
										<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
											{`${
												discountedFromPriceWithVatFormatted ?? priceWithVAT
											} ${currencyTitle}`}
										</span>
										<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
											{`${priceWithVAT} ${currencyTitle}`}
										</span>
									</div>
									<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
										{`${t({
											id: 'basket.priceWithoutVAT',
										})} `}
										<span className="fw-bolder price-span">{`${priceWithoutVAT} ${currencyTitle}`}</span>
									</div>
									</div>*/}
							</div>
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__info__data mt-2 flex-wrap align-items-start">
							<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat w-100">
								{t(
									{ id: 'bundle.supplier.name' },
									{
										name: (
											<span className="vinisto-color-success fw-bolder">
												{supplierName.length > 0
													? supplierName
													: t({
															id: 'productDetail.seller.name.others',
													  })}
											</span>
										),
									}
								)}
							</div>
							<div className="vinisto-wine__count mt-0">
								<button className="vinisto-wine__count__minus btn-plus-minus">
									<Suspense fallback={<Loader blank />}>
										<AddToCartButtonMinus
											id={uniqueId()}
											alt={t({ id: 'alt.less' })}
											title={``}
											className={``}
										/>
									</Suspense>
								</button>
								<label className="vinisto-wine__count__amount-label">
									<Suspense fallback={<Loader blank />}>
										<BottleTimesIcon
											id={uniqueId()}
											alt={t({ id: 'alt.numberOfBottles' })}
											title={``}
											className={`BottleTimesIcon`}
										/>
									</Suspense>
									<input
										type="number"
										min="1"
										onChange={() => {}}
										value={bundleQuantity}
										name="vinisto-wine__count__amount"
										className="vinisto-wine__count__amount"
									/>
								</label>
								<button className="vinisto-wine__count__plus btn-plus-minus">
									<Suspense fallback={<Loader blank />}>
										<AddToCartButtonPlus
											id={uniqueId()}
											alt={t({ id: 'alt.more' })}
											title={``}
											className={``}
										/>
									</Suspense>
								</button>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__prices">
								<div className="vinisto-user-orders__orders__order-body__item__prices__total">
									{`${t({ id: 'basket.totalPrice' })} `}
									<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
										{`${
											(!isSet
												? totalDiscountedFromPriceWithVatFormatted
												: setTotalDiscountedFromPriceWithVatFormatted) ??
											totalPriceWithVAT
										} ${currencyTitle}`}
									</span>
									<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">
										{`${totalPriceWithVAT} ${currencyTitle}`}
									</span>
								</div>
								<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
									{`${t({ id: 'basket.priceWithoutVAT' })} `}
									<span className="fw-bolder price-span">{`${totalPriceWithoutVAT} ${currencyTitle}`}</span>
								</div>
							</div>

							<div className="vinisto-user-favorite__delete-wrap">
								<div className="vinisto-user-favorite__delete-wrap__text">
									{t({ id: 'basket.removed.label' })}
									<br />
									<button
										className="vinisto-btn vinisto-clear-btn vinisto-color-success pointer fw-bolder"
										onClick={handleOnReturnItemToBasket}
									>
										{t({ id: 'basket.removed.returnBack' })}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InActiveCartItemMobile;
