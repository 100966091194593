import { useState } from 'react';
import cx from 'classnames';

import { SetItemProps } from './types';
import styles from './styles.module.css';

const SetItem = ({
	imageUrl,
	bundleName,
	basicInfo,
	seller,
	price,
	originalPrice,
	discountPercent,
	bundleInfo,
	params,
	translations,
}: SetItemProps) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className={styles.setWrapper}>
			<button
				className={cx(styles.setButton, isOpen && styles.open)}
				type="button"
				aria-expanded={isOpen ? 'true' : 'false'}
				onClick={() => setIsOpen((isOpen) => !isOpen)}
			>
				<div className={styles.imageWrap}>
					<img
						src={imageUrl}
						alt={bundleName}
						className={styles.setItemImage}
					/>
				</div>
				<div className={styles.dataWrap}>
					<div className={styles.setInfo}>
						<div className={styles.setName}>{bundleName}</div>
						<div className={styles.basicInfo}>{basicInfo}</div>
						<div className={styles.seller}>
							{translations.seller}: <span className="fw-bolder">{seller}</span>
						</div>
					</div>
					<div className={styles.priceMore}>
						<div className={cx(styles.setInfo, styles.prices)}>
							<div className={styles.priceWrap}>
								{originalPrice && (
									<div className={styles.originalPrice}>
										{originalPrice} {translations.currency}
									</div>
								)}
								<div className={styles.price}>
									{price} {translations.currency}
								</div>
							</div>
							{discountPercent}
						</div>
						<div className={cx(styles.setInfo, styles.moreWrap)}>
							{isOpen ? translations.less : translations.more}
							<img
								className={styles.arrowDown}
								src={'/assets/images/arrow-down.svg'}
							/>
						</div>
					</div>
				</div>
			</button>
			<div className={cx(styles.propsWrapper, isOpen && styles.open)}>
				<div className={styles.inner}>
					<div className={styles.bundleInfo}>{bundleInfo}</div>
					{params}
				</div>
			</div>
		</div>
	);
};

export default SetItem;
