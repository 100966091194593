import { useBundleDetail, useBundleSetParamsShort } from 'Pages/Bundle/hooks';
import { DiscountPercentage, SetItem } from 'vinisto_ui';
import BundleInfoParams from 'Pages/Bundle/Components/BundleDetail/Components/BundleInfoParams';
import BundleAdapter from 'Services/Bundle/adapter';
import useBundlePrice from 'Hooks/useBundlePrice';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import { BundleDataBundle } from 'Services/Bundle/interfaces';

const SetBundle = (set_data: BundleDataBundle) => {
	const adapter = new BundleAdapter();
	const getBundlePrice = useBundlePrice();
	const getBundleSetParamsShort = useBundleSetParamsShort;
	const use_set_data = adapter.fromApi(set_data);
	const bundle_set_product = useBundleDetail(use_set_data);
	const {
		discountedFromPriceVat: bundle_set_discounted_price,
		priceWithVat: bundle_set_price,
		currencyTitle,
	} = getBundlePrice(bundle_set_product);
	const set_price_rounded = createCurrencyValue(
		set_data.setItem?.price?.valueWithVat ?? 0
	);
	const set_params_short = getBundleSetParamsShort(
		use_set_data.specificationDetails ?? []
	);

	const is_discounted =
		(bundle_set_discounted_price ?? 0) >
			(set_data.setItem?.price?.valueWithVat ?? 0) ||
		(set_data.setItem?.price?.valueWithVat ?? 0) < bundle_set_price;

	return (
		<SetItem
			imageUrl={bundle_set_product.bundleImageSmall}
			bundleName={bundle_set_product.bundleName}
			basicInfo={set_params_short}
			seller={bundle_set_product.supplierName}
			price={set_price_rounded}
			discountPercent={
				is_discounted && (
					<DiscountPercentage
						discountedPriceWithVat={
							set_data.setItem?.price?.valueWithVat ?? bundle_set_price
						}
						standardPriceWithVat={
							bundle_set_discounted_price ?? bundle_set_price
						}
					/>
				)
			}
			originalPrice={
				is_discounted ? bundle_set_discounted_price ?? bundle_set_price : null
			}
			bundleInfo={bundle_set_product.bundleDescription}
			params={
				<BundleInfoParams
					bundleParams={bundle_set_product?.specificationDetails ?? []}
				/>
			}
			translations={{
				seller: 'prodejce',
				more: 'Více',
				less: 'Méně',
				currency: currencyTitle,
			}}
		/>
	);
};
export default SetBundle;
