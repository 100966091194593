import { FC, useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';

import { IOrderInfoHandoverProps } from './interfaces';
import styles from './styles.module.css';

const OrderInfoHandover: FC<IOrderInfoHandoverProps> = ({ orderNumber }) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	return (
		<ContainerFullWidth>
			<h2 className="vinisto-heading underline mt-2 mt-xxl-4">
				{t({
					id: 'cartShippingPayment.info',
				})}
			</h2>
			<div className={styles.info}>
				<p className={styles.infoText}>
					{t(
						{ id: 'cartShippingPayment.handover.summary' },
						{
							orderNumber: (
								<span className="fw-bolder">
									{t(
										{
											id: 'cartShippingPayment.handover.summary.orderNumber',
										},
										{ orderNumber }
									)}
								</span>
							),
						}
					)}
				</p>
				<p className={styles.infoText}>
					{t({ id: 'cartShippingPayment.handover.summary.follow' })}
				</p>
			</div>
		</ContainerFullWidth>
	);
};

export default OrderInfoHandover;
