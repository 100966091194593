import CategoryBundlesWithFilters from './Components/CategoryBundlesWithFilters';
import BundlesWithFiltersProvider from './Components/CategoryBundlesWithFilters/context';
import CategoryBreadcrumb from './Components/CategoryBreadcrumb';
import CategoryContextProvider from './context';

import './styles.css';

const Category = () => {
	return (
		<CategoryContextProvider>
			<section id="content-wrapper">
				<BundlesWithFiltersProvider>
					<CategoryBreadcrumb />
					<CategoryBundlesWithFilters />
				</BundlesWithFiltersProvider>
			</section>
		</CategoryContextProvider>
	);
};

export default Category;
