import { IQueryArgument } from 'Services/ApiService/interfaces';
import ApiService from 'Services/ApiService';

const apiService = new ApiService();

export const fetchDeliveriesData = async (requestData: Record<any, any>) => {
	try {
		return await apiService.post(
			'order-api/deliveries/GetDeliveriesByBasket',
			requestData,
			true
		);
	} catch (error) {
		return [];
	}
};

export const fetchPaymentsData = async (requestQuery: IQueryArgument[]) => {
	try {
		return await apiService.get(
			'order-api/payments/GetAllowedPayments',
			true,
			undefined,
			requestQuery
		);
	} catch (error) {
		return [];
	}
};
