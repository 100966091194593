import { FC, lazy, Suspense, useCallback, useContext } from 'react';
import cx from 'classnames';
import { get, uniqueId } from 'lodash-es';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';
import Loader from 'Components/View/Loader';
const AddToCartButtonMinus = lazy(
	() => import('Components/Icons/AddToCartButtonMinus')
);
const AddToCartButtonPlus = lazy(
	() => import('Components/Icons/AddToCartButtonPlus')
);
const BottleTimesIcon = lazy(() => import('Components/Icons/BottleTimes'));
const CloseIcon = lazy(() => import('Components/Icons/Close'));
import useLocalizedValue from 'Hooks/useLocalizedValue';
import useBundlePrice from 'Hooks/useBundlePrice';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import Rating from 'Components/Rating';

import { IInActiveCatItemProps } from './interfaces';

const InActiveCartItem: FC<IInActiveCatItemProps> = (
	props: IInActiveCatItemProps
): JSX.Element => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const basketContext = useContext(BasketContext);
	const handleOnChangeItemQuantity = get(
		basketContext,
		'handleOnChangeItemQuantity',
		() => {}
	);

	const bundleId = get(props, 'cartItem.bundleId', '');
	const bundleData = get(props, 'cartItem.bundle', {});
	const supplierName = get(bundleData, 'supplier.nameWeb', '');
	const bundleQuantity = get(props, 'cartItem.quantity', 1);
	const bundleEvaluation = get(bundleData, 'bundleEvaluation', {});
	const bundleAvgRating = get(bundleEvaluation, 'averageStars', 0) / 2;
	const bundleTotalRatingCount = get(
		bundleEvaluation,
		'totalEvaluationCount',
		0
	);
	const handleOnReturnItemToBasket = useCallback(() => {
		handleOnChangeItemQuantity(bundleQuantity, bundleId);
	}, [get(basketContext, 'basketState')]);

	const getLocalizedValue = useLocalizedValue();
	const getBundlePrice = useBundlePrice();

	const { price, priceWithVat, discountedFromPriceVat, currencyTitle } =
		getBundlePrice(bundleData);
	const priceWithVAT = createCurrencyValue(priceWithVat);
	const priceWithoutVAT = createCurrencyValue(price);
	const totalPriceWithVAT = createCurrencyValue(priceWithVat, bundleQuantity);
	const totalPriceWithoutVAT = createCurrencyValue(price, bundleQuantity);
	const discountedFromPriceWithVat = discountedFromPriceVat;
	const discountedFromPriceWithVatFormatted = discountedFromPriceWithVat
		? createCurrencyValue(discountedFromPriceWithVat)
		: null;
	const totalDiscountedFromPriceWithVatFormatted = discountedFromPriceWithVat
		? createCurrencyValue(discountedFromPriceWithVat, bundleQuantity)
		: null;

	const flagSpecification = getFlagSpecification(
		get(bundleData, 'specificationDetails')
	);

	const isSet = bundleData?.isSet;

	const isSetDiscount = Boolean(
		(bundleData?.prices?.[0]?.valueWithVat ?? 0) <
			(bundleData?.prices?.[1]?.valueWithVat ?? 0)
	);

	const setPriceWithVAT = createCurrencyValue(
		bundleData?.prices?.[1]?.valueWithVat ?? 0
	);
	const setTotalDiscountedFromPriceWithVatFormatted = bundleData?.prices?.[1]
		?.valueWithVat
		? createCurrencyValue(bundleData?.prices?.[1]?.valueWithVat, bundleQuantity)
		: null;

	return (
		<div
			className={cx(
				'vinisto-user-orders__orders__order-body__item vinisto-cart__item',
				{ discounted: !!discountedFromPriceWithVat || isSetDiscount }
			)}
		>
			<div className="vinisto-user-orders__orders__order-body__item__info">
				<div className="vinisto-user-orders__orders__order-body__item__info__img">
					<img
						src={getBundleImage(
							get(bundleData, 'images', []),
							IMAGE_SIZE_THUMB_64x80
						)}
						alt={`${t({ id: 'alt.bundleImage' })}`}
					/>
				</div>
				<div className="vinisto-user-orders__orders__order-body__item__info__data">
					<div className="vinisto-user-orders__orders__order-body__item__info__data__name">
						{getLocalizedValue(get(bundleData, 'name', [])) ?? '-'}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__data__score">
						<Rating
							defaultValue={bundleAvgRating}
							readOnly
						/>
						<span className="vinisto-wine__review__wrap-count">
							({bundleTotalRatingCount})
						</span>
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
						{get(flagSpecification, 'component') &&
							get(flagSpecification, 'component')}
						<span>
							{get(flagSpecification, 'shortVariety') &&
								get(flagSpecification, 'shortVariety')}
						</span>
					</div>
				</div>
			</div>

			<div className="vinisto-user-orders__orders__order-body__item__prices">
				<div className="vinisto-user-orders__orders__order-body__item__prices__total">
					<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">{`${
						discountedFromPriceWithVatFormatted ?? priceWithVAT
					} ${currencyTitle}`}</span>
					{discountedFromPriceWithVat && (
						<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">{`${priceWithVAT} ${currencyTitle}`}</span>
					)}
				</div>
				<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
					{`${t({ id: 'basket.priceWithoutVAT' })} `}
					<span className="fw-bolder price-span">{`${priceWithoutVAT} ${currencyTitle}`}</span>
				</div>
				<div className="vinisto-cart__supplier-name">
					{t(
						{ id: 'bundle.supplier.name' },
						{
							name: (
								<span className="vinisto-color-success fw-bolder">
									{supplierName.length > 0
										? supplierName
										: t({
												id: 'productDetail.seller.name.others',
										  })}
								</span>
							),
						}
					)}
				</div>
			</div>

			<div className="vinisto-wine__count">
				<button className="vinisto-wine__count__minus btn-plus-minus">
					<Suspense fallback={<Loader blank />}>
						<AddToCartButtonMinus
							id={uniqueId()}
							alt={t({ id: 'alt.less' })}
							title={``}
							className={``}
						/>
					</Suspense>
				</button>
				<label className="vinisto-wine__count__amount-label">
					<Suspense fallback={<Loader blank />}>
						<BottleTimesIcon
							id={uniqueId()}
							alt={t({ id: 'alt.numberOfBottles' })}
							title={``}
							className={`BottleTimesIcon`}
						/>
					</Suspense>
					<input
						type="number"
						min="1"
						onChange={() => {}}
						value={get(props, 'cartItem.quantity', 1)}
						name="vinisto-wine__count__amount"
						className="vinisto-wine__count__amount"
					/>
				</label>
				<button className="vinisto-wine__count__plus btn-plus-minus">
					<Suspense fallback={<Loader blank />}>
						<AddToCartButtonPlus
							id={uniqueId()}
							alt={t({ id: 'alt.more' })}
							title={``}
							className={``}
						/>
					</Suspense>
				</button>
			</div>

			<div className="vinisto-cart__item__price-remove">
				<div className="vinisto-user-orders__orders__order-body__item__prices">
					<div className="vinisto-user-orders__orders__order-body__item__prices__total">
						{`${t({ id: 'basket.totalPrice' })} `}
						<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">{`${
							(!isSet
								? totalDiscountedFromPriceWithVatFormatted
								: setTotalDiscountedFromPriceWithVatFormatted) ??
							totalPriceWithVAT
						} ${currencyTitle}`}</span>
						{!!(discountedFromPriceWithVat || setPriceWithVAT) && (
							<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">{`${priceWithVAT} ${currencyTitle}`}</span>
						)}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
						{`${t({ id: 'basket.priceWithoutVAT' })} `}
						<span className="fw-bolder price-span">{`${totalPriceWithoutVAT} ${currencyTitle}`}</span>
					</div>
				</div>

				<div className="vinisto-user-favorite__close">
					<Suspense fallback={<Loader blank />}>
						<CloseIcon
							id={uniqueId()}
							alt={t({ id: 'alt.delete' })}
							title={``}
							className={`CloseIcon `}
						/>
					</Suspense>
				</div>
			</div>

			<div className="vinisto-user-favorite__delete-wrap">
				<div className="vinisto-user-favorite__delete-wrap__text">
					{t({ id: 'basket.removed.label' })}
					<br />
					<button
						className="vinisto-btn vinisto-clear-btn vinisto-color-success fw-bold"
						onClick={handleOnReturnItemToBasket}
					>
						{t({ id: 'basket.removed.returnBack' })}
					</button>
				</div>
			</div>
		</div>
	);
};

export default InActiveCartItem;
