import { apiServiceInstance } from 'Services/ApiService';
import { VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn } from 'vinisto_api_client/src/api-types/product-api';

type FetchFullTextSearchResultsProps = {
	SearchString: string;
	Collections: ('BUNDLE' | 'CATEGORY')[];
	Page: number;
	Limit: number;
	UserLoginHash: string;
};

export const fetchFullTextSearchResults = ({
	SearchString,
	Collections,
	Page,
	Limit,
	UserLoginHash,
}: FetchFullTextSearchResultsProps) => {
	const filters = [
		{
			key: 'IsCache',
			value: true,
		},
		{
			key: 'SearchString',
			value: SearchString,
		},
		{
			key: 'Limit',
			value: Limit,
		},
		{
			key: 'Offset',
			value: (Page - 1) * Limit,
		},
		{
			key: 'IsB2b',
			value: false,
		},
		{
			key: 'UserLoginHash',
			value: UserLoginHash,
		},
	];

	if (Collections.length > 0) {
		Collections.forEach((collection) => {
			filters.push({
				key: 'Collections',
				value: collection,
			});
		});
	}

	return apiServiceInstance.get<VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn>(
		'search-api/full-search/search-string-separate-results',
		true,
		undefined,
		filters
	);
};
