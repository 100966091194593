import * as React from 'react';
import ApiService from 'Services/ApiService';

import { ICategoryProps, ICategoryValue } from './interfaces';

const defaultContextValue: ICategoryValue = {
	loading: false,
	loaded: false,
	categories: [],
};

export const CategoryContext = React.createContext(defaultContextValue);

const CategoryProvider: React.FC<ICategoryProps> = (props): JSX.Element => {
	const [loading, setLoading] = React.useState(false);
	const [loaded, setLoaded] = React.useState(false);
	const [categories, setCategories] = React.useState<Record<any, any>[]>([]);

	React.useEffect(() => {
		setLoading(true);
		setLoaded(false);
		const apiService = new ApiService();
		apiService
			.getCollection(
				`product-api/home-page/categories/${props.typeCategoryHomePage}`
			)
			.then((response: Record<any, any>) => {
				setCategories(response.homePageCategory);
				setLoading(false);
				setLoaded(true);
			})
			.catch(() => {
				setCategories([]);
				setLoading(false);
				setLoaded(false);
			});
	}, []);

	const contextValue: ICategoryValue = {
		loading,
		loaded,
		categories,
	};

	return (
		<CategoryContext.Provider value={contextValue}>
			{props.children}
		</CategoryContext.Provider>
	);
};

export default CategoryProvider;
