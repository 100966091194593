import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import Flag from 'Components/Flag';
import useMenuAnalytics from 'Components/Navbar/useMenuAnalytics';

import { ICategoryLink } from './interfaces';

const CategoryLink = ({ name, to, flag, className }: ICategoryLink) => {
	const resolved = useResolvedPath(to);
	const match = useMatch({ path: resolved.pathname, end: true });

	const linkAttrs: Record<string, any> = {};

	if (match) linkAttrs['aria-current'] = 'page';

	const { handleOnClickMenuItem } = useMenuAnalytics();

	return (
		<NavLink
			className="nav-link"
			onClick={() => handleOnClickMenuItem(name)}
			{...{ to, ...linkAttrs }}
		>
			{flag && (
				<Flag
					code={flag}
					width={23}
					height={15}
					className="vinisto-flag"
				/>
			)}
			<span className={className}>{name}</span>
		</NavLink>
	);
};

export default CategoryLink;
