import { useContext } from 'react';
import { orderBy } from 'lodash-es';
import getBundleImage, {
	IMAGE_SIZE_THUMB_368x490,
} from 'Helpers/getBundleImage';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import useIdenticalBundles from 'Hooks/Queries/useIdenticalBundles';
import useLocalizedCurrency from 'Hooks/useLocalizedCurrency';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import usePriceActiveCurrency from 'Hooks/usePriceActiveCurrency';
import { BundleDetailProps } from 'Pages/Bundle/interfaces';
import { BundleData } from 'Services/Bundle/interfaces';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import useSpecificationParamValue from 'Hooks/useSpecificationParamValue';

import {
	VinistoImageDllModelsApiImageImage,
	VinistoProductDllModelsApiBundleBundle,
	VinistoProductDllModelsApiSpecificationSpecificationDetail,
} from '@/api-types/product-api';

const useBundleDetail = (bundle: BundleData | undefined): BundleDetailProps => {
	const localizationContext = useContext(LocalizationContext);
	const warehouseContext = useContext(WarehouseContext);
	const getLocalizedValue = useLocalizedValue();
	const getPriceActiveCurrency = usePriceActiveCurrency();
	const getLocalizedCurrency = useLocalizedCurrency();

	const id = bundle?.id ?? '';
	const bundleCateogryId = bundle?.categories?.[0] ?? '';
	const bundleCateogryIds = bundle?.categories ?? [];
	const bundleName = getLocalizedValue(bundle?.name ?? []);
	const bundleDescription = getLocalizedValue(bundle?.description ?? []);
	const bundleShortDescription = getLocalizedValue(
		bundle?.shortDescription ?? []
	);
	const bundlePrice = getPriceActiveCurrency(bundle?.prices ?? []);
	const bundleLocalizedPrice = getLocalizedCurrency(bundle?.prices ?? []);
	const bundlePriceWithVAT = bundlePrice?.valueWithVat ?? 0;
	const bundlePriceCurrency = localizationContext.activeCurrency.currency ?? '';
	const bundleEvaluation = bundle?.bundleEvaluation ?? {};
	const bundleAverageRating = (bundleEvaluation.averageStars ?? 0) / 2;
	const reviewCount = bundleEvaluation.totalEvaluationCount ?? 0;
	const { data: identicalBundlesData } = useIdenticalBundles(id);
	const suppliers = [bundle, identicalBundlesData];
	const isOnlySupplier = suppliers.length === 1;
	const totalEvaluationCount = bundleEvaluation.totalEvaluationCount ?? 0;
	const categoryId = (bundle?.categories ?? [])?.[0];
	const temporaryUnavailable = bundle?.temporaryUnavailable ?? false;
	const isSaleOver = bundle?.isSaleOver ?? false;
	const isGift = bundle?.isGift ?? false;
	const specificationDetails = bundle?.specificationDetails ?? [];
	const {
		shortVariety: bundleProducerName,
		varietyUrl: bundleProducerUrl,
		component: bundleFlag,
	} = getFlagSpecification(specificationDetails ?? []);
	const bundleImageSmall = getBundleImage(
		bundle?.images ?? [],
		IMAGE_SIZE_THUMB_368x490
	);
	const bundleImages =
		bundle?.images
			?.sort((a) => (a.isMain ? -1 : 1))
			.map((image: VinistoImageDllModelsApiImageImage) => {
				return {
					src: image?.domainUrls?.original_png ?? '',
				};
			}) ?? [];
	const availableQuantity = [
		warehouseContext.getQuantity(bundle?.id ?? ''),
		...(isOnlySupplier
			? []
			: [
					...(identicalBundlesData?.map((bundle) =>
						warehouseContext.getQuantity(`${bundle?.id}`)
					) || []),
			  ]),
	].filter((x): x is number => x !== undefined);
	const supplierName = bundle?.supplier?.nameWeb ?? '';
	const bundleShopControls = bundle as VinistoProductDllModelsApiBundleBundle;
	const bundleUrl = getLocalizedValue(bundle?.url ?? []);
	const tagsDetails = bundle?.tagsDetail ?? [];
	const items = bundle?.items ?? [];

	return {
		id,
		bundleCateogryId,
		bundleCateogryIds,
		bundleName,
		bundleDescription,
		bundleShortDescription,
		bundleLocalizedPrice,
		bundlePriceWithVAT,
		bundlePriceCurrency,
		bundleEvaluation,
		bundleAverageRating,
		reviewCount,
		isOnlySupplier,
		totalEvaluationCount,
		categoryId,
		temporaryUnavailable,
		isGift,
		isSaleOver,
		specificationDetails,
		bundleProducerName,
		bundleProducerUrl,
		bundleFlag,
		bundleImageSmall,
		bundleImages,
		availableQuantity,
		supplierName,
		bundleShopControls,
		bundleUrl,
		tagsDetail: tagsDetails,
		items,
		prices: bundle?.prices,
		productsDetail: bundle?.productsDetail,
		isSet: bundle?.isSet ?? false,
		setBundles: bundle?.setBundles ?? [],
	};
};

const useBundleSetParamsShort = (
	bundleParams: VinistoProductDllModelsApiSpecificationSpecificationDetail[]
): string => {
	const getParamValue = useSpecificationParamValue();

	const params = bundleParams.filter(
		(param) =>
			param.definition.name.some(
				(item: { value: string }) =>
					item.value === 'Druh' ||
					item.value === 'Typ' ||
					item.value === 'Objem' ||
					item.value === 'Alkohol'
			) &&
			(param.value?.selectedValueName?.length > 0 ||
				param.value?.selectedValuesName?.length > 0 ||
				param.value?.value !== undefined ||
				(param.value?.specificationType === 'TEXT' &&
					param.definition.isHidden === true))
	);

	const paramsOrdered = orderBy(params, 'definition.orderDetail', 'asc');

	const parameters = paramsOrdered.map((param) => {
		const paramValues = getParamValue(param);
		return Array.isArray(paramValues)
			? paramValues?.map(({ name }) => name)
			: paramValues?.name;
	});
	return parameters.join(', ');
};

export { useBundleDetail, useBundleSetParamsShort };
