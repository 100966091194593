import * as React from 'react';
import { get, map } from 'lodash-es';

import { IListProps } from './interfaces';
import '../../styles.css';
import FavoritesItem from './FavoritesItem';

const ListMobile: React.FC<IListProps> = (props: IListProps): JSX.Element => {
	return (
		<div className="vinisto-user-orders__orders__order vinisto-user-favorites vinisto-user-favorites--mobile">
			{map(
				get(props, 'favoritesData', []),
				(favorite: Record<any, any>, key: number) => {
					return (
						<FavoritesItem
							key={`favorites-list-bundle-item-${get(favorite, 'ItemId', key)}`}
							favoriteData={favorite}
							isLoading={get(favorite, 'isLoading', false)}
							isRemoved={get(favorite, 'isTemp', false)}
							alwaysRedirectToDetail
						/>
					);
				}
			)}
		</div>
	);
};

export default ListMobile;
