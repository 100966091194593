import ReactDOM from 'react-dom/client';

import VinistoEshopApp from './App';

import 'react-loading-skeleton/dist/skeleton.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import './assets/styles/bootstrap/bootstrap.min.css';
import './assets/styles/fonts.css';
import './assets/styles/utility.css';
import './assets/styles/template.css';
import './assets/styles/responsive.css';
import './assets/styles/notifications.css';
import './assets/styles/alice-carousel.css';
import 'vinisto_ui/src/assets/styles/variables.css';

import 'Services/SentryService';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = document.getElementById('vinisto_eshop_root')!;

const VinistoAppRoot = ReactDOM.createRoot(root);

VinistoAppRoot.render(
	<>
		<VinistoEshopApp />
	</>
);
