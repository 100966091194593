import { AbstractAdapter } from '../abstract-adapter';
import { DEFAULT_CURRENCY } from '../../shared';

import Price from './index';

import {
	VinistoHelperDllEnumsVatRate,
	VinistoProductDllModelsApiCommonPrice,
} from '@/api-types/product-api';
import convertDateToDayjs from '@/utils/convert-date-to-dayjs';
import convertPrice from '@/utils/convert-price';

class PriceAdapter extends AbstractAdapter<
	Price,
	VinistoProductDllModelsApiCommonPrice
> {
	fromApi(apiData: VinistoProductDllModelsApiCommonPrice): Price {
		return {
			currency: apiData?.currency ?? DEFAULT_CURRENCY,
			value: convertPrice(apiData?.value ?? 0),
			valueWithVat: convertPrice(apiData?.valueWithVat) ?? 0,
			vat: apiData?.vat ?? VinistoHelperDllEnumsVatRate.BaseVat,
			vatValue: apiData?.vatValue ?? 21,
			priceType: apiData?.type,
			validFrom: convertDateToDayjs(apiData?.validFrom),
			validTo: convertDateToDayjs(apiData?.validTo),
		};
	}
}

export default PriceAdapter;
