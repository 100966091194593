import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import {
	forEach,
	get,
	isInteger,
	isNumber,
	join,
	round,
	size,
	upperFirst,
} from 'lodash-es';
import createFormattedDecimalNumber from 'Helpers/createFormattedDecimalNumber';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import LinkWidgetList from 'Components/link-widget';
import useSectionLinkWidgetsQuery from 'Hooks/use-section-link-widgets-query';
import { useLocation } from 'react-router-dom';
import { ShareProductLink } from 'vinisto_ui';

import { CategoryContext } from '../../context';
import { BundlesWithFiltersContext } from '../CategoryBundlesWithFilters/context';

import styles from './styles.module.css';

import { Allowed_Sections } from '@/domain/link-widget/enums';

const CategoryHeader = () => {
	// Rerender if resolution changes
	useContext(DeviceServiceContext);
	const location = useLocation();

	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const { categoryData } = useContext(CategoryContext);
	const { activeSpecificationFilters, activeTagFilters, specificationsQuery } =
		useContext(BundlesWithFiltersContext);

	const { query, filteredLinks } = useSectionLinkWidgetsQuery(
		Allowed_Sections.CATEGORY,
		location.pathname
	);

	const { isMobile, isTablet, isDesktop } = useContext(DeviceServiceContext);

	const isLoading = categoryData?.isLoading || specificationsQuery?.isLoading;
	const [showFullDescription, setShowFullDescription] =
		useState<boolean>(false);

	useEffect(() => {
		setShowFullDescription(false);
	}, [location]);

	const categoryDescription = getLocalizedValue(
		get(categoryData, 'data.category.description', [])
	);

	// This is mostly the same getters as the ones in frontend/eshop/src/Pages/Category/Components/CategoryBundlesWithFilters/index.tsx
	// Or frontend/eshop/src/Pages/Tag/Components/TagHeader/index.tsx
	// Or frontend/eshop/src/Pages/Wines/Components/WinesHeader/index.tsx
	// Or frontend/eshop/src/Pages/Category/Components/ActiveFilters/index.tsx (here are slightly different return shapes)
	// TODO: possibly refactor this to a shared file
	const getRangeValue = useCallback(
		(filter: Record<string, any>) => {
			return `${t(
				{ id: 'category.filter.from' },
				{
					value: `${
						isInteger(get(filter, 'min', ''))
							? get(filter, 'min', '')
							: createFormattedDecimalNumber(get(filter, 'min', ''))
					} ${getLocalizedValue(get(filter, 'unit'))}`,
				}
			)} ${t(
				{ id: 'category.filter.to' },
				{
					value: `${
						isInteger(get(filter, 'max', ''))
							? get(filter, 'max', '')
							: createFormattedDecimalNumber(get(filter, 'max', ''))
					} ${getLocalizedValue(get(filter, 'unit'))}`,
				}
			)}`;
		},
		[getLocalizedValue, t]
	);

	const getPriceValue = (filter: Record<string, any>) => {
		return `${t(
			{ id: 'category.filter.from' },
			{
				value: `${round(filter?.min, 0) ?? ''} ${get(
					localizationContext,
					'activeCurrency.title',
					''
				)}`,
			}
		)} ${t(
			{ id: 'category.filter.to' },
			{
				value: `${round(filter?.max, 0) ?? ''} ${get(
					localizationContext,
					'activeCurrency.title',
					''
				)}`,
			}
		)}`;
	};

	const getComboBoxValue = useCallback(
		(filter: Record<string, any>) => {
			return (filter.selectedValues ?? [])
				.map((value: string) => {
					const specification = (
						specificationsQuery?.data?.specifications ?? []
					).find((item) => item.id === filter.specificationDefinitionId);
					const allowedValue =
						// @ts-expect-error needs to assert this is a combobox specification
						specification?.allowedValues?.[value.toLowerCase()];
					return getLocalizedValue(allowedValue?.name ?? '');
				})
				.join(', ');
		},
		[specificationsQuery?.data?.specifications, getLocalizedValue]
	);

	const getCheckboxValue = useCallback(
		(filter: Record<string, any>) => {
			return get(filter, 'isChecked')
				? `${t({ id: 'category.filter.checkbox.yes' })}`
				: `${t({ id: 'category.filter.checkbox.no' })}`;
		},
		[t]
	);

	const specificationFiltersAsString: string = useMemo(() => {
		const filters: string[] = [];

		// Include tag filters
		forEach(activeSpecificationFilters, (filter: Record<string, any>) => {
			const filterStart = `${upperFirst(
				getLocalizedValue(get(filter, 'specificationName', []))
			)} `;
			if (get(filter, 'selectedValues')) {
				filters.push(`${filterStart} ${getComboBoxValue(filter)}`);
			} else if (
				isNumber(get(filter, 'min')) &&
				isNumber(get(filter, 'max')) &&
				get(filter, 'unit')
			) {
				filters.push(`${filterStart} ${getRangeValue(filter)}`);
			} else if (
				isNumber(get(filter, 'min')) &&
				isNumber(get(filter, 'max')) &&
				get(filter, 'currency')
			) {
				filters.push(`${filterStart} ${getPriceValue(filter)}`);
			} else if (get(filter, 'isChecked') !== undefined) {
				filters.push(`${filterStart} ${getCheckboxValue(filter)}`);
			}
		});

		if (size(filters) > 0) {
			return ` ${join(filters, ' ')}`;
		}
		return '';
	}, [activeSpecificationFilters]);

	const filterSafeText = (text: string): string => {
		const filteredText = text.split('\n')[0];
		return isDesktop ? filteredText : text;
	};

	const paragraphCount = categoryDescription.split('\n').length;

	const shouldRenderShowMoreButton = !isDesktop || paragraphCount > 1;
	const tagFiltersAsString = activeTagFilters.length
		? `${t({ id: 'tags' })} ${activeTagFilters
				.map((tagFilter) => getLocalizedValue(tagFilter.name ?? []))
				.join(', ')}`
		: '';

	const categoryDescriptionSliced = showFullDescription
		? categoryDescription
		: filterSafeText(categoryDescription);

	return (
		<div className="container mt-0 mb-0">
			<div className="row">
				<div className="col-12">
					<div className="vinisto-card vinisto-category-header">
						<h1 className="vinisto-category-header__heading">
							{isLoading ? (
								<Skeleton width="200px" />
							) : (
								`${getLocalizedValue(
									get(categoryData, 'data.category.name', [])
								)} ${specificationFiltersAsString} ${tagFiltersAsString}`
							)}
							<ShareProductLink
								className="ms-1"
								bundleName={''}
								isTabletMobile={isMobile || isTablet}
							/>
						</h1>

						<div
							className={cx(
								styles.readMoreWrap,
								!showFullDescription && styles.preview
							)}
						>
							{isLoading ? (
								<Skeleton count={2.75} />
							) : (
								<>
									<span
										dangerouslySetInnerHTML={{
											__html: categoryDescriptionSliced,
										}}
									/>
									{shouldRenderShowMoreButton && (
										<>
											{isDesktop && !showFullDescription && '… '}
											<button
												onClick={() =>
													setShowFullDescription(
														(setShowFullDescription) => !setShowFullDescription
													)
												}
												className={styles.readMoreLink}
											>
												{showFullDescription
													? t({
															id: 'category.header.stopReading',
													  })
													: t({
															id: 'category.header.continueReading',
													  })}
											</button>
										</>
									)}
								</>
							)}
						</div>

						<LinkWidgetList
							isLoading={query.isLoading}
							linkWidgets={filteredLinks?.map((linkWidget) => ({
								id: linkWidget.id,
								name: linkWidget.name,
								imageLocator: linkWidget.imageLocator,
								to: linkWidget.url,
								type: linkWidget.type,
							}))}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CategoryHeader;
