import { lazy, Suspense, useContext, useEffect, useRef } from 'react';
import cx from 'classnames';
import { get } from 'lodash-es';
import { Link, useMatch } from 'react-router-dom';
import { DeviceServiceAction } from 'Services/DeviceService/constants';
import useIsInBasket from 'Hooks/useIsInBasket';
import useLocalizedCurrency from 'Hooks/useLocalizedCurrency';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import { NavbarContext } from 'Components/Navbar/context';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { UserAvatar } from 'Components/Avatar';
import Basket from 'Components/Basket';
import { FavoriteCount } from 'Components/Favorite';
import FreeDeliveryProgressBar from 'Components/FreeDeliveryProgressBar';
import Loader from 'Components/View/Loader';

const BurgerMenuIcon = lazy(() => import('Components/Icons/BurgerMenu'));
const UserIcon = lazy(() => import('Components/Icons/User'));
const VinistoLogoBoldEmblemIcon = lazy(
	() => import('Components/Icons/VinistoLogoEmblemBold')
);

import './styles.css';

const BottomNavigation = () => {
	const { isLoggedIn, vinistoUser } = useContext(AuthenticationContext);
	const { useFormatMessage } = useContext(LocalizationContext);
	const {
		layoutWidth,
		layoutHeight,
		dispatch: deviceContextDispatch,
	} = useContext(DeviceServiceContext);
	const basketContext = useContext(BasketContext);
	const { handleOnToggleMenu, handleOnCloseMenu, menuRef } =
		useContext(NavbarContext);

	const t = useFormatMessage();
	const getLocalizedCurrency = useLocalizedCurrency();
	const isInBasket = useIsInBasket(true);

	const matchCartShippingPayment = useMatch(
		`/${t({ id: 'routes.cart.shippingPayment.route' })}`
	);
	const matchCartShippingData = useMatch(
		`/${t({ id: 'routes.cart.shipping.route' })}`
	);

	const isInverted = true;

	const showBottomNavigation =
		!(matchCartShippingPayment || matchCartShippingData) &&
		basketContext?.minimalPriceForFreeDelivery !== null;

	const openMobileMenuBtnRef = useRef(null);
	const footerRef = useRef<HTMLDivElement>(null);

	const priceRaw =
		basketContext?.basketState?.discountCoupons?.discountedBasketPriceWithVat ??
		basketContext.basketPriceWithVat;

	const cartTitle =
		priceRaw && basketContext?.basketState?.currency
			? `${createCurrencyValue(priceRaw)} ${
					getLocalizedCurrency(basketContext?.basketState?.currency)?.title
			  }`
			: `${t({ id: 'routes.cart.name' })}`;

	useOnClickOutside([openMobileMenuBtnRef, menuRef], handleOnCloseMenu);

	const matchBundleDetail = useMatch(`/${t({ id: 'routes.product.route' })}/*`);

	useEffect(() => {
		deviceContextDispatch([
			DeviceServiceAction.setFooterHeight,
			get(footerRef, 'current.clientHeight', 0),
		]);

		if (matchBundleDetail !== null) {
			return;
		}
		deviceContextDispatch([
			DeviceServiceAction.setSellerSelectionFooterHeight,
			get(footerRef, 'current.clientHeight', 0),
		]);
	}, [
		layoutWidth,
		layoutHeight,
		basketContext?.minimalPriceForFreeDelivery,
		showBottomNavigation,
		matchBundleDetail,
		deviceContextDispatch,
	]);

	if (!showBottomNavigation) {
		return null;
	}

	return (
		<div
			className={cx('bottom-navigation tablet-mobile-only', {
				inverted: isInverted,
			})}
			ref={footerRef}
		>
			{!isInBasket && (
				<div className="bottom-navigation-free-delivery-bar">
					<FreeDeliveryProgressBar variant="small-horizontal" />
				</div>
			)}
			<div className="bottom-navigation-links">
				<Link
					to={'/'}
					className="bottom-navigation-links__link"
				>
					<Suspense fallback={<Loader blank />}>
						<VinistoLogoBoldEmblemIcon className="force-3d" />
					</Suspense>
					<span className="bottom-navigation-links__link__title">
						{t({ id: 'routes.introduction.name' })}
					</span>
				</Link>
				<Link
					to={`/${t({ id: 'routes.user-section.route' })}/${t({
						id: 'routes.user-section.favorites.route',
					})}`}
					className="bottom-navigation-links__link"
				>
					<FavoriteCount
						size="sm"
						className="force-3d"
					/>
					<span className="bottom-navigation-links__link__title">
						{t({ id: 'routes.user-section.favorites.name' })}
					</span>
				</Link>
				<Link
					to={`/${t({ id: 'routes.cart.route' })}`}
					className="bottom-navigation-links__link"
					onClick={handleOnCloseMenu}
				>
					<Basket
						showCount={basketContext?.itemsQuantity !== 0}
						size="sm"
						className="pb-0 force-3d"
						isNavBottom
					/>
					<span className="bottom-navigation-links__link__title">
						{cartTitle}
					</span>
				</Link>
				<Link
					to={`${t({ id: 'routes.user-section.route' })}`}
					className="bottom-navigation-links__link"
				>
					{isLoggedIn && (vinistoUser?.nickname || vinistoUser?.email) ? (
						<UserAvatar
							size="xs"
							fw="bold"
							className="force-3d"
						/>
					) : (
						<Suspense fallback={<Loader blank />}>
							<UserIcon className="bottom-navigation-links__link__icon" />
						</Suspense>
					)}
					<span className="bottom-navigation-links__link__title">
						{isLoggedIn
							? t({ id: 'navbar.labels.myVinisto' })
							: t({ id: 'navbar.actionButton.logIn' })}
					</span>
				</Link>
				<button
					ref={openMobileMenuBtnRef}
					onClick={handleOnToggleMenu}
					className="bottom-navigation-links__link"
				>
					<Suspense fallback={<Loader blank />}>
						<BurgerMenuIcon
							className="bottom-navigation-links__link__icon force-3d"
							id="bottom-burger"
						/>
					</Suspense>
					<span className="bottom-navigation-links__link__title">
						{t({ id: 'routes.category.name' })}
					</span>
				</button>
			</div>
		</div>
	);
};

export default BottomNavigation;
