import { ButtonHTMLAttributes, forwardRef } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

export const buttonVariants = {
	BASIC: 'basic',
	SOLID_BACKGROUND: 'solid_background',
} as const;

export type ButtonVariant =
	(typeof buttonVariants)[keyof typeof buttonVariants];

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	variant?: ButtonVariant;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant = buttonVariants.BASIC, ...props }, ref) => {
		return (
			<button
				{...props}
				ref={ref}
				className={cx(styles.component, styles[variant], className)}
			>
				{props.children}
			</button>
		);
	}
);

Button.displayName = 'Button';

export default Button;
