type FeaturedImageProps = {
	imageUrl: string;
	additionalClass?: string;
	altText?: string;
};

const FeaturedImage = ({
	imageUrl,
	additionalClass,
	altText,
}: FeaturedImageProps) => {
	return (
		<img
			src={imageUrl}
			alt={altText}
			className={additionalClass}
		/>
	);
};

export default FeaturedImage;
