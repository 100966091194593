import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	filter,
	find,
	findIndex,
	get,
	map,
	remove,
	set,
	some,
	sortBy,
	sumBy,
	uniqBy,
} from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import getItemInBasket from 'Helpers/getItemInBasket';
import useAnalytics from 'Hooks/useAnalytics';
import useLocalizedCurrency from 'Hooks/useLocalizedCurrency';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import usePrevious from 'Hooks/usePrevious';
import { apiServiceInstance } from 'Services/ApiService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';
import { OrderContext } from 'Services/OrderService/context';
import { WarehouseContext } from 'Services/WarehouseService';
import { TrackEvent } from 'Services/FacebookPixel';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	VinistoBasketDllModelsApiBasketBasket,
	VinistoBasketDllModelsApiReturnDataBasketReturn,
	VinistoGiftsDllModelsApiReturnDataGiftsReturn,
} from 'vinisto_api_client/src/api-types/basket-api';
import useBundlePrice from 'Hooks/useBundlePrice';

import {
	INTERVAL_TO_REFETCH_BASKET,
	NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR,
} from './constants';
import {
	IBasketModel,
	IBasketServiceProviderProps,
	IBundleChange,
	SetAreGiftRefusedArgs,
} from './interfaces';

const defaultBasketContextModel: IBasketModel = {
	handleOnLoadBasket: () => null,
	isAlreadyInBasket: () => false,
	handleOnAddToBasket: () => Promise.resolve(false),
	handleOnMergeBaskets: () => null,
	handleOnChangeItemQuantity: () => Promise.resolve(true),
	handleOnClearBasket: () => null,
	handleOnAddCoupon: () => Promise.resolve(),
	handleOnRemoveCoupon: () => null,
	bulkUpdate: () => Promise.resolve(true),
	basketState: {},
	possibleGifts: [],
	assignedGifts: [],
	assignedGiftsWeight: 0,
	minimalPriceForFreeDelivery: null,
	basketTemp: { current: [] },
	basketItemsWithTemp: {},
	basketItemsGoogleAnalyticsData: [],
	basketPrice: 0,
	basketPriceWithVat: 0,
	basketStandardPrice: 0,
	basketStandardPriceWithVat: 0,
	itemsQuantity: 0,
	isUpdatingCount: false,
	isLoading: false,
};

export const BasketContext = createContext(defaultBasketContextModel);

const BasketServiceProvider = ({ children }: IBasketServiceProviderProps) => {
	const { useFormatMessage, activeLanguage } = useContext(LocalizationContext);
	const t = useFormatMessage();
	const { isLoggining, isLoggedIn, vinistoUser, anonymousUID } = useContext(
		AuthenticationContext
	);
	const { handleShowErrorNotification, handleShowSuccessNotification } =
		useContext(NotificationsContext);
	const orderContext = useContext(OrderContext);
	const warehouseContext = useContext(WarehouseContext);
	const [basketState, setBasketState] =
		useState<VinistoBasketDllModelsApiBasketBasket>(
			{} as VinistoBasketDllModelsApiBasketBasket // remove type assertion
		);

	const [minimalPriceForFreeDelivery, setMinimalPriceForFreeDelivery] =
		useState<number | null>(null);

	const [isLoading, setIsLoading] = useState(true);

	const basketTemp = useRef([]);
	const [basketItemsWithTemp, setBasketItemsWithTemp] = useState<
		Record<any, any>
	>({});

	const clearDeliveryPayment = useCallback(() => {
		orderContext.clearDeliveryPayment();
	}, [orderContext]);
	const [isUpdatingCount, setIsUpdatingCount] = useState(false);

	const history = useNavigate();
	const getLocalizedCurrency = useLocalizedCurrency();
	const getLocalizedValue = useLocalizedValue();
	const { sendEvent: sendAnalyticsEvent } = useAnalytics();
	const queryClient = useQueryClient();

	const isPrevLoggining = usePrevious(isLoggining);
	const anonymousUserId = anonymousUID.anonymousUserId;
	const prevAnonymousUID = usePrevious(anonymousUserId);
	const location = useLocation();

	const userLoginHash = vinistoUser.loginHash;

	useEffect(() => {
		if (isPrevLoggining && isLoggedIn) {
			handleOnMergeBaskets(prevAnonymousUID ?? '');
		}
	}, [isPrevLoggining, prevAnonymousUID, isLoggedIn]);

	useEffect(() => {
		if (basketState?.items?.length > 0 && !isLoggedIn) {
			//handleOnLoadBasket(true);
		}
	}, [basketState, isLoggedIn]);

	useEffect(() => {
		if (get(location, 'state.updateBasket', true)) {
			handleOnLoadBasket(true);
		}
		// If location is not changed, update basket after certain interval
		const interval = setInterval(() => {
			handleOnLoadBasket();
		}, INTERVAL_TO_REFETCH_BASKET);
		return () => clearInterval(interval);
	}, [location.pathname]);

	useEffect(() => {
		setBasketItemsWithTemp({
			items: uniqBy(
				sortBy(
					[...get(basketState, 'items', []), ...basketTemp.current],
					'bundleId'
				),
				'bundleId'
			),
		});
	}, [basketState]);

	const gifts = useQuery(
		['possibleGifts', userLoginHash, anonymousUserId],
		async () => {
			const response =
				await apiServiceInstance.get<VinistoGiftsDllModelsApiReturnDataGiftsReturn>(
					`basket-api/basket/gifts`,
					false,
					undefined,
					[
						{ key: 'Currency', value: 'CZK' },
						{ key: 'UserLoginHash', value: userLoginHash ?? '' },
						{ key: 'AnonymousUserId', value: anonymousUserId ?? '' },
					]
				);

			if (response.isError === true) return null;

			return response;
		}
	);

	const possibleGifts = (gifts.data?.possibleGifts as any[]) ?? [];
	const assignedGifts = (gifts.data?.assignedGifts as any[]) ?? [];
	const assignedGiftsWeight = gifts.data?.assignedGiftsWeight ?? 0;

	const getBundlePrice = useBundlePrice();

	const getBundleGoogleAnalyticsData = useCallback(
		(bundleInBasket: Record<string, any>) => {
			const localizedPrice = getLocalizedCurrency(
				bundleInBasket?.bundle?.prices ?? []
			);

			const { lowestPrice } = getBundlePrice(bundleInBasket?.bundle ?? {});
			const flagSpecification = getFlagSpecification(
				bundleInBasket?.bundle?.specificationDetails ?? []
			);

			return {
				item_id: bundleInBasket?.bundleId ?? '',
				item_name: getLocalizedValue(bundleInBasket?.bundle?.name ?? []) ?? '',
				item_brand: flagSpecification?.variety ?? '',
				price: lowestPrice,
				currency: localizedPrice?.currency ?? '',
				quantity: bundleInBasket?.quantity ?? 0,
			};
		},
		[getBundlePrice, getLocalizedCurrency, getLocalizedValue]
	);

	const sendAddToCartAnalytics = useCallback(
		(basket: Record<string, any>, bundleId: string) => {
			const bundleInBasket = getItemInBasket(basket, bundleId);
			const bundleData = getBundleGoogleAnalyticsData(bundleInBasket);

			TrackEvent('track', 'AddToCart', {
				content_type: 'product',
				contents: [
					{
						id: bundleData.item_id,
						quantity: bundleData.quantity,
					},
				],
				value: Math.round((bundleData.price + Number.EPSILON) * 100) / 100 || 0,
				content_name: bundleData.item_name,
				currency: bundleData.currency,
			});

			sendAnalyticsEvent(GA_EVENT.ADD_TO_CART, {
				items: [bundleData],
				currency: bundleData.currency,
				value: Math.round((bundleData.price + Number.EPSILON) * 100) / 100 || 0,
			});
		},
		[getBundleGoogleAnalyticsData, sendAnalyticsEvent]
	);

	const sendRemoveFromCartAnalytics = useCallback(
		(basket: Record<string, any>, bundleId: string) => {
			const bundleInBasket = getItemInBasket(basket, bundleId);
			const bundleData = getBundleGoogleAnalyticsData(bundleInBasket);
			sendAnalyticsEvent(GA_EVENT.REMOVE_FROM_CART, {
				items: [bundleData],
				currency: bundleData.currency,
				value: Math.round((bundleData.price + Number.EPSILON) * 100) / 100 || 0,
			});
		},
		[getBundleGoogleAnalyticsData, sendAnalyticsEvent]
	);

	const basketPrice = basketState?.basketPrice ?? 0;
	const basketPriceWithVat = basketState?.basketPriceWithVat ?? 0;
	const basketStandardPrice = basketState?.basketStandardPrice ?? 0;
	const basketStandardPriceWithVat =
		basketState?.basketStandardPriceWithVat ?? 0;

	const itemsQuantity = useMemo(() => {
		return sumBy(basketState.items ?? [], (basketItem: any) => {
			return basketItem.quantity;
		});
	}, [basketState, basketItemsWithTemp, activeLanguage]);

	const handleGoToCrossSell = useCallback(
		(itemUrl: string) => {
			history(`/${t({ id: 'routes.crossSell.route' })}/${itemUrl}`, {
				state: { updateBasket: false },
			});
			window.history.replaceState({}, '');
		},
		[history]
	);

	const handleOnLoadBasket = useCallback(
		// eslint-disable-next-line
		(clearTemp = false) => {
			setIsLoading(true);
			if (!userLoginHash && !anonymousUserId) return;
			const requestData = isLoggedIn
				? { key: 'UserLoginHash', value: userLoginHash ?? '' }
				: {
						key: 'AnonymousUserId',
						value: anonymousUserId ?? '',
				  };
			apiServiceInstance
				.get(`basket-api/basket`, true, undefined, [requestData])
				.then((payload: Record<any, any>) => {
					/* TODO: Petr Jaros... co timhle bylo myslno, co jsi tim chtel vyresit?
					if (clearTemp && size(basketTemp.current) > 0) {
						set(basketTemp, 'current', []);
						setBasketItemsWithTemp({
							items: basketState?.items ?? [],
						});
					}*/
					setBasketState(payload.basket ?? {});
					setMinimalPriceForFreeDelivery(
						payload.minimalPriceForFreeDelivery ?? null
					);
					queryClient.invalidateQueries(['possibleGifts']);
				})
				.catch((e) => {
					if (
						e.response &&
						e.response.error &&
						e.response.error[0].generalError === 'ObjectNotFound'
					) {
						void 0;
					} else {
						throw e;
					}
				})
				.finally(() => setIsLoading(false));
		},
		[anonymousUserId, isLoggedIn, userLoginHash]
	);

	const handleOnAddToBasket = useCallback(
		(
			count: number,
			bundleId: string,
			itemUrl?: string,
			availableCount?: number,
			redirectToCrossSell = true
		) => {
			let requestData: Record<any, any> = isLoggedIn
				? { userLoginHash }
				: {
						anonymousUserId: anonymousUserId ?? '',
				  };
			requestData = {
				...requestData,
				quantity: count,
				bundleId,
			};

			const itemInBasket = getItemInBasket(basketState, bundleId);
			if (itemInBasket) {
				set(requestData, 'quantity', get(itemInBasket, 'quantity', 1) + count);
			}

			return apiServiceInstance
				.put(`basket-api/basket`, requestData, true)
				.then((payload: Record<any, any>) => {
					const basket = payload.basket ?? {};
					const tempIndex = findIndex(
						basketTemp.current,
						(item) => get(item, 'bundleId') === bundleId
					);
					if (tempIndex > -1) {
						set(
							basketTemp,
							'current',
							filter(
								basketTemp.current,
								(_, index: number) => index !== tempIndex
							)
						);
					}
					clearDeliveryPayment();
					setBasketState(basket);
					setMinimalPriceForFreeDelivery(
						payload.minimalPriceForFreeDelivery ?? null
					);
					//queryClient.invalidateQueries(['possibleGifts']);
					sendAddToCartAnalytics(basket, bundleId);
					if (redirectToCrossSell && itemUrl) {
						handleGoToCrossSell(itemUrl);
					}
					return true;
				})
				.catch((error: Error) => {
					handleShowErrorNotification(
						get(error, 'message') === NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR
							? 'notification.message.basket.error.notAvailable'
							: 'notification.message.basketAdd.error'
					);
					return false;
				});
		},
		[
			isLoggedIn,
			userLoginHash,
			anonymousUserId,
			basketState,
			clearDeliveryPayment,
			sendAddToCartAnalytics,
			handleGoToCrossSell,
			handleShowErrorNotification,
		]
	);

	const handleOnMergeBaskets = useCallback(
		(anonymousHash: string) => {
			const requestData = {
				userLoginHash: userLoginHash,
				anonymousUserId: anonymousHash,
			};
			apiServiceInstance
				.put('basket-api/basket/MergeBaskets', requestData, true, undefined)
				.then(() => {
					handleOnClearBasket();
					handleOnLoadBasket();
				});

			clearDeliveryPayment();
		},
		[userLoginHash]
	);

	const handleOnChangeItemQuantity = useCallback(
		(count: number, bundleId: string, isMoveToTemp = true) => {
			setIsUpdatingCount(true);

			let requestData: Record<any, any> = isLoggedIn
				? { userLoginHash }
				: {
						anonymousUserId: anonymousUserId ?? '',
				  };
			requestData = {
				...requestData,
				quantity: count,
				bundleId,
			};

			return apiServiceInstance
				.put(`basket-api/basket`, requestData, true)
				.then((payload: Record<any, any>) => {
					if (count === 0) {
						const bundleIndex = findIndex(
							basketState?.items ?? [],
							(bundle) => (bundle.bundleId ?? '-1') === bundleId
						);
						const bundle = basketState?.items[bundleIndex] ?? {};
						const deletedBundleId = get(bundle, 'bundleId');
						const basketTempItems = get(basketTemp, 'current', []);

						some(basketTempItems, { bundleId: deletedBundleId }) &&
							remove(
								basketTempItems,
								(basketTempItem) => get(basketTempItem, 'bundleId') === bundleId
							);
						if (isMoveToTemp) {
							const availableQuantity = warehouseContext.getQuantity(
								bundle.bundleId ?? ''
							);
							set(basketTemp, 'current', [
								...basketTemp.current,
								{
									...bundle,
									quantity:
										availableQuantity === undefined ||
										(bundle.quantity ?? 0) < availableQuantity
											? bundle.quantity
											: availableQuantity,
									temp: true,
								},
							]);
						}
					}

					const basket = payload.basket ?? {};
					if (basket)
						if (count) {
							sendAddToCartAnalytics(basket, bundleId);
						} else {
							sendRemoveFromCartAnalytics(basketState, bundleId);
						}
					clearDeliveryPayment();
					setBasketState(basket);
					setMinimalPriceForFreeDelivery(
						payload.minimalPriceForFreeDelivery ?? null
					);
					queryClient.invalidateQueries(['possibleGifts']);
					return true;
				})
				.catch((error: Error) => {
					handleShowErrorNotification(
						get(error, 'message') === NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR
							? 'notification.message.basket.error.notAvailable'
							: 'notification.message.basketChange.error'
					);
					return false;
				});
		},
		[
			anonymousUserId,
			basketState,
			clearDeliveryPayment,
			handleShowErrorNotification,
			isLoggedIn,
			queryClient,
			sendAddToCartAnalytics,
			sendRemoveFromCartAnalytics,
			userLoginHash,
			warehouseContext,
		]
	);

	const handleOnClearBasket = useCallback(() => {
		const requestData = isLoggedIn
			? { key: 'UserLoginHash', value: userLoginHash ?? '' }
			: {
					key: 'AnonymousUserId',
					value: anonymousUserId ?? '',
			  };

		apiServiceInstance
			.delete('basket-api/basket', undefined, false, [requestData])
			.catch(() => {
				handleShowErrorNotification('notification.message.basketClear.error');
			});

		clearDeliveryPayment();
	}, [basketState]);

	const isAlreadyInBasket = useCallback(
		(bundleId: string) => {
			const itemsInBasket = get(basketState, 'items', []);

			return !!find(
				itemsInBasket,
				(itemsInBasket: Record<any, any>) => itemsInBasket.bundleId === bundleId
			);
		},
		[basketState]
	);

	const handleOnAddCoupon = useCallback(
		(discountCouponCode: string, displayToasts = true) => {
			let requestData: Record<any, any> = isLoggedIn
				? { userLoginHash }
				: {
						anonymousUserId: anonymousUserId ?? '',
				  };
			requestData = {
				...requestData,
				discountCouponCode: discountCouponCode,
				currency: 'CZK',
			};

			clearDeliveryPayment();

			return apiServiceInstance
				.put(`basket-api/basket/SetDiscountCoupon`, requestData, true)
				.then((payload: Record<any, any>) => {
					setBasketState(payload.basket ?? {});
					setMinimalPriceForFreeDelivery(
						payload.minimalPriceForFreeDelivery ?? null
					);
					queryClient.invalidateQueries(['possibleGifts']);
					if (displayToasts) {
						handleShowSuccessNotification(
							'notification.message.discountCouponAdd.success'
						);
					}
				})
				.catch((err: Error) => {
					if (displayToasts) {
						handleShowErrorNotification(
							'notification.message.discountCouponAdd.error'
						);
					} else {
						throw err;
					}
				});
		},
		[
			isLoggedIn,
			userLoginHash,
			anonymousUserId,
			clearDeliveryPayment,
			handleShowSuccessNotification,
			handleShowErrorNotification,
		]
	);

	const handleOnRemoveCoupon = useCallback(
		(id: string) => {
			const requestData: Record<any, any> = isLoggedIn
				? { userLoginHash }
				: {
						anonymousUserId: anonymousUserId ?? '',
				  };

			apiServiceInstance
				.put(
					`basket-api/basket/RemoveDiscountCoupon?discountCouponId=${encodeURIComponent(
						id
					)}`,
					requestData,
					true
				)
				.then(() => {
					handleOnLoadBasket();
				})
				.catch(() => {
					handleShowErrorNotification(
						'notification.message.removediscountCoupon.error'
					);
				});

			clearDeliveryPayment();
		},
		[
			isLoggedIn,
			userLoginHash,
			anonymousUserId,
			clearDeliveryPayment,
			handleOnLoadBasket,
			handleShowErrorNotification,
		]
	);

	const bulkUpdate = useCallback(
		async (basketBundles: IBundleChange[], isMoveToTemp = true) => {
			setIsUpdatingCount(true);
			const requestData = {
				...(isLoggedIn
					? { userLoginHash: vinistoUser.loginHash }
					: {
							anonymousUserId: anonymousUserId,
					  }),
				basketBundles,
			};

			return apiServiceInstance
				.put('basket-api/basket/UpdateBasketRange', requestData, true)
				.then((payload: Record<any, any>) => {
					const basket = payload.basket ?? {};
					if (basket) {
						basketBundles.forEach((bundleUpdate) => {
							if (bundleUpdate.quantity === 0) {
								const bundleIndex = findIndex(
									get(basketState, 'items', []),
									(bundle) =>
										(bundle.bundleId ?? '-1') === bundleUpdate.bundleId
								);
								const bundle = basketState.items[bundleIndex] ?? {};
								const deletedBundleId = bundle.bundleId;
								const basketTempItems = basketTemp.current ?? [];

								some(basketTempItems, { bundleId: deletedBundleId }) &&
									remove(
										basketTempItems,
										(basketTempItem) =>
											get(basketTempItem, 'bundleId') === bundleUpdate.bundleId
									);
								if (isMoveToTemp) {
									const availableQuantity = warehouseContext.getQuantity(
										bundle.bundleId ?? ''
									);
									set(basketTemp, 'current', [
										...basketTemp.current,
										{
											...bundle,
											quantity:
												availableQuantity === undefined ||
												(bundle?.quantity ?? 0) < availableQuantity
													? bundle.quantity
													: availableQuantity,
											temp: true,
										},
									]);
								}
							}

							if (bundleUpdate.quantity) {
								sendAddToCartAnalytics(basket, bundleUpdate.bundleId);
							} else {
								sendRemoveFromCartAnalytics(basketState, bundleUpdate.bundleId);
							}
						});
					}
					clearDeliveryPayment();
					setBasketState(basket);
					setMinimalPriceForFreeDelivery(
						payload.minimalPriceForFreeDelivery ?? null
					);
					queryClient.invalidateQueries(['possibleGifts']);
					return true;
				})
				.catch((error: Error) => {
					// TODO: test errors
					handleShowErrorNotification(
						get(error, 'message') === NOT_ENOUGH_ITEMS_IN_WAREHOUSE_ERROR
							? 'notification.message.basket.error.notAvailable'
							: 'notification.message.basketChange.error'
					);
					return false;
				});
		},
		[
			isLoggedIn,
			vinistoUser.loginHash,
			anonymousUserId,
			clearDeliveryPayment,
			basketState,
			warehouseContext,
			sendAddToCartAnalytics,
			sendRemoveFromCartAnalytics,
			handleShowErrorNotification,
		]
	);

	useEffect(() => {
		if (isUpdatingCount) {
			setIsUpdatingCount(false);
		}
	}, [basketState]);

	const basketItemsGoogleAnalyticsData = () =>
		map(basketState?.items, (item: Record<string, any>) =>
			getBundleGoogleAnalyticsData(item)
		);

	const setAreGiftsRefused = useMutation({
		mutationFn: async (requestParams: SetAreGiftRefusedArgs) => {
			const {
				userLoginHash = null,
				anonymousUserId = null,
				areGiftsRefused,
			} = requestParams;

			const response =
				await apiServiceInstance.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
					`basket-api/basket/refuse-gifts`,
					{
						userLoginHash,
						anonymousUserId,
						areGiftsRefused,
					}
				);

			if (response.isError) {
				return Promise.reject(response.error);
			}

			return {
				...requestParams,
				basket: response.basket,
			};
		},
		onSuccess() {
			handleOnLoadBasket();
		},
	});

	const basketContextModel: IBasketModel = {
		handleOnAddToBasket,
		handleOnMergeBaskets,
		handleOnChangeItemQuantity,
		isAlreadyInBasket,
		handleOnLoadBasket,
		handleOnClearBasket,
		handleOnAddCoupon,
		handleOnRemoveCoupon,
		bulkUpdate,
		basketState,
		possibleGifts,
		assignedGifts,
		assignedGiftsWeight,
		minimalPriceForFreeDelivery,
		basketTemp,
		basketItemsWithTemp,
		basketItemsGoogleAnalyticsData,
		basketPrice,
		basketPriceWithVat,
		basketStandardPrice,
		basketStandardPriceWithVat,
		itemsQuantity,
		isUpdatingCount,
		setAreGiftsRefused,
		isLoading,
	};
	return (
		<BasketContext.Provider value={basketContextModel}>
			{children}
		</BasketContext.Provider>
	);
};

export default BasketServiceProvider;
