import {
	VinistoCommonDllModelsApiCouponLimitationsLimitationDefinitionCategory as CategoryLimitation,
	VinistoCommonDllModelsApiCouponLimitationsLimitationDefinitionSpecification as SpecificationLimitation,
	VinistoBasketDllModelsApiBasketBasketDiscountCoupon,
} from 'vinisto_api_client/src/api-types/basket-api';

import CategoryMessage from './CategoryMessage';
import SpecificationMessage from './SpecificationMessage';
import PriceLimitMessage from './PriceLimitMessage';
import { LIMITATION_TYPE } from './constants';
import UnspecifiedErrorMessage from './UnspecifiedErrorMessage';
import NotForDiscountedItemsMessage from './NotForDiscountedItemsMessage';

interface ICouponNotAppliedWarningProps {
	coupon: VinistoBasketDllModelsApiBasketBasketDiscountCoupon;
}

const CouponNotAppliedWarning = ({ coupon }: ICouponNotAppliedWarningProps) => {
	const categoryId =
		coupon.limitationDefinition?.limitationType ===
		LIMITATION_TYPE.CATEGORY_LIMITATION
			? (coupon.limitationDefinition as CategoryLimitation).categoryId
			: null;

	const specification =
		coupon.limitationDefinition?.limitationType ===
		LIMITATION_TYPE.SPECIFICATION_LIMITATION
			? (coupon.limitationDefinition as SpecificationLimitation).specification
			: null;

	if (coupon.isCouponApplied) return null;

	if (coupon.notAppliedReason === 'DISCOUNT_COUPON_BELOW_ORDER_PRICE_LIMIT')
		return <PriceLimitMessage coupon={coupon} />;

	if (
		coupon.notAppliedReason ===
		'ITEMS_DOES_NOT_MATCH_DISCOUNT_COUPON_LIMITATION_REQUIREMENTS'
	)
		return (
			<>
				{coupon.limitationDefinition?.limitationType ===
					'CATEGORY_LIMITATION' && (
					<CategoryMessage categoryId={categoryId ?? ''} />
				)}
				{coupon.limitationDefinition?.limitationType ===
					'SPECIFICATION_LIMITATION' && (
					<SpecificationMessage specification={specification} />
				)}
			</>
		);

	if (
		coupon.notForDiscountedItemsMessage ===
		'DISCOUNT_COUPON_IS_NOT_FOR_DISCOUNTED_ITEMS'
	) {
		return <NotForDiscountedItemsMessage />;
	}

	return <UnspecifiedErrorMessage />;
};

export default CouponNotAppliedWarning;
