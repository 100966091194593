import {
	ChangeEvent,
	KeyboardEvent,
	lazy,
	MouseEvent,
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import cx from 'classnames';
import {
	debounce,
	get,
	invoke,
	isNaN,
	parseInt,
	replace,
	uniqueId,
} from 'lodash-es';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import {
	ARROW_DOWN_KEY,
	ARROW_UP_KEY,
	MIN_QUANTITY,
} from 'Components/ProductBox/Components/QuantityBox/constants';
import { CHANGE_DELAY, QUANTITY_TO_REMOVE } from 'Pages/Cart/constants';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import useBundlePrice from 'Hooks/useBundlePrice';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import { BasketContext } from 'Services/BasketService';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import Rating from 'Components/Rating';
import Loader from 'Components/View/Loader';
import getBundleLimitPerOrder from 'Helpers/getBundleLimitPerOrder';

import { IActiveCartItemProps } from './interfaces';

const AddToCartButtonMinus = lazy(
	() => import('Components/Icons/AddToCartButtonMinus')
);
const AddToCartButtonPlus = lazy(
	() => import('Components/Icons/AddToCartButtonPlus')
);
const BottleTimesIcon = lazy(() => import('Components/Icons/BottleTimes'));
const CloseIcon = lazy(() => import('Components/Icons/Close'));

const ActiveCartItem = ({
	cartItem,
	isLoading = false,
}: IActiveCartItemProps) => {
	const { useFormatMessage } = useContext(LocalizationContext);
	const t = useFormatMessage();

	const basketContext = useContext(BasketContext);
	const isUpdatingCount = get(basketContext, 'isUpdatingCount', false);
	const changeCartItemQuantity = get(
		basketContext,
		'handleOnChangeItemQuantity',
		() => null
	);

	const bundleId = cartItem.bundleId ?? '';
	const bundleData = cartItem.bundle;
	const supplierName = bundleData?.supplier?.nameWeb ?? '';

	const availableCount = bundleData?.availableCount ?? 0;
	const minAllowedQuantity = Math.min(availableCount, MIN_QUANTITY);
	const bundleQuantity = cartItem.quantity ?? minAllowedQuantity;
	const bundleEvaluation = bundleData?.bundleEvaluation ?? {};
	const bundleAvgRating = (bundleEvaluation.averageStars ?? 0) / 2;

	const bundleTotalRatingCount = get(
		bundleEvaluation,
		'totalEvaluationCount',
		0
	);

	const getLocalizedValue = useLocalizedValue();
	const getBundlePrice = useBundlePrice();

	const { price, priceWithVat, discountedFromPriceVat, currencyTitle } =
		getBundlePrice(bundleData);
	const priceWithVAT = createCurrencyValue(priceWithVat);
	const priceWithoutVAT = createCurrencyValue(price);
	const totalPriceWithVAT = createCurrencyValue(priceWithVat, bundleQuantity);
	const totalPriceWithoutVAT = createCurrencyValue(price, bundleQuantity);
	const discountedFromPriceWithVat = discountedFromPriceVat;
	const discountedFromPriceWithVatFormatted = discountedFromPriceWithVat
		? createCurrencyValue(discountedFromPriceWithVat)
		: null;
	const totalDiscountedFromPriceWithVatFormatted = discountedFromPriceWithVat
		? createCurrencyValue(discountedFromPriceWithVat, bundleQuantity)
		: null;

	const { shortVariety: producerName, component: flag } = getFlagSpecification(
		bundleData?.specificationDetails ?? []
	);

	const bundleLimitPerOrder = getBundleLimitPerOrder(
		bundleData?.orderLimitation
	);

	const [quantity, setQuantity] = useState<number | null>(bundleQuantity);
	const quantityInputValue = quantity === null ? '' : String(quantity); // to avoid leading zeros

	const isSet = bundleData?.isSet;

	const isSetDiscount = Boolean(
		(bundleData?.prices?.[0]?.valueWithVat ?? 0) <
			(bundleData?.prices?.[1]?.valueWithVat ?? 0)
	);

	const setPriceWithVAT = createCurrencyValue(
		bundleData?.prices?.[1]?.valueWithVat ?? 0
	);
	const setTotalDiscountedFromPriceWithVatFormatted = bundleData?.prices?.[1]
		?.valueWithVat
		? createCurrencyValue(bundleData?.prices?.[1]?.valueWithVat, bundleQuantity)
		: null;

	useEffect(() => {
		setQuantity(bundleQuantity);
	}, [setQuantity, bundleQuantity]);

	const changeQuantity = useCallback(
		debounce((value: any) => {
			const newQuantity = parseInt(value);
			if (!isNaN(newQuantity) && newQuantity >= QUANTITY_TO_REMOVE) {
				changeCartItemQuantity(
					Math.max(newQuantity, QUANTITY_TO_REMOVE),
					bundleId
				);
			}
		}, CHANGE_DELAY),
		[bundleId, changeCartItemQuantity]
	);

	const handleOnRemove = useCallback(
		(event?: MouseEvent<HTMLElement>) => {
			event?.preventDefault();
			event?.stopPropagation();
			changeQuantity.cancel();
			changeCartItemQuantity(QUANTITY_TO_REMOVE, bundleId);
		},
		[changeQuantity, bundleId, changeCartItemQuantity]
	);

	const handleOnChangeQuantity = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (isUpdatingCount) return;
			const value = replace(get(event, 'target.value'), /[^\d]/, '');
			if (value === '') {
				setQuantity(null);
			} else {
				const numericValue = parseInt(value);
				const nextQuantity = isNaN(numericValue)
					? MIN_QUANTITY
					: Math.min(
							availableCount,
							bundleLimitPerOrder ?? Infinity,
							Math.max(QUANTITY_TO_REMOVE, numericValue)
					  );

				setQuantity((oldQuantity) => {
					if (nextQuantity !== oldQuantity) {
						changeQuantity(nextQuantity);
					}
					return nextQuantity;
				});
			}
		},
		[setQuantity, changeQuantity, isUpdatingCount]
	);

	const increaseQuantity = useCallback(() => {
		if (isUpdatingCount) return;
		setQuantity((oldQuantity) => {
			const nextQuantity =
				oldQuantity === null
					? minAllowedQuantity
					: Math.min(
							availableCount,
							bundleLimitPerOrder ?? Infinity,
							oldQuantity + 1
					  );
			if (nextQuantity !== oldQuantity) {
				changeQuantity(nextQuantity);
			}
			return nextQuantity;
		});
	}, [
		isUpdatingCount,
		setQuantity,
		minAllowedQuantity,
		availableCount,
		changeQuantity,
	]);

	const decreaseQuantity = useCallback(() => {
		if (isUpdatingCount) return;
		setQuantity((oldQuantity) => {
			const nextQuantity =
				oldQuantity === null
					? minAllowedQuantity
					: Math.max(oldQuantity - 1, QUANTITY_TO_REMOVE);
			if (nextQuantity === QUANTITY_TO_REMOVE) {
				handleOnRemove();
			} else if (nextQuantity !== oldQuantity) {
				changeQuantity(nextQuantity);
			}
			return nextQuantity;
		});
	}, [
		isUpdatingCount,
		setQuantity,
		minAllowedQuantity,
		handleOnRemove,
		changeQuantity,
	]);

	const handleOnIncreaseQuantity = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			event.preventDefault();
			event.stopPropagation();
			increaseQuantity();
		},
		[increaseQuantity]
	);

	const handleOnDecreaseQuantity = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			event.preventDefault();
			event.stopPropagation();
			decreaseQuantity();
		},
		[decreaseQuantity]
	);

	const handleOnBlurQuantity = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = parseInt(
				get(event, 'target.value', String(minAllowedQuantity))
			);
			if (isNaN(value)) {
				setQuantity(bundleQuantity);
			} else if (value <= QUANTITY_TO_REMOVE) {
				setQuantity(QUANTITY_TO_REMOVE);
				changeQuantity(QUANTITY_TO_REMOVE);
			}
		},
		[setQuantity, bundleQuantity, changeQuantity, minAllowedQuantity]
	);

	const handleOnKeyDownQuantity = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key === ARROW_UP_KEY) {
				increaseQuantity();
				event.preventDefault(); // prevent cursor going to front
			} else if (event.key === ARROW_DOWN_KEY) {
				decreaseQuantity();
			}
		},
		[increaseQuantity, decreaseQuantity]
	);

	const handleOnClickQuantity = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			if (event) {
				event.preventDefault();
				event.stopPropagation();
				invoke(event, 'target.focus');
			}
		},
		[]
	);

	return (
		<div
			className={cx(
				'vinisto-user-orders__orders__order-body__item',
				'vinisto-cart__item',
				{ discounted: !!discountedFromPriceWithVat || isSetDiscount }
			)}
		>
			<div className="vinisto-user-orders__orders__order-body__item__info">
				{!isLoading && (
					<Link
						className="vinisto-wine__item-overlay"
						to={`/${t({
							id: 'routes.product.route',
						})}/${getLocalizedValue(bundleData?.url ?? [])}`}
					/>
				)}
				<div className="vinisto-user-orders__orders__order-body__item__info__img">
					{isLoading ? (
						<Skeleton
							height="80px"
							width="80%"
						/>
					) : (
						<img
							src={getBundleImage(
								bundleData?.images ?? [],
								IMAGE_SIZE_THUMB_64x80
							)}
							alt={`${t({ id: 'alt.bundleImage' })}`}
						/>
					)}
				</div>
				<div className="vinisto-user-orders__orders__order-body__item__info__data">
					<div className="vinisto-user-orders__orders__order-body__item__info__data__name">
						{isLoading ? (
							<Skeleton />
						) : (
							getLocalizedValue(bundleData?.name ?? [])
						)}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__data__score">
						{isLoading ? (
							<Skeleton
								count={5}
								width="15px"
								style={{ margin: '0 .125rem' }}
								inline
							/>
						) : (
							<div className="vinisto-wine__review mb-0">
								<Rating
									defaultValue={bundleAvgRating}
									readOnly
								/>
								<span className="vinisto-wine__review__wrap-count">
									({bundleTotalRatingCount})
								</span>
							</div>
						)}
					</div>
					{isLoading ? (
						<Skeleton width="90%" />
					) : (
						<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
							<BundleProducer
								flag={flag}
								name={producerName}
							/>
						</div>
					)}
				</div>
			</div>

			<div className="vinisto-user-orders__orders__order-body__item__prices">
				{isLoading ? (
					<Skeleton
						count={1.6}
						width="80px"
					/>
				) : (
					<>
						<div className="vinisto-user-orders__orders__order-body__item__prices__total">
							<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">{`${
								(!isSet
									? discountedFromPriceWithVatFormatted
									: setPriceWithVAT) ?? priceWithVAT
							} ${currencyTitle}`}</span>
							{!!(discountedFromPriceWithVat || setPriceWithVAT) && (
								<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">{`${priceWithVAT} ${currencyTitle}`}</span>
							)}
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
							{`${t({ id: 'basket.priceWithoutVAT' })} `}
							<span className="fw-bolder price-span">{`${priceWithoutVAT} ${currencyTitle}`}</span>
						</div>
						<div className="vinisto-cart__supplier-name">
							{t(
								{ id: 'bundle.supplierSmall.name' },
								{
									name: (
										<span className="vinisto-color-success fw-bolder">
											{supplierName.length > 0
												? supplierName
												: t({
														id: 'productDetail.seller.name.others',
												  })}
										</span>
									),
								}
							)}
						</div>
					</>
				)}
			</div>

			<div className="vinisto-wine__count">
				{isLoading ? (
					<Skeleton
						width="30px"
						height="30px"
					/>
				) : (
					<button
						className="vinisto-wine__count__minus btn-plus-minus redesigned"
						onClick={handleOnDecreaseQuantity}
					>
						<Suspense fallback={<Loader blank />}>
							<AddToCartButtonMinus
								id={`ico-decrease-${bundleId ?? uniqueId()}`}
								alt={t({ id: 'alt.less' })}
								title={``}
								className={``}
							/>
						</Suspense>
					</button>
				)}
				{isLoading ? (
					<Skeleton
						width="70px"
						height="30px"
						style={{ margin: '0 .125rem' }}
					/>
				) : (
					<label className="vinisto-wine__count__amount-label redesigned">
						<Suspense fallback={<Loader blank />}>
							<BottleTimesIcon
								id={`ico-bottle-times-${bundleId ?? uniqueId()}`}
								alt={t({ id: 'alt.numberOfBottles' })}
								title={``}
								className={`BottleTimesIcon`}
							/>
						</Suspense>
						<input
							/* cannot use `type=number` as it would not provide actual value
                              (including  allowed non-numerical characters such as "."", "-" or
                              "e") in input to onChange handler - supplying its behavior with
                              three following params */
							type="text"
							inputMode="numeric"
							onKeyDown={handleOnKeyDownQuantity}
							onChange={handleOnChangeQuantity}
							onClick={handleOnClickQuantity}
							onBlur={handleOnBlurQuantity}
							value={quantityInputValue}
							autoComplete="off"
							className="vinisto-wine__count__amount redesigned"
						/>
					</label>
				)}
				{isLoading ? (
					<Skeleton
						width="30px"
						height="30px"
					/>
				) : (
					<button
						className="vinisto-wine__count__plus btn-plus-minus redesigned"
						onClick={handleOnIncreaseQuantity}
					>
						<Suspense fallback={<Loader blank />}>
							<AddToCartButtonPlus
								id={`ico-increase-${bundleId ?? uniqueId()}`}
								alt={t({ id: 'alt.more' })}
								title={``}
								className={``}
							/>
						</Suspense>
					</button>
				)}
			</div>

			<div className="vinisto-cart__item__price-remove">
				<div className="vinisto-user-orders__orders__order-body__item__prices">
					{isLoading ? (
						<Skeleton
							count={1.6}
							width="80px"
						/>
					) : (
						<>
							<div className="vinisto-user-orders__orders__order-body__item__prices__total">
								{`${t({ id: 'basket.totalPrice' })} `}
								<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">{`${
									(!isSet
										? totalDiscountedFromPriceWithVatFormatted
										: setTotalDiscountedFromPriceWithVatFormatted) ??
									totalPriceWithVAT
								} ${currencyTitle}`}</span>
								{!!(discountedFromPriceWithVat || setPriceWithVAT) && (
									<span className="vinisto-user-orders__orders__order-body__item__prices__big-price-discounted">{`${totalPriceWithVAT} ${currencyTitle}`}</span>
								)}
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
								{`${t({ id: 'basket.priceWithoutVAT' })} `}
								<span className="fw-bolder price-span">{`${totalPriceWithoutVAT} ${currencyTitle}`}</span>
							</div>
						</>
					)}
				</div>

				{isLoading ? (
					<Skeleton
						width="25px"
						height="25px"
					/>
				) : (
					<div
						className="vinisto-user-favorite__close"
						onClick={handleOnRemove}
					>
						<Suspense fallback={<Loader blank />}>
							<CloseIcon
								id={`ico-close-${bundleId ?? uniqueId()}`}
								alt={t({ id: 'alt.delete' })}
								title={``}
								className={`CloseIcon `}
							/>
						</Suspense>
					</div>
				)}
			</div>

			<div className="vinisto-user-favorite__delete-wrap d-none">
				<div className="vinisto-user-favorite__delete-wrap__text">
					{t({ id: 'basket.removed.label' })}
					<br />
					<span className="color-primary pointer fw-bolder">
						{t({ id: 'basket.removed.returnBack' })}
					</span>
				</div>
			</div>
		</div>
	);
};

export default ActiveCartItem;
