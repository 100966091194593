import { type MouseEvent, useContext, useEffect } from 'react';
import useFormatMessage from 'Hooks/useFormatMessage';
import { useFormContext } from 'react-hook-form';
import {
	ADDRESS_DELIVERY_MODAL,
	CONFIRM_DIALOG,
} from 'Components/Modal/constants';
import { DELIVERY_ELEMENT_TYPE } from 'Pages/UserSection/Addresses/Components/AddressAdd/constants';
import { ADDRESS_DELIVERY_MODAL_TYPE } from 'Components/Modal/Components/AddressDelivery/constants';
import { apiServiceInstance } from 'Services/ApiService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { NotificationsContext } from 'Services/NotificationService';
import { ModalContext } from 'Components/Modal/context';
import { AddressesApiHooks } from 'Services/Addresses/hooks';
import { getShippingId, setShippingId } from 'Services/Addresses/helpers';
import { VinistoAuthDllModelsApiAddressAddress } from 'vinisto_api_client/src/api-types/user-api';
import AddressAdd from 'Pages/UserSection/Addresses/Components/AddressAdd';
import { DELIVERY_ADDRESS_ID_FIELD } from 'Pages/CartShippingData/constants';

import styles from '../../styles.module.css';

import DeliveryAddressBox from './Components/DeliveryAddressBox';

const DeliveryAddresses = () => {
	const authenticationContext = useContext(AuthenticationContext);
	const notificationContext = useContext(NotificationsContext);
	const modalContext = useContext(ModalContext);

	const t = useFormatMessage();
	const {
		vinistoUser: { id, loginHash },
	} = useContext(AuthenticationContext);

	const { setValue } = useFormContext();
	const { data, isFetched, refetch } = AddressesApiHooks.useGetAll();

	const deliveryAddressId = getShippingId(DELIVERY_ADDRESS_ID_FIELD);

	const handleOnSelect = (id: string) => {
		setShippingId(DELIVERY_ADDRESS_ID_FIELD, id);
		setValue(DELIVERY_ADDRESS_ID_FIELD, id);
	};

	const handleOnCreate = (id: string | null | undefined) => {
		if (!id) return;
		setShippingId(DELIVERY_ADDRESS_ID_FIELD, id);
		setValue(DELIVERY_ADDRESS_ID_FIELD, id);
	};

	const handleOnEdit = ({
		data,
	}: {
		data: VinistoAuthDllModelsApiAddressAddress;
	}) => {
		modalContext.handleOpenModal(ADDRESS_DELIVERY_MODAL, {
			modalType: ADDRESS_DELIVERY_MODAL_TYPE.UPDATE,
			address: data,
			onCreateCallback: async () =>
				await refetch({
					stale: true,
				}),
		});
	};

	const handleOnDelete =
		(addressId: string) => (event: MouseEvent<HTMLDivElement>) => {
			event.stopPropagation();
			modalContext.handleOpenModal(CONFIRM_DIALOG, {
				title: `${t({ id: 'userSection.addresses.remove' })}`,
				text: 'userSection.addresses.remove.confirm',
				onConfirm: () => {
					apiServiceInstance
						.delete(
							`user-api/users/${authenticationContext?.vinistoUser?.id}/addresses/${addressId}`,
							undefined,
							true,
							[
								{
									key: 'UserLoginHash',
									value: authenticationContext?.vinistoUser?.loginHash,
								},
							]
						)
						.then(() => {
							setValue(DELIVERY_ADDRESS_ID_FIELD, null);
							refetch();
						})
						.catch(() => {
							notificationContext.handleShowErrorNotification(
								'userSection.addresses.remove.error'
							);
						});
				},
			});
		};

	useEffect(() => {
		if (
			isFetched &&
			!data?.addresses?.find(
				({ id }) => id === getShippingId(DELIVERY_ADDRESS_ID_FIELD)
			)
		) {
			setShippingId(DELIVERY_ADDRESS_ID_FIELD, data?.addresses?.[0]?.id);
			setValue(DELIVERY_ADDRESS_ID_FIELD, data?.addresses?.[0]?.id);
		}
	}, [isFetched, data?.addresses, setValue]);

	if (!id || !loginHash) {
		return <></>;
	}

	return (
		<div className={styles.address}>
			<div className={styles.addressHeading}>
				{t({ id: 'cartShippingData.savedDeliveryDetails' })}
			</div>
			<div className="vinisto-order-addresses-wrap">
				{data?.addresses?.map((address, index) => (
					<DeliveryAddressBox
						key={address?.id ?? index}
						data={address}
						handleOnEdit={() => handleOnEdit({ data: address })}
						handleOnSelect={() => handleOnSelect(address?.id as string)}
						handleOnDelete={(event) =>
							handleOnDelete(address?.id as string)(event)
						}
						isSelected={deliveryAddressId === address?.id}
					/>
				))}
				<AddressAdd
					elementType={DELIVERY_ELEMENT_TYPE}
					handleOnCreate={handleOnCreate}
				/>
			</div>
		</div>
	);
};

export default DeliveryAddresses;
