import { get } from 'lodash-es';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { apiServiceInstance } from 'Services/ApiService';
import { VinistoImageDllModelsApiImageImage } from 'vinisto_api_client/src/api-types/warehouse-api';

import {
	BANNER_POSITION,
	GET_BANNERS_ENDPOINT,
	POSITION_VALUES_MAP,
} from './constants';
import { Banner, BannerFromApi } from './interfaces';
import { DEFAULT_RENDER_LIMIT } from './constants';

class BannerService {
	private getLocalizedValue: ReturnType<typeof useLocalizedValue>;

	constructor(getLocalizedValue: ReturnType<typeof useLocalizedValue>) {
		this.getLocalizedValue = getLocalizedValue;
	}

	private getPosition(banner: BannerFromApi) {
		let position: BANNER_POSITION;
		if (banner.type === POSITION_VALUES_MAP[BANNER_POSITION.TOP]) {
			position = BANNER_POSITION.TOP;
		}
		if (banner.type === POSITION_VALUES_MAP[BANNER_POSITION.BOTTOM]) {
			position = BANNER_POSITION.BOTTOM;
		} else {
			position = BANNER_POSITION.PRODUCT_DETAIL;
		}
		return position;
	}

	private getSrcSet(
		domainUrls: VinistoImageDllModelsApiImageImage['domainUrls']
	) {
		if (!domainUrls) return null;
		const sources = Object.keys(domainUrls)
			.map((key) => {
				const width = key.match(/\d+/)?.[0];
				return width ? `${domainUrls[key]} ${width}w` : null;
			})
			.filter(Boolean);
		return sources.join(', ');
	}

	private mapApiToEntity(banner: BannerFromApi): Banner {
		return {
			position: this.getPosition(banner),
			title: this.getLocalizedValue(banner.title ?? []),
			subtitle: this.getLocalizedValue(banner.subtitle ?? []),
			imageOriginalUrl: banner.image?.domainUrls?.original_png ?? '',
			imageUrl: banner.image?.domainUrls?.thumb_500x500 ?? '',
			ctaLabel: this.getLocalizedValue(banner.textLink ?? []),
			order: Number(banner.position ?? 0),
			url: this.getLocalizedValue(banner.url ?? []),
			srcSet:
				(banner.image?.domainUrls &&
					this.getSrcSet(banner.image?.domainUrls)) ??
				undefined,
		};
	}

	fetch =
		(type: BANNER_POSITION, limit = DEFAULT_RENDER_LIMIT) =>
		async (): Promise<Banner[]> => {
			return await apiServiceInstance
				.getCollection(
					GET_BANNERS_ENDPOINT,
					[
						{
							key: 'carouselType',
							value: type,
						},
					],
					true
				)
				.then((response) =>
					get(response, 'sliderCarousels', [])
						.slice(0, limit)
						.map((banner: BannerFromApi) => this.mapApiToEntity(banner))
				);
		};
}

export default BannerService;
