import { IBundlePrice } from './interfaces';

export const getLowestPriceBundle = (
	bundles: Record<any, any>[]
): Record<any, any> | undefined => {
	let lowestPrice: null | number = null;
	let lowestPriceBundleId: null | string = null;
	bundles?.forEach((bundle) => {
		bundle?.prices?.forEach((price: IBundlePrice) => {
			if (
				lowestPrice === null ||
				(typeof lowestPrice === 'number' && lowestPrice > price?.valueWithVat)
			) {
				lowestPrice = price?.valueWithVat;
				lowestPriceBundleId = bundle?.id;
			}
		});
	});
	return bundles?.find((bundle) => bundle?.id === lowestPriceBundleId);
};
