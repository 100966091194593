import * as React from 'react';
import { get } from 'lodash-es';
import ApiService from 'Services/ApiService';
import { NotificationsContext } from 'Services/NotificationService';

import { IOrderPaymentData } from './interfaces';

const usePaymentDetail = (paymentId: string) => {
	const [paymentDetail, setPaymentDetail] = React.useState<IOrderPaymentData>({
		isLoaded: false,
		data: {},
	});

	const apiService = React.useMemo(() => new ApiService(), []);
	const notificationsContext = React.useContext(NotificationsContext);

	React.useEffect(() => {
		if (!paymentId) {
			setPaymentDetail({
				isLoaded: true,
				data: {},
			});
			return;
		}
		setPaymentDetail({
			isLoaded: false,
			data: {},
		});
		if (!paymentId) return;
		apiService
			.get(`order-api/payments/${paymentId}/GetPayment`, true)
			.then((response: Record<any, any>) => {
				setPaymentDetail({
					isLoaded: true,
					data: get(response, 'payment', {}),
				});
			})
			.catch(() => {
				notificationsContext.handleShowErrorNotification(
					'notification.message.orderOverview.paymentLoadError'
				);
				setPaymentDetail({
					isLoaded: true,
					data: {},
				});
			});
	}, [paymentId, notificationsContext]);

	return paymentDetail;
};

export default usePaymentDetail;
