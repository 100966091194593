import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { useContext, useEffect } from 'react';

import WinesBundlesWithFilters from './Components/WinesBundlesWithFilters';
import BundlesWithFiltersProvider from './Components/WinesBundlesWithFilters/context';
import WinesBundlesBreadcrumb from './Components/WinesBundlesBreadcrumb';

const Wines = () => {
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.products.name' })}` }
			)}`,
		});
		// filling dep array causes infinite loop, handle outside of useEffect
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section id="content-wrapper">
			<BundlesWithFiltersProvider>
				<WinesBundlesBreadcrumb />
				<WinesBundlesWithFilters />
			</BundlesWithFiltersProvider>
		</section>
	);
};

export default Wines;
