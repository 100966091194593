import { FC, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
	AddressType,
	IAddressProps,
} from 'Pages/CartConfirmation/Components/Address/interfaces';
import { LocalizationContext } from 'Services/LocalizationService';

const Address: FC<IAddressProps> = ({
	addressData,
	addressPhone,
	addressEmail,
	addressType,
}) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const isLoading = addressData === null;

	return (
		<div className="vinisto-address">
			<div className="vinisto-address__header">
				<div className="vinisto-address__title">
					{isLoading ? (
						<Skeleton width="140px" />
					) : addressType === AddressType.DELIVERY ? (
						t({
							id: 'orderConfirmation.orderSummary.address.delivery.title',
						})
					) : (
						addressType === AddressType.BILLING &&
						t({
							id: 'orderConfirmation.orderSummary.address.billing.title',
						})
					)}
				</div>
			</div>
			{isLoading ? (
				<Skeleton
					count={5.6}
					width="75%"
				/>
			) : (
				<>
					{addressType === AddressType.BILLING && addressData.ico && (
						<div>{addressData.ico}</div>
					)}
					{addressData.company && <div>{addressData.company}</div>}
					<div className="vinisto-address__name">
						{(addressData.name !== undefined || null) &&
							`${addressData.name} ${addressData.surname}`}
					</div>
					<div className="vinisto-address_email">{addressEmail}</div>
					<div className="vinisto-address__phone">{addressPhone}</div>
					<div className="vinisto-address__street">
						{addressData.street}
						<span>
							{` ${
								addressData.houseNumber !== null
									? addressData.landRegistryNumber +
									  '/' +
									  addressData.houseNumber
									: addressData.landRegistryNumber
							}`}
						</span>
					</div>
					<div className="vinisto-address__city">{addressData.city}</div>
					<div className="vinisto-address__zip">{addressData.zip}</div>
					{addressType === AddressType.BILLING && addressData.dic && (
						<div>{addressData.dic}</div>
					)}
				</>
			)}
		</div>
	);
};

export default Address;
