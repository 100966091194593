import { ReactNode } from 'react';
import { QuantityBoxTypes } from 'Components/QuantityBox/constants';
import { BundleData } from 'Services/Bundle/interfaces';

export interface ICarouselProps {
	children?: ReactNode;
	products?: BundleData[];
	carouselType: string;
	cardType?: CARD_TYPE;
	data?: Record<any, any> | null | undefined;
	btnLabel?: string;
	btnUrl?: string;
	isLoading?: boolean;
	handleOnSelectTab?: any;
	activeTabId?: string | number | null;
	redirectToCrossSell?: boolean;
	displayPriceAsRange?: boolean;
	isCrossSell?: boolean;
	variableWidth?: boolean;
	openBundleProducerInNewTab?: boolean;
	showAddToBasketBtn?: boolean;
	quantityBoxType?: QuantityBoxTypes;
}

export enum CARD_TYPE {
	CAROUSEL_CLASSIC,
	CAROUSEL_PRODUCER,
	CAROUSEL_REVIEW,
	CAROUSEL_TAB,
}
