import { useCallback, useContext, useMemo } from 'react';
import { isNumber, last } from 'lodash-es';
import { useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
	BundleSorting,
	IBundlesWithFiltersProviderProps,
} from 'Pages/Category/Components/CategoryBundlesWithFilters/interfaces';
import removeDiacritics from 'Helpers/removeDiacritics';
import useCategoryView from 'Hooks/useCategoryView';
import useURLParams from 'Hooks/useURLParams';
import { NavbarContext } from 'Components/Navbar/context';
import { BundlesWithFiltersContext } from 'Pages/Category/Components/CategoryBundlesWithFilters/context';
import { TagContext } from 'Pages/Tag/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import { useBundleQueries } from 'Hooks/Queries/useBundleQueries';
import useLocalizedValue from 'Hooks/useLocalizedValue';

import {
	calculateBundlesToLoadMore,
	createBundlePageQueries,
	generateBundlesToShow,
	generateRequestFilters,
	getBundlesCount,
	getPageFromParam,
	mergeSpecificationsWithBundleFilters,
} from './helpers';
import {
	FILTER_CODE,
	SORTING,
	SORTING_DEFAULT,
	URL_PARAM_LIMIT,
	URL_PARAM_LIMIT_DEFAULT_VALUE,
	URL_PARAM_PAGE,
} from './constants';

import SpecificationService from '@/product-service/specification';
import TagService from '@/product-service/tag';
import {
	VinistoHelperDllEnumsTagSortableColumns,
	VinistoProductDllModelsApiBundleBundlesReturn,
} from '@/api-types/product-api';

const { getTagSpecifications } = SpecificationService;
const { getAll } = TagService;

const BundlesWithFiltersProvider = (
	props: IBundlesWithFiltersProviderProps
) => {
	const tagContext = useContext(TagContext);
	const warehouseContext = useContext(WarehouseContext);
	const navBarContext = useContext(NavbarContext);
	// TODO destructure right in the top context, this is horrible
	// Can be probably abstracted as something like routerContext
	const tagId = tagContext?.tagData?.data?.tag?.id ?? null;
	const isTagDataLoading = tagContext?.tagData?.isLoading ?? false;

	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	// This can be called right in the categoryContext
	// Also, same query key, different functions!
	const specificationsQuery = useQuery(
		['category-specifications', { tagId }],
		() => getTagSpecifications(tagId),
		{
			enabled: !!tagId,
		}
	);

	const tagQueryParams = {
		IsShownInFilters: true,
		IsCache: true,
		SortingColumn: VinistoHelperDllEnumsTagSortableColumns.ORDER_IN_FILTER,
		limit: 100,
	};
	const { data: tags } = useQuery(['tags', tagQueryParams], () =>
		getAll(tagQueryParams)
	);

	const [query, setQuery] = useURLParams();

	const activeSpecificationFilters = useMemo(
		() =>
			generateRequestFilters(
				specificationsQuery?.data?.specifications ?? [],
				query,
				localizationContext.activeLanguageKey
			),
		[specificationsQuery?.data?.specifications, query, localizationContext]
	);

	const urlParamTags = `${t({ id: 'tags.urlParam' })}`;

	const activeTagFilters = useMemo(() => {
		if (!tags) return [];

		const tagFiltersAsArray: string[] = Array.isArray(query[urlParamTags])
			? query[urlParamTags]
			: [query[urlParamTags]];

		const tagFilters = tagFiltersAsArray
			.map((slug: string) => {
				return tags.find(
					(tag) => removeDiacritics(getLocalizedValue(tag.name ?? [])) === slug
				);
			})
			.filter(
				(tag): tag is Exclude<typeof tag, undefined> => tag !== undefined
			);
		return tagFilters;
	}, [tags, query, urlParamTags, getLocalizedValue]);

	const handleOnRemoveFilter = useCallback(
		(specificationName: string) => () => {
			setQuery({ [specificationName]: undefined });
		},
		[setQuery]
	);

	const sortParamUrlName = `${t({ id: 'category.sorting.urlParam' })}`;
	const sortParam = useMemo(() => {
		const urlParam = query[sortParamUrlName];
		if (urlParam === undefined) {
			return SORTING_DEFAULT;
		}
		return (
			SORTING.filter(
				(sorting) =>
					removeDiacritics(`${t({ id: sorting.title })}`) === urlParam
			)[0] ?? SORTING_DEFAULT
		);
	}, [query, sortParamUrlName, t]);

	const setSortParam = useCallback(
		(sort: BundleSorting) => {
			const sortUrlParam =
				sort === SORTING_DEFAULT
					? undefined
					: removeDiacritics(`${t({ id: sort.title })}`);
			setQuery({ [sortParamUrlName]: sortUrlParam });
		},
		[setQuery, sortParamUrlName, t]
	);

	// Category bundles view mode
	const [view, handleOnViewChange] = useCategoryView();

	const pageParam = useMemo(() => query[URL_PARAM_PAGE] ?? [1], [query]);
	const setPageParam = useCallback(
		(pageArray: [number] | [number, number]) => {
			setQuery({ [URL_PARAM_PAGE]: pageArray });
		},
		[setQuery]
	);

	const page = useMemo(() => getPageFromParam(pageParam), [pageParam]);
	const currentPage = useMemo(() => last(page) as number, [page]);

	const limitParam = useMemo(
		() => query[URL_PARAM_LIMIT] ?? URL_PARAM_LIMIT_DEFAULT_VALUE,
		[query]
	);
	const limit = useMemo(
		() => (!isNumber(limitParam) ? URL_PARAM_LIMIT_DEFAULT_VALUE : limitParam),
		[limitParam]
	);
	const isInStockParam = `${t({ id: 'isInStock.urlParam' })}`;
	const isInStockParamRef = query[isInStockParam];

	const isDiscountedParam = `${t({ id: 'isDiscounted.urlParam' })}`;
	const isDiscountedParamRef = query[isDiscountedParam];

	const bundlePageQueries = useMemo(
		() =>
			createBundlePageQueries({
				tagId,
				sortingColumn: sortParam?.sortingColumn ?? '',
				isSortingDescending: sortParam?.isSortingDescending ?? false,
				page,
				currency: localizationContext?.activeCurrency.currency,
				filters: [
					...activeSpecificationFilters.map((filter) => {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { specificationName, unit, imperialUnit, ...rest } = filter;
						return rest;
					}),
					...(activeTagFilters.length
						? [
								{
									filterType: FILTER_CODE.TAG,
									tags: activeTagFilters.map((filter) => filter.id),
								},
						  ]
						: []),
					...(isInStockParamRef
						? [
								{
									filterType: FILTER_CODE.STOCK,
									isInStock: true,
								},
						  ]
						: []),
					...(isDiscountedParamRef
						? [
								{
									filterType: FILTER_CODE.DISCOUNT,
									isDiscounted: true,
								},
						  ]
						: []),
				],
				limit,
				enabled: tagId && !!specificationsQuery?.isFetched,
				onSuccess: (data) => {
					if (data?.bundles) {
						const allIds = data.bundles.map((bundle) => bundle.id);
						warehouseContext.fetchQuantity(allIds);
					}
				},

				onError: () => {
					setPageParam([1]);
				},
			}),
		[
			tagId,
			sortParam?.sortingColumn,
			sortParam?.isSortingDescending,
			page,
			localizationContext?.activeCurrency.currency,
			activeSpecificationFilters,
			activeTagFilters,
			isInStockParamRef,
			isDiscountedParamRef,
			limit,
			specificationsQuery?.isFetched,
			warehouseContext,
			setPageParam,
		]
	);

	const { filtersQuery } = useBundleQueries({
		categoryId: null,
		tagId,
		sortParam,
		page,
		limit,
		activeSpecificationFilters,
		activeTagFilters,
		isInStockParamRef,
		isDiscountedParamRef,
		localizationContext,
	});

	type BundlesBatchedByPages =
		UseQueryOptions<VinistoProductDllModelsApiBundleBundlesReturn>[];

	const paginatedBundlesData = useQueries<BundlesBatchedByPages>({
		queries: bundlePageQueries,
	});

	const bundlesCount = useMemo(
		() => getBundlesCount(paginatedBundlesData),
		[paginatedBundlesData]
	);
	const bundlesToLoadMore = useMemo(
		() => calculateBundlesToLoadMore(bundlesCount, currentPage, limit),
		[bundlesCount, currentPage, limit]
	);
	const isBundlesLoading = useMemo(
		() =>
			specificationsQuery?.isLoading ||
			paginatedBundlesData.some((item) => item.isLoading),

		[specificationsQuery?.isLoading, paginatedBundlesData]
	);
	const bundles = useMemo(
		() =>
			// TODO Refactor, this has to be a shareable function with object parameter
			generateBundlesToShow(
				tagId,
				paginatedBundlesData,
				bundlesCount,
				currentPage,
				limit,
				isBundlesLoading
			),
		[
			tagId,
			paginatedBundlesData,
			bundlesCount,
			currentPage,
			limit,
			isBundlesLoading,
		]
	);

	const isInStockActive = Boolean(isInStockParamRef);
	const isDiscountedActive = Boolean(isDiscountedParamRef);

	const totalActiveFiltersCount =
		activeTagFilters.length +
		activeSpecificationFilters.length +
		(isInStockActive ? 1 : 0) +
		(isDiscountedActive ? 1 : 0);

	// This is zipping specifications data (get-category-specification, get-tag-specification) with get-avaliable-filters data
	// The resultuing object is used to render the filters. Its shape is somewhat weird and should be probably refactored
	const specificationsWithBundleFilters = useMemo(() => {
		return mergeSpecificationsWithBundleFilters(
			specificationsQuery.data?.specifications ?? [],
			filtersQuery.data?.specificationFilters ?? []
		);
	}, [
		filtersQuery.data?.specificationFilters,
		specificationsQuery.data?.specifications,
	]);

	return (
		<BundlesWithFiltersContext.Provider
			value={{
				activeSpecificationFilters,
				activeTagFilters,
				bundlesData: paginatedBundlesData,
				bundles,
				bundlesCount,
				bundlesToLoadMore,
				currentPage,
				isBundlesLoading,
				isDataLoading: isTagDataLoading,
				handleOnRemoveFilter,
				handleOnViewChange,
				limit,
				page,
				query,
				setPageParam,
				isInStockParam,
				isInStockParamRef,
				isDiscountedParam,
				isInStockActive,
				isDiscountedActive,
				totalActiveFiltersCount,
				setQuery,
				specificationsQuery,
				specificationsWithBundleFilters,
				view,
				sorting: sortParam,
				setSorting: setSortParam,
				isVisible: navBarContext.isFiltersVisible,
				setIsVisible: navBarContext.setIsFiltersVisible,
			}}
		>
			{props.children}
		</BundlesWithFiltersContext.Provider>
	);
};

export default BundlesWithFiltersProvider;
