import CategoryLink from 'Components/Navbar/Components/CategoryLink';
import Container from 'Components/View/Container';
import cx from 'classnames';
import { debounce, throttle } from 'lodash-es';
import {
	MouseEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useLocation } from 'react-router-dom';

// Temporary disabled: https://vinisto.atlassian.net/browse/VWA-1574
// import CategoryLinkListMenu from '../CategoryLinkListMenu';

import {
	CLOSING_DELAY_MS,
	OPENING_DELAY_MS,
	PREVIOUS_MOUSE_POSITION,
} from './constants';
import { ICategoryLinkListProps } from './interfaces';
import './styles.css';
import styles from './styles.module.css';

const CategoryLinkList = ({ links }: ICategoryLinkListProps) => {
	const location = useLocation();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [displayedCategory, setDisplayedCategory] = useState<string | null>(
		null
	);
	const [pathname, setPathname] = useState<string>(location.pathname);
	const [previousMousePosition, setPreviousMousePosition] = useState<
		PREVIOUS_MOUSE_POSITION.OUT | PREVIOUS_MOUSE_POSITION.IN
	>();

	useEffect(() => {
		if (location.pathname !== pathname) {
			setDisplayedCategory(null);
			setPathname(location.pathname);
		}
	}, [location.pathname, pathname, setDisplayedCategory, setPathname]);

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			setDisplayedCategory(null);
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const navMenuRef = useRef<HTMLUListElement>(null);
	const { x, y, width, height } =
		navMenuRef.current instanceof HTMLElement
			? navMenuRef.current.getBoundingClientRect()
			: { x: 0, y: 0, width: 0, height: 0 };

	const clamp = (number: number, min: number, max: number) =>
		Math.max(min, Math.min(number, max));

	const updateRelativePosition = throttle((event: MouseEvent) => {
		if (!navMenuRef.current) return;

		const relativeX = event.clientX - x;
		const relativeY = event.clientY - y;

		const percentualWidth = (relativeX / width) * 100;
		const percentualHeight = (relativeY / height) * 100;

		// The small deviations are ad-hoc adjustments to make it work more reliably(?)
		// For the moment, I'll leave it like this, but it should be improved
		const left = clamp(percentualWidth - 3, 0, 100);
		const right = clamp(percentualWidth + 3, 0, 100);
		const top = clamp(percentualHeight + 8, 0, 75);

		navMenuRef.current.style.setProperty(
			'--hover-trapezoid-top-coords',
			`${left}% ${top}%, ${right}% ${top}%`
		);
	}, 10);

	const handleMenuBarMouseEnter = useCallback(() => {
		setPreviousMousePosition(PREVIOUS_MOUSE_POSITION.IN);
	}, []);

	const handleMenuBarMouseLeave = useCallback(() => {
		setPreviousMousePosition(PREVIOUS_MOUSE_POSITION.OUT);
	}, []);

	const handleMenuItemMouseLeave = useCallback(
		debounce(() => setDisplayedCategory(null), CLOSING_DELAY_MS),
		[setDisplayedCategory]
	);

	const handleMenuItemMouseEnter = useCallback(
		(id: string) => (event: MouseEvent) => {
			handleMenuItemMouseLeave.cancel();
			const target = event.target as HTMLLIElement;

			setTimeout(
				() => {
					if (target.matches(':hover')) {
						setDisplayedCategory(id);
					}
				},
				previousMousePosition === 'IN' ? 0 : OPENING_DELAY_MS
			);
		},
		[handleMenuItemMouseLeave, previousMousePosition]
	);

	const MemoizedList = useMemo(() => {
		return links.map((category, index) => {
			const key = category.name ?? String(index);
			return (
				<li
					key={key}
					className={cx('nav-item', styles.navItem, {
						// Temporary disabled: https://vinisto.atlassian.net/browse/VWA-1574
						// 'is-open': displayedCategory === key,
						// 'has-children': category.children,
					})}
					onMouseLeave={category.children && handleMenuItemMouseLeave}
					onMouseEnter={category.children && handleMenuItemMouseEnter(key)}
				>
					<CategoryLink {...category} />
					{/*
					// Temporary disabled: https://vinisto.atlassian.net/browse/VWA-1574
						category.children && (
						<CategoryLinkListMenu
							sections={category.children}
							handleOnMouseLeave={() => setDisplayedCategory(null)}
						/>
					)*/}
				</li>
			);
		});
	}, [
		links,
		// Temporary disabled: https://vinisto.atlassian.net/browse/VWA-1574
		// displayedCategory,
		handleMenuItemMouseLeave,
		handleMenuItemMouseEnter,
	]);

	return (
		<div className={styles.productsWrapper}>
			<Container>
				<nav className="navbar navbar-dark navbar-expand-lg products-navbar py-0">
					<ul
						className="navbar-nav container-fluid d-flex justify-content-between pe-0"
						onMouseEnter={handleMenuBarMouseEnter}
						onMouseLeave={handleMenuBarMouseLeave}
						ref={navMenuRef}
						onMouseMove={updateRelativePosition}
					>
						{MemoizedList}
					</ul>
				</nav>
			</Container>
		</div>
	);
};

export default CategoryLinkList;
