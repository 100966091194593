import { BUNDLES_URI, AUTOCOMPLETE_LIMIT } from '../constants';

import api from '@/api';
import {
	VinistoGiftsDllModelsApiGiftRuleGiftRulesReturn,
	VinistoHelperDllEnumsLanguage,
	VinistoProductDllModelsApiBundleBundleAssignParameters,
	VinistoProductDllModelsApiBundleBundleCreateParameters,
	VinistoProductDllModelsApiBundleBundleReturn,
	VinistoProductDllModelsApiBundleBundleSaleOverParameters,
	VinistoProductDllModelsApiBundleBundleUnassignParameters,
	VinistoProductDllModelsApiBundleBundlesGetParameters,
	VinistoProductDllModelsApiBundleBundlesReturn,
	VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn,
} from '@/api-types/product-api';
import { bundleAdapter, giftRuleAdapter } from '@/index';
import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';

// TODO check if this waterfalling is necessary (what is the default limit?)
const getBundlesInCategory = async (categoryId: string) => {
	const requestParams = {
		isCache: false,
		categoryId,
	};
	return api
		.post<VinistoProductDllModelsApiBundleBundlesReturn>(
			`${BUNDLES_URI}/GetBundles`,
			undefined,
			{
				...requestParams,
				limit: 1,
			}
		)
		.then((payload) => {
			const totalCount = payload?.count;
			if (!totalCount) return [];
			return api
				.post<VinistoProductDllModelsApiBundleBundlesReturn>(
					`${BUNDLES_URI}/GetBundles`,
					undefined,
					{
						...requestParams,
						limit: totalCount,
					}
				)
				.then((payload) =>
					(payload.bundles ?? []).map((bundle) => bundleAdapter.fromApi(bundle))
				);
		});
};

const getAutocompleteBundles = (value: string) =>
	api.get<VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn>(
		`${BUNDLES_URI}/GetAutocompleteNames`,
		{
			searchingNameString: value,
			limit: AUTOCOMPLETE_LIMIT,
			...DEFAULT_BUNDLE_API_PARAMS
		}
	);

const getBundleById = async (
	id: string,
	params?: VinistoProductDllModelsApiBundleBundlesGetParameters
) => {
	const res = await api.get<VinistoProductDllModelsApiBundleBundleReturn>(
		`${BUNDLES_URI}/${id}`,
		params
	);

	if (res.bundle === null || res.bundle === undefined)
		throw new Error('No bundle data in response');

	return bundleAdapter.fromApi(res.bundle);
};

const getIdenticalBundles = async (
	bundleId: string,
	params?: { language?: VinistoHelperDllEnumsLanguage; isCache?: boolean }
) => {
	const res = await api.get<VinistoProductDllModelsApiBundleBundlesReturn>(
		`${BUNDLES_URI}/${bundleId}/GetIdenticalBundles`,
		params
	);

	if (res.bundles === null || res.bundles === undefined)
		throw new Error('No bundles data in response');

	return res.bundles.map((bundle) => bundleAdapter.fromApi(bundle));
};

const getBundleGifts = async (bundleId: string) => {
	const res = await api.get<VinistoGiftsDllModelsApiGiftRuleGiftRulesReturn>(
		`${BUNDLES_URI}/${bundleId}/get-bundle-gifts`
	);

	if (res.giftRules === null || res.giftRules === undefined)
		throw new Error('No gifts data in response');

	return res.giftRules.map((giftRule) => giftRuleAdapter.fromApi(giftRule));
};

const createBundleSet = async (
	params: VinistoProductDllModelsApiBundleBundleCreateParameters
) =>
	api.post<VinistoProductDllModelsApiBundleBundleReturn>(
		`${BUNDLES_URI}`,
		undefined,
		params
	);

const addBundleToSet = async (
	bundleSetId: string,
	params: VinistoProductDllModelsApiBundleBundleAssignParameters
) =>
	api.post<VinistoProductDllModelsApiBundleBundleReturn>(
		`${BUNDLES_URI}/${bundleSetId}/bundles`,
		undefined,
		params
	);

const removeBundleFromSet = async (
	bundleSetId: string,
	params: VinistoProductDllModelsApiBundleBundleUnassignParameters
) => {
	const { itemId, amount, price, userLoginHash } = params;
	return api.delete(`${BUNDLES_URI}/${bundleSetId}/bundles`, undefined, {
		itemId,
		amount,
		price,
		userLoginHash,
	});
};

const setIsSellOver = async (
	bundleId: string,
	req: VinistoProductDllModelsApiBundleBundleSaleOverParameters
) => {
	const res = await api.put(
		`${BUNDLES_URI}/${bundleId}/set-sell-over`,
		undefined,
		req
	);

	return res;
};

const BundleService = {
	getBundleById,
	getIdenticalBundles,
	getBundlesInCategory,
	getAutocompleteBundles,
	getBundleGifts,
	createBundleSet,
	addBundleToSet,
	removeBundleFromSet,
	setIsSellOver,
};

export default BundleService;
