import { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { DeviceServiceContext } from 'Services/DeviceService';

import { useInfoColumns } from './hooks';
import InfoColumn from './Components/InfoColumn';

const InfoColumns = () => {
	const deviceContext = useContext(DeviceServiceContext);
	const infoColumns = useInfoColumns();

	return (
		<div className="row footer-nav footer-accordions px-md-0 mt-0">
			{infoColumns?.map(({ title, links }, index) => (
				<div
					key={index}
					className="col-md-3 px-md-3 col-12 px-0"
				>
					{deviceContext.isMobile ? (
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>{title}</Accordion.Header>
								<Accordion.Body>
									<InfoColumn links={links} />
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					) : (
						<>
							<h5>{title}</h5>
							<InfoColumn links={links} />
						</>
					)}
				</div>
			))}
		</div>
	);
};

export default InfoColumns;
