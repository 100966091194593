import { VinistoOrderDllModelsApiOrderAddress } from 'vinisto_api_client/src/api-types/order-api';

export interface IAddressProps {
	addressPhone: string | null | undefined;
	addressEmail: string | null | undefined;
	addressData: VinistoOrderDllModelsApiOrderAddress;
	addressType: AddressType;
}

export enum AddressType {
	DELIVERY = 'DELIVERY',
	BILLING = 'BILLING',
}
