import { SpecificationExplanation } from 'vinisto_ui';

import { SpecificationExplanationViewProps } from './interfaces';

const SpecificationExplanationView = ({
	data,
}: SpecificationExplanationViewProps) => {
	// Specification count must be 3 (https://vinisto.atlassian.net/browse/VWA-1439)
	// const minimumDataLength = 3;
	return (
		data && (
			<div>
				{data.map((item, index) => (
					<SpecificationExplanation
						key={index}
						position={index === 0 ? 'left' : index === 1 ? 'right' : 'full'}
						imageUrl={item.imageUrl}
						heading={item.heading}
						text={item.text}
						anchorLink={item.anchorLink ?? undefined}
						anchorText={item.anchorText ?? undefined}
					/>
				))}
			</div>
		)
	);
};

export default SpecificationExplanationView;
