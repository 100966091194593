import { VinistoHelperDllBaseError } from '@/api-types/product-api';
import { ApiError, NetworkError } from './domain/error';
import isNetworkError from './utils/is-network-error';

// The discriminate union is, sadly, very likely not wworking as intended :(
// User-defined type guard is probably the only way to go
export type BaseResponse =
  | {
      isError: true;
      error: VinistoHelperDllBaseError[];
    }
  | {
      isError: false;
      error: null;
    }
  | {
      isError?: boolean;
      error: VinistoHelperDllBaseError[] | null | undefined;
    };

//TODO: Remove once BE correctly deserializes URL params (anon hash, colons, etc.)
//TODO: This shouldn't be here for longer than 2 sprints !!!!!!!
type TempRequestInit = RequestInit & {
	serializeUrlParams?: boolean;
};

export class VinistoApiService {
  constructor(baseURL?: RequestInfo | URL, baseOptions?: RequestInit) {
    this.baseUrl = baseURL ?? '';
    this.options = {
      ...this.baseOptions,
      ...baseOptions,
    };
  }

  private options;

  private baseUrl;

  private baseOptions: RequestInit = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

	// api processes arrays like this: OrderStates=CREATED&OrderStates=PAID&OrderStates=REFUNDED
	// and not like this OrderStates: CREATED%2CPAID%2CIN_WMS%2CWMS_ACCEPTED%2CWMS_INCOMPLETE%2CWMS_READY%2CSENT
	private serializeQueryParams(params: Record<string, any>): string {
		const queryString = Object.entries(params)
			.map(([key, value]) => {
				if (Array.isArray(value)) {
					return value.map(val => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
				}
				return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
			})
			.join('&');
		return queryString;
	}

	private constructUrlWithParams(url: string, queryParams?: Record<PropertyKey, any>, serialize: boolean = true): string {
    if (!queryParams) return url;

    const queryString = serialize ? this.serializeQueryParams(queryParams)
			: Object.entries(queryParams).map(([key, val]) => `${key}=${val}`).join('&');
    return `${url}?${queryString}`;
  }

  private async request<
    TResponse extends BaseResponse,
    TQueryParams extends Record<PropertyKey, any>,
    TBody extends Record<PropertyKey, any> | FormData
  >(
    method: RequestInit['method'],
    url: RequestInfo | URL,
    body?: TBody,
    queryParams?: TQueryParams,
    options?: TempRequestInit & { responseType?: 'blob' | 'text' | 'json' },
  ): Promise<TResponse> {
		const serialize = options?.serializeUrlParams !== false;
    const requestURL = this.constructUrlWithParams(`${this.baseUrl}${url}`, queryParams, serialize);
		// TODO: Uncomment and use instead of prev 2 lines after BE fixes URL deserialization
    // const requestURL = `${this.baseUrl}${url}${
    //   queryParams ? `?${this.serializeQueryParams(queryParams)}` : ''
    // }`;

    const requestInit = {
      ...this.options,
      ...options,
      method,
      ...(body &&
        method !== 'GET' && {
          body: body instanceof FormData ? body : JSON.stringify(body),
        }),
    } satisfies RequestInit;

    try {
      const response = await fetch(requestURL, requestInit);

			let data;
      switch (options?.responseType) {
        case 'blob':
          data = await response.blob();
          break;
        case 'text':
          data = await response.text();
          break;
        default:
          data = await response.json();
          break;
      }

      if (!response.ok || data.isError) {
        return Promise.reject(new ApiError(data, requestInit, response));
      }

      return data;
    } catch (error) {
      if (isNetworkError(error)) {
        /* Error properties are not enumerable, we need to hack it a bit to get a regular object
        const errorObject = JSON.parse(
          JSON.stringify(error, Object.getOwnPropertyNames(error))
        );*/
        return Promise.reject(new NetworkError(requestInit));
      }
      // Probably unknown error? This should not happen
      return Promise.reject(error);
    }
  }

  get<TResponse extends BaseResponse>(
    url: RequestInfo | URL,
    queryParams?: Record<PropertyKey, any>,
    options?: TempRequestInit & { responseType?: 'blob' | 'text' | 'json' }
  ): Promise<TResponse> {
    return this.request('GET', url, undefined, queryParams, options);
  }

  post<TResponse extends BaseResponse>(
    url: RequestInfo | URL,
    queryParams?: Record<PropertyKey, any>,
    body?: Record<any, any> | FormData,
    options?: TempRequestInit
  ): Promise<TResponse> {
    return this.request('POST', url, body, queryParams, options);
  }

  put<TResponse extends BaseResponse>(
    url: RequestInfo | URL,
    queryParams?: Record<PropertyKey, any>,
    body?: Record<any, any> | FormData,
    options?: TempRequestInit
  ): Promise<TResponse> {
    return this.request('PUT', url, body, queryParams, options);
  }

  patch<TResponse extends BaseResponse>(
    url: RequestInfo | URL,
    queryParams?: Record<PropertyKey, any>,
    body?: Record<any, any> | FormData,
    options?: TempRequestInit
  ): Promise<TResponse> {
    return this.request('PATCH', url, body, queryParams, options);
  }

  delete<TResponse extends BaseResponse>(
    url: RequestInfo | URL,
    queryParams?: Record<PropertyKey, any>,
    body?: Record<any, any> | FormData,
    options?: TempRequestInit
  ): Promise<TResponse> {
    return this.request('DELETE', url, body, queryParams, options);
  }

  upload<TResponse extends BaseResponse>(
    url: RequestInfo | URL,
    queryParams?: Record<PropertyKey, any>,
    body?: FormData,
    options?: TempRequestInit
  ): Promise<TResponse> {
    return this.request('POST', url, body, queryParams, {
      ...options,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export default new VinistoApiService(import.meta.env.VITE_API_URI);
