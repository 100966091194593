import BundleItem from '.';

import { AbstractAdapter } from '@/domain/abstract-adapter';
import { VinistoProductDllModelsApiBundleItemsBundleItem, VinistoProductDllModelsApiBundleItemsProductItem, VinistoProductDllModelsApiCommonPrice } from '@/api-types/product-api';
import PriceAdapter from '@/domain/price/adapter';
import { priceAdapter } from '@/index';

class BundleItemAdapter extends AbstractAdapter<
	BundleItem,
	(
    | VinistoProductDllModelsApiBundleItemsBundleItem & {
			price?: VinistoProductDllModelsApiCommonPrice | null;
		}
    | VinistoProductDllModelsApiBundleItemsProductItem & {
			price?: VinistoProductDllModelsApiCommonPrice | null;
		}
  )
> {
	fromApi(apiData: (
    | VinistoProductDllModelsApiBundleItemsBundleItem & {
			price?: VinistoProductDllModelsApiCommonPrice | null;
		}
    | VinistoProductDllModelsApiBundleItemsProductItem & {
			price?: VinistoProductDllModelsApiCommonPrice | null;
		}
  )): BundleItem {
		const productId = apiData.itemId;

		if (!productId) throw new Error('No productId in bundle item');

		return {
			productId,
			amount: apiData.amount ?? 0,
			price: apiData.price ? priceAdapter.fromApi(apiData.price) : undefined,
		};
	}
}

export default BundleItemAdapter;
