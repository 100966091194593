import cx from 'classnames';
import useStaticCategoriesMobile from 'Components/Navbar/useStaticCategoriesMobile';
import useNavLinks from 'Components/Navbar/useNavLinks';
import { formatPhoneNumber } from 'Components/Navbar/helpers';
import MobileMenu from 'Components/MobileMenu';
import NavLink from 'Components/Navbar/Components/NavLink';
import styles from 'Components/MobileMenu/Components/ItemLink/styles.module.css';
import { PHONE_NUMBER } from 'Components/Navbar/constants';

const MobileMenuInitialPage = () => {
	const categories = useStaticCategoriesMobile();
	const navLinks = useNavLinks();

	return (
		<MobileMenu.Page topPanel={{ title: 'Menu' }}>
			{categories?.map((category, index) => (
				<MobileMenu.MenuItemLink
					key={index}
					to={category.to}
					hasSubmenu={false}
					// Temporary disabled: https://vinisto.atlassian.net/browse/VWA-1574
					// to={category.children ? `/category/${index}` : category.to}
					// hasSubmenu={Boolean(category.children)}
				>
					<span className="ps-2">{category?.name}</span>
				</MobileMenu.MenuItemLink>
			))}
			{navLinks?.map(({ name, to, ...props }, index) => (
				<MobileMenu.ItemLink
					to={`${to}`}
					key={index}
				>
					<NavLink
						{...props}
						withUnderline={false}
					>
						{name}
					</NavLink>
				</MobileMenu.ItemLink>
			))}
			<div className={cx(styles.itemLink, 'ps-3')}>
				{formatPhoneNumber(PHONE_NUMBER)}
			</div>
		</MobileMenu.Page>
	);
};

export default MobileMenuInitialPage;
