import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { WarehouseContext } from 'Services/WarehouseService';
import { apiServiceInstance } from 'Services/ApiService';

import { RECOMMENDED_CAROUSEL_ID } from '../Components/Navbar/Components/Search/constants';

import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';
import { VinistoProductDllModelsApiHomePageHomePageCustomCarouselReturn } from '@/api-types/product-api';

export const useRecommendedBundles = () => {
	const warehouseContext = useContext(WarehouseContext);
	const BUNDLE_SLICE_INDICES: [number, number] = [0, 5];

	const { data, isLoading } = useQuery(['recommendedCarousel'], async () => {
		const processedParams: any[] = [];
		Object.entries({ ...DEFAULT_BUNDLE_API_PARAMS }).forEach(([key, value]) => {
			processedParams.push({ key: key, value: value });
		});

		const response =
			await apiServiceInstance.get<VinistoProductDllModelsApiHomePageHomePageCustomCarouselReturn>(
				`product-api/home-page/custom-carousels/${RECOMMENDED_CAROUSEL_ID}`,
				false,
				undefined,
				[{ key: 'IsCache', value: true }, ...processedParams]
			);

		if (response.isError) return Promise.reject(response.error);

		const uniqueIds = Array.from(
			new Set(
				(response.homePageCustomCarousel?.bundles ?? []).map(
					(bundle) => bundle.id
				)
			)
		).filter((id): id is string => Boolean(id));

		warehouseContext.fetchQuantity(uniqueIds);

		return (
			response.homePageCustomCarousel?.bundles?.slice(
				...BUNDLE_SLICE_INDICES
			) || []
		);
	});

	return { data, isLoading };
};
