import { QuantityBoxTypes } from 'Components/QuantityBox/constants';
import { ReactNode } from 'react';

export enum CARD_TYPE {
	SINGLE,
}

export interface IProductBoxProps {
	bundleData?: Record<PropertyKey, any> | null;
	cardType?: CARD_TYPE;
	isLoading?: boolean;
	redirectToCrossSell?: boolean;
	displayPriceAsRange?: boolean;
	isCrossSell?: boolean;
	showAddToBasketBtn?: boolean;
	quantityBoxType?: QuantityBoxTypes;
	showSpecifications?: boolean;
	showAddToFavoritesBtn?: boolean;
	showProducer?: boolean;
	carouselType?: string;
}

export interface ProductBoxContextProps {
	addToBasketCallback: () => void;
	addToBasketButtonLabel?: string;
}

export interface ProductBoxProviderProps extends ProductBoxContextProps {
	children?: ReactNode;
}
