import { useCallback, useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/product-api';

import { FALLBACK_CURRENCY, PRICE_TYPES } from './constants';
import { IBundlePrice, IBundlePriceReturnData } from './interfaces';
import { getLowestPriceBundle } from './helpers';

/**
 *
 * @deprecated - either calculate prices directly as needed or create a better hook, this one just doesn't make sense anymore.
 */
const useBundlePrice = () => {
	const {
		activeCurrency: { currency, title },
	} = useContext(LocalizationContext);

	return useCallback(
		(
			bundle: any,
			identicalBundles: VinistoProductDllModelsApiBundleBundle[] = []
		): IBundlePriceReturnData => {
			const bundles = [bundle, ...identicalBundles];

			const activeCurrencyBundlePrices: IBundlePrice[] = bundle?.prices?.filter(
				(price: IBundlePrice) =>
					price?.currency ?? FALLBACK_CURRENCY === currency
			);

			const bundlePrice =
				activeCurrencyBundlePrices?.find(
					(price) => price?.type === PRICE_TYPES.STANDARD
				) ?? null;
			const discountedBundlePrice =
				activeCurrencyBundlePrices?.find(
					(price) => price?.type !== PRICE_TYPES.STANDARD
				) ?? null;

			const lowestPriceBundle = getLowestPriceBundle(bundles);

			const lowestPrice =
				lowestPriceBundle?.prices?.find(
					(price: IBundlePrice) => price?.type === PRICE_TYPES.STANDARD
				) ?? null;
			const lowestDiscountedPrice =
				lowestPriceBundle?.prices?.find(
					(price: IBundlePrice) => price?.type !== PRICE_TYPES.STANDARD
				) ?? null;

			return {
				price: discountedBundlePrice?.value ?? bundlePrice?.value ?? 0,
				priceWithVat:
					discountedBundlePrice?.valueWithVat ?? bundlePrice?.valueWithVat ?? 0,
				discountedFromPrice: discountedBundlePrice?.value
					? bundlePrice?.value ?? null
					: null,
				discountedFromPriceVat: discountedBundlePrice?.valueWithVat
					? bundlePrice?.valueWithVat ?? null
					: null,
				lowestPrice: lowestDiscountedPrice?.value ?? lowestPrice?.value ?? 0,
				lowestPriceWithVat:
					lowestDiscountedPrice?.valueWithVat ?? lowestPrice?.valueWithVat ?? 0,
				discountedFromLowestPrice: lowestDiscountedPrice?.value
					? lowestPrice?.value ?? null
					: null,
				discountedFromLowestPriceWithVat: lowestDiscountedPrice?.valueWithVat
					? lowestPrice?.valueWithVat ?? null
					: null,
				currency: bundlePrice?.currency ?? FALLBACK_CURRENCY,
				currencyTitle: title,
				isPriceDiscounted: !!discountedBundlePrice?.value,
				isLowestPriceDiscounted: !!lowestDiscountedPrice?.value,
				isLowestPriceSameBundle: bundle?.id === lowestPriceBundle?.id,
			};
		},
		[]
	);
};

export default useBundlePrice;
