import { FC, useContext } from 'react';
import cx from 'classnames';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';

import { IOutOfStockCartItemListProps } from './interfaces';
import OutOfStockCartItem from './OutOfStockCartItem';

const OutOfStockCartItemList: FC<IOutOfStockCartItemListProps> = ({
	basketItemsOutOfStock,
	handleRemoveItemFromBasket,
	setBasketItemsOutOfStock,
}) => {
	const localizationContext = useContext(LocalizationContext);
	const deviceContext = useContext(DeviceServiceContext);
	const t = localizationContext.useFormatMessage();

	return (
		<div className="vinisto-cart__mb-3rem">
			<h2 className="vinisto-heading">
				{t({ id: 'cart.outOfStockItems.avalabilityChanged.heading' })}
			</h2>

			<div
				className={cx({
					'vinisto-user-orders__orders__order-body vinisto-cart__items':
						!deviceContext.isMobile,
					'vinisto-user-orders__orders__order vinisto-user-favorites--mobile vinisto-crosssell--mobile vinisto-cart__items--mobile':
						deviceContext.isMobile,
				})}
			>
				{Array.from(basketItemsOutOfStock.values()).map((cartItem) => {
					const bundleId = cartItem.bundleData.bundleId;
					if (bundleId === undefined || cartItem.isBeingRemoved === true) {
						return;
					}

					return (
						<OutOfStockCartItem
							{...{ cartItem, setBasketItemsOutOfStock }}
							handleRemoveItemFromBasket={handleRemoveItemFromBasket(
								cartItem.bundleData.bundleId
							)}
							key={bundleId}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default OutOfStockCartItemList;
