import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { FC, useContext } from 'react';
import cx from 'classnames';
import FeaturedImage from 'Pages/Blog/Components/FeaturedImage/FeaturedImage';
import { LocalizationContext } from 'Services/LocalizationService';
import useArticleImage from 'Hooks/Queries/useArticleTitleImage';
import removeInlineStyles from 'Helpers/removeInlineStyles';
const fallbackImage = '/assets/images/blog-fallback.webp';
import sanitizeHTML from 'Helpers/sanitizeHTML';
import { DeviceServiceContext } from 'Services/DeviceService';

import { IArticleInfoProps } from './interfaces';
import styles from './styles.module.css';

const ArticleInfo: FC<IArticleInfoProps> = ({ data, isLoading }) => {
	const localizationContext = useContext(LocalizationContext);
	const { isMobile } = useContext(DeviceServiceContext);
	const t = localizationContext.useFormatMessage();

	const articleContentNoStyles = sanitizeHTML(
		removeInlineStyles(data?.perex ?? '')
	).substring(0, 140);

	const titleImageId = data?.titleImageId ?? '';

	const imageData = useArticleImage(titleImageId);

	const imageUrl =
		(isMobile
			? imageData?.image?.urls?.thumb_300
			: imageData?.image?.urls?.thumb_1000) || fallbackImage;
	const altText = imageData?.image?.alternativeText || '';

	return (
		<Link
			to={
				isLoading ? '#' : `/${t({ id: 'routes.community.route' })}/${data?.url}`
			}
			className={cx(styles.blogArticle)}
		>
			<div className={styles.blogArticleThumbnail}>
				{isLoading ? (
					<Skeleton
						height="99%"
						width="99.75%"
					/>
				) : (
					<FeaturedImage
						imageUrl={imageUrl}
						altText={altText}
					/>
				)}
			</div>
			{isLoading ? (
				<Skeleton width="100%" />
			) : (
				<p
					className={styles.blogAritcleHeading}
					dangerouslySetInnerHTML={{
						__html: data?.title ?? '',
					}}
				/>
			)}
			{isLoading ? (
				<Skeleton width="100%" />
			) : (
				<div className={styles.blogArticleInfo}>
					<div className={styles.blogArticleDate}>
						{data?.publishDate.toLocaleDateString()}
					</div>
					<div className={styles.blogArticleInfoSeparator}>-</div>
					<div className={styles.blogArticleAuthor}>
						{data?.authors?.map((author) => author.name).join(', ')}
					</div>
					<div className={styles.blogArticleInfoSeparator}>-</div>
					<div className={styles.blogArticleReadingTime}>
						{t(
							{ id: 'blog.readingTime' },
							{
								time: data?.readingTime.toString(),
							}
						)}
					</div>
				</div>
			)}
			<div>
				{isLoading ? (
					<Skeleton count={5.6} />
				) : (
					<>
						<span
							className={styles.blogArticleContent}
							dangerouslySetInnerHTML={{
								__html: `${articleContentNoStyles ?? ''} ${'...'}`,
							}}
						/>
						<span className={styles.articleblogArticleLink}>
							{t({ id: 'articleTabs.link.continueReading' })}
						</span>
					</>
				)}
			</div>
		</Link>
	);
};

export default ArticleInfo;
