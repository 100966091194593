import { useContext } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/product-api';
import { QuantityBoxTypes } from 'Components/QuantityBox/constants';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import useBundlePrice from 'Hooks/useBundlePrice';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import usePreventDragRedirect from 'Hooks/use-prevent-drag-redirect';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import { DirectQuantityBox, StandardQuantityBox } from 'Components/QuantityBox';
import StockAvailability from 'Components/StockAvailability';
import quantityBoxStyles from 'Components/QuantityBox/styles.module.css';
import { useProductBasketCount } from 'Components/ProductBox/hooks';

import ProductBasketCount from './Components/ProductBasketCount';
import BundleProducer from './Components/BundleProducer';
import BundleTag from './Components/BundleTag';
import ButtonAddToFavorites from './Components/ButtonAddToFavorites';
import ProductSpecifications from './Components/Specifications';
import ProductTitleAndImage from './Components/TitleAndImage';
import { CARD_TYPE, IProductBoxProps } from './interfaces';
import styles from './styles.module.css';
import useFavoriteItem from './Components/ButtonAddToFavorites/hook';

const ProductBox = (props: IProductBoxProps) => {
	const localizationContext = useContext(LocalizationContext);
	const warehouseContext = useContext(WarehouseContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const getBundlePrice = useBundlePrice();

	const bundleData = (props?.bundleData ??
		{}) as VinistoProductDllModelsApiBundleBundle;
	const bundleId = bundleData?.id ?? '';
	const bundleUrl = getLocalizedValue(bundleData?.url ?? []);
	const displayPriceAsRange = props?.displayPriceAsRange ?? false;

	const isTemporaryUnavailable = bundleData?.temporaryUnavailable ?? false;

	const isGift = bundleData?.isGift ?? false;

	const showAddToBasketBtn = props.showAddToBasketBtn ?? true;
	const showSpecifications = props.showSpecifications ?? true;
	const showProducer = props.showProducer ?? true;
	const showAddToFavoritesBtn =
		(props?.showAddToFavoritesBtn ?? true) &&
		!isTemporaryUnavailable &&
		!isGift;

	const specificationDetails = bundleData?.specificationDetails ?? [];

	const { shortVariety: producerName, component: flag } =
		getFlagSpecification(specificationDetails);

	const isLoading = props?.isLoading ?? false;
	const redirectToCrossSell = props?.redirectToCrossSell ?? true;

	const {
		price,
		priceWithVat,
		lowestPrice,
		lowestPriceWithVat,
		discountedFromPriceVat,
		discountedFromLowestPriceWithVat,
		currencyTitle,
	} = getBundlePrice(props.bundleData ?? {});

	const isSet = bundleData?.isSet ?? false;
	const setBasePriceFormatted = isSet
		? createCurrencyValue(bundleData?.prices?.[1]?.valueWithVat ?? 0)
		: null;

	const priceWithoutVAT = createCurrencyValue(
		displayPriceAsRange ? price : lowestPrice
	);
	const priceWithVAT = createCurrencyValue(
		displayPriceAsRange ? priceWithVat : lowestPriceWithVat
	);
	const discountedFromPriceWithVat = displayPriceAsRange
		? discountedFromPriceVat
		: discountedFromLowestPriceWithVat;
	const discountedFromPriceWithVatFormatted = discountedFromPriceWithVat
		? createCurrencyValue(discountedFromPriceWithVat)
		: null;

	const availableQuantity = [warehouseContext.getQuantity(bundleId)].filter(
		(x): x is number => x !== undefined
	);

	const { basketQuantityPopover } = useProductBasketCount(bundleData);

	const { handleAddItemToFavorites, handleRemoveItemFromFavorites } =
		useFavoriteItem(bundleId, {
			price: lowestPrice,
			currency: currencyTitle,
			item_name: getLocalizedValue(bundleData?.name),
		});

	const { handleClick, handleMouseDown, preventDefaultEvent } =
		usePreventDragRedirect();

	return (
		<div
			className={cx('vinisto-wine__item', {
				loading: isLoading,
				[styles.cardMargin]: props.cardType === CARD_TYPE.SINGLE,
			})}
		>
			{isLoading ? (
				<ProductTitleAndImage
					isLoading
					bundleData={bundleData}
				/>
			) : (
				<>
					<Link
						className="vinisto-wine__item-overlay"
						to={`/${t({
							id: 'routes.product.route',
						})}/${bundleUrl}`}
						rel="nofollow"
						onDragStart={preventDefaultEvent}
						onMouseDown={handleMouseDown}
						onMouseUp={preventDefaultEvent}
						onClick={handleClick}
					/>
					<ProductBasketCount text={basketQuantityPopover} />
					<div className="vinisto-wine__img-labels">
						{(bundleData.tagsDetail ?? []).map(
							(tagDetail: any, index: number) => {
								return (
									<BundleTag
										{...{ tagDetail }}
										key={`bundle-${bundleId}-tag-${tagDetail?.id ?? index}`}
										isLink={false}
									/>
								);
							}
						)}
					</div>
					<Link
						to={`/${t({
							id: 'routes.product.route',
						})}/${bundleUrl}`}
						tabIndex={-1}
						onDragStart={preventDefaultEvent}
						onMouseDown={handleMouseDown}
						onMouseUp={preventDefaultEvent}
						onClick={handleClick}
					>
						<ProductTitleAndImage bundleData={bundleData} />
					</Link>

					{showAddToFavoritesBtn && (
						<ButtonAddToFavorites
							itemId={bundleData?.id ?? ''}
							addToFavorites={handleAddItemToFavorites}
							removeItemFromFavorites={handleRemoveItemFromFavorites}
							carouselType={props?.carouselType ?? ''}
						/>
					)}
				</>
			)}
			{showProducer && (
				<BundleProducer
					flag={flag}
					name={producerName}
					isLoading={isLoading}
					isLink={false}
				/>
			)}
			{showSpecifications && (
				<div className="vinisto-wine__specifications">
					<ProductSpecifications
						specifications={specificationDetails}
						isLoading={isLoading}
					/>
				</div>
			)}
			<div className="vinisto-wine__bottom-info">
				<div className="vinisto-wine__price">
					{isLoading ? (
						<Skeleton
							width="100%"
							inline
						/>
					) : (
						<>
							{discountedFromPriceWithVat !== null ||
								(setBasePriceFormatted && (
									<span className="vinisto-wine__price--original">
										{t(
											{ id: displayPriceAsRange ? 'price' : 'price.from' },
											{
												value:
													setBasePriceFormatted ??
													discountedFromPriceWithVatFormatted,
												currency: currencyTitle,
											}
										)}
									</span>
								))}
							{!setBasePriceFormatted && !isSet && discountedFromPriceVat && (
								<span className="vinisto-wine__price--original">
									{t(
										{ id: displayPriceAsRange ? 'price' : 'price.from' },
										{
											value: discountedFromPriceVat,
											currency: currencyTitle,
										}
									)}
								</span>
							)}
							{t(
								{ id: displayPriceAsRange ? 'price' : 'price.from' },
								{
									value: priceWithVAT,
									currency: currencyTitle,
								}
							)}
						</>
					)}
				</div>
				<div className="vinisto-wine__price--no-vat">
					{isLoading ? (
						<Skeleton
							width="100%"
							inline
						/>
					) : (
						t(
							{
								id: 'carousel.info.withoutVAT',
							},
							{
								priceWithCurrency: `${t(
									{ id: 'carousel.info.priceWithCurrency' },
									{ value: priceWithoutVAT, currency: currencyTitle }
								)}`,
							}
						)
					)}
				</div>
				<div className="vinisto-wine__stock">
					<StockAvailability
						availableQuantity={availableQuantity}
						deliveryDate={warehouseContext.deliveryDate}
						isTemporaryUnavailable={isTemporaryUnavailable}
						fallback={<Skeleton width="80px" />}
					/>
				</div>
				{showAddToBasketBtn && !isTemporaryUnavailable && (
					<div
						className={cx(
							styles.aboveProductLink,
							styles.cta,
							'mt-3 vinisto-wine__qunatity-box-wrapper'
						)}
						onClick={(e) => e.stopPropagation()}
					>
						{props?.quantityBoxType === QuantityBoxTypes.STANDARD ? (
							<StandardQuantityBox
								bundle={bundleData as VinistoProductDllModelsApiBundleBundle}
								shouldRedirectToCrossSell={redirectToCrossSell}
								isLoading={isLoading}
								className={quantityBoxStyles.fullWidth}
								orderLimitation={bundleData?.orderLimitation}
								carouselType={props?.carouselType ?? ''}
							/>
						) : (
							<DirectQuantityBox
								bundle={bundleData as VinistoProductDllModelsApiBundleBundle}
								isLoading={isLoading}
								className={quantityBoxStyles.fullWidth}
								orderLimitation={bundleData?.orderLimitation}
								carouselType={props?.carouselType ?? ''}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductBox;
