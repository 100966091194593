import { lazy, Suspense, useCallback, useContext } from 'react';
import { uniqueId } from 'lodash-es';
import { Form } from 'react-final-form';
import { InputEmail, InputPassword } from 'Components/Form';
import { requireEmail } from 'Components/Form/validators';
import { LocalizationContext } from 'Services/LocalizationService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import Loader from 'Components/View/Loader';
const AdvantageIcon = lazy(() => import('Components/Icons/Advantage'));

interface RegisterFormProps {
	email?: string;
}

const RegisterForm = ({ email }: RegisterFormProps) => {
	const localizationContext = useContext(LocalizationContext);
	const authenticationContext = useContext(AuthenticationContext);

	const t = localizationContext.useFormatMessage();
	const handleOnSubmit = useCallback(
		(formValues: Record<any, any>): void => {
			authenticationContext.handleOnRegister(
				formValues.email,
				formValues.password,
				false,
				true
			);
		},
		[authenticationContext]
	);

	const initialValues = {} as Record<any, any>;

	if (email) {
		initialValues.email = email;
	}

	return (
		<Form
			initialValues={initialValues}
			onSubmit={handleOnSubmit}
			render={({ handleSubmit }) => {
				return (
					<form
						onSubmit={handleSubmit}
						className="vinisto-card"
					>
						<h1 className="vinisto-card__heading">
							{t({ id: 'order.finalRegisterForm.title' })}
						</h1>
						<p className="mb-0 mt-2 vinisto-font-18">
							{t({ id: 'order.finalRegisterForm.subTitle' })}
						</p>
						<div className="vinisto-cart-confirmation__advantages mt-3">
							<div className="vinisto-cart-confirmation__advantage">
								<Suspense fallback={<Loader blank />}>
									<AdvantageIcon
										id={uniqueId()}
										alt={t({ id: 'alt.advantage' })}
										title={``}
										className={``}
									/>
								</Suspense>
								<span>{t({ id: 'order.finalRegisterForm.text1' })}</span>
							</div>
							<div className="vinisto-cart-confirmation__advantage">
								<Suspense fallback={<Loader blank />}>
									<AdvantageIcon
										id={uniqueId()}
										alt={t({ id: 'alt.advantage' })}
										title={``}
										className={``}
									/>
								</Suspense>
								<span>{t({ id: 'order.finalRegisterForm.text2' })}</span>
							</div>
						</div>

						<div className="vinisto-cart-confirmation__advantages">
							<div className="vinisto-cart-confirmation__advantage">
								<Suspense fallback={<Loader blank />}>
									<AdvantageIcon
										id={uniqueId()}
										alt={t({ id: 'alt.advantage' })}
										title={``}
										className={``}
									/>
								</Suspense>
								<span>{t({ id: 'order.finalRegisterForm.text3' })}</span>
							</div>
							<div className="vinisto-cart-confirmation__advantage">
								<Suspense fallback={<Loader blank />}>
									<AdvantageIcon
										id={uniqueId()}
										alt={t({ id: 'alt.advantage' })}
										title={``}
										className={``}
									/>
								</Suspense>
								<span>{t({ id: 'order.finalRegisterForm.text4' })}</span>
							</div>
						</div>

						<div className="vinisto-cart-confirmation__inputs">
							<div className="vinisto-password-wrap">
								<InputEmail validate={requireEmail} />
							</div>

							<div className="vinisto-cart-confirmation__inputs__finish-wrap">
								<div className="vinisto-password-wrap">
									<InputPassword
										label={`${t({
											id: 'order.finalRegisterForm.password.label',
										})}`}
									/>
								</div>
								<button
									type="submit"
									className="vinisto-btn vinisto-success-btn vinisto-cart-confirmation__finish-btn"
								>
									{t({
										id: 'order.finalRegisterForm.submit',
									})}
								</button>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default RegisterForm;
