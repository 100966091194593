import { useContext } from 'react';
import useFormatMessage from 'Hooks/useFormatMessage';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import { LangValuePair } from 'Hooks/useLocalizedValue/interfaces';
import { VinistoOrderDllModelsApiPaymentPayment } from 'vinisto_api_client/src/api-types/order-api';
import { LocalizationContext } from 'Services/LocalizationService';
import { OrderContext } from 'Services/OrderService/context';
import useLocalizedValue from 'Hooks/useLocalizedValue';

import { ShippingItemProps } from '../ShippingItem/interfaces';

export const usePaymentItem = (
	payment: VinistoOrderDllModelsApiPaymentPayment
) => {
	const { paymentMethod, setPaymentMethod } = useContext(OrderContext);
	const localizationContext = useContext(LocalizationContext);
	const t = useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const price = payment?.prices?.[0];

	const currency =
		localizationContext?.currencies?.find(
			(currency) => currency.currency === price?.currency
		)?.title ?? price?.currency;

	const handleOnClick = () => {
		if (!payment?.id) return;
		setPaymentMethod({ id: payment?.id });
	};

	const titleContent = getLocalizedValue(
		(payment?.name as LangValuePair[]) ?? []
	);

	const priceText =
		price?.valueWithVat === 0
			? t({ id: 'basket.price.free' })
			: !price?.valueWithVat
			? ''
			: t(
					{ id: 'price' },
					{
						value: createCurrencyValue(price?.valueWithVat),
						currency,
					}
			  );

	const value: ShippingItemProps = {
		onClick: handleOnClick,
		isLoading: payment === undefined,
		isSelected:
			!!paymentMethod?.id && !!payment?.id && paymentMethod?.id === payment?.id,
		titleContent,
		priceContent: priceText,
		selectable: true,
	};

	return value;
};
