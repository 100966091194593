import { useContext, useRef } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import {
	VinistoProductDllModelsApiBundleBundle,
	VinistoProductDllModelsApiTagTag,
} from 'vinisto_api_client/src/api-types/product-api';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import useBundlePrice from 'Hooks/useBundlePrice';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import BundleTag from 'Components/ProductBox/Components/BundleTag';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import ButtonAddToFavorites from 'Components/ProductBox/Components/ButtonAddToFavorites';
import { DirectQuantityBox } from 'Components/QuantityBox';
import ProductSpecifications from 'Components/ProductBox/Components/Specifications';
import ProductTitleAndImage from 'Components/ProductBox/Components/TitleAndImage';
import StockAvailability from 'Components/StockAvailability';
import quantityBoxStyles from 'Components/QuantityBox/styles.module.css';
import ProductBasketCount from 'Components/ProductBox/Components/ProductBasketCount';
import { useProductBasketCount } from 'Components/ProductBox/hooks';
import purgeHTML from 'Helpers/purgeHTML';
import useFavoriteItem from 'Components/ProductBox/Components/ButtonAddToFavorites/hook';

import { IBundleProps } from './Interfaces';
import styles from './style.module.css';

const BundleItem = (props: IBundleProps) => {
	const localizationContext = useContext(LocalizationContext);
	const warehouseContext = useContext(WarehouseContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const getBundlePrice = useBundlePrice();

	const bundleData = (props?.bundleData ??
		{}) as VinistoProductDllModelsApiBundleBundle;
	const bundleId = bundleData?.id ?? '';
	const bundleUrl = getLocalizedValue(bundleData?.url ?? []);
	const bundleDescription = getLocalizedValue(bundleData?.description ?? []);
	const specificationDetails = bundleData?.specificationDetails ?? [];
	const { shortVariety: producerName, component: flag } =
		getFlagSpecification(specificationDetails);

	const alwaysRedirectToDetail = props?.alwaysRedirectToDetail ?? false;
	const isTemporaryUnavailable = bundleData?.temporaryUnavailable ?? false;
	const isGift = bundleData?.isGift ?? false;

	const isLoading = props?.isLoading ?? false;

	const {
		lowestPrice,
		lowestPriceWithVat,
		discountedFromLowestPriceWithVat,
		currencyTitle,
	} = getBundlePrice(props?.bundleData ?? {});

	const priceWithoutVAT = createCurrencyValue(lowestPrice);
	const priceWithVAT = createCurrencyValue(lowestPriceWithVat);
	const discountedFromPriceWithVat = discountedFromLowestPriceWithVat;
	const discountedFromPriceWithVatFormatted = discountedFromPriceWithVat
		? createCurrencyValue(discountedFromLowestPriceWithVat)
		: null;

	const isSet = props?.bundleData?.isSet ?? false;
	const setBasePrice =
		props?.bundleData?.prices && props.bundleData.prices.length > 1
			? props.bundleData.prices[1]?.valueWithVat ?? 0
			: 0;

	const setBasePriceFormatted = createCurrencyValue(setBasePrice);

	const isSetDiscount = Boolean(
		(props?.bundleData?.prices?.[0]?.valueWithVat ?? 0) <
			(props?.bundleData?.prices?.[1]?.valueWithVat ?? 0)
	);

	const discountPercentage = Math.round(
		(((discountedFromPriceWithVat ?? 0) - lowestPriceWithVat) /
			(discountedFromPriceWithVat ?? 0)) *
			100
	);

	const setDiscountPercentage = Math.round(
		(((setBasePrice ?? 0) - lowestPriceWithVat) / (setBasePrice ?? 0)) * 100
	);

	const { basketQuantityPopover } = useProductBasketCount(bundleData);

	const availableQuantity = [warehouseContext.getQuantity(bundleId)].filter(
		(x): x is number => x !== undefined
	);

	const orderLimitation = bundleData?.orderLimitation;

	const { handleAddItemToFavorites, handleRemoveItemFromFavorites } =
		useFavoriteItem(bundleId, {
			item_name: getLocalizedValue(bundleData?.name),
			price: lowestPrice,
			currency: currencyTitle,
		});

	const bundleItemRef = useRef<HTMLDivElement | null>(null);

	return (
		<div
			className={cx('vinisto-wine__item', { loading: isLoading })}
			ref={bundleItemRef}
		>
			{isLoading ? (
				<ProductTitleAndImage
					isLoading
					bundleData={bundleData}
				/>
			) : (
				<>
					<Link
						className="vinisto-wine__item-overlay"
						to={`/${t({ id: 'routes.product.route' })}/${bundleUrl}`}
						rel="nofollow"
					/>
					<ProductBasketCount text={basketQuantityPopover} />
					<div className="vinisto-wine__img-labels">
						{!isLoading &&
							bundleData?.tagsDetail?.map(
								(
									tagDetail: VinistoProductDllModelsApiTagTag,
									index: number
								) => (
									<BundleTag
										{...{ tagDetail }}
										key={`category-detail-grid-tag-item-${
											tagDetail?.id ?? index
										}`}
										isLink={false}
									/>
								)
							)}
					</div>
					<Link
						to={`/${t({
							id: 'routes.product.route',
						})}/${bundleUrl}`}
						tabIndex={-1}
					>
						<ProductTitleAndImage bundleData={bundleData} />
					</Link>
					{!isTemporaryUnavailable && !isGift && (
						<ButtonAddToFavorites
							itemId={bundleId}
							addToFavorites={handleAddItemToFavorites}
							removeItemFromFavorites={handleRemoveItemFromFavorites}
						/>
					)}
				</>
			)}
			<BundleProducer
				flag={flag}
				name={producerName}
				isLoading={isLoading}
				isLink={false}
			/>
			<div className="vinisto-wine__specifications">
				<ProductSpecifications
					specifications={specificationDetails}
					isLoading={isLoading}
				/>
			</div>
			{isLoading ? (
				<Skeleton
					containerClassName="vinisto-wine__desc d-block"
					className="d-block h-100"
					inline
				/>
			) : (
				<>
					<p className="vinisto-wine__desc max-lines--4 ">
						{purgeHTML(bundleDescription)}
					</p>
				</>
			)}
			<div className="vinisto-wine__bottom-info">
				<div className="vinisto-wine__price">
					{isLoading ? (
						<Skeleton
							width="100%"
							inline
						/>
					) : (
						<>
							{!isSet && discountedFromPriceWithVatFormatted !== null && (
								<span className="vinisto-wine__price--original">
									{t(
										{ id: 'price' },
										{
											value: discountedFromPriceWithVatFormatted,
											currency: currencyTitle,
										}
									)}
								</span>
							)}
							{isSet && isSetDiscount && (
								<span className="vinisto-wine__price--original">
									{t(
										{ id: 'price' },
										{
											value: setBasePriceFormatted ?? 0,
											currency: currencyTitle,
										}
									)}
								</span>
							)}
							<div className={styles.discountPriceWrap}>
								<span>
									{t(
										{ id: 'price' },
										{
											value: priceWithVAT,
											currency: currencyTitle,
										}
									)}
								</span>
								{(setDiscountPercentage > 0 || discountPercentage > 0) && (
									<span className={styles.discountPercentage}>
										{`-${isSet ? setDiscountPercentage : discountPercentage} %`}
									</span>
								)}
							</div>
						</>
					)}
				</div>
				<div className="vinisto-wine__price--no-vat">
					{isLoading ? (
						<Skeleton
							width="100%"
							inline
						/>
					) : (
						t(
							{
								id: 'carousel.info.withoutVAT',
							},
							{
								priceWithCurrency: `${t(
									{ id: 'carousel.info.priceWithCurrency' },
									{ value: priceWithoutVAT, currency: currencyTitle }
								)}`,
							}
						)
					)}
				</div>
				<div className="vinisto-wine__stock">
					<StockAvailability
						availableQuantity={availableQuantity}
						deliveryDate={warehouseContext.deliveryDate}
						isTemporaryUnavailable={isTemporaryUnavailable}
						fallback={<Skeleton width="70px" />}
					/>
				</div>
				{!alwaysRedirectToDetail && !isTemporaryUnavailable && (
					<div className={cx(styles.aboveProductLink, styles.cta, 'mt-3')}>
						<DirectQuantityBox
							bundle={bundleData}
							className={quantityBoxStyles.fullWidth}
							orderLimitation={orderLimitation}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default BundleItem;
