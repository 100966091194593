import { VinistoOrderDllModelsApiPaymentPayment } from 'vinisto_api_client/src/api-types/order-api';

import styles from './styles.module.css';

const PaymentItem = ({
	payment,
}: {
	payment: VinistoOrderDllModelsApiPaymentPayment;
}) => {
	return (
		<div className={['test', styles.paymentItem].join(' ')}>
			{payment?.image?.domainUrls?.original_png && (
				<img
					src={payment.image.domainUrls.original_png}
					className={styles.paymentItemImage}
					alt=""
				/>
			)}
		</div>
	);
};

export default PaymentItem;
