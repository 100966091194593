import { AbstractAdapter } from '../abstract-adapter';
import { languages } from '../../shared';
import {
	bundleAdapter,
	bundleEvaluationAdapter,
	bundleItemAdapter,
	bundleSpecificationDetailAdapter,
	imageAdapter,
	orderLimitationAdapter,
	priceAdapter,
	productAdapter,
	supplierAdapter,
	tagAdapter,
} from '../../index';

import { Bundle } from '.';

import { VinistoProductDllModelsApiBundleBundle } from '@/api-types/product-api';

const mapApiPricesTBundlePrices = (
	apiData: VinistoProductDllModelsApiBundleBundle
) => {
	const { prices, isSet } = apiData;

	const basePrice = isSet
		? prices[1]
		: prices.filter((price) => price.type === 'STANDARD')[0];
	const discountedPrice = isSet
		? prices[0]
		: prices.filter(
				(price) =>
					price.type === 'VINISTO_DISCOUNT' ||
					price.type === 'SUPPLIER_DISCOUNT'
		  )[0];
	const discountDifferenceAsAmount =
		Number(discountedPrice?.valueWithVat) - Number(basePrice?.valueWithVat);
	const discountDifferenceAsPercentage =
		(discountDifferenceAsAmount / Number(basePrice?.valueWithVat)) * 100;
	const b2bPrices = prices.filter((price) =>
		price.type?.startsWith('B2b_LEVEL_')
	);

	return {
		basePrice: priceAdapter.fromApi(basePrice),
		discountedPrice: priceAdapter.fromApi(discountedPrice),
		b2bPrices: b2bPrices.map(priceAdapter.fromApi),
		discountDifferenceAsAmount: Number.isNaN(discountDifferenceAsAmount)
			? 0
			: discountDifferenceAsAmount,
		discountDifferenceAsPercentage: Number.isNaN(discountDifferenceAsPercentage)
			? 0
			: discountDifferenceAsPercentage,
		isDiscounted:
			!Number.isNaN(discountDifferenceAsAmount) &&
			discountDifferenceAsAmount < 0,
	};
};

class BundleAdapter extends AbstractAdapter<
	Bundle,
	VinistoProductDllModelsApiBundleBundle
> {
	fromApi(apiData: VinistoProductDllModelsApiBundleBundle): Bundle {
		const id = apiData.id;

		if (!id) throw new Error('No id in bundle');

		const orderLimitation =
			apiData.orderLimitation && apiData.orderLimitation.limit !== undefined
				? orderLimitationAdapter.fromApi(apiData.orderLimitation ?? {})
				: undefined;

		return {
			id,
			name: this.convertMultiLangValue(apiData.name),
			description: this.convertMultiLangValue(apiData.description),
			shortDescription: this.convertMultiLangValue(apiData.shortDescription),
			keywords: this.convertMultiLangValue(apiData.keywords),
			text: this.convertMultiLangValue(apiData.text),
			url: this.convertMultiLangValue(apiData.url),
			language: apiData.language ?? languages[0],
			prices: apiData.prices.map((price) => priceAdapter.fromApi(price)),
			bundlePrices: mapApiPricesTBundlePrices(apiData),
			items: apiData.items.map((item) => bundleItemAdapter.fromApi(item)),
			categoryIds: apiData.categories, // TODO: @hynek refaktorovat na categories
			products: apiData.productsDetail.map((product) =>
				productAdapter.fromApi(product)
			),
			setBundles: apiData.setBundles?.map((bundle) =>
				bundleAdapter.fromApi(bundle)
			),
			alternativeBundles: apiData.alternativeBundleObjects?.map((bundle) =>
				bundleAdapter.fromApi(bundle)
			),
			tags: apiData.tagsDetail.map((tag) => tagAdapter.fromApi(tag)),
			images: apiData.images
				.map((image) => imageAdapter.fromApi(image))
				.filter((images) => Object.keys(images).length),
			supplier: apiData.supplier
				? supplierAdapter.fromApi(apiData.supplier)
				: null,
			specificationDetails: apiData.specificationDetails?.map((spec) =>
				bundleSpecificationDetailAdapter.fromApi(spec)
			),
			bundleEvaluation: bundleEvaluationAdapter.fromApi(
				apiData.bundleEvaluation ?? {}
			),
			orderLimitation,

			scoring: apiData.scoring ?? 0,
			scoringWarehouse: apiData.scoringWarehouse ?? 0,
			scoringDiscount: apiData.scoringDiscount ?? 0,
			scoringAdmin: apiData.scoringAdmin ?? 0,
			piecesPerPackage: apiData.piecesPerPackage ?? 0,
			packagesOnPallet: apiData.packagesOnPallet ?? 0,
			flags: {
				isSet: apiData.isSet ?? false,
				isB2B: apiData.isB2b ?? false,
				isClearanceSale: apiData.isClearanceSale ?? false,
				isDeliveryFree: apiData.isDeliveryFree ?? false,
				isDeleted: apiData.isDeleted ?? false,
				isEnabled: apiData.isEnabled ?? false,
				isForLogged: apiData.isForLogged ?? false,
				isGift: apiData.isGift ?? false,
				isTemporaryUnavailable: apiData.temporaryUnavailable ?? false,
				canSendToWms: apiData.canSendToWms ?? false,
				isSaleOver: apiData.isSaleOver ?? false,
			},
		};
	}
}

export default BundleAdapter;
