import GiftProgressBar from '../../components/gift-progress-bar';
import GiftPurchase from '../../components/gift-purchase';
import GiftProductListView from '../gift-product-list';

import { GiftPurchaseListProps } from './types';
import styles from './styles.module.css';

const GiftPurchaseListView = ({
	translations,
	giftProductsProps,
	giftProgressBarProps,
	giftPurchaseProps,
}: GiftPurchaseListProps) => {
	return (
		<div>
			{giftProductsProps?.giftProducts.length > 0 && (
				<GiftProductListView
					giftProducts={giftProductsProps?.giftProducts ?? []}
					translations={{ title: giftProductsProps.translations.title }}
				/>
			)}
			<p className={styles.giftHeading}>{translations.title}</p>
			<GiftProgressBar {...giftProgressBarProps} />
			<div>
				{giftPurchaseProps.map((giftPurchaseProps, key) => (
					<GiftPurchase
						{...giftPurchaseProps}
						key={key}
					/>
				))}
			</div>
		</div>
	);
};

export default GiftPurchaseListView;
