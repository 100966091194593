import { FC, useContext } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import Flag from 'Components/Flag';
import { IOrderItemDataProps } from 'Pages/CartConfirmation/Components/OrderItem/interfaces';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import { getCountryCode } from 'Helpers/getFlagSpecification';
import useLocalizedCurrency from 'Hooks/useLocalizedCurrency';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';

const OrderItem: FC<IOrderItemDataProps> = ({
	orderItemData: { bundle, quantity },
	isGift,
}) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getLocalizedCurrency = useLocalizedCurrency();
	const deviceServiceContext = useContext(DeviceServiceContext);
	const isDesktop = deviceServiceContext.isDesktop;

	const discountPriceValueWithVAT = bundle.discountPrice?.valueWithVat ?? 0;

	const discountPriceValueWithoutVAT = bundle.discountPrice?.value ?? 0;

	const priceValueWithVAT = bundle.price?.valueWithVat ?? 0;
	const priceValueWithoutVAT = bundle.price?.value ?? 0;

	const priceCurrency = [bundle.price?.currency];

	const producerSpecificationValue = bundle.producerSpecification?.value ?? '';

	const countrySpecification = bundle.countrySpecification ?? '';

	const supplierName = bundle.supplierDetail?.nameWeb ?? '';

	const isLoading = bundle === null;

	const localizedCurrency = getLocalizedCurrency([priceCurrency])?.title;

	const priceWithVAT = createCurrencyValue(
		bundle.discountPrice !== null
			? discountPriceValueWithVAT
			: priceValueWithVAT
	);

	const totalPriceWithoutVAT = createCurrencyValue(
		bundle.discountPrice !== null
			? discountPriceValueWithoutVAT
			: priceValueWithoutVAT * quantity
	);

	const totalPriceWithVAT = createCurrencyValue(
		bundle.discountPrice !== null
			? discountPriceValueWithVAT * quantity
			: priceValueWithVAT * quantity
	);

	return (
		<>
			<div className="vinisto-user-orders__orders__order-body__item vinisto-cart__item vinisto-order-summary desktop-only">
				<div className="vinisto-user-orders__orders__order-body__item__info">
					<div className="vinisto-user-orders__orders__order-body__item__info__img">
						{isLoading ? (
							<Skeleton
								height="80px"
								width="80%"
							/>
						) : (
							<img
								src={bundle.mainImage?.domainUrls?.thumb_64x80}
								alt={`${t({ id: 'alt.bundleImage' })}`}
							/>
						)}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__data">
						<div className="vinisto-user-orders__orders__order-body__item__info__data__name">
							{isLoading ? <Skeleton /> : bundle.name}
						</div>
						{isLoading ? (
							<Skeleton width="90%" />
						) : (
							<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
								<BundleProducer
									flag={
										<Flag
											code={getCountryCode(countrySpecification)}
											width="24"
											className="vinisto-flag"
										/>
									}
									name={producerSpecificationValue}
								/>
							</div>
						)}
					</div>
				</div>

				<div>
					{isLoading ? (
						<Skeleton
							count={1.6}
							width="80px"
						/>
					) : (
						<>
							<div className="vinisto-user-orders__orders__order-body__item__prices__total">
								<span className="fw-bolder">
									<span className="fw-light">{quantity} x </span>
									{`${t(
										{ id: 'price' },
										{
											value: priceWithVAT,
											currency: localizedCurrency,
										}
									)}`}
								</span>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__prices__total mt-1">
								{t(
									{ id: 'bundle.supplierSmall.name' },
									{
										name: (
											<span className="vinisto-color-success fw-bolder">
												{supplierName.length > 0
													? supplierName
													: t({
															id: 'productDetail.seller.name.others',
													  })}
											</span>
										),
									}
								)}
							</div>
						</>
					)}
				</div>

				<div className="vinisto-cart__item__price">
					<div className="vinisto-user-orders__orders__order-body__item__prices">
						{isLoading ? (
							<Skeleton
								count={1.6}
								width="80px"
							/>
						) : !isGift ? (
							<>
								<div className="vinisto-user-orders__orders__order-body__item__prices__total">
									{`${t({ id: 'basket.totalPrice' })} `}
									<span
										className={cx('fw-bold', {
											'vinisto-user-orders__orders__order-body__item__prices__big-price':
												isDesktop,
										})}
									>
										{`${t(
											{ id: 'price' },
											{
												value: totalPriceWithVAT,
												currency: localizedCurrency,
											}
										)}`}
									</span>
								</div>

								<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
									{`${t({ id: 'basket.priceWithoutVAT' })} `}
									<span className="fw-bolder price-span">
										{`${t(
											{ id: 'price' },
											{
												value: totalPriceWithoutVAT,
												currency: localizedCurrency,
											}
										)}`}
									</span>
								</div>
							</>
						) : (
							<>
								<div className="vinisto-user-orders__orders__order-body__item__prices__total fw-bold vinisto-user-orders__orders__order-body__item__prices__big-price">
									{`${t({ id: 'basket.price.free' })} `}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<div className="vinisto-user-favorite vinisto-cart__item--mobile tablet-mobile-only">
				<div className="vinisto-user-orders__orders__order-body">
					<div className="vinisto-user-orders__orders__order-body__item">
						<div className="vinisto-user-orders__orders__order-body__item__close"></div>
						<div className="vinisto-user-orders__orders__order-body__item__info__img">
							{isLoading ? (
								<Skeleton
									height="80px"
									width="80%"
								/>
							) : (
								<img
									src={bundle.mainImage?.domainUrls?.thumb_64x80}
									alt={`${t({ id: 'alt.bundleImage' })}`}
								/>
							)}
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__info vinisto-cart-item-info">
							<div className="vinisto-user-orders__orders__order-body__item__info__data">
								<div className="vinisto-user-orders__orders__order-body__item__info__data__name mb-0">
									{isLoading ? <Skeleton /> : bundle.name}
								</div>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__info__data w-100">
								<div className="w-100">
									<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
										{isLoading ? (
											<Skeleton width="90%" />
										) : (
											<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
												<BundleProducer
													flag={
														<Flag
															code={getCountryCode(countrySpecification)}
															width="24"
															className="vinisto-flag"
														/>
													}
													name={producerSpecificationValue}
												/>
											</div>
										)}
									</div>
								</div>
								<div></div>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__info__data mt-2 flex-wrap align-items-start">
								<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat d-flex flex-column">
									<div className="vinisto-order-finish__count">
										{t({ id: 'carousel.info.pcs' }, { count: quantity })}
									</div>
									<div className="tablet-mobile-only">
										{t(
											{ id: 'bundle.supplierSmall.name' },
											{
												name: (
													<span className="vinisto-color-success fw-bolder">
														{supplierName.length > 0
															? supplierName
															: t({
																	id: 'productDetail.seller.name.others',
															  })}
													</span>
												),
											}
										)}
									</div>
								</div>
								{!isGift ? (
									<div className="vinisto-user-orders__orders__order-body__item__prices">
										<div className="vinisto-user-orders__orders__order-body__item__prices__total">
											<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
												{`${t(
													{ id: 'price' },
													{
														value: priceWithVAT,
														currency: localizedCurrency,
													}
												)}`}
											</span>
										</div>
										<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
											{`${t({ id: 'basket.priceWithoutVAT' })} `}
											<span className="fw-bolder price-span">
												{`${t(
													{ id: 'price' },
													{
														value: totalPriceWithoutVAT,
														currency: localizedCurrency,
													}
												)}`}
											</span>
										</div>
									</div>
								) : (
									<div className="vinisto-user-orders__orders__order-body__item__prices">
										<div className="vinisto-user-orders__orders__order-body__item__prices__total">
											<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
												{`${t({ id: 'basket.price.free' })}`}
											</span>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OrderItem;
