import { FC, useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import { LOCAL_ENVIRONMENT_KEYS } from 'Services/EnvironmentProvider/constants';

import { IIconProps } from './Interfaces';

const VinistoLogoIcon: FC<IIconProps> = ({ id, className, environment }) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	let slogan = t({ id: 'logo.slogan' });
	let logoColor = '#4d4d4e';

	if (environment === LOCAL_ENVIRONMENT_KEYS.LOCALHOST) {
		slogan = 'LOCALHOST preview';
		logoColor = '#ff0000';
	} else if (environment === LOCAL_ENVIRONMENT_KEYS.DEV) {
		slogan = 'developer preview';
		logoColor = '#c9062e';
	}

	return (
		<svg
			width={145.74}
			height={35}
			viewBox="0 0 145.74 35"
			className={className}
		>
			<g transform="translate(-2274.367 -961.636)">
				<text
					id={id}
					transform="translate(2313.107 994.636)"
					fill={logoColor}
					fontSize="7.063"
					fontFamily="Roboto-Bold, Roboto"
					fontWeight="700"
					letterSpacing="0.025em"
				>
					<tspan
						x="0"
						y="0"
					>
						{slogan}
					</tspan>
				</text>
				<g transform="translate(2274.367 965.426)">
					<path
						d="M2298.532,969.334"
						transform="translate(-2274.367 -965.426)"
						fill={logoColor}
					/>
					<path
						d="M2281.8,969.334"
						transform="translate(-2274.367 -965.426)"
						fill={logoColor}
					/>
					<path
						d="M2281.8,969.519"
						transform="translate(-2274.367 -965.426)"
						fill={logoColor}
						stroke="#680b19"
						strokeLinejoin="round"
						strokeWidth="0.45"
					/>
					<path
						d="M2298.532,969.519"
						transform="translate(-2274.367 -965.426)"
						fill={logoColor}
						stroke="#680b19"
						strokeLinejoin="round"
						strokeWidth="0.45"
					/>
					<path
						d="M2297.576,984.864l-4.157,1.567a.1.1,0,0,1-.1-.168l3.435-2.817c-.048-.034-.088-.078-.139-.108l-5.471-3.158a1.961,1.961,0,0,0-1.963,0l-5.471,3.158a1.965,1.965,0,0,0-.981,1.7v6.317a1.964,1.964,0,0,0,.981,1.7l5.528,3.192.01-.005c.147-2.241.467-7.153.5-7.623a.349.349,0,0,0-.109-.286l-1.519-1.025-2.031-1.362a.1.1,0,0,1,.1-.17l2.211,1.048c.284.139,1,.471,1.277.6a4.1,4.1,0,0,1,.462.235,1.331,1.331,0,0,1,.546,1.172c0,.078.283,5.116.409,7.382l.025.013,5.5-3.175a1.962,1.962,0,0,0,.981-1.7v-6.317C2297.6,984.979,2297.581,984.923,2297.576,984.864Z"
						transform="translate(-2274.367 -965.512)"
						fill={logoColor}
					/>
					<path
						d="M2305.943,970.373l-4.158,1.566a.1.1,0,0,1-.1-.167l3.435-2.817c-.048-.034-.088-.078-.14-.108l-5.47-3.158a1.963,1.963,0,0,0-1.964,0l-5.47,3.158a1.963,1.963,0,0,0-.982,1.7v6.317a1.963,1.963,0,0,0,.982,1.7l5.528,3.191.01-.005c.146-2.24.467-7.153.5-7.622a.352.352,0,0,0-.11-.287l-1.518-1.025-2.031-1.361a.1.1,0,0,1,.1-.17l2.211,1.047c.284.14,1,.471,1.276.6a4.133,4.133,0,0,1,.463.235,1.328,1.328,0,0,1,.545,1.171c0,.079.284,5.117.409,7.383l.026.013,5.5-3.176a1.961,1.961,0,0,0,.981-1.7v-6.317C2305.966,970.488,2305.948,970.432,2305.943,970.373Z"
						transform="translate(-2274.367 -965.426)"
						fill={logoColor}
					/>
					<path
						d="M2289.211,970.373l-4.157,1.566a.1.1,0,0,1-.1-.167l3.435-2.817c-.048-.034-.088-.078-.139-.108l-5.471-3.158a1.961,1.961,0,0,0-1.963,0l-5.471,3.158a1.965,1.965,0,0,0-.981,1.7v6.317a1.964,1.964,0,0,0,.981,1.7l5.528,3.191.01-.005c.147-2.24.467-7.153.5-7.622a.35.35,0,0,0-.109-.287l-1.519-1.025-2.031-1.361a.1.1,0,0,1,.1-.17l2.212,1.047c.283.14,1,.471,1.276.6a4.218,4.218,0,0,1,.463.235,1.33,1.33,0,0,1,.545,1.171c0,.079.283,5.117.409,7.383l.026.013,5.5-3.176a1.959,1.959,0,0,0,.981-1.7v-6.317C2289.234,970.488,2289.216,970.432,2289.211,970.373Z"
						transform="translate(-2274.367 -965.426)"
						fill={logoColor}
					/>
				</g>
				<g transform="translate(2312.594 961.636)">
					<path
						d="M2328.434,966.94a86.263,86.263,0,0,1-3.014,9.225q-1.729,4.414-3.344,7.906h-2.767q-1.616-3.49-3.344-7.906a86.208,86.208,0,0,1-3.014-9.225h3.294q.4,1.616.939,3.443t1.153,3.624q.609,1.8,1.235,3.475t1.186,3q.561-1.317,1.186-3t1.236-3.475q.609-1.8,1.153-3.624t.939-3.443Z"
						transform="translate(-2312.951 -961.636)"
						fill={logoColor}
					/>
					<path
						d="M2336.308,984.071h-3.064V966.94h3.064Z"
						transform="translate(-2312.951 -961.636)"
						fill={logoColor}
					/>
					<path
						d="M2343.226,967.434q1.054-.263,2.8-.56a24.3,24.3,0,0,1,4.019-.3,8.692,8.692,0,0,1,3.394.576,5.321,5.321,0,0,1,2.158,1.615,6.257,6.257,0,0,1,1.136,2.487,14.475,14.475,0,0,1,.329,3.2v9.62H2354V975.11a14.683,14.683,0,0,0-.214-2.7,4.563,4.563,0,0,0-.709-1.812,2.808,2.808,0,0,0-1.317-1.005,5.812,5.812,0,0,0-2.043-.313c-.329,0-.67.012-1.021.033s-.687.05-1.005.083-.6.071-.857.115-.434.077-.543.1v14.462h-3.064Z"
						transform="translate(-2312.951 -961.636)"
						fill={logoColor}
					/>
					<path
						d="M2366.847,984.071h-3.064V966.94h3.064Z"
						transform="translate(-2312.951 -961.636)"
						fill={logoColor}
					/>
					<path
						d="M2377.751,981.864a6.04,6.04,0,0,0,2.784-.494,1.674,1.674,0,0,0,.906-1.581,2.1,2.1,0,0,0-.89-1.779,14.049,14.049,0,0,0-2.932-1.483q-.988-.4-1.894-.807a6.606,6.606,0,0,1-1.565-.972,4.21,4.21,0,0,1-1.054-1.351,4.307,4.307,0,0,1-.4-1.943,4.363,4.363,0,0,1,1.681-3.608,7.17,7.17,0,0,1,4.579-1.334,13.029,13.029,0,0,1,1.449.082q.725.082,1.351.2t1.1.247q.477.132.741.231l-.56,2.635a7.737,7.737,0,0,0-1.548-.543,9.989,9.989,0,0,0-2.537-.28,4.685,4.685,0,0,0-2.24.51,1.692,1.692,0,0,0-.956,1.6,2.173,2.173,0,0,0,.214.988,2.293,2.293,0,0,0,.659.775,5.464,5.464,0,0,0,1.1.642q.658.3,1.581.626,1.219.462,2.175.906a6.777,6.777,0,0,1,1.63,1.038,3.985,3.985,0,0,1,1.038,1.433,5.206,5.206,0,0,1,.362,2.059,4.086,4.086,0,0,1-1.762,3.591,8.779,8.779,0,0,1-5.024,1.219,13.145,13.145,0,0,1-3.558-.379q-1.286-.379-1.746-.577l.56-2.635q.526.2,1.68.593A9.714,9.714,0,0,0,2377.751,981.864Z"
						transform="translate(-2312.951 -961.636)"
						fill={logoColor}
					/>
					<path
						d="M2392.971,966.94h6.49v2.57h-6.49v7.906a9.5,9.5,0,0,0,.2,2.125,3.171,3.171,0,0,0,.593,1.318,2.121,2.121,0,0,0,.988.675,4.374,4.374,0,0,0,1.384.2,6.492,6.492,0,0,0,2.223-.313q.84-.312,1.17-.444l.593,2.536a11.446,11.446,0,0,1-1.614.577,9.208,9.208,0,0,1-2.636.346,8,8,0,0,1-2.883-.445,4.2,4.2,0,0,1-1.828-1.334,5.35,5.35,0,0,1-.972-2.191,14.422,14.422,0,0,1-.28-3.015V962.163l3.064-.527Z"
						transform="translate(-2312.951 -961.636)"
						fill={logoColor}
					/>
					<path
						d="M2419.656,975.506a10.844,10.844,0,0,1-.593,3.69,8.432,8.432,0,0,1-1.664,2.833,7.325,7.325,0,0,1-2.553,1.828,8.438,8.438,0,0,1-6.457,0,7.318,7.318,0,0,1-2.553-1.828,8.432,8.432,0,0,1-1.664-2.833,11.724,11.724,0,0,1,0-7.363,8.4,8.4,0,0,1,1.664-2.85,7.321,7.321,0,0,1,2.553-1.829,8.439,8.439,0,0,1,6.457,0,7.328,7.328,0,0,1,2.553,1.829,8.4,8.4,0,0,1,1.664,2.85A10.88,10.88,0,0,1,2419.656,975.506Zm-3.2,0a7.374,7.374,0,0,0-1.3-4.6,4.544,4.544,0,0,0-7.083,0,8.768,8.768,0,0,0,0,9.192,4.546,4.546,0,0,0,7.083,0A7.374,7.374,0,0,0,2416.46,975.506Z"
						transform="translate(-2312.951 -961.636)"
						fill={logoColor}
					/>
				</g>
			</g>
		</svg>
	);
};

export default VinistoLogoIcon;
