import { useCallback, useContext, useEffect } from 'react';
import cx from 'classnames';
import userSectionStyles from 'Pages/UserSection/styles.module.css';
import { LocalizationContext } from 'Services/LocalizationService';
import { PHONE_NUMBER } from 'Components/Navbar/constants';
import { formatPhoneNumber } from 'Components/Navbar/helpers';
import Config from 'Config';
import PhoneIcon from 'Components/Icons/Phone';
import MailIcon from 'Components/Icons/Mail';
import ChatDarkIcon from 'Components/Icons/ChatDark';
import useChat from 'Hooks/useChat';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { useQuery } from '@tanstack/react-query';
import { OrderService } from 'vinisto_api_client';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { dayjsInstance } from 'vinisto_shared';
import { Link } from 'react-router-dom';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import { DeviceServiceContext } from 'Services/DeviceService';
import { OrderItem } from 'vinisto_ui';
import useAddItemsToBasket, {
	showBuyAgainButton,
} from 'Hooks/use-add-items-to-basket';
import { IBundleChange } from 'Services/BasketService/interfaces';

import UserTileMenu from '../UserTileMenu';
import { getLocalizedOrderState } from '../Orders/helpers';

import UserCarousel from './Carousel';
import styles from './styles.module.css';

import { VinistoHelperDllEnumsOrderOrderState } from '@/api-types/order-api';

const activeOrderStates = [
	VinistoHelperDllEnumsOrderOrderState.CREATED,
	VinistoHelperDllEnumsOrderOrderState.PAID,
	VinistoHelperDllEnumsOrderOrderState.IN_WMS,
	VinistoHelperDllEnumsOrderOrderState.WMS_ACCEPTED,
	VinistoHelperDllEnumsOrderOrderState.WMS_INCOMPLETE,
	VinistoHelperDllEnumsOrderOrderState.WMS_READY,
	VinistoHelperDllEnumsOrderOrderState.SENT,
];

const UserSectionDashboard = () => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const auth = useContext(AuthenticationContext);
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const { isDesktop } = useContext(DeviceServiceContext);

	const { openChat } = useChat();

	const { addItemsToBasket } = useAddItemsToBasket();

	const documentTitle = `${t(
		{ id: 'app.title.page' },
		{ title: `${t({ id: 'routes.user-section.name' })}` }
	)}`;

	const updateDocumentTitle = useCallback(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: documentTitle,
		});
	}, [documentHeaderContext, documentTitle]);

	useEffect(() => {
		updateDocumentTitle;
	}, [updateDocumentTitle]);

	const req = {
		UserId: auth.vinistoUser.id ?? '',
		UserLoginHash: auth.vinistoUser.loginHash,
		OrderStates: activeOrderStates,
		IsSortingDescending: true,
	};
	const activeOrdersQueryKey = ['userActiveOrders', req];

	const { data, isLoading, isError } = useQuery(activeOrdersQueryKey, {
		queryFn: async () => OrderService.getOrderList(req),
	});

	if (isLoading || isError) {
		return <></>;
	}

	return (
		<>
			<UserTileMenu />
			<h2 className={userSectionStyles.userSectionHeader}>
				{t({ id: 'userSection.dashboard.activeOrders' })}
			</h2>
			<div>
				{data.length === 0 ? (
					<div className={styles.noOrders}>
						V tuto chvíli nemáte žádné probíhající objednávky
					</div>
				) : (
					data.map((order) => {
						const formattedDate = dayjsInstance
							.unix(order.stateChangeRecords?.[0].changeTime ?? 0)
							.format('DD.MM.YYYY');

						const totalPrice = createCurrencyValue(
							order.orderPriceWithVat ?? 0
						);

						const itemsToAdd: IBundleChange[] = order?.orderItems
							?.filter((orderItem: any) => !orderItem.bundle.isGift)
							.map((orderItem: any) => {
								const id = orderItem?.bundle?.id ?? '';

								return {
									bundleId: id,
									quantity: orderItem.quantity,
								};
							});

						return (
							<OrderItem
								key={order.id}
								id={order.id}
								internalId={order.orderNumber ?? ''}
								totalPrice={totalPrice}
								state={
									t({
										id: getLocalizedOrderState(order?.state ?? '').text,
									}) as string
								}
								color={getLocalizedOrderState(order?.state ?? '').color}
								createdAtDate={formattedDate}
								trackingLink={order.delivery?.trackingUrl ?? ''}
								isDesktop={isDesktop}
								showBuyAgainButton={showBuyAgainButton(order?.state ?? '')}
								addItemsToBasket={addItemsToBasket}
								itemsToAdd={itemsToAdd}
								orderNumberAsLink={false}
								isLoading={isLoading}
							/>
						);
					})
				)}
			</div>
			<h2 className={userSectionStyles.userSectionHeader}>
				{t({ id: 'userSection.dashboard.carouselHeading' })}
				<Link
					to="/stitek/pro-cleny"
					className={styles.linkAll}
				>
					{t({ id: 'userSection.dashboard.showAll' })}
				</Link>
			</h2>
			<UserCarousel />
			<h2
				className={cx(
					userSectionStyles.userSectionHeader,
					styles.contactsHeader
				)}
			>
				{t({ id: 'userSection.dashboard.weAreHereForYou' })}
			</h2>
			<div className={styles.contactsGrid}>
				<a
					href={`tel:${PHONE_NUMBER}`}
					className={styles.contact}
				>
					<div className={styles.icon}>
						<PhoneIcon />
					</div>
					<div className={styles.heading}>
						{t({ id: 'userSection.dashboard.customerPhone' })}:
					</div>
					<div className={styles.value}>{formatPhoneNumber(PHONE_NUMBER)}</div>
				</a>
				<a
					href={`mailto:${Config.market.supportEmail}`}
					className={styles.contact}
				>
					<div className={styles.icon}>
						<MailIcon />
					</div>
					<div className={styles.heading}>
						{t({ id: 'userSection.dashboard.email' })}:
					</div>
					<div className={styles.value}>{Config.market.supportEmail}</div>
				</a>
				<button
					className={styles.contact}
					onClick={() => {
						openChat();
					}}
				>
					<div className={styles.icon}>
						<ChatDarkIcon />
					</div>

					<div className={styles.heading}>
						{t({ id: 'userSection.dashboard.chat' })}:
					</div>
					<div className={styles.value}>
						{t({ id: 'userSection.dashboard.writeUs' })}
					</div>
				</button>
			</div>
		</>
	);
};

export default UserSectionDashboard;
