import { useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';
import useTodayTomorrowDate from 'Hooks/useTodayTomorrowDate/useTodayTomorrowDate';

import { SellInfoProps } from './interfaces';
import styles from './styles.module.css';

const SellInfo = ({ supplierName, deliveryDate }: SellInfoProps) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const getDateLabel = useTodayTomorrowDate();

	return (
		<div>
			<div className={styles.mobileShopInfo}>
				<div className={styles.seller}>
					<span className={styles.sellerTitle}>{`${t({
						id: 'productDetail.seller.name',
					})}: `}</span>
					<span className={styles.sellerName}>
						{supplierName.length > 0
							? supplierName
							: t({
									id: 'productDetail.seller.name.others',
							  })}
					</span>
				</div>

				<div className={styles.mobileSender}>
					{t(
						{
							id: 'bundle.warehouse.from',
						},
						{
							value: (
								<span className={styles.senderName}>
									{t({
										id: 'bundle.warehouse.from.vinisto',
									})}
								</span>
							),
						}
					)}
				</div>

				<br />

				<div className={styles.availableCount}>
					{t(
						{
							id: 'bundle.supplier.avaibility.available.inThumbnail',
						},
						{
							value: (
								<span className={`${styles.textGreen}`}>
									{t({
										id: 'bundle.supplier.avaibility.centralWarehouse',
									})}
								</span>
							),
						}
					)}
				</div>

				<div className={styles.deliveryDate}>
					{t(
						{ id: 'bundle.supplier.deliveryDate' },
						{
							date: (
								<span
									className={`${styles.deliveryDateValue} ${styles.textGreen}`}
								>
									{getDateLabel(deliveryDate ?? '')}
								</span>
							),
						}
					)}
				</div>
			</div>
		</div>
	);
};
export default SellInfo;
