import { useQuery } from '@tanstack/react-query';
import Carousel from 'Components/Carousel';
import { HOME_PAGE_PRODUCTS_CAROUSEL } from 'Components/Carousel/constants';
import { CARD_TYPE } from 'Components/Carousel/interfaces';
import DeliveryMethods from 'Components/DeliveryMethods';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import FreeDeliveryProgressBar from 'Components/FreeDeliveryProgressBar';
import { QuantityBoxTypes } from 'Components/QuantityBox/constants';
import Loader from 'Components/View/Loader';
import { TEST_IDS } from 'Constants/test-ids';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { LangValuePair } from 'Hooks/useLocalizedValue/interfaces';
import { filter, get, map, orderBy, size, uniqBy, uniqueId } from 'lodash-es';
import PaymentItem from 'Pages/Bundle/Components/BundleDetail/Components/PaymentItem';
import {
	fetchDeliveriesData,
	fetchPaymentsData,
} from 'Pages/Bundle/Components/BundleDetail/helpers';
import {
	ALLOWED_COUNTRY,
	CURRENCY,
	LANGUAGE,
	LIMIT,
} from 'Pages/Bundle/constants';
import GiftInfo from 'Pages/Cart/Components/GiftInfo';
import GiftItemDesktop from 'Pages/Cart/Components/GiftItemDesktop';
import GiftItemMobile from 'Pages/Cart/Components/GiftItemMobile';
import { sortGiftsByorderPriceLimitFrom } from 'Pages/Cart/helpers';
import { AssignedGiftBundles, BundleName } from 'Pages/Cart/interfaces';
import {
	CTA_VISIBILITY_THRESHOLD,
	SKELETONS_NUM_ITEMS,
} from 'Pages/CartShippingData/constants';
import {
	lazy,
	MouseEvent,
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useInViewport } from 'react-in-viewport';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { VinistoBasketDllModelsApiBasketBasketDiscountCoupon } from 'vinisto_api_client/src/api-types/basket-api';
import { IQueryArgument } from 'Services/ApiService/interfaces';
import { VinistoOrderDllModelsApiPaymentPayment } from 'vinisto_api_client/src/api-types/order-api';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';
import { IBundleChange } from 'Services/BasketService/interfaces';
import BundleService from 'Services/Bundle';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
const CloseIcon = lazy(() => import('Components/Icons/Close'));
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import { GaItem } from 'Hooks/useAnalytics/types';
import '../UserSection/Orders/styles.css';
import useAnalytics from 'Hooks/useAnalytics';
import { DeliveryMethodsPlacement } from 'Components/DeliveryMethods/types';

import ActiveCartItem from './Components/ActiveCartItem';
import ActiveCartItemMobile from './Components/ActiveCartItemMobile';
import CartHeader from './Components/CartHeader';
import CouponForm from './Components/CouponForm';
import CouponItem from './Components/CouponItem';
import InActiveCartItem from './Components/InActiveCartItem';
import InActiveCartItemMobile from './Components/InActiveCartItemMobile';
import OutOfStockCartItemList from './Components/OutOfStockCartItemList';
import { useOosItems } from './hooks';
import styles from './styles.module.css';

import './styles.css';

const Cart = () => {
	const basketContext = useContext(BasketContext);
	const {
		basketState,
		bulkUpdate,
		setAreGiftsRefused,
		basketPrice,
		isLoading: isFetching,
		basketItemsWithTemp: basketItemsWithTempFromContext,
	} = useContext(BasketContext);

	const { isDesktop, isTablet, isMobile, footerHeight, headerHeight } =
		useContext(DeviceServiceContext);
	const documentHeaderContext = useContext(DocumentHeaderContext);
	const localizationContext = useContext(LocalizationContext);
	const { activeCurrency, useFormatMessage } = localizationContext;

	const { isLoggedIn, anonymousUID, vinistoUser } = useContext(
		AuthenticationContext
	);
	const { fetchQuantity } = useContext(WarehouseContext);

	const t = useFormatMessage();
	const history = useNavigate();
	const getLocalizedValue = useLocalizedValue();

	// Dont ask me, just refactor it.
	const giftInfoOpen = false;

	// is it necessary to memoize this?
	const basketItems = useMemo(
		() => basketItemsWithTempFromContext?.items ?? [],
		[basketItemsWithTempFromContext]
	);

	const isLoading = isFetching && basketItems?.length === 0;

	const {
		basketItemsOutOfStock,
		setBasketItemsOutOfStock,
		totalPriceBasketOos,
		totalStandardPriceBasketOos,
		handleRemoveOosItemFromBasket,
	} = useOosItems(basketItems);

	const basketItemsWithTemp: Record<any, unknown>[] = isLoading
		? map(Array(SKELETONS_NUM_ITEMS), () => ({}))
		: basketItems.filter(
				(basketItem: Record<any, any>) =>
					basketItemsOutOfStock.has(basketItem.bundleId) === false
		  );

	const orderableItems = useMemo(() => {
		if (isLoading || !basketItemsWithTemp || basketItemsWithTemp?.length === 0)
			return [];
		return basketItemsWithTemp?.filter((item: Record<string, any>) => {
			return !basketItemsOutOfStock.has(item.bundleId);
		});
	}, [basketItemsOutOfStock, basketItemsWithTemp, isLoading]);

	const isBasketEmpty =
		!isLoading && (!orderableItems || orderableItems.length === 0);

	const basketStandardPriceWithVat: number =
		basketContext.basketStandardPriceWithVat -
		totalStandardPriceBasketOos.sumWithVat;

	const basketStandardPriceWithVatCurrency =
		createCurrencyValue(basketStandardPriceWithVat) +
		' ' +
		localizationContext.activeCurrency.title;

	const totalBasketPriceWithVat: number =
		(basketContext.basketState?.discountCoupons?.discountedBasketPriceWithVat ??
			basketContext.basketPriceWithVat) - totalPriceBasketOos.sumWithVat;

	const totalBasketPriceWithoutVat: number =
		(basketContext.basketState?.discountCoupons?.discountedBasketPrice ??
			basketContext.basketPrice) - totalPriceBasketOos.sumWithoutVat;

	const totalBasketPriceWithVatCurrency =
		createCurrencyValue(totalBasketPriceWithVat) +
		' ' +
		localizationContext.activeCurrency.title;

	const totalBasketPriceWithoutVatCurrency =
		createCurrencyValue(totalBasketPriceWithoutVat) +
		' ' +
		localizationContext.activeCurrency.title;

	const discountCoupons =
		basketContext.basketState?.discountCoupons?.discountCoupons;

	const areGiftsRefused = basketState?.areGiftsRefused;

	const handleOnSetAreGiftsRefused = () => {
		const requestParams = isLoggedIn
			? {
					userLoginHash: vinistoUser.loginHash,
					areGiftsRefused: !basketState?.areGiftsRefused,
			  }
			: {
					anonymousUserId: anonymousUID.anonymousUserId ?? '',
					areGiftsRefused: !basketState?.areGiftsRefused,
			  };
		setAreGiftsRefused?.mutate(requestParams);
	};

	const handleGoToShippingPayment = useCallback(
		async (event: MouseEvent<HTMLElement>) => {
			event?.preventDefault();
			event?.stopPropagation();
			if (basketItemsOutOfStock.size > 0) {
				const bundleUpdates: IBundleChange[] = [];
				basketItemsOutOfStock.forEach((cartItem) => {
					const bundleId: string = cartItem.bundleData.bundleId;
					if (bundleId === undefined) return;

					bundleUpdates.push({
						bundleId,
						quantity: cartItem.isTemporaryUnavailable
							? 0
							: cartItem.bundleLimitPerOrder
							? cartItem.bundleLimitPerOrder
							: cartItem.availableWarehouseQty,
					});
				});
				bulkUpdate(bundleUpdates, false);
			}
			history(`/${t({ id: 'routes.cart.shippingPayment.route' })}`);
		},
		[basketItemsOutOfStock, basketContext, history, t]
	);

	const [isShowFloatingFooter, setIsShowFloatingFooter] = useState(false);
	const ctaRef = useRef<HTMLDivElement>(null);
	const { inViewport: isCtaInViewport } = useInViewport(ctaRef, {
		// consider CTA button hidden behind header and footer
		rootMargin: `${headerHeight * -1}px 0px ${footerHeight * -1}px 0px`,
		threshold: CTA_VISIBILITY_THRESHOLD,
	});

	useEffect(() => {
		if (
			(isMobile || isTablet) &&
			ctaRef.current &&
			get(getComputedStyle(ctaRef.current), 'display') !== 'none'
		) {
			setIsShowFloatingFooter(!isCtaInViewport);
		}
	}, [setIsShowFloatingFooter, isCtaInViewport, isMobile, isTablet]);

	useEffect(() => {
		documentHeaderContext.dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.cart.name' })}` }
			)}`,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { sendEvent } = useAnalytics();

	useEffect(() => {
		const mapBasketItemsToGA = (basketItems: any): GaItem[] =>
			map(basketItems, (item) => {
				return {
					item_id: item?.bundleId,
					item_name: getLocalizedValue(item?.bundle?.name ?? []),
					item_category: item?.bundle?.categories?.[0],
					price: item?.bundle?.prices?.[0]?.value,
					quantity: item?.quantity,
				};
			});

		sendEvent(GA_EVENT.VIEW_CART, {
			currency: activeCurrency.currency,
			value: basketPrice,
			items: mapBasketItemsToGA(basketItems),
		});
	}, [
		basketItems,
		activeCurrency.currency,
		sendEvent,
		getLocalizedValue,
		basketPrice,
	]);

	useEffect(() => {
		if (!Array.isArray(basketItems)) return;
		fetchQuantity(
			basketItems.map((bundle: Record<string, any>) => bundle.bundleId)
		);
	}, [basketItems]);

	const deliveriesData = useQuery(
		['deliveries'],
		async () => {
			const requestQuery: IQueryArgument[] = [
				{ key: 'Language', value: LANGUAGE },
				{ key: 'Currency', value: CURRENCY },
				{ key: 'AllowedCountry', value: ALLOWED_COUNTRY },
				{ key: 'Limit', value: LIMIT },
				{ key: 'isForCustomerDelivery', value: true },
				{ key: 'isForStocking', value: false },
			];
			return await fetchDeliveriesData(requestQuery);
		},
		{
			cacheTime: 0,
		}
	);

	const deliveries = useMemo(() => {
		const trimmedDeliveries = map(
			get(deliveriesData, 'data.deliveries', []),
			(delivery) => ({
				// @ts-expect-error fetch fn is written incorrectly, it needs to throw an error not return an empty array!
				...delivery,
				// @ts-expect-error fetch fn is written incorrectly, it needs to throw an error not return an empty array!
				name: map(delivery.name, (nameItem) => ({
					...nameItem,
					value: nameItem.value.trim(),
				})),
			})
		);

		const activeDeliveries = orderBy(
			uniqBy(filter(trimmedDeliveries, 'isActive'), 'alternativeName[0].value'),
			['order', (delivery) => get(delivery, 'deliveryTime')],
			['asc', 'asc']
		);
		return activeDeliveries;
	}, [deliveriesData]);

	const paymentsData = useQuery(
		['payments'],
		async () => {
			const requestQuery: IQueryArgument[] = [
				{ key: 'Language', value: LANGUAGE },
				{ key: 'AllowedCountry', value: ALLOWED_COUNTRY },
			];
			return await fetchPaymentsData(requestQuery);
		},
		{
			cacheTime: 0,
		}
	);

	// @ts-expect-error fetch fn is written incorrectly, it needs to throw an error not return an empty array!
	const payments: VinistoOrderDllModelsApiPaymentPayment[] = useMemo(() => {
		const activePayments = orderBy(
			uniqBy(
				filter(get(paymentsData, 'data.payments', []), {
					image: {},
					isActive: true,
				}),
				'name[0].value'
			),
			'order',
			'asc'
		);
		return activePayments;
	}, [deliveriesData.isFetched, deliveriesData.data]);

	const possibleGiftsArraySort = basketContext?.possibleGifts?.sort(
		sortGiftsByorderPriceLimitFrom
	);

	const possibleGiftsArray =
		Boolean(basketContext?.possibleGifts?.[0]) &&
		possibleGiftsArraySort?.[0].bundles?.map(
			(bundle: { bundle: { name: BundleName[] } }) =>
				getLocalizedValue(bundle.bundle.name)
		);

	const giftsLeftToSpent =
		(basketContext?.possibleGifts?.[0] &&
			(basketContext?.possibleGifts?.[0].leftToSpent ?? 0)) ??
		0;
	const possibleGifts = basketContext?.possibleGifts?.[0]
		? possibleGiftsArray?.join(', ')
		: '';

	const hasAnyGift = Boolean(basketContext?.assignedGifts?.[0]);

	const currentlyRecommendedCarouselDataQuery = useQuery(
		['getCurrentlyRecommendedBundles'],
		() =>
			BundleService.getCurrentlyRecommendedBundles().then((payload) => {
				const bundles = payload?.bundles || [];
				const bundleIds = bundles.map((bundle) => bundle.id);

				fetchQuantity(bundleIds);
				return bundles;
			})
	);

	return (
		<section id="content-wrapper">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<CartHeader />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-12">
						{!isLoading && basketItemsOutOfStock.size > 0 && (
							<OutOfStockCartItemList
								{...{
									basketItemsOutOfStock,
									handleRemoveItemFromBasket: handleRemoveOosItemFromBasket,
									setBasketItemsOutOfStock,
								}}
							/>
						)}

						<div>
							<h1 className="vinisto-heading">
								{t({ id: 'basket.inBasket' })}
							</h1>

							{!isMobile && (
								<>
									<div className="vinisto-user-orders__orders__order-body vinisto-cart__items">
										{!isLoading && size(basketItemsWithTemp) === 0 && (
											<strong className={styles.emptyBasketMessage}>
												{t({
													id: 'basket.emptyBasket.message',
												})}
											</strong>
										)}
										{map(
											basketItemsWithTemp,
											(cartItem: Record<string, any>) => {
												const key = `cart-item-${
													get(cartItem, 'bundleId') ?? uniqueId()
												}`;
												if (!get(cartItem, 'temp', false)) {
													return (
														<ActiveCartItem {...{ isLoading, key, cartItem }} />
													);
												} else if (!isLoading) {
													return <InActiveCartItem {...{ cartItem, key }} />;
												}

												return <></>;
											}
										)}
										{!!discountCoupons?.length &&
											discountCoupons?.map(
												(
													discountCoupon: VinistoBasketDllModelsApiBasketBasketDiscountCoupon,
													index: number
												) => (
													<CouponItem
														key={`${discountCoupon?.id}-${index}`}
														{...discountCoupon}
													/>
												)
											)}
									</div>

									{(!hasAnyGift && areGiftsRefused) ||
										((basketContext?.possibleGifts?.[0] ||
											basketContext?.assignedGifts?.[0]) && (
											<div className="mt-5 giftInfoBox">
												<div className="giftInfoHeading">
													<h1 className="vinisto-heading">
														{t({ id: 'giftInfo.heading' })}
													</h1>
													{!areGiftsRefused && hasAnyGift && (
														<button
															className="refuseGiftButton"
															onClick={handleOnSetAreGiftsRefused}
														>
															{t({
																id: 'giftInfo.button.refuseGifts',
															})}
															<Suspense fallback={<Loader blank />}>
																<CloseIcon
																	className="CloseIcon"
																	alt="Close"
																/>
															</Suspense>
														</button>
													)}
												</div>
												<div className="vinisto-user-orders__orders__order-body vinisto-cart__items vinisto-cart__items-gifts">
													<button className="showGiftsButton">
														{Boolean(
															(basketContext?.assignedGifts?.[0] ||
																basketContext?.possibleGifts?.[0]) &&
																(((basketContext?.possibleGifts?.[0]
																	?.leftToSpent as number) ?? 0) > 0 ||
																	basketState?.areGiftsRefused)
														) && (
															<GiftInfo
																areGiftsRefused={areGiftsRefused}
																possibleGifts={possibleGifts}
																handleOnAcceptGifts={handleOnSetAreGiftsRefused}
																giftsLeftToSpent={giftsLeftToSpent}
															/>
														)}
													</button>
													{!areGiftsRefused &&
														giftInfoOpen &&
														basketContext?.possibleGifts?.map(
															(possibleGift: AssignedGiftBundles) => {
																return possibleGift?.bundles.map(
																	(bundle, key) => {
																		const giftData = {
																			bundle: {
																				...bundle.bundle,
																				name: getLocalizedValue(
																					bundle.bundle
																						.name as unknown as LangValuePair[]
																				),
																			},
																			amount: bundle.amount,
																		};
																		return (
																			<GiftItemDesktop
																				key={`possibleGiftItem-${key}`}
																				orderItemData={giftData}
																				noBorder={key === 0}
																			/>
																		);
																	}
																);
															}
														)}
													{!areGiftsRefused &&
														basketContext?.assignedGifts?.map(
															(assignedGift: AssignedGiftBundles) => {
																return assignedGift?.bundles.map(
																	(bundle, key) => {
																		const giftData = {
																			bundle: {
																				...bundle.bundle,
																				name: getLocalizedValue(
																					bundle.bundle
																						.name as unknown as LangValuePair[]
																				),
																			},
																			amount: bundle.amount,
																		};
																		return (
																			<GiftItemDesktop
																				key={`assignedGiftItem-${key}`}
																				orderItemData={giftData}
																			/>
																		);
																	}
																);
															}
														)}
												</div>
											</div>
										))}
								</>
							)}

							{isMobile && (
								<>
									<div className="vinisto-user-orders__orders__order vinisto-user-favorites--mobile vinisto-crosssell--mobile vinisto-cart__items--mobile">
										{!isLoading && size(basketItemsWithTemp) === 0 && (
											<strong className={styles.emptyBasketMessage}>
												{t({
													id: 'basket.emptyBasket.message',
												})}
											</strong>
										)}
										{map(basketItemsWithTemp, (cartItem: Record<any, any>) => {
											const key = `cart-item-${
												get(cartItem, 'bundleId') ?? uniqueId()
											}`;
											if (!get(cartItem, 'temp', false)) {
												return (
													<ActiveCartItemMobile
														cartItem={cartItem}
														{...{ isLoading, key }}
													/>
												);
											} else if (!isLoading) {
												return (
													<InActiveCartItemMobile
														{...{ key }}
														cartItem={cartItem}
													/>
												);
											}

											return <></>;
										})}
										{!!discountCoupons?.length &&
											discountCoupons?.map(
												(
													discountCoupon: VinistoBasketDllModelsApiBasketBasketDiscountCoupon,
													index: number
												) => (
													<CouponItem
														key={`${discountCoupon?.id}-${index}`}
														{...discountCoupon}
													/>
												)
											)}
									</div>
									{(!hasAnyGift && areGiftsRefused) ||
										((basketContext?.possibleGifts?.[0] ||
											basketContext?.assignedGifts?.[0]) && (
											<div className="mt-5">
												<div className="giftInfoHeading">
													<h1 className="vinisto-heading">
														{t({ id: 'giftInfo.heading' })}
													</h1>
													{!areGiftsRefused && hasAnyGift && (
														<button
															className="refuseGiftButton"
															onClick={handleOnSetAreGiftsRefused}
														>
															{t({
																id: 'giftInfo.button.refuseGifts',
															})}
															<Suspense fallback={<Loader blank />}>
																<CloseIcon
																	className="CloseIcon"
																	alt="Close"
																/>
															</Suspense>
														</button>
													)}
												</div>
												<div className="vinisto-user-orders__orders__order-body vinisto-cart__items">
													<button className="showGiftsButton">
														{Boolean(
															(basketContext?.assignedGifts?.[0] ||
																basketContext?.possibleGifts?.[0]) &&
																(((basketContext?.possibleGifts?.[0]
																	?.leftToSpent as number) ?? 0) > 0 ||
																	basketState?.areGiftsRefused)
														) && (
															<GiftInfo
																areGiftsRefused={areGiftsRefused}
																possibleGifts={possibleGifts}
																handleOnAcceptGifts={handleOnSetAreGiftsRefused}
																giftsLeftToSpent={giftsLeftToSpent}
															/>
														)}
													</button>
													{!areGiftsRefused &&
														giftInfoOpen &&
														basketContext?.possibleGifts?.map(
															(possibleGift: AssignedGiftBundles) => {
																return possibleGift?.bundles.map(
																	(bundle, index) => {
																		const giftData = {
																			bundle: {
																				...bundle.bundle,
																				name: getLocalizedValue(
																					bundle.bundle
																						.name as unknown as LangValuePair[]
																				),
																			},
																			amount: bundle.amount,
																		};
																		return (
																			<GiftItemMobile
																				key={`possibleGiftItem-${index}`}
																				orderItemData={giftData}
																				noBorder={index === 0}
																			/>
																		);
																	}
																);
															}
														)}
													{!areGiftsRefused &&
														basketContext?.assignedGifts?.map(
															(assignedGift: AssignedGiftBundles) => {
																return assignedGift?.bundles.map(
																	(bundle, index) => {
																		const giftData = {
																			bundle: {
																				...bundle.bundle,
																				name: getLocalizedValue(
																					bundle.bundle
																						.name as unknown as LangValuePair[]
																				),
																			},
																			amount: bundle.amount,
																		};
																		return (
																			<GiftItemMobile
																				key={`assignedGiftItem-${index}`}
																				orderItemData={giftData}
																			/>
																		);
																	}
																);
															}
														)}
												</div>
											</div>
										))}
								</>
							)}

							{isMobile && (
								<div className="vinisto-cart__coupon-wrap-mobile">
									<CouponForm className="vinisto-cart__coupon-mobile" />
								</div>
							)}

							{
								<div className="vinisto-cart__discount-total">
									{!isMobile && <CouponForm />}

									{basketContext?.minimalPriceForFreeDelivery !== null && (
										<div className="vinisto-crosssell__ctas__delivery-bar desktop-only">
											<FreeDeliveryProgressBar variant="wide-horizontal" />
										</div>
									)}

									{!isMobile && (
										<div className="vinisto-user-orders__orders__order-body__item__prices">
											{isLoading ? (
												<Skeleton
													count={1.9}
													width="120px"
												/>
											) : (
												<>
													<div className="vinisto-user-orders__orders__order-body__item__prices__total">
														<span className="vinisto-user-orders__orders__order-body__item__prices__big-total">{`${t(
															{
																id: 'basket.totalPriceBig',
															}
														)} `}</span>
														<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
															{basketStandardPriceWithVat -
																totalBasketPriceWithVat >
																0 && (
																<span className={styles.originalPrice}>
																	{basketStandardPriceWithVatCurrency}
																</span>
															)}
															{totalBasketPriceWithVatCurrency}
														</span>
													</div>
													<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
														{`${t({
															id: 'basket.totalPriceWithoutVAT',
														})} `}
														<span className="fw-normal price-span">
															{totalBasketPriceWithoutVatCurrency}
														</span>
													</div>
												</>
											)}
										</div>
									)}

									{isMobile && (
										<div className="vinisto-cart__price-continue font-family-ubuntu w-100">
											{isLoading ? (
												<Skeleton
													count={1.9}
													width="220px"
												/>
											) : (
												<>
													<div className="d-flex justify-content-between">
														<span className="vinisto-cart__total-label"></span>
														<span className="vinisto-cart__total-price">
															{basketStandardPriceWithVat -
																totalBasketPriceWithVat >
																0 && (
																<span className={styles.originalPrice}>
																	{basketStandardPriceWithVatCurrency}
																</span>
															)}
														</span>
													</div>
													<div className="d-flex justify-content-between">
														<span className="vinisto-cart__total-label">
															{`${t({
																id: 'basket.totalPriceBig',
															})} `}
														</span>
														<span className="vinisto-cart__total-price">
															{totalBasketPriceWithVatCurrency}
														</span>
													</div>
													<div className="d-flex justify-content-between mb-1">
														<span className="vinisto-cart__total-label--no-vat">
															{`${t({
																id: 'basket.priceWithoutVAT',
															})} `}
														</span>
														<span className="vinisto-cart__total-label--no-vat">
															{totalBasketPriceWithoutVatCurrency}
														</span>
													</div>
												</>
											)}
										</div>
									)}
								</div>
							}

							{isDesktop && (
								<>
									<div className="vinisto-crosssell__ctas mt-5 justify-content-end">
										<button
											type="button"
											onClick={handleGoToShippingPayment}
											disabled={isBasketEmpty}
											className="vinisto-btn vinisto-success-btn vinisto-cart__btn-next"
											data-testid={TEST_IDS.BASKET_CONTINUE_BUTTON_DESKTOP}
										>
											{t({ id: 'basket.orderContinue' })}
										</button>
									</div>
									<div className="vinisto-cart__payments-wrap">
										{paymentsData.isFetched && payments?.length > 0 && (
											<>
												<div className="vinisto-product-detail-other-sellers vinisto-product-detail-payment-methods">
													{t({ id: 'productDetail.paymentMethods' })}
												</div>
												<div className="payment-icons-wrapper">
													{payments?.map((payment) => (
														<PaymentItem
															key={payment?.id}
															payment={payment}
														/>
													))}
												</div>
											</>
										)}
										{deliveriesData.isFetched && deliveries?.length > 0 && (
											<div className="vinisto-cart__delivery-methods">
												<DeliveryMethods
													deliveries={deliveries}
													placement={DeliveryMethodsPlacement.CART}
												/>
											</div>
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>

			{!isMobile && (
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="position-relative overflow-hidden mb-5">
								<p className="h2 vinisto-heading underline">
									{t({ id: 'basket.buyOthers' })}
								</p>
								<Carousel
									isLoading={currentlyRecommendedCarouselDataQuery.isLoading}
									data={currentlyRecommendedCarouselDataQuery.data}
									carouselType={HOME_PAGE_PRODUCTS_CAROUSEL}
									cardType={CARD_TYPE.CAROUSEL_CLASSIC}
									redirectToCrossSell={false}
									displayPriceAsRange
									quantityBoxType={QuantityBoxTypes.DIRECT}
								/>
							</div>
						</div>
					</div>
				</div>
			)}

			{!isDesktop && (
				<div className="container vinisto-crosssell">
					<div className="row">
						<div className="col-12">
							<div
								className="tablet-mobile-only"
								ref={ctaRef}
							>
								<button
									type="button"
									onClick={handleGoToShippingPayment}
									disabled={isBasketEmpty}
									className="vinisto-btn vinisto-success-btn vinisto-cart-btn-next w-100"
								>
									{t({ id: 'basket.orderContinue' })}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{isShowFloatingFooter && !isDesktop && (
				<div
					className="container vinisto-crosssell--floating"
					style={{ bottom: `${footerHeight}px` }}
				>
					<div className="row">
						<div className="col-12">
							<div className="vinisto-cart__price-continue font-family-ubuntu">
								<div>
									{isLoading ? (
										<Skeleton
											count={1.9}
											width="220px"
										/>
									) : (
										<>
											<div className="d-flex justify-content-between">
												<span className="vinisto-cart__total-label"></span>
												<span className="vinisto-cart__total-price">
													{basketStandardPriceWithVat -
														totalBasketPriceWithVat >
														0 && (
														<span className={styles.originalPrice}>
															{basketStandardPriceWithVatCurrency}
														</span>
													)}
												</span>
											</div>
											<div className="d-flex justify-content-between">
												<span className="vinisto-cart__total-label">
													{`${t({
														id: 'basket.totalPriceBig',
													})} `}
												</span>
												<span className="vinisto-cart__total-price">
													{totalBasketPriceWithVatCurrency}
												</span>
											</div>
											<div className="d-flex justify-content-between mb-1">
												<span className="vinisto-cart__total-label--no-vat">
													{`${t({
														id: 'basket.priceWithoutVAT',
													})} `}
												</span>
												<span className="vinisto-cart__total-label--no-vat">
													{totalBasketPriceWithoutVatCurrency}
												</span>
											</div>
										</>
									)}
								</div>
								<button
									type="button"
									onClick={handleGoToShippingPayment}
									disabled={isBasketEmpty}
									className="vinisto-btn vinisto-success-btn vinisto-cart-btn-next w-100"
								>
									{t({ id: 'basket.orderContinue' })}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</section>
	);
};

export default Cart;
