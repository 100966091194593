import { useCallback, useContext, useMemo } from 'react';
import { ceil, first, last } from 'lodash-es';
import cx from 'classnames';
import { DeviceServiceContext } from 'Services/DeviceService';
import PaginationNav from 'Components/Pagination';
import { useQuery } from '@tanstack/react-query';
import { BannerListing } from 'vinisto_ui';
import BannerService from 'Services/Banner';
import { BANNER_POSITION } from 'Services/Banner/constants';
import { Banner } from 'Services/Banner/interfaces';
import useLocalizedValue from 'Hooks/useLocalizedValue';

import {
	BANNER_OBJECT,
	URL_PARAM_LIMIT_UNLIMITED_VALUE,
} from '../../constants';
import { BundlesWithFiltersContext } from '../../context';

import Grid from './Components/Grid';
import CategoryView from './Components/CategoryView';
import SortingTabs from './Components/SortingTabs';
import BundleItem from './Components/Grid/Components/BundleItem';

const Bundles = () => {
	const getLocalizedValue = useLocalizedValue();
	const deviceContext = useContext(DeviceServiceContext);

	const {
		bundles,
		bundlesCount,
		bundlesToLoadMore,
		isBundlesLoading,
		currentPage,
		isDataLoading,
		limit,
		page,
		setPageParam,
	} = useContext(BundlesWithFiltersContext);

	const isFirstPage = page[0] === 1;

	const bannerService = useMemo(
		() => new BannerService(getLocalizedValue),
		[getLocalizedValue]
	);

	const { data: banners } = useQuery(
		['BannersProductListing'],
		bannerService.fetch(
			BANNER_POSITION.PRODUCT_LIST,
			URL_PARAM_LIMIT_UNLIMITED_VALUE
		),
		{
			enabled: isFirstPage,
		}
	);

	const totalPaginationPages = ceil(bundlesCount / limit);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
	};

	const handleOnSelectPage = useCallback(
		(page: number) => {
			setPageParam([page]);
			scrollToTop();
		},
		[setPageParam]
	);
	const handleOnSelectNextPage = useCallback(() => {
		setPageParam([currentPage + 1]);
		scrollToTop();
	}, [currentPage, setPageParam]);

	const handleOnSelectPreviousPage = useCallback(() => {
		if (currentPage <= 1) {
			return;
		}
		setPageParam([currentPage - 1]);
		scrollToTop();
	}, [currentPage, setPageParam]);

	const handleOnLoadMore = useCallback(
		() => setPageParam([first(page) ?? 1, (last(page) ?? 1) + 1]),
		[page, setPageParam]
	);

	const bannersCount = banners?.length ?? 0;

	const bannersAndBundlesMerged = useMemo(() => {
		// Bundles are just skeletons in the loading state.
		// If we omit this, the positions would shift when the actual bundles load.
		if (isBundlesLoading) return bundles;
		// Display banners only on the first page
		if (!isFirstPage) return bundles;
		if (!banners) return bundles;

		// TODO better types, at least for bundles
		const merged: Array<
			| (Banner & { type: string; isLoading: undefined; id: undefined })
			| Record<any, any>
		> = new Array(bannersCount + bundlesCount);

		banners.forEach((banner) => {
			merged[Math.min(banner.order, merged.length)] = {
				type: BANNER_OBJECT,
				...banner,
			};
		});

		let lastCheckedIndex = 0;
		let bundleIndex = 0;
		while (bundleIndex < bundles.length) {
			if (merged[lastCheckedIndex] === undefined) {
				merged[lastCheckedIndex] = bundles[bundleIndex];
				bundleIndex++;
				lastCheckedIndex++;
			} else {
				lastCheckedIndex++;
			}
		}

		return merged;
	}, [
		isBundlesLoading,
		bundles,
		isFirstPage,
		banners,
		bannersCount,
		bundlesCount,
	]);

	return (
		<>
			<div>
				{deviceContext.isMobile || deviceContext.isTablet ? (
					<div className="vinisto-card">
						<CategoryView
							bundlesCount={bundlesCount}
							isLoading={isDataLoading}
						/>
						<SortingTabs />
					</div>
				) : (
					<div className="d-flex justify-content-between align-items-center ps-3">
						<SortingTabs />
						<CategoryView
							bundlesCount={bundlesCount}
							isLoading={isDataLoading}
							className="pb-0"
						/>
					</div>
				)}
			</div>

			<div
				className={cx(
					deviceContext.isMobile || deviceContext.isTablet
						? 'vinisto-card'
						: 'tabs-content rounded-tab-content-corners p-3'
				)}
			>
				<div className="col-12">
					<Grid>
						{bannersAndBundlesMerged.map((item, i) => {
							if (item.type === BANNER_OBJECT) {
								return (
									<BannerListing
										{...item}
										buttonText={item.ctaLabel}
										buttonLink={item.url}
										key={`category-grid-banner-item-${i}`}
									/>
								);
							}

							return (
								<BundleItem
									bundleData={item}
									key={`category-grid-bundle-item-${item?.id ?? i}`}
									isLoading={!!item?.isLoading}
								/>
							);
						})}
					</Grid>
				</div>
				<div className="col-12">
					<PaginationNav
						currentPage={currentPage}
						totalPaginationPages={totalPaginationPages}
						itemsToLoadMore={bundlesToLoadMore}
						handleOnLoadMore={handleOnLoadMore}
						handleOnSelectPreviousPage={handleOnSelectPreviousPage}
						handleOnSelectNextPage={handleOnSelectNextPage}
						handleOnSelectPage={handleOnSelectPage}
					/>
				</div>
			</div>
		</>
	);
};

export default Bundles;
