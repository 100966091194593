import { useContext } from 'react';
import { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/product-api';
import { LocalizationContext } from 'Services/LocalizationService';
import useBundlePrice from 'Hooks/useBundlePrice';
import { useDirectQuantityBox } from 'Components/QuantityBox/Variants/DirectQuantityBox/hooks';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import useLocalizedCurrency from 'Hooks/useLocalizedCurrency';
import { BasketContext } from 'Services/BasketService';
import { BundleDetailProps } from 'Pages/Bundle/interfaces';

export const useProductBasketCount = (
	bundle: VinistoProductDllModelsApiBundleBundle | BundleDetailProps
) => {
	const localizationContext = useContext(LocalizationContext);
	const basketContext = useContext(BasketContext);
	const t = localizationContext.useFormatMessage();
	const getBundlePrice = useBundlePrice();
	const { quantityInBasket } = useDirectQuantityBox(bundle);
	const { lowestPriceWithVat } = getBundlePrice(bundle);
	const basketBundlesPriceWithCurrency = createCurrencyValue(
		lowestPriceWithVat,
		quantityInBasket
	);
	const getLocalizedCurrency = useLocalizedCurrency();
	const localizedCurrency = getLocalizedCurrency(
		basketContext.basketState?.currency
	)?.title;
	const basketBundlesPriceWithCurrencyLocalized = `${basketBundlesPriceWithCurrency} ${localizedCurrency}`;
	const basketQuantityPopover =
		quantityInBasket &&
		t(
			{ id: 'popover.addedToBasket' },
			{
				quantity: <strong> {quantityInBasket} </strong>,
				priceWithCurrency: (
					<strong>{basketBundlesPriceWithCurrencyLocalized}</strong>
				),
			}
		);

	return {
		basketQuantityPopover,
	};
};
