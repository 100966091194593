import { ChangeEvent, lazy, Suspense, useContext } from 'react';
import cx from 'classnames';
import useFormatMessage from 'Hooks/useFormatMessage';
import Skeleton from 'react-loading-skeleton';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { ProductBoxContext } from 'Components/ProductBox/context';
import Popover from 'Components/Popover';
import Loader from 'Components/View/Loader';
import { usePopover } from 'Components/Popover/hooks';
import { ModalContext } from 'Components/Modal/context';
import { LOGIN_MODAL } from 'Components/Modal/constants';
import getBundleLimitPerOrder from 'Helpers/getBundleLimitPerOrder';
import { TEST_IDS } from 'Constants/test-ids';

import ProductNotAvailablePopover from './Components/ProductNotAvailablePopover';
import MaximumQuantityReachedPopover from './Components/MaximumQuantityReachedPopover';
import OrderLimitReachedPopover from './Components/OrderLimitReachedPopover';
const AddToCartButtonMinus = lazy(
	() => import('Components/Icons/AddToCartButtonMinus')
);
const AddToCartButtonPlusWhite = lazy(
	() => import('Components/Icons/AddToCartButtonPlusWhite')
);
import {
	Popovers,
	PopoverTypes,
	QuantityBoxPlusBtnTypes,
	QuantityBoxPlusBtnVariants,
	QuantityBoxTypes,
	ShoppingIssues,
} from './constants';
import { QuantityBoxProps } from './interfaces';
import styles from './styles.module.css';

const QuantityBox = ({
	productId,
	type,
	plusBtnVariant,
	plusBtnType = QuantityBoxPlusBtnTypes.STANDARD,
	popoverType = PopoverTypes.COMPONENT,
	quantityInBasket,
	availableCount,
	count,
	onIncrement,
	onDecrement,
	onCountChange,
	onAddToBasket,
	onBlur,
	className,
	isLoading,
	isForLogged,
	orderLimitation,
	carouselType,
}: QuantityBoxProps) => {
	const isDirect = type === QuantityBoxTypes.DIRECT;
	const authenticationContext = useContext(AuthenticationContext);
	const modalContext = useContext(ModalContext);
	const productBoxContext = useContext(ProductBoxContext);

	const t = useFormatMessage();

	const popover = usePopover({
		[Popovers.PRODUCT_NOT_AVAILABLE]: {
			content: <ProductNotAvailablePopover />,
			padding: 5,
		},
		[Popovers.MAXIMUM_QUANTITY_REACHED]: {
			content: (
				<MaximumQuantityReachedPopover availableCount={availableCount ?? 0} />
			),
			padding: 5,
		},
		[Popovers.ORDER_LIMIT_REACHED]: {
			content: <OrderLimitReachedPopover orderLimitation={orderLimitation} />,
			padding: 5,
		},
	});

	const isAvailableCount = typeof availableCount === 'number';
	const isUnavailable = !isAvailableCount || availableCount < 1;

	const bundleLimitPerOrder = getBundleLimitPerOrder(orderLimitation);
	const isBundleLimitPerOrder = typeof bundleLimitPerOrder === 'number';

	const isInBasket =
		typeof quantityInBasket === 'number' && quantityInBasket > 0;

	const getShoppingIssue = () => {
		if (isLoading) return null;
		if (!isDirect) {
			if (
				isBundleLimitPerOrder &&
				quantityInBasket + count > bundleLimitPerOrder
			) {
				return ShoppingIssues.ORDER_LIMIT_REACHED;
			}

			if (isAvailableCount && count + quantityInBasket > availableCount) {
				return ShoppingIssues.MAXIMUM_QUANTITY_REACHED;
			}
		}
		if (isDirect) {
			if (isBundleLimitPerOrder && count >= bundleLimitPerOrder) {
				return ShoppingIssues.ORDER_LIMIT_REACHED;
			}

			if (availableCount === 0) {
				return ShoppingIssues.PRODUCT_NOT_AVAILABLE;
			}

			if (isAvailableCount && count >= availableCount) {
				return ShoppingIssues.MAXIMUM_QUANTITY_REACHED;
			}
		}
		return null;
	};

	const shoppingIssue = getShoppingIssue();

	const isStandardOutOfStock =
		!isDirect && count >= (availableCount ?? 0) - (quantityInBasket ?? 0);

	const isDirectOutOfStock = isDirect && count >= (availableCount ?? 0);

	const isOutOfStock = isStandardOutOfStock || isDirectOutOfStock;

	const handleShoppingIssue = (issue: ShoppingIssues) => {
		if (issue === ShoppingIssues.ORDER_LIMIT_REACHED) {
			return popover.open(Popovers.ORDER_LIMIT_REACHED);
		}

		if (issue === ShoppingIssues.PRODUCT_NOT_AVAILABLE) {
			return popover.open(Popovers.PRODUCT_NOT_AVAILABLE);
		}

		if (issue === ShoppingIssues.MAXIMUM_QUANTITY_REACHED) {
			return popover.open(Popovers.MAXIMUM_QUANTITY_REACHED);
		}
	};

	const handleOnMouseEnter = () => {
		if (!isLoading && isUnavailable) {
			popover.open(Popovers.PRODUCT_NOT_AVAILABLE);
		}
	};

	const handleOnMouseLeave = () => {
		if (!isLoading) {
			popover.close();
		}
	};

	const handleOnDecrement = () => {
		if (isDirect && isAvailableCount && availableCount < quantityInBasket) {
			onCountChange(`${isAvailableCount ? availableCount : 0}`);
		} else {
			onDecrement();
		}
		popover.close();
	};

	const handleOnIncrement = () => {
		if (isPlusButtonDisabled && isOutOfStock) {
			popover.open(Popovers.MAXIMUM_QUANTITY_REACHED);
			return;
		}

		if (
			isBundleLimitPerOrder &&
			((isDirect && count >= bundleLimitPerOrder) ||
				(!isDirect && quantityInBasket + count >= bundleLimitPerOrder))
		) {
			return handleShoppingIssue(ShoppingIssues.ORDER_LIMIT_REACHED);
		}
		onIncrement();
	};

	const handleOnCountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const userInput = e?.target?.value;
		if (
			!Number.isNaN(parseInt(userInput)) &&
			isAvailableCount &&
			parseInt(userInput) > availableCount
		) {
			popover.open(Popovers.MAXIMUM_QUANTITY_REACHED);
			onCountChange(e?.target?.value);
			popover.close();
			return;
		}
		if (
			!Number.isNaN(parseInt(userInput)) &&
			isBundleLimitPerOrder &&
			(parseInt(userInput) > bundleLimitPerOrder ||
				(isDirect && parseInt(userInput) > bundleLimitPerOrder))
		) {
			popover.open(Popovers.ORDER_LIMIT_REACHED);
			onCountChange(String(bundleLimitPerOrder));
			popover.close();
			return;
		}
		onCountChange(userInput);
		popover.close();
	};

	const handleOnAddToBasket = () => {
		if (!shoppingIssue) {
			onAddToBasket().then(() => {
				if (productBoxContext === undefined) return;
				productBoxContext.addToBasketCallback();
			});
		} else {
			handleShoppingIssue(shoppingIssue);
		}
	};

	const isShopingIssue = Boolean(shoppingIssue);

	const isMinusButtonDisabled =
		(isDirect && quantityInBasket === 0) || (!isDirect && count === 1);

	const isStandardPlusButtonDisabled =
		!isDirect &&
		(isStandardOutOfStock ||
			(typeof bundleLimitPerOrder === 'number' &&
				bundleLimitPerOrder !== 0 &&
				count >= bundleLimitPerOrder - (quantityInBasket ?? 0)));

	const isDirectPlusButtonDisabled =
		isDirect &&
		(isDirectOutOfStock ||
			(typeof bundleLimitPerOrder === 'number' &&
				bundleLimitPerOrder !== 0 &&
				count >= bundleLimitPerOrder));

	const isPlusButtonDisabled =
		isDirectPlusButtonDisabled || isStandardPlusButtonDisabled;

	const isCustomPopoverPosition = popoverType === PopoverTypes.WINDOW;

	if (!authenticationContext.isLoggedIn && isForLogged) {
		return (
			<Popover
				{...popover.methods}
				transform={isCustomPopoverPosition ? {} : undefined}
				containerClassName={cx(
					isCustomPopoverPosition && styles.customPopoverPosition
				)}
			>
				<div
					className={cx(
						'quantity-box-wrapper',
						styles.wrapper,
						{
							[styles.disabled]: isShopingIssue,
						},
						className
					)}
					onMouseEnter={handleOnMouseEnter}
					onMouseLeave={handleOnMouseLeave}
				>
					<button
						type="button"
						className={cx(
							'w-100',
							'addToBasketButton',
							styles.addToBasketButton,
							styles.isForLogged,
							{
								[styles.disabled]: isShopingIssue,
							}
						)}
						onClick={() => modalContext.handleOpenModal(LOGIN_MODAL, {})}
						data-testid={`${TEST_IDS.PRODUCT_BOX_LOGIN_AND_SHOP}${
							carouselType !== undefined ? '_' + carouselType : ''
						}_${productId}`}
					>
						{t({
							id:
								productBoxContext?.addToBasketButtonLabel ??
								'carousel.button.loginAndShop',
						})}
					</button>
				</div>
			</Popover>
		);
	}

	return (
		<Popover
			{...popover.methods}
			transform={isCustomPopoverPosition ? {} : undefined}
			containerClassName={cx(
				isCustomPopoverPosition && styles.customPopoverPosition
			)}
		>
			<div
				className={cx(
					'quantity-box-wrapper',
					styles.wrapper,
					{
						[styles.disabled]: isShopingIssue,
					},
					className
				)}
				onMouseEnter={handleOnMouseEnter}
				onMouseLeave={handleOnMouseLeave}
			>
				{(!isDirect || (isDirect && isInBasket)) && (
					<div className={cx(styles.countWrapper)}>
						{isLoading ? (
							<Skeleton
								width="1.875rem"
								height="1.875rem"
							/>
						) : (
							<button
								type="button"
								className={cx('minusCartDetailButton', styles.minusButton, {
									[styles.disabled]: isMinusButtonDisabled,
									[styles.detailMinusButton]: !isDirect,
								})}
								onClick={handleOnDecrement}
							>
								<Suspense fallback={<Loader blank />}>
									<AddToCartButtonMinus
										alt={t({ id: 'alt.less' })}
										className={styles.minusButtonIcon}
										stroke="#4D4D4E"
									/>
								</Suspense>
							</button>
						)}
						<label className={styles.wineCountLabel}>
							{isLoading ? (
								<Skeleton
									width="2.375rem"
									height="1.875rem"
								/>
							) : (
								<input
									className={cx(styles.countInput, 'inputCartDetailButton', {
										[styles.detailCountInput]: !isDirect,
									})}
									type="text"
									value={count === -1 ? '' : count}
									onChange={handleOnCountChange}
									onBlur={() => onBlur}
									inputMode="numeric"
								/>
							)}
						</label>
						{isLoading ? (
							<Skeleton
								width="1.875rem"
								height="1.875rem"
							/>
						) : (
							<div
								className={cx(styles.plusButtonWrapper, {
									[styles.detailPlusButton]: !isDirect,
								})}
								onClick={handleOnIncrement}
								onKeyDown={handleOnIncrement}
								role="button"
								tabIndex={0}
							>
								<button
									type="button"
									className={cx(styles.plusButton, {
										[styles.success]:
											plusBtnVariant === QuantityBoxPlusBtnVariants.SUCCESS,
										[styles.extended]:
											plusBtnType === QuantityBoxPlusBtnTypes.EXTENDED,
										[styles.detailPlusButton]: !isDirect,
									})}
									disabled={isPlusButtonDisabled}
								>
									<Suspense fallback={<Loader blank />}>
										<AddToCartButtonPlusWhite
											alt={t({ id: 'alt.more' })}
											className={cx(styles.plusButtonIcon)}
										/>
									</Suspense>
								</button>
							</div>
						)}
					</div>
				)}
				{(!isDirect || (isDirect && !isInBasket)) &&
					(isLoading ? (
						<Skeleton
							width="135px"
							height="30px"
						/>
					) : (
						<button
							type="button"
							className={cx(
								'w-100',
								'addToCartDetailButton',
								styles.addToBasketButton,
								styles.isForLogged,
								{
									[styles.disabled]: isShopingIssue,
									[styles.detailAddToBasketButton]: !isDirect,
								}
							)}
							onClick={handleOnAddToBasket}
							data-testid={`${TEST_IDS.PRODUCT_BOX_ADD_TO_BASKET}${
								carouselType !== undefined ? '_' + carouselType : ''
							}_${productId}`}
						>
							{t({
								id:
									productBoxContext?.addToBasketButtonLabel ??
									'cart.outOfStockItem.alternative.addToBasketBtnLabel',
							})}
						</button>
					))}
			</div>
		</Popover>
	);
};

export default QuantityBox;

export { default as StandardQuantityBox } from './Variants/StandardQuantityBox';
export { default as DirectQuantityBox } from './Variants/DirectQuantityBox';
