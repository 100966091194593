import { useCallback, useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { BasketContext } from 'Services/BasketService';
import { WarehouseContext } from 'Services/WarehouseService';
import useBundlePrice from 'Hooks/useBundlePrice';
import { VinistoBasketDllModelsApiBasketBasketBundle } from 'vinisto_api_client/src/api-types/basket-api';
import { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/product-api';
import { BundleDetailProps } from 'Pages/Bundle/interfaces';

export const useDirectQuantityBox = (
	bundle: VinistoProductDllModelsApiBundleBundle | BundleDetailProps
) => {
	const { basketState, handleOnChangeItemQuantity, isUpdatingCount } =
		useContext(BasketContext);
	const { getQuantity } = useContext(WarehouseContext);
	const getBundlePrice = useBundlePrice();

	const availableCount = getQuantity(bundle?.id ?? '');

	const itemInBasket = (
		basketState?.items as VinistoBasketDllModelsApiBasketBasketBundle[]
	)?.find((item) => item?.bundleId === bundle?.id);

	const quantityInBasket = itemInBasket?.quantity ?? 0;
	const { priceWithVat: bundlePriceWithVat } = getBundlePrice(bundle);

	const [count, setCount] = useState(quantityInBasket);

	const addToBasketImmediately = (
		count: number,
		callback?: (result: boolean) => void
	) =>
		handleOnChangeItemQuantity(count, bundle?.id as string).then((result) => {
			if (typeof callback === 'function') {
				callback(result);
			}
			return result;
		});

	const addToBasket = useCallback(
		debounce((count, callback?: (result: boolean) => void) => {
			addToBasketImmediately(count, callback);
		}, 500),
		[bundle?.id]
	);

	const onCountChange = (
		value: string,
		callback?: (result: boolean) => void
	) => {
		if (
			value?.length > 0 &&
			value?.split('')?.some((number: string) => Number.isNaN(parseInt(number)))
		) {
			return;
		} else if (value === '') {
			setCount(-1);
		} else if (
			!Number.isNaN(parseInt(value)) &&
			typeof availableCount === 'number' &&
			parseInt(value) > availableCount
		) {
			setCount(availableCount);
			addToBasket(availableCount, callback);
		} else if (
			!Number.isNaN(parseInt(value)) &&
			typeof availableCount === 'number' &&
			parseInt(value) < 0
		) {
			setCount(0);
			addToBasket(0, callback);
		} else {
			setCount(parseInt(value));
			addToBasket(parseInt(value), callback);
		}
	};

	const onBlur = (callback?: (result: boolean) => void) => {
		if (
			typeof availableCount === 'number' &&
			availableCount > 0 &&
			count === -1
		) {
			setCount(0);
			addToBasket(0, callback);
		}
	};

	const onIncrement = (callback?: (result: boolean) => void) => {
		if (
			typeof availableCount === 'number' &&
			count < availableCount &&
			!isUpdatingCount
		) {
			setCount((count) => {
				addToBasket(count + 1, callback);
				return count + 1;
			});
		}
	};

	const onDecrement = (callback?: (result: boolean) => void) => {
		if (typeof availableCount === 'number' && count > 0 && !isUpdatingCount) {
			setCount((count) => {
				addToBasket(count - 1, callback);
				return count - 1;
			});
		}
	};

	const onAddToBasket = (callback?: (result: boolean) => void) => {
		setCount(1);
		return addToBasketImmediately(1, callback);
	};

	useEffect(() => {
		if (typeof quantityInBasket === 'number' && count !== quantityInBasket) {
			setCount(quantityInBasket);
		}
	}, [quantityInBasket]);

	return {
		count,
		onIncrement,
		onDecrement,
		onCountChange,
		onBlur,
		onAddToBasket,
		availableCount,
		quantityInBasket,
		bundlePriceWithVat,
	};
};
