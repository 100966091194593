import { VinistoProductDllModelsApiBundleOrderLimitation } from 'vinisto_api_client/src/api-types/product-api';

const getBundleLimitPerOrder = (
	orderLimitation?: VinistoProductDllModelsApiBundleOrderLimitation | null
) => {
	if (!orderLimitation) return null;

	const nowInSeconds = +new Date() / 1000;

	const isCurrentlyLimited = Boolean(
		orderLimitation &&
			orderLimitation?.validFrom &&
			orderLimitation.validFrom < nowInSeconds &&
			orderLimitation?.validTo &&
			orderLimitation.validTo > nowInSeconds
	);

	const bundleLimitPerOrder =
		(isCurrentlyLimited && orderLimitation?.limit) || null;

	return bundleLimitPerOrder;
};

export default getBundleLimitPerOrder;
