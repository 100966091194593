import { useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { omit } from 'lodash-es';
import { VinistoProductDllModelsApiBundleBundlesGetWithoutLimitParameters } from 'vinisto_api_client/src/api-types/product-api';
import BundleService from 'Services/Bundle';
import { BundleSorting } from 'Pages/Category/Components/CategoryBundlesWithFilters/interfaces';
import { FILTER_CODE } from 'Pages/Category/Components/CategoryBundlesWithFilters/constants';
import { LocalizationContext } from 'Services/LocalizationService';

import ProductTag from '@/domain/tag';

interface UseBundleQueriesParams {
	categoryId: string | null;
	tagId: string | null;
	sortParam: BundleSorting | null;
	page: number[];
	limit: number;
	activeSpecificationFilters: Record<string, any>[];
	activeTagFilters: ProductTag[];
	isInStockParamRef: { filterType: number; isInStock: boolean }[];
	isDiscountedParamRef: { filterType: number; isDiscounted: boolean }[];
	localizationContext: {
		activeCurrency: {
			currency: string;
		};
	};
	isEnabled?: boolean;
	isCache?: boolean;
	isInImperialUnits?: boolean;
	isDeleted?: boolean;
	isGift?: boolean;
	isTemporaryUnavailable?: boolean;
}

export const useBundleQueries = ({
	categoryId = null,
	tagId = null,
	sortParam,
	page,
	limit,
	activeSpecificationFilters,
	activeTagFilters,
	isInStockParamRef,
	isDiscountedParamRef,
	localizationContext,
	isCache = true,
	isInImperialUnits = false,
}: UseBundleQueriesParams) => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const queryParams = useMemo(() => {
		const processedParams = {
			hiddenSpecification: false,
			isDeleted: false,
			isEnabled: true,
			isGift: false,
			isTemporaryUnavailable: false,
			isSaleOver: false,
		};

		return {
			...processedParams,
			isCache,
			categoryId,
			tagId,
			sortingColumn: sortParam?.sortingColumn ?? '',
			isSortingDescending: sortParam?.isSortingDescending ?? false,
			page,
			currency: localizationContext.activeCurrency.currency,
			filters: [
				...activeSpecificationFilters.map((filter) =>
					omit(filter, ['specificationName', 'unit', 'imperialUnit'])
				),
				...(activeTagFilters.length
					? [
							{
								filterType: FILTER_CODE.TAG,
								tags: activeTagFilters.map((filter) => filter.id),
							},
					  ]
					: []),
				...(isInStockParamRef
					? [
							{
								filterType: FILTER_CODE.STOCK,
								isInStock: isInStockParamRef === t({ id: 'yes' }),
							},
					  ]
					: []),
				...(isDiscountedParamRef
					? [
							{
								filterType: FILTER_CODE.DISCOUNT,
								isDiscounted: true,
							},
					  ]
					: []),
			],
			limit,
			isInImperialUnits,
		} as VinistoProductDllModelsApiBundleBundlesGetWithoutLimitParameters;
	}, [
		isCache,
		categoryId,
		tagId,
		sortParam?.sortingColumn,
		sortParam?.isSortingDescending,
		page,
		localizationContext.activeCurrency.currency,
		activeSpecificationFilters,
		activeTagFilters,
		isInStockParamRef,
		t,
		isDiscountedParamRef,
		limit,
		isInImperialUnits,
	]);

	const filtersQuery = useQuery(
		['available-filters', { queryParams }],
		() => {
			return BundleService.getAvailableFilters(queryParams);
		},
		{
			keepPreviousData: true,
		}
	);

	// This seem to return empty array always(?)
	//const bundlesCategoriesQuery = useQuery(
	//	['bundles-categories', queryParams],
	//	() => BundleService.getBundlesCategories(queryParams),
	//	{
	//		keepPreviousData: true,
	//	}
	//);

	return {
		filtersQuery,
		// bundlesCategoriesQuery,
		queryParams,
	};
};
