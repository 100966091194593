const LIST_API_ENDPOINT = 'product-api/bundles/GetBundles';
const AUTOCOMPLETE_API_ENDPOINT = 'product-api/bundles/GetAutocompleteNames';
const AUTOCOMPLETE_LIMIT = 5;
const BUNDLE_DETAIL_URI = 'product-api/bundle-detail/carousels';
const BUNDLES_URI = 'product-api/bundles';
const CATEGORIES_URI = 'product-api/categories';
const WAREHOUSE_API_ENDPOINT =
	'warehouse-api/warehouse/bundles/GetWarehouseItemsQuantities';
const SUPPLIER_CAROUSEL_ENDPOINT =
	'product-api/bundle-detail/carousels/get-supplier-bundles';
const SIMILAR_BUNDLES_CAROUSEL_ENDPOINT =
	'product-api/bundle-detail/carousels/GetSimilarBundles';
const CURRENTLY_RECOMMENDED_TAG_ID = '65732a9ffe577d37044f5902';

export {
	LIST_API_ENDPOINT,
	AUTOCOMPLETE_API_ENDPOINT,
	AUTOCOMPLETE_LIMIT,
	BUNDLE_DETAIL_URI,
	BUNDLES_URI,
	CATEGORIES_URI,
	WAREHOUSE_API_ENDPOINT,
	SUPPLIER_CAROUSEL_ENDPOINT,
	SIMILAR_BUNDLES_CAROUSEL_ENDPOINT,
	CURRENTLY_RECOMMENDED_TAG_ID,
};
