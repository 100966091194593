import * as React from 'react';
import { get } from 'lodash-es';
import { Modal } from 'react-bootstrap';
import { LocalizationContext } from 'Services/LocalizationService';

import { getModalComponent, getModalTitleLocalization } from './helpers';
import { ModalContext } from './context';
import ModalCloseIcon from './Components/ModalCloseIcon';
import ModalPreloader from './Components/ModalPreloader';

import './styles.css';

/**
 * @category Component Modal Base
 */
const ModalComponent: React.FC = (): JSX.Element => {
	const modalContext = React.useContext(ModalContext);
	const { handleCloseModal, isOpened, modalConfig } = modalContext;
	const localizationContext = React.useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const {
		closeOnBackdropClick,
		closeButton,
		bodyClassName,
		...modalConfigProps
	} = modalConfig;

	const ModalContentComponent = getModalComponent(modalContext.modalType);

	const isShowPreloader = get(modalContext, 'showModalPreloader', false);
	const showModal = get(modalContext, 'isOpened', false) || isShowPreloader;

	let title = get(modalContext, 'modalData.title', '');
	if (!title) {
		const titleFromConfig = getModalTitleLocalization(modalContext.modalType);
		if (titleFromConfig) title = t({ id: titleFromConfig })?.toString() ?? '';
	}
	const onCloseCallback = modalContext?.modalData?.onCloseCallback;

	const showCloseButton: boolean =
		modalContext?.modalData?.showCloseButton ?? closeButton ?? true;

	const handleOnCloseModal = React.useCallback(() => {
		if (handleCloseModal && isOpened) {
			handleCloseModal();
			if (typeof onCloseCallback === 'function') {
				onCloseCallback();
			}
		}
	}, [handleCloseModal, isOpened, onCloseCallback]);

	const handleOnBackdropClick = () => {
		if (closeOnBackdropClick !== true) return;
		onCloseCallback();
		handleCloseModal();
	};

	return (
		<Modal
			show={showModal}
			onHide={handleOnBackdropClick}
			{...modalConfigProps}
		>
			{isShowPreloader && <ModalPreloader />}
			{get(modalContext, 'isOpened', false) && (
				<Modal.Body className={bodyClassName}>
					{(showCloseButton || !!title) && (
						<>
							<div className="vinisto-popup__heading-wrap">
								{!!title && <h2 className="vinisto-popup__heading">{title}</h2>}
								{showCloseButton && (
									<button
										type="button"
										className="vinisto-popup__close"
										onClick={handleOnCloseModal}
									>
										<ModalCloseIcon />
									</button>
								)}
							</div>
							<br />
						</>
					)}
					{!!ModalContentComponent && <ModalContentComponent />}
				</Modal.Body>
			)}
		</Modal>
	);
};

export default ModalComponent;
