import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';
import getBundleImage, {
	IMAGE_SIZE_THUMB_208x240,
} from 'Helpers/getBundleImage';
import useLocalizedValue from 'Hooks/useLocalizedValue';

import { ProductTitleAndImageProps } from './interfaces';

const ProductTitleAndImage: FC<ProductTitleAndImageProps> = ({
	bundleData: { name: bundleName = [], images = [] },
	isLoading = false,
}) => {
	const getLocalizedValue = useLocalizedValue();

	return (
		<>
			<div className="vinisto-wine__img-wrap">
				{isLoading ? (
					<Skeleton
						className={cx('vinisto-wine__img', {
							'vinisto-wine__img--loading': isLoading,
						})}
						width="75%"
						inline
					/>
				) : (
					<img
						src={getBundleImage(images, IMAGE_SIZE_THUMB_208x240)}
						alt={getLocalizedValue(bundleName) ?? '-'}
						className="vinisto-wine__img"
					/>
				)}
			</div>

			<div className="vinisto-wine__info-wrap">
				<div className="vinisto-wine__name">
					{isLoading ? (
						<Skeleton
							containerClassName="w-100"
							width="100%"
							inline
						/>
					) : (
						getLocalizedValue(bundleName) ?? '-'
					)}
				</div>
			</div>
		</>
	);
};

export default ProductTitleAndImage;
