import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import Skeleton from 'react-loading-skeleton';
import { BlogArticlePreview } from 'Services/ApiService/Cms/Blog/interfaces';
import { DeviceServiceContext } from 'Services/DeviceService';
import Config from 'Config';

import { CARD_TYPE, ICarouselProps } from './interfaces';
import {
	CAROUSEL_MODE_BREAKPOINT,
	CONTAINER_PADDING_X,
	PRIMARY_CARD_ASPECT_RATIO,
} from './constants';
import CarouselArticleCard from './Components/CarouselArticleCard';

const CarouselArticles = (props: ICarouselProps) => {
	const { layoutWidth } = useContext(DeviceServiceContext);
	const isLoading = props.isLoading ?? false;
	const data = useMemo(() => {
		if (!isLoading) return props.data ?? [];

		if (props.cardType === CARD_TYPE.CUSTOM) {
			return new Array(3).fill(() => (
				<Skeleton style={{ aspectRatio: PRIMARY_CARD_ASPECT_RATIO }} />
			));
		}
		return [{}];
	}, [isLoading, props]);

	const isCarouselBreakpoint = layoutWidth > 500;

	// Reference: https://maxmarinich.github.io/react-alice-carousel/#stage-padding-percents
	const section = useRef<HTMLDivElement | null>(null);
	const [padding, setPadding] = useState(0);

	const syncState = () => {
		const { current } = section;
		if (current) {
			setPadding(
				(current.offsetWidth - CAROUSEL_MODE_BREAKPOINT + CONTAINER_PADDING_X) /
					2
			);
		}
	};

	useEffect(syncState, []);

	const currentCarouselConfig =
		Config?.carousels?.[
			props.carouselType as keyof (typeof Config)['carousels']
		] ?? {};

	return (
		<>
			{props.cardType === CARD_TYPE.CUSTOM && (
				<div
					ref={section}
					className="TopBannerCarousel"
				>
					<AliceCarousel
						{...currentCarouselConfig}
						disableDotsControls={false}
						mouseTracking
						controlsStrategy="alternate"
						infinite
						paddingRight={isCarouselBreakpoint ? padding : undefined}
						paddingLeft={isCarouselBreakpoint ? padding : undefined}
						onResized={syncState}
						items={data.map((Content: React.FC, i: number) => (
							<Content key={i} />
						))}
					/>
				</div>
			)}
			{props.cardType === CARD_TYPE.ARTICLE && (
				<AliceCarousel
					{...currentCarouselConfig}
					items={data.map((card: BlogArticlePreview, index: number) => (
						<CarouselArticleCard
							key={index}
							data={card || {}}
							{...{ isLoading }}
						/>
					))}
				/>
			)}
		</>
	);
};

export default CarouselArticles;
