import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import useBundlePrice from 'Hooks/useBundlePrice';
import { apiServiceInstance } from 'Services/ApiService';
import { BasketContext } from 'Services/BasketService';
import { WarehouseContext } from 'Services/WarehouseService';
import getBundleLimitPerOrder from 'Helpers/getBundleLimitPerOrder';

import { ITEM_NOT_AVAILABLE } from './Components/OutOfStockCartItemList/constants';
import {
	BasketOutOfStockItem,
	GetSimilarBundlesResponse,
	OutOfStockBundle,
} from './Components/OutOfStockCartItemList/interfaces';

export const useOosItems = (basketItems: OutOfStockBundle[]) => {
	const basketContext = useContext(BasketContext);
	const warehouseContext = useContext(WarehouseContext);

	const getBundlePrice = useBundlePrice();

	const handleOnChangeItemQuantity = basketContext.handleOnChangeItemQuantity;

	const [basketItemsOutOfStock, setBasketItemsOutOfStock] = useState<
		Map<string, BasketOutOfStockItem>
	>(new Map());

	useEffect(() => {
		if (!Array.isArray(basketItems) || basketContext.isUpdatingCount) return;
		const newItemsOos: typeof basketItemsOutOfStock = new Map();

		basketItems.forEach((bundle) => {
			const availableQuantity = warehouseContext.getQuantity(bundle.bundleId);
			const isTemporaryUnavailable =
				bundle?.bundle?.temporaryUnavailable ?? false;
			const isDisabled = !bundle?.bundle?.isEnabled ?? false;

			const bundleLimitPerOrder = getBundleLimitPerOrder(
				bundle?.bundle?.orderLimitation
			);

			if (
				(availableQuantity === undefined ||
					availableQuantity >= bundle.quantity) &&
				(!bundleLimitPerOrder || bundleLimitPerOrder >= bundle.quantity) &&
				!isTemporaryUnavailable &&
				!isDisabled
			) {
				return;
			} else if (bundle.temp) {
				return;
			}

			const savedOosItem = basketItemsOutOfStock.get(bundle.bundleId);

			if (savedOosItem === undefined) {
				newItemsOos.set(bundle.bundleId, {
					cartItemType: (() => {
						if (isTemporaryUnavailable)
							return ITEM_NOT_AVAILABLE.TEMPORARY_UNAVAILABLE;
						if (bundleLimitPerOrder)
							return ITEM_NOT_AVAILABLE.ORDER_LIMIT_EXCEEDED;
						if (availableQuantity && availableQuantity > 0)
							return ITEM_NOT_AVAILABLE.QUANTITY_LESS_THAN_SELECTED;
						if (isDisabled) return ITEM_NOT_AVAILABLE.IS_DISABLED;
						return ITEM_NOT_AVAILABLE.OUT_OF_STOCK;
					})(),
					bundleData: bundle,
					isTemporaryUnavailable,
					isDisabled,
					bundleLimitPerOrder,
					availableWarehouseQty: availableQuantity || 0,
					similarBundlesCarousel: {
						loading: true,
						bundles: [],
					},
				});
			}

			if (
				(availableQuantity === 0 || isTemporaryUnavailable) &&
				(savedOosItem === undefined ||
					savedOosItem.similarBundlesCarousel.loading === true)
			) {
				let result: GetSimilarBundlesResponse | null = null;
				apiServiceInstance
					.get(
						'product-api/bundle-detail/carousels/GetSimilarBundles',
						true,
						undefined,
						[
							{
								key: 'BundleId',
								value: bundle.bundleId ?? null,
							},
							{
								key: 'IsCache',
								value: true,
							},
						]
					)
					.then((payload) => {
						const bundles = (payload as GetSimilarBundlesResponse).bundles
							?.map((bundle) => bundle.id)
							.filter(Boolean);
						if (bundles) {
							warehouseContext.fetchQuantity(bundles as string[]);
						}
						result = payload as GetSimilarBundlesResponse;
					})
					.catch(() => {
						result = null;
					})
					.finally(() => {
						let similarBundlesMerged = [];
						if (
							result?.bundles?.length &&
							bundle.bundle?.alternativeBundleObjects?.length
						) {
							similarBundlesMerged = [
								...bundle.bundle.alternativeBundleObjects,
								...result.bundles,
							];
						} else {
							similarBundlesMerged =
								result?.bundles ?? bundle.bundle.alternativeBundleObjects;
						}

						setBasketItemsOutOfStock((prev) => {
							const bundleToUpdate = prev.get(bundle.bundleId);
							if (bundleToUpdate === undefined) return prev;
							prev.set(bundle.bundleId, {
								...bundleToUpdate,
								similarBundlesCarousel: {
									loading: false,
									bundles: similarBundlesMerged ?? [],
								},
							});
							return new Map(prev);
						});
					});
			}
		});

		setBasketItemsOutOfStock((prev) => {
			const itemsOosPresentInBasket: [string, BasketOutOfStockItem][] = [];
			// keep only OOS items still present in basket and were not removed
			prev.forEach((itemOos) => {
				if (
					basketItems.find(
						(bundle) => bundle.bundleId === itemOos.bundleData.bundleId
					) &&
					!itemOos.isBeingRemoved
				) {
					itemsOosPresentInBasket.push([itemOos.bundleData.bundleId, itemOos]);
				}
			});
			return new Map([...itemsOosPresentInBasket, ...Array.from(newItemsOos)]);
		});
	}, [basketItems, basketContext, warehouseContext]);

	const handleRemoveOosItemFromBasket = useCallback(
		(bundleId: string) => () => {
			const bundleToRemove = basketItemsOutOfStock.get(bundleId);
			if (bundleToRemove === undefined) return;
			/* if we simply remove bundle from OOS list, it will appear in general list of basket
				items until response from API is received */
			setBasketItemsOutOfStock((prev) => {
				prev.set(bundleId, {
					...bundleToRemove,
					isBeingRemoved: true,
				});
				return new Map(prev);
			});
			handleOnChangeItemQuantity(
				0,
				bundleId,
				bundleToRemove.availableWarehouseQty !== 0
			);
		},
		[basketItemsOutOfStock, handleOnChangeItemQuantity]
	);

	const totalPriceBasketOos = useMemo(() => {
		const sum = { sumWithVat: 0, sumWithoutVat: 0 };
		basketItemsOutOfStock.forEach((cartItem) => {
			const quantity =
				cartItem.cartItemType === ITEM_NOT_AVAILABLE.ORDER_LIMIT_EXCEEDED
					? cartItem.bundleData.quantity - Number(cartItem.bundleLimitPerOrder)
					: cartItem.bundleData.quantity;

			const { price, priceWithVat } = getBundlePrice(
				cartItem.bundleData.bundle
			);
			const totalPriceWithVAT = Number(
				createCurrencyValue(priceWithVat, quantity)
			);
			const totalPriceWithoutVAT = Number(createCurrencyValue(price, quantity));
			sum.sumWithVat = sum.sumWithVat + totalPriceWithVAT;
			sum.sumWithoutVat = sum.sumWithoutVat + totalPriceWithoutVAT;
		});
		return sum;
	}, [basketItemsOutOfStock, getBundlePrice]);

	const totalStandardPriceBasketOos = useMemo(() => {
		const sum = { sumWithVat: 0, sumWithoutVat: 0 };
		basketItemsOutOfStock.forEach((cartItem) => {
			let price = 0;
			let priceWithVat = 0;
			cartItem.bundleData.bundle?.prices.forEach((itemPrice) => {
				if (itemPrice.type === 'STANDARD') {
					price = itemPrice?.value || 0;
					priceWithVat = itemPrice?.valueWithVat || 0;
				}
			});
			const quantity =
				cartItem.cartItemType === ITEM_NOT_AVAILABLE.ORDER_LIMIT_EXCEEDED
					? cartItem.bundleData.quantity - Number(cartItem.bundleLimitPerOrder)
					: cartItem.bundleData.quantity;

			const totalPriceWithVAT = Number(
				createCurrencyValue(priceWithVat, quantity)
			);
			const totalPriceWithoutVAT = Number(createCurrencyValue(price, quantity));
			sum.sumWithVat = sum.sumWithVat + totalPriceWithVAT;
			sum.sumWithoutVat = sum.sumWithoutVat + totalPriceWithoutVAT;
		});
		return sum;
	}, [basketItemsOutOfStock]);

	return {
		basketItemsOutOfStock,
		setBasketItemsOutOfStock,
		totalPriceBasketOos,
		totalStandardPriceBasketOos,
		handleRemoveOosItemFromBasket,
	};
};
