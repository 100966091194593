import { lazy, Suspense, useCallback, useContext } from 'react';
import { uniqueId } from 'lodash-es';
import cx from 'classnames';
import { BasketContext } from 'Services/BasketService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import Loader from 'Components/View/Loader';
const CloseIcon = lazy(() => import('Components/Icons/Close'));
const CloseSmallIcon = lazy(() => import('Components/Icons/CloseSmall'));
const ErrorIcon = lazy(() => import('Components/Icons/Warning'));
import createCurrencyValue from 'Helpers/createCurrencyValue';
import CouponNotAppliedWarning from 'Components/DiscountCoupon/Components/CouponNotAppliedWarning';
import { VinistoBasketDllModelsApiBasketBasketDiscountCoupon } from 'vinisto_api_client/src/api-types/basket-api';

const CouponItem = ({
	id,
	code,
	discountAmountWithVat,
	isCouponApplied = true,
	limitationDefinition,
	notAppliedReason,
	minimalBasketAllowedPrice,
	isForDiscountedItems,
	notForDiscountedItemsMessage = null,
}: VinistoBasketDllModelsApiBasketBasketDiscountCoupon) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const { handleOnRemoveCoupon } = useContext(BasketContext);
	const deviceServiceContext = useContext(DeviceServiceContext);
	const discountAmountWithVatCurrency = createCurrencyValue(
		discountAmountWithVat ?? 0,
		undefined,
		2
	);
	const discountCouponCode = code;
	const priceCurrency = localizationContext?.activeCurrency?.title ?? '-';
	const isMobile = Boolean(deviceServiceContext.isMobile);

	const handleRemoveCoupon = useCallback(
		(id: string) => (event: React.MouseEvent<HTMLElement>) => {
			if (event) {
				event.stopPropagation();
				event.preventDefault();
			}
			handleOnRemoveCoupon(id);
		},
		[handleOnRemoveCoupon]
	);

	return (
		<div
			className={cx(
				'vinisto-user-orders__orders__order-body__item vinisto-cart__item vinisto-cart__item--coupon',
				{ ['errorCoupon']: !isCouponApplied && !isMobile }
			)}
		>
			<div
				className={cx(
					'vinisto-user-orders__orders__order-body__item__info fw-normal vinisto-coupont-code vinisto-coupon-item',
					{ ['errorCoupon']: !isCouponApplied && isMobile }
				)}
			>
				{!isCouponApplied && (
					<div>
						<Suspense fallback={<Loader blank />}>
							<ErrorIcon
								id={uniqueId()}
								alt={t({ id: 'alt.warning' })}
								title={``}
								className={`ErrorIcon`}
							/>
						</Suspense>
					</div>
				)}
				<div className="discountCouponCodeCode">
					<div className="discountCouponCodeCodeItem">
						{!isMobile && <div>{t({ id: 'basket.discountCoupon.label' })}</div>}
						<div>{`"${discountCouponCode}"`}</div>
						{!isForDiscountedItems && isCouponApplied && (
							<em>
								{' '}
								{t({
									id: 'notification.message.discountCoupon.discountNotForDiscountedItems.info',
								})}
							</em>
						)}
						{isMobile && (
							<div className="vinisto-cart__item__price-remove vinisto-coupon__item__price-remove">
								<div className="vinisto-user-orders__orders__order-body__item__prices vinisto-font-18 vinisto-coupon__item-prices">
									<div className="vinisto-user-orders__orders__order-body__item__prices__total">
										{t(
											{ id: 'basket.discountCoupon.youWillSave' },
											{
												priceWithCurrency: (
													<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
														{`${discountAmountWithVatCurrency} ${priceCurrency}`}
													</span>
												),
											}
										)}
									</div>
								</div>

								<div
									className="vinisto-user-favorite__close"
									onClick={handleRemoveCoupon(id)}
									role="button"
									tabIndex={0}
								>
									{isMobile ? (
										<Suspense fallback={<Loader blank />}>
											<CloseSmallIcon
												id={uniqueId()}
												alt={t({ id: 'alt.delete' })}
												title={``}
												className={`CloseSmallIcon`}
											/>
										</Suspense>
									) : (
										<Suspense fallback={<Loader blank />}>
											<CloseIcon
												id={uniqueId()}
												alt={t({ id: 'alt.delete' })}
												title={``}
												className={`CloseIcon `}
											/>
										</Suspense>
									)}
								</div>
								<div className="discountCouponHeading">
									{t({ id: 'basket.discountCoupon.label' })}
								</div>
							</div>
						)}
					</div>
					{!isCouponApplied && (
						<CouponNotAppliedWarning
							coupon={{
								id,
								code: discountCouponCode,
								discountAmountWithVat,
								isCouponApplied,
								limitationDefinition,
								notAppliedReason,
								notForDiscountedItemsMessage,
								minimalBasketAllowedPrice,
							}}
						/>
					)}
				</div>
			</div>

			{!isMobile && (
				<div className="vinisto-cart__item__price-remove vinisto-coupon__item__price-remove">
					<div className="vinisto-user-orders__orders__order-body__item__prices vinisto-font-18 vinisto-coupon__item-prices">
						<div className="vinisto-user-orders__orders__order-body__item__prices__total">
							{t(
								{ id: 'basket.discountCoupon.youWillSave' },
								{
									priceWithCurrency: (
										<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
											{`${discountAmountWithVatCurrency} ${priceCurrency}`}
										</span>
									),
								}
							)}
						</div>
					</div>

					<div
						className="vinisto-user-favorite__close"
						onClick={handleRemoveCoupon(id)}
						role="button"
						tabIndex={0}
					>
						{isMobile ? (
							<Suspense fallback={<Loader blank />}>
								<CloseSmallIcon
									id={uniqueId()}
									alt={t({ id: 'alt.delete' })}
									title={``}
									className={`CloseSmallIcon`}
								/>
							</Suspense>
						) : (
							<Suspense fallback={<Loader blank />}>
								<CloseIcon
									id={uniqueId()}
									alt={t({ id: 'alt.delete' })}
									title={``}
									className={`CloseIcon `}
								/>
							</Suspense>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default CouponItem;
